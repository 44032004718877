@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");


:root {
  --blue: #4743db;
  --indigo: #5877be;
  --purple: #6f42c1;
  --pink: #fc4980;
  --red: #f71e3f;
  --orange: #fa5f1c;
  --yellow: #ffb300;
  --green: #8B3300;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #8B3300;
  --secondary: #4743db;
  --success: #8B3300;
  --info: #17a2b8;
  --warning: #ffb300;
  --danger: #f71e3f;
  --light: #f8f9fa;
  --dark: #343a40;
  --white: #fff;
  --green: #8B3300;
  --blue: #4743db;
  --eastern: #2397af;
  --denim: #1a5aeb;
  --dodger: #336ef7;
  --dodger-2: #4D83FF;
  --spray: #65efe3;
  --turquoise: #50D7E5;
  --regent: #b0d5e8;
  --coral: #fa8557;
  --orange: #fa5f1c;
  --orange-2: #fa6032;
  --red: #f71e3f;
  --red-2: #FC3F3F;
  --yellow: #ffb300;
  --yellow-2: #facf32;
  --casablanca: #f8c546;
  --indigo: #5877be;
  --shamrock: #25ce95;
  --black: #000;
  --black-2: #2b3940;
  --gray: #6b6e6f;
  --smoke: #7e8989;
  --pink: #fc4980;
  --violet: #9c5fff;
  --mercury: #e5e5e5;
  --ebony-clay: #1d292e;
  --hit-gray: #adb4b7;
  --gallery: #f0f0f0;
  --squeeze: #EDF8F5;
  --helio: #9C5FFF;
  --athens: #F4F5F8;
  --concrete: #F2F2F2;
  --allports: #0070ac;
  --marino: #4064AD;
  --poppy: #BE392B;
  --polar: #F3F9FC;
  --egg-blue: #02BFD5;
  --conch: #D2DCD6;
  --breakpoint-xxs: 0px;
  --breakpoint-xs: 480px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1366px;
  --breakpoint-xxxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.center-sign-in-loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}


.notification-wrapper {
  position: fixed;
  top: 14px;
  right: 10px;
  width: 300px;
}

.notification-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification-item.exit {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
}

.notification-item .bar {
  height: 10px;
}

.notification-item.success .bar {
  background-color: #65d266;
}

.notification-item.error .bar {
  background-color: red;
}

.center-loader {
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  background-color: transparent;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-texts);
  text-align: left;
  background-color: var(--bg);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #8B3300;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #994000;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.9375rem;
}

button {
  border-radius: 0;
}

.trial_btn{
  background: #1E1E2D;
    border-radius: 50px;
    color: #F8F9FF;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none !important;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-headings);
}

h1,
.h1 {
  font-size: 3rem;
}

h3,
.h3 {
  font-size: 2.125rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h2,
.h2 {
  font-size: 2.5rem;
}


h5,
.h5 {
  font-size: 1.3125rem;
}



h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}


.display-2 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}



.display-3 {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}



.display-4 {
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 1.2;
}


hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bg);
  border: 1px solid #dee2e6;
  border-radius: 0.625rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #fc4980;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.3125rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs,
.col-xs-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto,
.col-xxxl-1,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xxxl-fixed {
  position: fixed;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--color-texts);
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid var(--border-color);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--border-color);
}

.table tbody+tbody {
  border-top: 2px solid var(--border-color);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid var(--border-color);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid var(--border-color);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: var(--color-texts);
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #b8e9d8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #7ad6b7;
}

.table-hover .table-primary:hover {
  background-color: #a5e3cd;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #a5e3cd;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #cbcaf5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #9f9dec;
}

.table-hover .table-secondary:hover {
  background-color: #b6b5f1;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #b6b5f1;
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #b8e9d8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #7ad6b7;
}

.table-hover .table-success:hover {
  background-color: #a5e3cd;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #a5e3cd;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeab8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ffd77a;
}

.table-hover .table-warning:hover {
  background-color: #ffe29f;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe29f;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #fdc0c9;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #fb8a9b;
}

.table-hover .table-danger:hover {
  background-color: #fca7b4;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #fca7b4;
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-white,
.table-white>th,
.table-white>td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody+tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover>td,
.table-hover .table-white:hover>th {
  background-color: #f2f2f2;
}

.table-green,
.table-green>th,
.table-green>td {
  background-color: #b8e9d8;
}

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody+tbody {
  border-color: #7ad6b7;
}

.table-hover .table-green:hover {
  background-color: #a5e3cd;
}

.table-hover .table-green:hover>td,
.table-hover .table-green:hover>th {
  background-color: #a5e3cd;
}

.table-blue,
.table-blue>th,
.table-blue>td {
  background-color: #cbcaf5;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody+tbody {
  border-color: #9f9dec;
}

.table-hover .table-blue:hover {
  background-color: #b6b5f1;
}

.table-hover .table-blue:hover>td,
.table-hover .table-blue:hover>th {
  background-color: #b6b5f1;
}

.table-eastern,
.table-eastern>th,
.table-eastern>td {
  background-color: #c1e2e9;
}

.table-eastern th,
.table-eastern td,
.table-eastern thead th,
.table-eastern tbody+tbody {
  border-color: #8dc9d5;
}

.table-hover .table-eastern:hover {
  background-color: #aed9e2;
}

.table-hover .table-eastern:hover>td,
.table-hover .table-eastern:hover>th {
  background-color: #aed9e2;
}

.table-denim,
.table-denim>th,
.table-denim>td {
  background-color: #bfd1f9;
}

.table-denim th,
.table-denim td,
.table-denim thead th,
.table-denim tbody+tbody {
  border-color: #88a9f5;
}

.table-hover .table-denim:hover {
  background-color: #a8c0f7;
}

.table-hover .table-denim:hover>td,
.table-hover .table-denim:hover>th {
  background-color: #a8c0f7;
}

.table-dodger,
.table-dodger>th,
.table-dodger>td {
  background-color: #c6d6fd;
}

.table-dodger th,
.table-dodger td,
.table-dodger thead th,
.table-dodger tbody+tbody {
  border-color: #95b4fb;
}

.table-hover .table-dodger:hover {
  background-color: #adc4fc;
}

.table-hover .table-dodger:hover>td,
.table-hover .table-dodger:hover>th {
  background-color: #adc4fc;
}

.table-dodger-2,
.table-dodger-2>th,
.table-dodger-2>td {
  background-color: #cddcff;
}

.table-dodger-2 th,
.table-dodger-2 td,
.table-dodger-2 thead th,
.table-dodger-2 tbody+tbody {
  border-color: #a2bfff;
}

.table-hover .table-dodger-2:hover {
  background-color: #b4caff;
}

.table-hover .table-dodger-2:hover>td,
.table-hover .table-dodger-2:hover>th {
  background-color: #b4caff;
}

.table-spray,
.table-spray>th,
.table-spray>td {
  background-color: #d4fbf7;
}

.table-spray th,
.table-spray td,
.table-spray thead th,
.table-spray tbody+tbody {
  border-color: #aff7f0;
}

.table-hover .table-spray:hover {
  background-color: #bdf9f3;
}

.table-hover .table-spray:hover>td,
.table-hover .table-spray:hover>th {
  background-color: #bdf9f3;
}

.table-turquoise,
.table-turquoise>th,
.table-turquoise>td {
  background-color: #cef4f8;
}

.table-turquoise th,
.table-turquoise td,
.table-turquoise thead th,
.table-turquoise tbody+tbody {
  border-color: #a4eaf1;
}

.table-hover .table-turquoise:hover {
  background-color: #b8eff5;
}

.table-hover .table-turquoise:hover>td,
.table-hover .table-turquoise:hover>th {
  background-color: #b8eff5;
}

.table-regent,
.table-regent>th,
.table-regent>td {
  background-color: #e9f3f9;
}

.table-regent th,
.table-regent td,
.table-regent thead th,
.table-regent tbody+tbody {
  border-color: #d6e9f3;
}

.table-hover .table-regent:hover {
  background-color: #d5e8f4;
}

.table-hover .table-regent:hover>td,
.table-hover .table-regent:hover>th {
  background-color: #d5e8f4;
}

.table-coral,
.table-coral>th,
.table-coral>td {
  background-color: #feddd0;
}

.table-coral th,
.table-coral td,
.table-coral thead th,
.table-coral tbody+tbody {
  border-color: #fcc0a8;
}

.table-hover .table-coral:hover {
  background-color: #fdcbb7;
}

.table-hover .table-coral:hover>td,
.table-hover .table-coral:hover>th {
  background-color: #fdcbb7;
}

.table-orange,
.table-orange>th,
.table-orange>td {
  background-color: #fed2bf;
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody+tbody {
  border-color: #fcac89;
}

.table-hover .table-orange:hover {
  background-color: #fec0a6;
}

.table-hover .table-orange:hover>td,
.table-hover .table-orange:hover>th {
  background-color: #fec0a6;
}

.table-orange-2,
.table-orange-2>th,
.table-orange-2>td {
  background-color: #fed2c6;
}

.table-orange-2 th,
.table-orange-2 td,
.table-orange-2 thead th,
.table-orange-2 tbody+tbody {
  border-color: #fcac94;
}

.table-hover .table-orange-2:hover {
  background-color: #febead;
}

.table-hover .table-orange-2:hover>td,
.table-hover .table-orange-2:hover>th {
  background-color: #febead;
}

.table-red,
.table-red>th,
.table-red>td {
  background-color: #fdc0c9;
}

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody+tbody {
  border-color: #fb8a9b;
}

.table-hover .table-red:hover {
  background-color: #fca7b4;
}

.table-hover .table-red:hover>td,
.table-hover .table-red:hover>th {
  background-color: #fca7b4;
}

.table-red-2,
.table-red-2>th,
.table-red-2>td {
  background-color: #fec9c9;
}

.table-red-2 th,
.table-red-2 td,
.table-red-2 thead th,
.table-red-2 tbody+tbody {
  border-color: #fd9b9b;
}

.table-hover .table-red-2:hover {
  background-color: #feb0b0;
}

.table-hover .table-red-2:hover>td,
.table-hover .table-red-2:hover>th {
  background-color: #feb0b0;
}

.table-yellow,
.table-yellow>th,
.table-yellow>td {
  background-color: #ffeab8;
}

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody+tbody {
  border-color: #ffd77a;
}

.table-hover .table-yellow:hover {
  background-color: #ffe29f;
}

.table-hover .table-yellow:hover>td,
.table-hover .table-yellow:hover>th {
  background-color: #ffe29f;
}

.table-yellow-2,
.table-yellow-2>th,
.table-yellow-2>td {
  background-color: #fef2c6;
}

.table-yellow-2 th,
.table-yellow-2 td,
.table-yellow-2 thead th,
.table-yellow-2 tbody+tbody {
  border-color: #fce694;
}

.table-hover .table-yellow-2:hover {
  background-color: #feecad;
}

.table-hover .table-yellow-2:hover>td,
.table-hover .table-yellow-2:hover>th {
  background-color: #feecad;
}

.table-casablanca,
.table-casablanca>th,
.table-casablanca>td {
  background-color: #fdefcb;
}

.table-casablanca th,
.table-casablanca td,
.table-casablanca thead th,
.table-casablanca tbody+tbody {
  border-color: #fbe19f;
}

.table-hover .table-casablanca:hover {
  background-color: #fce7b2;
}

.table-hover .table-casablanca:hover>td,
.table-hover .table-casablanca:hover>th {
  background-color: #fce7b2;
}

.table-indigo,
.table-indigo>th,
.table-indigo>td {
  background-color: #d0d9ed;
}

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody+tbody {
  border-color: #a8b8dd;
}

.table-hover .table-indigo:hover {
  background-color: #becae6;
}

.table-hover .table-indigo:hover>td,
.table-hover .table-indigo:hover>th {
  background-color: #becae6;
}

.table-shamrock,
.table-shamrock>th,
.table-shamrock>td {
  background-color: #c2f1e1;
}

.table-shamrock th,
.table-shamrock td,
.table-shamrock thead th,
.table-shamrock tbody+tbody {
  border-color: #8ee6c8;
}

.table-hover .table-shamrock:hover {
  background-color: #adecd7;
}

.table-hover .table-shamrock:hover>td,
.table-hover .table-shamrock:hover>th {
  background-color: #adecd7;
}

.table-black,
.table-black>th,
.table-black>td {
  background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody+tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}

.table-hover .table-black:hover>td,
.table-hover .table-black:hover>th {
  background-color: #ababab;
}

.table-black-2,
.table-black-2>th,
.table-black-2>td {
  background-color: #c4c8ca;
}

.table-black-2 th,
.table-black-2 td,
.table-black-2 thead th,
.table-black-2 tbody+tbody {
  border-color: #91989c;
}

.table-hover .table-black-2:hover {
  background-color: #b7bbbe;
}

.table-hover .table-black-2:hover>td,
.table-hover .table-black-2:hover>th {
  background-color: #b7bbbe;
}

.table-gray,
.table-gray>th,
.table-gray>td {
  background-color: #d6d6d7;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody+tbody {
  border-color: #b2b4b4;
}

.table-hover .table-gray:hover {
  background-color: #c9c9ca;
}

.table-hover .table-gray:hover>td,
.table-hover .table-gray:hover>th {
  background-color: #c9c9ca;
}

.table-smoke,
.table-smoke>th,
.table-smoke>td {
  background-color: #dbdede;
}

.table-smoke th,
.table-smoke td,
.table-smoke thead th,
.table-smoke tbody+tbody {
  border-color: #bcc2c2;
}

.table-hover .table-smoke:hover {
  background-color: #ced2d2;
}

.table-hover .table-smoke:hover>td,
.table-hover .table-smoke:hover>th {
  background-color: #ced2d2;
}

.table-pink,
.table-pink>th,
.table-pink>td {
  background-color: #feccdb;
}

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody+tbody {
  border-color: #fda0bd;
}

.table-hover .table-pink:hover {
  background-color: #feb3c9;
}

.table-hover .table-pink:hover>td,
.table-hover .table-pink:hover>th {
  background-color: #feb3c9;
}

.table-violet,
.table-violet>th,
.table-violet>td {
  background-color: #e3d2ff;
}

.table-violet th,
.table-violet td,
.table-violet thead th,
.table-violet tbody+tbody {
  border-color: #ccacff;
}

.table-hover .table-violet:hover {
  background-color: #d3b9ff;
}

.table-hover .table-violet:hover>td,
.table-hover .table-violet:hover>th {
  background-color: #d3b9ff;
}

.table-mercury,
.table-mercury>th,
.table-mercury>td {
  background-color: #f8f8f8;
}

.table-mercury th,
.table-mercury td,
.table-mercury thead th,
.table-mercury tbody+tbody {
  border-color: #f1f1f1;
}

.table-hover .table-mercury:hover {
  background-color: #ebebeb;
}

.table-hover .table-mercury:hover>td,
.table-hover .table-mercury:hover>th {
  background-color: #ebebeb;
}

.table-ebony-clay,
.table-ebony-clay>th,
.table-ebony-clay>td {
  background-color: #c0c3c4;
}

.table-ebony-clay th,
.table-ebony-clay td,
.table-ebony-clay thead th,
.table-ebony-clay tbody+tbody {
  border-color: #899092;
}

.table-hover .table-ebony-clay:hover {
  background-color: #b3b6b8;
}

.table-hover .table-ebony-clay:hover>td,
.table-hover .table-ebony-clay:hover>th {
  background-color: #b3b6b8;
}

.table-hit-gray,
.table-hit-gray>th,
.table-hit-gray>td {
  background-color: #e8eaeb;
}

.table-hit-gray th,
.table-hit-gray td,
.table-hit-gray thead th,
.table-hit-gray tbody+tbody {
  border-color: #d4d8da;
}

.table-hover .table-hit-gray:hover {
  background-color: #dadedf;
}

.table-hover .table-hit-gray:hover>td,
.table-hover .table-hit-gray:hover>th {
  background-color: #dadedf;
}

.table-gallery,
.table-gallery>th,
.table-gallery>td {
  background-color: #fbfbfb;
}

.table-gallery th,
.table-gallery td,
.table-gallery thead th,
.table-gallery tbody+tbody {
  border-color: #f7f7f7;
}

.table-hover .table-gallery:hover {
  background-color: #eeeeee;
}

.table-hover .table-gallery:hover>td,
.table-hover .table-gallery:hover>th {
  background-color: #eeeeee;
}

.table-squeeze,
.table-squeeze>th,
.table-squeeze>td {
  background-color: #fafdfc;
}

.table-squeeze th,
.table-squeeze td,
.table-squeeze thead th,
.table-squeeze tbody+tbody {
  border-color: #f6fbfa;
}

.table-hover .table-squeeze:hover {
  background-color: #e8f6f1;
}

.table-hover .table-squeeze:hover>td,
.table-hover .table-squeeze:hover>th {
  background-color: #e8f6f1;
}

.table-helio,
.table-helio>th,
.table-helio>td {
  background-color: #e3d2ff;
}

.table-helio th,
.table-helio td,
.table-helio thead th,
.table-helio tbody+tbody {
  border-color: #ccacff;
}

.table-hover .table-helio:hover {
  background-color: #d3b9ff;
}

.table-hover .table-helio:hover>td,
.table-hover .table-helio:hover>th {
  background-color: #d3b9ff;
}

.table-athens,
.table-athens>th,
.table-athens>td {
  background-color: #fcfcfd;
}

.table-athens th,
.table-athens td,
.table-athens thead th,
.table-athens tbody+tbody {
  border-color: #f9fafb;
}

.table-hover .table-athens:hover {
  background-color: #ededf3;
}

.table-hover .table-athens:hover>td,
.table-hover .table-athens:hover>th {
  background-color: #ededf3;
}

.table-concrete,
.table-concrete>th,
.table-concrete>td {
  background-color: #fbfbfb;
}

.table-concrete th,
.table-concrete td,
.table-concrete thead th,
.table-concrete tbody+tbody {
  border-color: #f8f8f8;
}

.table-hover .table-concrete:hover {
  background-color: #eeeeee;
}

.table-hover .table-concrete:hover>td,
.table-hover .table-concrete:hover>th {
  background-color: #eeeeee;
}

.table-allports,
.table-allports>th,
.table-allports>td {
  background-color: #b8d7e8;
}

.table-allports th,
.table-allports td,
.table-allports thead th,
.table-allports tbody+tbody {
  border-color: #7ab5d4;
}

.table-hover .table-allports:hover {
  background-color: #a5cce2;
}

.table-hover .table-allports:hover>td,
.table-hover .table-allports:hover>th {
  background-color: #a5cce2;
}

.table-marino,
.table-marino>th,
.table-marino>td {
  background-color: #cad4e8;
}

.table-marino th,
.table-marino td,
.table-marino thead th,
.table-marino tbody+tbody {
  border-color: #9caed4;
}

.table-hover .table-marino:hover {
  background-color: #b8c6e0;
}

.table-hover .table-marino:hover>td,
.table-hover .table-marino:hover>th {
  background-color: #b8c6e0;
}

.table-poppy,
.table-poppy>th,
.table-poppy>td {
  background-color: #edc8c4;
}

.table-poppy th,
.table-poppy td,
.table-poppy thead th,
.table-poppy tbody+tbody {
  border-color: #dd9891;
}

.table-hover .table-poppy:hover {
  background-color: #e7b6b0;
}

.table-hover .table-poppy:hover>td,
.table-hover .table-poppy:hover>th {
  background-color: #e7b6b0;
}

.table-polar,
.table-polar>th,
.table-polar>td {
  background-color: #fcfdfe;
}

.table-polar th,
.table-polar td,
.table-polar thead th,
.table-polar tbody+tbody {
  border-color: #f9fcfd;
}

.table-hover .table-polar:hover {
  background-color: #e9f0f8;
}

.table-hover .table-polar:hover>td,
.table-hover .table-polar:hover>th {
  background-color: #e9f0f8;
}

.table-egg-blue,
.table-egg-blue>th,
.table-egg-blue>td {
  background-color: #b8edf3;
}

.table-egg-blue th,
.table-egg-blue td,
.table-egg-blue thead th,
.table-egg-blue tbody+tbody {
  border-color: #7bdee9;
}

.table-hover .table-egg-blue:hover {
  background-color: #a2e7ef;
}

.table-hover .table-egg-blue:hover>td,
.table-hover .table-egg-blue:hover>th {
  background-color: #a2e7ef;
}

.table-conch,
.table-conch>th,
.table-conch>td {
  background-color: #f2f5f4;
}

.table-conch th,
.table-conch td,
.table-conch thead th,
.table-conch tbody+tbody {
  border-color: #e8edea;
}

.table-hover .table-conch:hover {
  background-color: #e4eae8;
}

.table-hover .table-conch:hover>td,
.table-hover .table-conch:hover>th {
  background-color: #e4eae8;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: var(--border-color);
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 479.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xs>.table-bordered {
    border: 0;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1365.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xxl>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xxxl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 3.5rem;
  padding: 0.75rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.88;
  color: #6b6e6f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6b6e6f;
}

.form-control:focus {
  color: #6b6e6f;
  background-color: #fff;
  border-color: #8B3300;
  outline: 0;
  box-shadow: 0;
}

.form-control::-moz-placeholder {
  color: #6b6e6f;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6b6e6f;
  opacity: 1;
}

.form-control::placeholder {
  color: #6b6e6f;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #6b6e6f;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.88;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.8125rem;
  line-height: 1.2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.88;
  color: var(--color-texts);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.2;
  border-radius: 0.3125rem;
}

.form-control-lg {
  height: 4.4375rem;
  padding: 0.5rem 1.5625rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.625rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #8B3300;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 176, 116, 0.9);
  border-radius: 0.625rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #8B3300;
  padding-right: calc(1.88em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%238B3300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.47em + 0.375rem) center;
  background-size: calc(0.94em + 0.75rem) calc(0.94em + 0.75rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #8B3300;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.88em + 1.5rem);
  background-position: top calc(0.47em + 0.375rem) right calc(0.47em + 0.375rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #8B3300;
  padding-right: calc(0.75em + 3.0625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.9375rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%238B3300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.9375rem/calc(0.94em + 0.75rem) calc(0.94em + 0.75rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #8B3300;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #8B3300;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #8B3300;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  border-color: #8B3300;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #00e396;
  background-color: #00e396;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #8B3300;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #8B3300;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  border-color: #8B3300;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f71e3f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(247, 30, 63, 0.9);
  border-radius: 0.625rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #f71e3f;
  padding-right: calc(1.88em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f71e3f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f71e3f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.47em + 0.375rem) center;
  background-size: calc(0.94em + 0.75rem) calc(0.94em + 0.75rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #f71e3f;
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.88em + 1.5rem);
  background-position: top calc(0.47em + 0.375rem) right calc(0.47em + 0.375rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f71e3f;
  padding-right: calc(0.75em + 3.0625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.9375rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f71e3f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f71e3f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.9375rem/calc(0.94em + 0.75rem) calc(0.94em + 0.75rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f71e3f;
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #f71e3f;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #f71e3f;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  border-color: #f71e3f;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  border-color: #f94f69;
  background-color: #f94f69;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #f71e3f;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #f71e3f;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  border-color: #f71e3f;
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: var(--color-texts);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5625rem;
  font-size: 1rem;
  line-height: 1.88;
  border-radius: 5px;
  transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--color-texts);
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-primary:hover {
  color: #fff;
  background-color: #994000;
  border-color: #8B3300;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #994000;
  border-color: #8B3300;
  box-shadow: 0 0 0 0.2rem #994102;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #8B3300;
  border-color: #752B00;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #994102;
  ;
}

.btn-secondary {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #2c28d0;
  border-color: #2a26c5;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #2c28d0;
  border-color: #2a26c5;
  box-shadow: 0 0 0 0.2rem rgba(99, 95, 224, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2a26c5;
  border-color: #2824bb;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 95, 224, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-success:hover {
  color: #fff;
  background-color: #994000;
  border-color: #8B3300;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #994000;
  border-color: #8B3300;
  box-shadow: 0 0 0 0.2rem #8B3300;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #8B3300;
  border-color: #00704a;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #8B3300;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-warning:hover {
  color: #212529;
  background-color: #d99800;
  border-color: #cc8f00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #d99800;
  border-color: #cc8f00;
  box-shadow: 0 0 0 0.2rem rgba(222, 158, 6, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc8f00;
  border-color: #bf8600;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 158, 6, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e7082a;
  border-color: #da0828;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #e7082a;
  border-color: #da0828;
  box-shadow: 0 0 0 0.2rem rgba(248, 64, 92, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #da0828;
  border-color: #ce0726;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 64, 92, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus,
.btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show>.btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-green {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-green:hover {
  color: #fff;
  background-color: #994000;
  border-color: #8B3300;
}

.btn-green:focus,
.btn-green.focus {
  color: #fff;
  background-color: #994000;
  border-color: #8B3300;
  box-shadow: 0 0 0 0.2rem #8B3300;
}

.btn-green.disabled,
.btn-green:disabled {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show>.btn-green.dropdown-toggle {
  color: #fff;
  background-color: #8B3300;
  border-color: #00704a;
}

.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show>.btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #8B3300;
}

.btn-blue {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-blue:hover {
  color: #fff;
  background-color: #2c28d0;
  border-color: #2a26c5;
}

.btn-blue:focus,
.btn-blue.focus {
  color: #fff;
  background-color: #2c28d0;
  border-color: #2a26c5;
  box-shadow: 0 0 0 0.2rem rgba(99, 95, 224, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show>.btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #2a26c5;
  border-color: #2824bb;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 95, 224, 0.5);
}

.btn-eastern {
  color: #fff;
  background-color: #2397af;
  border-color: #2397af;
}

.btn-eastern:hover {
  color: #fff;
  background-color: #1d7b8f;
  border-color: #1b7285;
}

.btn-eastern:focus,
.btn-eastern.focus {
  color: #fff;
  background-color: #1d7b8f;
  border-color: #1b7285;
  box-shadow: 0 0 0 0.2rem rgba(68, 167, 187, 0.5);
}

.btn-eastern.disabled,
.btn-eastern:disabled {
  color: #fff;
  background-color: #2397af;
  border-color: #2397af;
}

.btn-eastern:not(:disabled):not(.disabled):active,
.btn-eastern:not(:disabled):not(.disabled).active,
.show>.btn-eastern.dropdown-toggle {
  color: #fff;
  background-color: #1b7285;
  border-color: #18697a;
}

.btn-eastern:not(:disabled):not(.disabled):active:focus,
.btn-eastern:not(:disabled):not(.disabled).active:focus,
.show>.btn-eastern.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 167, 187, 0.5);
}

.btn-denim {
  color: #fff;
  background-color: #1a5aeb;
  border-color: #1a5aeb;
}

.btn-denim:hover {
  color: #fff;
  background-color: #124bcd;
  border-color: #1147c1;
}

.btn-denim:focus,
.btn-denim.focus {
  color: #fff;
  background-color: #124bcd;
  border-color: #1147c1;
  box-shadow: 0 0 0 0.2rem rgba(60, 115, 238, 0.5);
}

.btn-denim.disabled,
.btn-denim:disabled {
  color: #fff;
  background-color: #1a5aeb;
  border-color: #1a5aeb;
}

.btn-denim:not(:disabled):not(.disabled):active,
.btn-denim:not(:disabled):not(.disabled).active,
.show>.btn-denim.dropdown-toggle {
  color: #fff;
  background-color: #1147c1;
  border-color: #1043b5;
}

.btn-denim:not(:disabled):not(.disabled):active:focus,
.btn-denim:not(:disabled):not(.disabled).active:focus,
.show>.btn-denim.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 115, 238, 0.5);
}

.btn-dodger {
  color: #fff;
  background-color: #336ef7;
  border-color: #336ef7;
}

.btn-dodger:hover {
  color: #fff;
  background-color: #0e54f6;
  border-color: #094eee;
}

.btn-dodger:focus,
.btn-dodger.focus {
  color: #fff;
  background-color: #0e54f6;
  border-color: #094eee;
  box-shadow: 0 0 0 0.2rem rgba(82, 132, 248, 0.5);
}

.btn-dodger.disabled,
.btn-dodger:disabled {
  color: #fff;
  background-color: #336ef7;
  border-color: #336ef7;
}

.btn-dodger:not(:disabled):not(.disabled):active,
.btn-dodger:not(:disabled):not(.disabled).active,
.show>.btn-dodger.dropdown-toggle {
  color: #fff;
  background-color: #094eee;
  border-color: #094ae1;
}

.btn-dodger:not(:disabled):not(.disabled):active:focus,
.btn-dodger:not(:disabled):not(.disabled).active:focus,
.show>.btn-dodger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 132, 248, 0.5);
}

.btn-dodger-2 {
  color: #fff;
  background-color: #4D83FF;
  border-color: #4D83FF;
}

.btn-dodger-2:hover {
  color: #fff;
  background-color: #2768ff;
  border-color: #1a5fff;
}

.btn-dodger-2:focus,
.btn-dodger-2.focus {
  color: #fff;
  background-color: #2768ff;
  border-color: #1a5fff;
  box-shadow: 0 0 0 0.2rem rgba(104, 150, 255, 0.5);
}

.btn-dodger-2.disabled,
.btn-dodger-2:disabled {
  color: #fff;
  background-color: #4D83FF;
  border-color: #4D83FF;
}

.btn-dodger-2:not(:disabled):not(.disabled):active,
.btn-dodger-2:not(:disabled):not(.disabled).active,
.show>.btn-dodger-2.dropdown-toggle {
  color: #fff;
  background-color: #1a5fff;
  border-color: #0d57ff;
}

.btn-dodger-2:not(:disabled):not(.disabled):active:focus,
.btn-dodger-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-dodger-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 150, 255, 0.5);
}

.btn-spray {
  color: #212529;
  background-color: #65efe3;
  border-color: #65efe3;
}

.btn-spray:hover {
  color: #212529;
  background-color: #42ebdd;
  border-color: #37eadb;
}

.btn-spray:focus,
.btn-spray.focus {
  color: #212529;
  background-color: #42ebdd;
  border-color: #37eadb;
  box-shadow: 0 0 0 0.2rem rgba(91, 209, 199, 0.5);
}

.btn-spray.disabled,
.btn-spray:disabled {
  color: #212529;
  background-color: #65efe3;
  border-color: #65efe3;
}

.btn-spray:not(:disabled):not(.disabled):active,
.btn-spray:not(:disabled):not(.disabled).active,
.show>.btn-spray.dropdown-toggle {
  color: #212529;
  background-color: #37eadb;
  border-color: #2be9d9;
}

.btn-spray:not(:disabled):not(.disabled):active:focus,
.btn-spray:not(:disabled):not(.disabled).active:focus,
.show>.btn-spray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 209, 199, 0.5);
}

.btn-turquoise {
  color: #212529;
  background-color: #50D7E5;
  border-color: #50D7E5;
}

.btn-turquoise:hover {
  color: #212529;
  background-color: #2fcfe0;
  border-color: #24cdde;
}

.btn-turquoise:focus,
.btn-turquoise.focus {
  color: #212529;
  background-color: #2fcfe0;
  border-color: #24cdde;
  box-shadow: 0 0 0 0.2rem rgba(73, 188, 201, 0.5);
}

.btn-turquoise.disabled,
.btn-turquoise:disabled {
  color: #212529;
  background-color: #50D7E5;
  border-color: #50D7E5;
}

.btn-turquoise:not(:disabled):not(.disabled):active,
.btn-turquoise:not(:disabled):not(.disabled).active,
.show>.btn-turquoise.dropdown-toggle {
  color: #212529;
  background-color: #24cdde;
  border-color: #20c4d6;
}

.btn-turquoise:not(:disabled):not(.disabled):active:focus,
.btn-turquoise:not(:disabled):not(.disabled).active:focus,
.show>.btn-turquoise.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 188, 201, 0.5);
}

.btn-regent {
  color: #212529;
  background-color: #b0d5e8;
  border-color: #b0d5e8;
}

.btn-regent:hover {
  color: #212529;
  background-color: #92c5df;
  border-color: #89c0dd;
}

.btn-regent:focus,
.btn-regent.focus {
  color: #212529;
  background-color: #92c5df;
  border-color: #89c0dd;
  box-shadow: 0 0 0 0.2rem rgba(155, 187, 203, 0.5);
}

.btn-regent.disabled,
.btn-regent:disabled {
  color: #212529;
  background-color: #b0d5e8;
  border-color: #b0d5e8;
}

.btn-regent:not(:disabled):not(.disabled):active,
.btn-regent:not(:disabled):not(.disabled).active,
.show>.btn-regent.dropdown-toggle {
  color: #212529;
  background-color: #89c0dd;
  border-color: #7fbbda;
}

.btn-regent:not(:disabled):not(.disabled):active:focus,
.btn-regent:not(:disabled):not(.disabled).active:focus,
.show>.btn-regent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 187, 203, 0.5);
}

.btn-coral {
  color: #212529;
  background-color: #fa8557;
  border-color: #fa8557;
}

.btn-coral:hover {
  color: #fff;
  background-color: #f96a32;
  border-color: #f96125;
}

.btn-coral:focus,
.btn-coral.focus {
  color: #fff;
  background-color: #f96a32;
  border-color: #f96125;
  box-shadow: 0 0 0 0.2rem rgba(217, 119, 80, 0.5);
}

.btn-coral.disabled,
.btn-coral:disabled {
  color: #212529;
  background-color: #fa8557;
  border-color: #fa8557;
}

.btn-coral:not(:disabled):not(.disabled):active,
.btn-coral:not(:disabled):not(.disabled).active,
.show>.btn-coral.dropdown-toggle {
  color: #fff;
  background-color: #f96125;
  border-color: #f85819;
}

.btn-coral:not(:disabled):not(.disabled):active:focus,
.btn-coral:not(:disabled):not(.disabled).active:focus,
.show>.btn-coral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 119, 80, 0.5);
}

.btn-orange {
  color: #fff;
  background-color: #fa5f1c;
  border-color: #fa5f1c;
}

.btn-orange:hover {
  color: #fff;
  background-color: #eb4a05;
  border-color: #de4605;
}

.btn-orange:focus,
.btn-orange.focus {
  color: #fff;
  background-color: #eb4a05;
  border-color: #de4605;
  box-shadow: 0 0 0 0.2rem rgba(251, 119, 62, 0.5);
}

.btn-orange.disabled,
.btn-orange:disabled {
  color: #fff;
  background-color: #fa5f1c;
  border-color: #fa5f1c;
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show>.btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #de4605;
  border-color: #d24205;
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 119, 62, 0.5);
}

.btn-orange-2 {
  color: #fff;
  background-color: #fa6032;
  border-color: #fa6032;
}

.btn-orange-2:hover {
  color: #fff;
  background-color: #f9430d;
  border-color: #f33c06;
}

.btn-orange-2:focus,
.btn-orange-2.focus {
  color: #fff;
  background-color: #f9430d;
  border-color: #f33c06;
  box-shadow: 0 0 0 0.2rem rgba(251, 120, 81, 0.5);
}

.btn-orange-2.disabled,
.btn-orange-2:disabled {
  color: #fff;
  background-color: #fa6032;
  border-color: #fa6032;
}

.btn-orange-2:not(:disabled):not(.disabled):active,
.btn-orange-2:not(:disabled):not(.disabled).active,
.show>.btn-orange-2.dropdown-toggle {
  color: #fff;
  background-color: #f33c06;
  border-color: #e73906;
}

.btn-orange-2:not(:disabled):not(.disabled):active:focus,
.btn-orange-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-orange-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 120, 81, 0.5);
}

.btn-red {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-red:hover {
  color: #fff;
  background-color: #e7082a;
  border-color: #da0828;
}

.btn-red:focus,
.btn-red.focus {
  color: #fff;
  background-color: #e7082a;
  border-color: #da0828;
  box-shadow: 0 0 0 0.2rem rgba(248, 64, 92, 0.5);
}

.btn-red.disabled,
.btn-red:disabled {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-red:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.show>.btn-red.dropdown-toggle {
  color: #fff;
  background-color: #da0828;
  border-color: #ce0726;
}

.btn-red:not(:disabled):not(.disabled):active:focus,
.btn-red:not(:disabled):not(.disabled).active:focus,
.show>.btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 64, 92, 0.5);
}

.btn-red-2 {
  color: #fff;
  background-color: #FC3F3F;
  border-color: #FC3F3F;
}

.btn-red-2:hover {
  color: #fff;
  background-color: #fb1919;
  border-color: #fb0d0d;
}

.btn-red-2:focus,
.btn-red-2.focus {
  color: #fff;
  background-color: #fb1919;
  border-color: #fb0d0d;
  box-shadow: 0 0 0 0.2rem rgba(252, 92, 92, 0.5);
}

.btn-red-2.disabled,
.btn-red-2:disabled {
  color: #fff;
  background-color: #FC3F3F;
  border-color: #FC3F3F;
}

.btn-red-2:not(:disabled):not(.disabled):active,
.btn-red-2:not(:disabled):not(.disabled).active,
.show>.btn-red-2.dropdown-toggle {
  color: #fff;
  background-color: #fb0d0d;
  border-color: #f70404;
}

.btn-red-2:not(:disabled):not(.disabled):active:focus,
.btn-red-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-red-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 92, 92, 0.5);
}

.btn-yellow {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-yellow:hover {
  color: #212529;
  background-color: #d99800;
  border-color: #cc8f00;
}

.btn-yellow:focus,
.btn-yellow.focus {
  color: #212529;
  background-color: #d99800;
  border-color: #cc8f00;
  box-shadow: 0 0 0 0.2rem rgba(222, 158, 6, 0.5);
}

.btn-yellow.disabled,
.btn-yellow:disabled {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show>.btn-yellow.dropdown-toggle {
  color: #fff;
  background-color: #cc8f00;
  border-color: #bf8600;
}

.btn-yellow:not(:disabled):not(.disabled):active:focus,
.btn-yellow:not(:disabled):not(.disabled).active:focus,
.show>.btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 158, 6, 0.5);
}

.btn-yellow-2 {
  color: #212529;
  background-color: #facf32;
  border-color: #facf32;
}

.btn-yellow-2:hover {
  color: #212529;
  background-color: #f9c60d;
  border-color: #f3c006;
}

.btn-yellow-2:focus,
.btn-yellow-2.focus {
  color: #212529;
  background-color: #f9c60d;
  border-color: #f3c006;
  box-shadow: 0 0 0 0.2rem rgba(217, 182, 49, 0.5);
}

.btn-yellow-2.disabled,
.btn-yellow-2:disabled {
  color: #212529;
  background-color: #facf32;
  border-color: #facf32;
}

.btn-yellow-2:not(:disabled):not(.disabled):active,
.btn-yellow-2:not(:disabled):not(.disabled).active,
.show>.btn-yellow-2.dropdown-toggle {
  color: #212529;
  background-color: #f3c006;
  border-color: #e7b606;
}

.btn-yellow-2:not(:disabled):not(.disabled):active:focus,
.btn-yellow-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-yellow-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 182, 49, 0.5);
}

.btn-casablanca {
  color: #212529;
  background-color: #f8c546;
  border-color: #f8c546;
}

.btn-casablanca:hover {
  color: #212529;
  background-color: #f7b921;
  border-color: #f6b615;
}

.btn-casablanca:focus,
.btn-casablanca.focus {
  color: #212529;
  background-color: #f7b921;
  border-color: #f6b615;
  box-shadow: 0 0 0 0.2rem rgba(216, 173, 66, 0.5);
}

.btn-casablanca.disabled,
.btn-casablanca:disabled {
  color: #212529;
  background-color: #f8c546;
  border-color: #f8c546;
}

.btn-casablanca:not(:disabled):not(.disabled):active,
.btn-casablanca:not(:disabled):not(.disabled).active,
.show>.btn-casablanca.dropdown-toggle {
  color: #212529;
  background-color: #f6b615;
  border-color: #f5b109;
}

.btn-casablanca:not(:disabled):not(.disabled):active:focus,
.btn-casablanca:not(:disabled):not(.disabled).active:focus,
.show>.btn-casablanca.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 173, 66, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #5877be;
  border-color: #5877be;
}

.btn-indigo:hover {
  color: #fff;
  background-color: #4363ad;
  border-color: #405ea3;
}

.btn-indigo:focus,
.btn-indigo.focus {
  color: #fff;
  background-color: #4363ad;
  border-color: #405ea3;
  box-shadow: 0 0 0 0.2rem rgba(113, 139, 200, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
  color: #fff;
  background-color: #5877be;
  border-color: #5877be;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show>.btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #405ea3;
  border-color: #3c599a;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 139, 200, 0.5);
}

.btn-shamrock {
  color: #fff;
  background-color: #25ce95;
  border-color: #25ce95;
}

.btn-shamrock:hover {
  color: #fff;
  background-color: #1fae7e;
  border-color: #1da376;
}

.btn-shamrock:focus,
.btn-shamrock.focus {
  color: #fff;
  background-color: #1fae7e;
  border-color: #1da376;
  box-shadow: 0 0 0 0.2rem rgba(70, 213, 165, 0.5);
}

.btn-shamrock.disabled,
.btn-shamrock:disabled {
  color: #fff;
  background-color: #25ce95;
  border-color: #25ce95;
}

.btn-shamrock:not(:disabled):not(.disabled):active,
.btn-shamrock:not(:disabled):not(.disabled).active,
.show>.btn-shamrock.dropdown-toggle {
  color: #fff;
  background-color: #1da376;
  border-color: #1b986e;
}

.btn-shamrock:not(:disabled):not(.disabled):active:focus,
.btn-shamrock:not(:disabled):not(.disabled).active:focus,
.show>.btn-shamrock.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 213, 165, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:focus,
.btn-black.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled,
.btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show>.btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus,
.btn-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black-2 {
  color: #fff;
  background-color: #2b3940;
  border-color: #2b3940;
}

.btn-black-2:hover {
  color: #fff;
  background-color: #1c2529;
  border-color: #171e21;
}

.btn-black-2:focus,
.btn-black-2.focus {
  color: #fff;
  background-color: #1c2529;
  border-color: #171e21;
  box-shadow: 0 0 0 0.2rem rgba(75, 87, 93, 0.5);
}

.btn-black-2.disabled,
.btn-black-2:disabled {
  color: #fff;
  background-color: #2b3940;
  border-color: #2b3940;
}

.btn-black-2:not(:disabled):not(.disabled):active,
.btn-black-2:not(:disabled):not(.disabled).active,
.show>.btn-black-2.dropdown-toggle {
  color: #fff;
  background-color: #171e21;
  border-color: #11171a;
}

.btn-black-2:not(:disabled):not(.disabled):active:focus,
.btn-black-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-black-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(75, 87, 93, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #6b6e6f;
  border-color: #6b6e6f;
}

.btn-gray:hover {
  color: #fff;
  background-color: #585b5c;
  border-color: #525455;
}

.btn-gray:focus,
.btn-gray.focus {
  color: #fff;
  background-color: #585b5c;
  border-color: #525455;
  box-shadow: 0 0 0 0.2rem rgba(129, 132, 133, 0.5);
}

.btn-gray.disabled,
.btn-gray:disabled {
  color: #fff;
  background-color: #6b6e6f;
  border-color: #6b6e6f;
}

.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show>.btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #525455;
  border-color: #4c4e4f;
}

.btn-gray:not(:disabled):not(.disabled):active:focus,
.btn-gray:not(:disabled):not(.disabled).active:focus,
.show>.btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 132, 133, 0.5);
}

.btn-smoke {
  color: #fff;
  background-color: #7e8989;
  border-color: #7e8989;
}

.btn-smoke:hover {
  color: #fff;
  background-color: #6b7575;
  border-color: #656f6f;
}

.btn-smoke:focus,
.btn-smoke.focus {
  color: #fff;
  background-color: #6b7575;
  border-color: #656f6f;
  box-shadow: 0 0 0 0.2rem rgba(145, 155, 155, 0.5);
}

.btn-smoke.disabled,
.btn-smoke:disabled {
  color: #fff;
  background-color: #7e8989;
  border-color: #7e8989;
}

.btn-smoke:not(:disabled):not(.disabled):active,
.btn-smoke:not(:disabled):not(.disabled).active,
.show>.btn-smoke.dropdown-toggle {
  color: #fff;
  background-color: #656f6f;
  border-color: #5f6868;
}

.btn-smoke:not(:disabled):not(.disabled):active:focus,
.btn-smoke:not(:disabled):not(.disabled).active:focus,
.show>.btn-smoke.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 155, 155, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #fc4980;
  border-color: #fc4980;
}

.btn-pink:hover {
  color: #fff;
  background-color: #fb2366;
  border-color: #fb175d;
}

.btn-pink:focus,
.btn-pink.focus {
  color: #fff;
  background-color: #fb2366;
  border-color: #fb175d;
  box-shadow: 0 0 0 0.2rem rgba(252, 100, 147, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #fc4980;
  border-color: #fc4980;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show>.btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #fb175d;
  border-color: #fb0a54;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show>.btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 100, 147, 0.5);
}

.btn-violet {
  color: #fff;
  background-color: #9c5fff;
  border-color: #9c5fff;
}

.btn-violet:hover {
  color: #fff;
  background-color: #8439ff;
  border-color: #7c2cff;
}

.btn-violet:focus,
.btn-violet.focus {
  color: #fff;
  background-color: #8439ff;
  border-color: #7c2cff;
  box-shadow: 0 0 0 0.2rem rgba(171, 119, 255, 0.5);
}

.btn-violet.disabled,
.btn-violet:disabled {
  color: #fff;
  background-color: #9c5fff;
  border-color: #9c5fff;
}

.btn-violet:not(:disabled):not(.disabled):active,
.btn-violet:not(:disabled):not(.disabled).active,
.show>.btn-violet.dropdown-toggle {
  color: #fff;
  background-color: #7c2cff;
  border-color: #751fff;
}

.btn-violet:not(:disabled):not(.disabled):active:focus,
.btn-violet:not(:disabled):not(.disabled).active:focus,
.show>.btn-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 119, 255, 0.5);
}

.btn-mercury {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-mercury:hover {
  color: #212529;
  background-color: #d2d2d2;
  border-color: #cccccc;
}

.btn-mercury:focus,
.btn-mercury.focus {
  color: #212529;
  background-color: #d2d2d2;
  border-color: #cccccc;
  box-shadow: 0 0 0 0.2rem rgba(200, 200, 201, 0.5);
}

.btn-mercury.disabled,
.btn-mercury:disabled {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-mercury:not(:disabled):not(.disabled):active,
.btn-mercury:not(:disabled):not(.disabled).active,
.show>.btn-mercury.dropdown-toggle {
  color: #212529;
  background-color: #cccccc;
  border-color: #c5c5c5;
}

.btn-mercury:not(:disabled):not(.disabled):active:focus,
.btn-mercury:not(:disabled):not(.disabled).active:focus,
.show>.btn-mercury.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 200, 201, 0.5);
}

.btn-ebony-clay {
  color: #fff;
  background-color: #1d292e;
  border-color: #1d292e;
}

.btn-ebony-clay:hover {
  color: #fff;
  background-color: #0e1417;
  border-color: #090d0f;
}

.btn-ebony-clay:focus,
.btn-ebony-clay.focus {
  color: #fff;
  background-color: #0e1417;
  border-color: #090d0f;
  box-shadow: 0 0 0 0.2rem rgba(63, 73, 77, 0.5);
}

.btn-ebony-clay.disabled,
.btn-ebony-clay:disabled {
  color: #fff;
  background-color: #1d292e;
  border-color: #1d292e;
}

.btn-ebony-clay:not(:disabled):not(.disabled):active,
.btn-ebony-clay:not(:disabled):not(.disabled).active,
.show>.btn-ebony-clay.dropdown-toggle {
  color: #fff;
  background-color: #090d0f;
  border-color: #040607;
}

.btn-ebony-clay:not(:disabled):not(.disabled):active:focus,
.btn-ebony-clay:not(:disabled):not(.disabled).active:focus,
.show>.btn-ebony-clay.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 73, 77, 0.5);
}

.btn-hit-gray {
  color: #212529;
  background-color: #adb4b7;
  border-color: #adb4b7;
}

.btn-hit-gray:hover {
  color: #212529;
  background-color: #99a1a5;
  border-color: #929b9f;
}

.btn-hit-gray:focus,
.btn-hit-gray.focus {
  color: #212529;
  background-color: #99a1a5;
  border-color: #929b9f;
  box-shadow: 0 0 0 0.2rem rgba(152, 159, 162, 0.5);
}

.btn-hit-gray.disabled,
.btn-hit-gray:disabled {
  color: #212529;
  background-color: #adb4b7;
  border-color: #adb4b7;
}

.btn-hit-gray:not(:disabled):not(.disabled):active,
.btn-hit-gray:not(:disabled):not(.disabled).active,
.show>.btn-hit-gray.dropdown-toggle {
  color: #212529;
  background-color: #929b9f;
  border-color: #8b9599;
}

.btn-hit-gray:not(:disabled):not(.disabled):active:focus,
.btn-hit-gray:not(:disabled):not(.disabled).active:focus,
.show>.btn-hit-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 159, 162, 0.5);
}

.btn-gallery {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-gallery:hover {
  color: #212529;
  background-color: #dddddd;
  border-color: #d7d7d7;
}

.btn-gallery:focus,
.btn-gallery.focus {
  color: #212529;
  background-color: #dddddd;
  border-color: #d7d7d7;
  box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
}

.btn-gallery.disabled,
.btn-gallery:disabled {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-gallery:not(:disabled):not(.disabled):active,
.btn-gallery:not(:disabled):not(.disabled).active,
.show>.btn-gallery.dropdown-toggle {
  color: #212529;
  background-color: #d7d7d7;
  border-color: #d0d0d0;
}

.btn-gallery:not(:disabled):not(.disabled):active:focus,
.btn-gallery:not(:disabled):not(.disabled).active:focus,
.show>.btn-gallery.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
}

.btn-squeeze {
  color: #212529;
  background-color: #EDF8F5;
  border-color: #EDF8F5;
}

.btn-squeeze:hover {
  color: #212529;
  background-color: #d1ede6;
  border-color: #c8eae1;
}

.btn-squeeze:focus,
.btn-squeeze.focus {
  color: #212529;
  background-color: #d1ede6;
  border-color: #c8eae1;
  box-shadow: 0 0 0 0.2rem rgba(206, 216, 214, 0.5);
}

.btn-squeeze.disabled,
.btn-squeeze:disabled {
  color: #212529;
  background-color: #EDF8F5;
  border-color: #EDF8F5;
}

.btn-squeeze:not(:disabled):not(.disabled):active,
.btn-squeeze:not(:disabled):not(.disabled).active,
.show>.btn-squeeze.dropdown-toggle {
  color: #212529;
  background-color: #c8eae1;
  border-color: #bfe6dc;
}

.btn-squeeze:not(:disabled):not(.disabled):active:focus,
.btn-squeeze:not(:disabled):not(.disabled).active:focus,
.show>.btn-squeeze.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 216, 214, 0.5);
}

.btn-helio {
  color: #fff;
  background-color: #9C5FFF;
  border-color: #9C5FFF;
}

.btn-helio:hover {
  color: #fff;
  background-color: #8439ff;
  border-color: #7c2cff;
}

.btn-helio:focus,
.btn-helio.focus {
  color: #fff;
  background-color: #8439ff;
  border-color: #7c2cff;
  box-shadow: 0 0 0 0.2rem rgba(171, 119, 255, 0.5);
}

.btn-helio.disabled,
.btn-helio:disabled {
  color: #fff;
  background-color: #9C5FFF;
  border-color: #9C5FFF;
}

.btn-helio:not(:disabled):not(.disabled):active,
.btn-helio:not(:disabled):not(.disabled).active,
.show>.btn-helio.dropdown-toggle {
  color: #fff;
  background-color: #7c2cff;
  border-color: #751fff;
}

.btn-helio:not(:disabled):not(.disabled):active:focus,
.btn-helio:not(:disabled):not(.disabled).active:focus,
.show>.btn-helio.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 119, 255, 0.5);
}

.btn-athens {
  color: #212529;
  background-color: #F4F5F8;
  border-color: #F4F5F8;
}

.btn-athens:hover {
  color: #212529;
  background-color: #dde0e9;
  border-color: #d5d9e4;
}

.btn-athens:focus,
.btn-athens.focus {
  color: #212529;
  background-color: #dde0e9;
  border-color: #d5d9e4;
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 217, 0.5);
}

.btn-athens.disabled,
.btn-athens:disabled {
  color: #212529;
  background-color: #F4F5F8;
  border-color: #F4F5F8;
}

.btn-athens:not(:disabled):not(.disabled):active,
.btn-athens:not(:disabled):not(.disabled).active,
.show>.btn-athens.dropdown-toggle {
  color: #212529;
  background-color: #d5d9e4;
  border-color: #cdd2df;
}

.btn-athens:not(:disabled):not(.disabled):active:focus,
.btn-athens:not(:disabled):not(.disabled).active:focus,
.show>.btn-athens.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 217, 0.5);
}

.btn-concrete {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-concrete:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}

.btn-concrete:focus,
.btn-concrete.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-concrete.disabled,
.btn-concrete:disabled {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-concrete:not(:disabled):not(.disabled):active,
.btn-concrete:not(:disabled):not(.disabled).active,
.show>.btn-concrete.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}

.btn-concrete:not(:disabled):not(.disabled):active:focus,
.btn-concrete:not(:disabled):not(.disabled).active:focus,
.show>.btn-concrete.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-allports {
  color: #fff;
  background-color: #0070ac;
  border-color: #0070ac;
}

.btn-allports:hover {
  color: #fff;
  background-color: #005786;
  border-color: #004f79;
}

.btn-allports:focus,
.btn-allports.focus {
  color: #fff;
  background-color: #005786;
  border-color: #004f79;
  box-shadow: 0 0 0 0.2rem rgba(38, 133, 184, 0.5);
}

.btn-allports.disabled,
.btn-allports:disabled {
  color: #fff;
  background-color: #0070ac;
  border-color: #0070ac;
}

.btn-allports:not(:disabled):not(.disabled):active,
.btn-allports:not(:disabled):not(.disabled).active,
.show>.btn-allports.dropdown-toggle {
  color: #fff;
  background-color: #004f79;
  border-color: #00466c;
}

.btn-allports:not(:disabled):not(.disabled):active:focus,
.btn-allports:not(:disabled):not(.disabled).active:focus,
.show>.btn-allports.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 133, 184, 0.5);
}

.btn-marino {
  color: #fff;
  background-color: #4064AD;
  border-color: #4064AD;
}

.btn-marino:hover {
  color: #fff;
  background-color: #365491;
  border-color: #324e88;
}

.btn-marino:focus,
.btn-marino.focus {
  color: #fff;
  background-color: #365491;
  border-color: #324e88;
  box-shadow: 0 0 0 0.2rem rgba(93, 123, 185, 0.5);
}

.btn-marino.disabled,
.btn-marino:disabled {
  color: #fff;
  background-color: #4064AD;
  border-color: #4064AD;
}

.btn-marino:not(:disabled):not(.disabled):active,
.btn-marino:not(:disabled):not(.disabled).active,
.show>.btn-marino.dropdown-toggle {
  color: #fff;
  background-color: #324e88;
  border-color: #2f497e;
}

.btn-marino:not(:disabled):not(.disabled):active:focus,
.btn-marino:not(:disabled):not(.disabled).active:focus,
.show>.btn-marino.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 123, 185, 0.5);
}

.btn-poppy {
  color: #fff;
  background-color: #BE392B;
  border-color: #BE392B;
}

.btn-poppy:hover {
  color: #fff;
  background-color: #9f3024;
  border-color: #942d22;
}

.btn-poppy:focus,
.btn-poppy.focus {
  color: #fff;
  background-color: #9f3024;
  border-color: #942d22;
  box-shadow: 0 0 0 0.2rem rgba(200, 87, 75, 0.5);
}

.btn-poppy.disabled,
.btn-poppy:disabled {
  color: #fff;
  background-color: #BE392B;
  border-color: #BE392B;
}

.btn-poppy:not(:disabled):not(.disabled):active,
.btn-poppy:not(:disabled):not(.disabled).active,
.show>.btn-poppy.dropdown-toggle {
  color: #fff;
  background-color: #942d22;
  border-color: #8a291f;
}

.btn-poppy:not(:disabled):not(.disabled):active:focus,
.btn-poppy:not(:disabled):not(.disabled).active:focus,
.show>.btn-poppy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 87, 75, 0.5);
}

.btn-polar {
  color: #212529;
  background-color: #F3F9FC;
  border-color: #F3F9FC;
}

.btn-polar:hover {
  color: #212529;
  background-color: #d4eaf4;
  border-color: #cae5f2;
}

.btn-polar:focus,
.btn-polar.focus {
  color: #212529;
  background-color: #d4eaf4;
  border-color: #cae5f2;
  box-shadow: 0 0 0 0.2rem rgba(212, 217, 220, 0.5);
}

.btn-polar.disabled,
.btn-polar:disabled {
  color: #212529;
  background-color: #F3F9FC;
  border-color: #F3F9FC;
}

.btn-polar:not(:disabled):not(.disabled):active,
.btn-polar:not(:disabled):not(.disabled).active,
.show>.btn-polar.dropdown-toggle {
  color: #212529;
  background-color: #cae5f2;
  border-color: #c0e0ef;
}

.btn-polar:not(:disabled):not(.disabled):active:focus,
.btn-polar:not(:disabled):not(.disabled).active:focus,
.show>.btn-polar.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 217, 220, 0.5);
}

.btn-egg-blue {
  color: #fff;
  background-color: #02BFD5;
  border-color: #02BFD5;
}

.btn-egg-blue:hover {
  color: #fff;
  background-color: #029daf;
  border-color: #0292a2;
}

.btn-egg-blue:focus,
.btn-egg-blue.focus {
  color: #fff;
  background-color: #029daf;
  border-color: #0292a2;
  box-shadow: 0 0 0 0.2rem rgba(40, 201, 219, 0.5);
}

.btn-egg-blue.disabled,
.btn-egg-blue:disabled {
  color: #fff;
  background-color: #02BFD5;
  border-color: #02BFD5;
}

.btn-egg-blue:not(:disabled):not(.disabled):active,
.btn-egg-blue:not(:disabled):not(.disabled).active,
.show>.btn-egg-blue.dropdown-toggle {
  color: #fff;
  background-color: #0292a2;
  border-color: #018696;
}

.btn-egg-blue:not(:disabled):not(.disabled):active:focus,
.btn-egg-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-egg-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 201, 219, 0.5);
}

.btn-conch {
  color: #212529;
  background-color: #D2DCD6;
  border-color: #D2DCD6;
}

.btn-conch:hover {
  color: #212529;
  background-color: #bccbc2;
  border-color: #b5c6bc;
}

.btn-conch:focus,
.btn-conch.focus {
  color: #212529;
  background-color: #bccbc2;
  border-color: #b5c6bc;
  box-shadow: 0 0 0 0.2rem rgba(183, 193, 188, 0.5);
}

.btn-conch.disabled,
.btn-conch:disabled {
  color: #212529;
  background-color: #D2DCD6;
  border-color: #D2DCD6;
}

.btn-conch:not(:disabled):not(.disabled):active,
.btn-conch:not(:disabled):not(.disabled).active,
.show>.btn-conch.dropdown-toggle {
  color: #212529;
  background-color: #b5c6bc;
  border-color: #aec0b5;
}

.btn-conch:not(:disabled):not(.disabled):active:focus,
.btn-conch:not(:disabled):not(.disabled).active:focus,
.show>.btn-conch.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 193, 188, 0.5);
}

.btn-outline-primary {
  color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #8B3300;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.btn-outline-secondary {
  color: #4743db;
  border-color: #4743db;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 67, 219, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #4743db;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 67, 219, 0.5);
}

.btn-outline-success {
  color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #8B3300;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffb300;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.5);
}

.btn-outline-danger {
  color: #f71e3f;
  border-color: #f71e3f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f71e3f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus,
.btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show>.btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-green {
  color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-green:focus,
.btn-outline-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.btn-outline-green.disabled,
.btn-outline-green:disabled {
  color: #8B3300;
  background-color: transparent;
}

.btn-outline-green:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.show>.btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.btn-outline-green:not(:disabled):not(.disabled):active:focus,
.btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.btn-outline-blue {
  color: #4743db;
  border-color: #4743db;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-outline-blue:focus,
.btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 67, 219, 0.5);
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  color: #4743db;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show>.btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #4743db;
  border-color: #4743db;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 67, 219, 0.5);
}

.btn-outline-eastern {
  color: #2397af;
  border-color: #2397af;
}

.btn-outline-eastern:hover {
  color: #fff;
  background-color: #2397af;
  border-color: #2397af;
}

.btn-outline-eastern:focus,
.btn-outline-eastern.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 151, 175, 0.5);
}

.btn-outline-eastern.disabled,
.btn-outline-eastern:disabled {
  color: #2397af;
  background-color: transparent;
}

.btn-outline-eastern:not(:disabled):not(.disabled):active,
.btn-outline-eastern:not(:disabled):not(.disabled).active,
.show>.btn-outline-eastern.dropdown-toggle {
  color: #fff;
  background-color: #2397af;
  border-color: #2397af;
}

.btn-outline-eastern:not(:disabled):not(.disabled):active:focus,
.btn-outline-eastern:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-eastern.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 151, 175, 0.5);
}

.btn-outline-denim {
  color: #1a5aeb;
  border-color: #1a5aeb;
}

.btn-outline-denim:hover {
  color: #fff;
  background-color: #1a5aeb;
  border-color: #1a5aeb;
}

.btn-outline-denim:focus,
.btn-outline-denim.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 90, 235, 0.5);
}

.btn-outline-denim.disabled,
.btn-outline-denim:disabled {
  color: #1a5aeb;
  background-color: transparent;
}

.btn-outline-denim:not(:disabled):not(.disabled):active,
.btn-outline-denim:not(:disabled):not(.disabled).active,
.show>.btn-outline-denim.dropdown-toggle {
  color: #fff;
  background-color: #1a5aeb;
  border-color: #1a5aeb;
}

.btn-outline-denim:not(:disabled):not(.disabled):active:focus,
.btn-outline-denim:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-denim.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 90, 235, 0.5);
}

.btn-outline-dodger {
  color: #336ef7;
  border-color: #336ef7;
}

.btn-outline-dodger:hover {
  color: #fff;
  background-color: #336ef7;
  border-color: #336ef7;
}

.btn-outline-dodger:focus,
.btn-outline-dodger.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 110, 247, 0.5);
}

.btn-outline-dodger.disabled,
.btn-outline-dodger:disabled {
  color: #336ef7;
  background-color: transparent;
}

.btn-outline-dodger:not(:disabled):not(.disabled):active,
.btn-outline-dodger:not(:disabled):not(.disabled).active,
.show>.btn-outline-dodger.dropdown-toggle {
  color: #fff;
  background-color: #336ef7;
  border-color: #336ef7;
}

.btn-outline-dodger:not(:disabled):not(.disabled):active:focus,
.btn-outline-dodger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dodger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 110, 247, 0.5);
}

.btn-outline-dodger-2 {
  color: #4D83FF;
  border-color: #4D83FF;
}

.btn-outline-dodger-2:hover {
  color: #fff;
  background-color: #4D83FF;
  border-color: #4D83FF;
}

.btn-outline-dodger-2:focus,
.btn-outline-dodger-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 131, 255, 0.5);
}

.btn-outline-dodger-2.disabled,
.btn-outline-dodger-2:disabled {
  color: #4D83FF;
  background-color: transparent;
}

.btn-outline-dodger-2:not(:disabled):not(.disabled):active,
.btn-outline-dodger-2:not(:disabled):not(.disabled).active,
.show>.btn-outline-dodger-2.dropdown-toggle {
  color: #fff;
  background-color: #4D83FF;
  border-color: #4D83FF;
}

.btn-outline-dodger-2:not(:disabled):not(.disabled):active:focus,
.btn-outline-dodger-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dodger-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 131, 255, 0.5);
}

.btn-outline-spray {
  color: #65efe3;
  border-color: #65efe3;
}

.btn-outline-spray:hover {
  color: #212529;
  background-color: #65efe3;
  border-color: #65efe3;
}

.btn-outline-spray:focus,
.btn-outline-spray.focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 239, 227, 0.5);
}

.btn-outline-spray.disabled,
.btn-outline-spray:disabled {
  color: #65efe3;
  background-color: transparent;
}

.btn-outline-spray:not(:disabled):not(.disabled):active,
.btn-outline-spray:not(:disabled):not(.disabled).active,
.show>.btn-outline-spray.dropdown-toggle {
  color: #212529;
  background-color: #65efe3;
  border-color: #65efe3;
}

.btn-outline-spray:not(:disabled):not(.disabled):active:focus,
.btn-outline-spray:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-spray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 239, 227, 0.5);
}

.btn-outline-turquoise {
  color: #50D7E5;
  border-color: #50D7E5;
}

.btn-outline-turquoise:hover {
  color: #212529;
  background-color: #50D7E5;
  border-color: #50D7E5;
}

.btn-outline-turquoise:focus,
.btn-outline-turquoise.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 215, 229, 0.5);
}

.btn-outline-turquoise.disabled,
.btn-outline-turquoise:disabled {
  color: #50D7E5;
  background-color: transparent;
}

.btn-outline-turquoise:not(:disabled):not(.disabled):active,
.btn-outline-turquoise:not(:disabled):not(.disabled).active,
.show>.btn-outline-turquoise.dropdown-toggle {
  color: #212529;
  background-color: #50D7E5;
  border-color: #50D7E5;
}

.btn-outline-turquoise:not(:disabled):not(.disabled):active:focus,
.btn-outline-turquoise:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-turquoise.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 215, 229, 0.5);
}

.btn-outline-regent {
  color: #b0d5e8;
  border-color: #b0d5e8;
}

.btn-outline-regent:hover {
  color: #212529;
  background-color: #b0d5e8;
  border-color: #b0d5e8;
}

.btn-outline-regent:focus,
.btn-outline-regent.focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 213, 232, 0.5);
}

.btn-outline-regent.disabled,
.btn-outline-regent:disabled {
  color: #b0d5e8;
  background-color: transparent;
}

.btn-outline-regent:not(:disabled):not(.disabled):active,
.btn-outline-regent:not(:disabled):not(.disabled).active,
.show>.btn-outline-regent.dropdown-toggle {
  color: #212529;
  background-color: #b0d5e8;
  border-color: #b0d5e8;
}

.btn-outline-regent:not(:disabled):not(.disabled):active:focus,
.btn-outline-regent:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-regent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 213, 232, 0.5);
}

.btn-outline-coral {
  color: #fa8557;
  border-color: #fa8557;
}

.btn-outline-coral:hover {
  color: #212529;
  background-color: #fa8557;
  border-color: #fa8557;
}

.btn-outline-coral:focus,
.btn-outline-coral.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 133, 87, 0.5);
}

.btn-outline-coral.disabled,
.btn-outline-coral:disabled {
  color: #fa8557;
  background-color: transparent;
}

.btn-outline-coral:not(:disabled):not(.disabled):active,
.btn-outline-coral:not(:disabled):not(.disabled).active,
.show>.btn-outline-coral.dropdown-toggle {
  color: #212529;
  background-color: #fa8557;
  border-color: #fa8557;
}

.btn-outline-coral:not(:disabled):not(.disabled):active:focus,
.btn-outline-coral:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-coral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 133, 87, 0.5);
}

.btn-outline-orange {
  color: #fa5f1c;
  border-color: #fa5f1c;
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: #fa5f1c;
  border-color: #fa5f1c;
}

.btn-outline-orange:focus,
.btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 95, 28, 0.5);
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #fa5f1c;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show>.btn-outline-orange.dropdown-toggle {
  color: #fff;
  background-color: #fa5f1c;
  border-color: #fa5f1c;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 95, 28, 0.5);
}

.btn-outline-orange-2 {
  color: #fa6032;
  border-color: #fa6032;
}

.btn-outline-orange-2:hover {
  color: #fff;
  background-color: #fa6032;
  border-color: #fa6032;
}

.btn-outline-orange-2:focus,
.btn-outline-orange-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 96, 50, 0.5);
}

.btn-outline-orange-2.disabled,
.btn-outline-orange-2:disabled {
  color: #fa6032;
  background-color: transparent;
}

.btn-outline-orange-2:not(:disabled):not(.disabled):active,
.btn-outline-orange-2:not(:disabled):not(.disabled).active,
.show>.btn-outline-orange-2.dropdown-toggle {
  color: #fff;
  background-color: #fa6032;
  border-color: #fa6032;
}

.btn-outline-orange-2:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-orange-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 96, 50, 0.5);
}

.btn-outline-red {
  color: #f71e3f;
  border-color: #f71e3f;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-outline-red:focus,
.btn-outline-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.5);
}

.btn-outline-red.disabled,
.btn-outline-red:disabled {
  color: #f71e3f;
  background-color: transparent;
}

.btn-outline-red:not(:disabled):not(.disabled):active,
.btn-outline-red:not(:disabled):not(.disabled).active,
.show>.btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #f71e3f;
  border-color: #f71e3f;
}

.btn-outline-red:not(:disabled):not(.disabled):active:focus,
.btn-outline-red:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.5);
}

.btn-outline-red-2 {
  color: #FC3F3F;
  border-color: #FC3F3F;
}

.btn-outline-red-2:hover {
  color: #fff;
  background-color: #FC3F3F;
  border-color: #FC3F3F;
}

.btn-outline-red-2:focus,
.btn-outline-red-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 63, 63, 0.5);
}

.btn-outline-red-2.disabled,
.btn-outline-red-2:disabled {
  color: #FC3F3F;
  background-color: transparent;
}

.btn-outline-red-2:not(:disabled):not(.disabled):active,
.btn-outline-red-2:not(:disabled):not(.disabled).active,
.show>.btn-outline-red-2.dropdown-toggle {
  color: #fff;
  background-color: #FC3F3F;
  border-color: #FC3F3F;
}

.btn-outline-red-2:not(:disabled):not(.disabled):active:focus,
.btn-outline-red-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-red-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 63, 63, 0.5);
}

.btn-outline-yellow {
  color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-yellow:hover {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-yellow:focus,
.btn-outline-yellow.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.5);
}

.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
  color: #ffb300;
  background-color: transparent;
}

.btn-outline-yellow:not(:disabled):not(.disabled):active,
.btn-outline-yellow:not(:disabled):not(.disabled).active,
.show>.btn-outline-yellow.dropdown-toggle {
  color: #212529;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-yellow:not(:disabled):not(.disabled):active:focus,
.btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.5);
}

.btn-outline-yellow-2 {
  color: #facf32;
  border-color: #facf32;
}

.btn-outline-yellow-2:hover {
  color: #212529;
  background-color: #facf32;
  border-color: #facf32;
}

.btn-outline-yellow-2:focus,
.btn-outline-yellow-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 207, 50, 0.5);
}

.btn-outline-yellow-2.disabled,
.btn-outline-yellow-2:disabled {
  color: #facf32;
  background-color: transparent;
}

.btn-outline-yellow-2:not(:disabled):not(.disabled):active,
.btn-outline-yellow-2:not(:disabled):not(.disabled).active,
.show>.btn-outline-yellow-2.dropdown-toggle {
  color: #212529;
  background-color: #facf32;
  border-color: #facf32;
}

.btn-outline-yellow-2:not(:disabled):not(.disabled):active:focus,
.btn-outline-yellow-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-yellow-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 207, 50, 0.5);
}

.btn-outline-casablanca {
  color: #f8c546;
  border-color: #f8c546;
}

.btn-outline-casablanca:hover {
  color: #212529;
  background-color: #f8c546;
  border-color: #f8c546;
}

.btn-outline-casablanca:focus,
.btn-outline-casablanca.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 197, 70, 0.5);
}

.btn-outline-casablanca.disabled,
.btn-outline-casablanca:disabled {
  color: #f8c546;
  background-color: transparent;
}

.btn-outline-casablanca:not(:disabled):not(.disabled):active,
.btn-outline-casablanca:not(:disabled):not(.disabled).active,
.show>.btn-outline-casablanca.dropdown-toggle {
  color: #212529;
  background-color: #f8c546;
  border-color: #f8c546;
}

.btn-outline-casablanca:not(:disabled):not(.disabled):active:focus,
.btn-outline-casablanca:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-casablanca.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 197, 70, 0.5);
}

.btn-outline-indigo {
  color: #5877be;
  border-color: #5877be;
}

.btn-outline-indigo:hover {
  color: #fff;
  background-color: #5877be;
  border-color: #5877be;
}

.btn-outline-indigo:focus,
.btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 119, 190, 0.5);
}

.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
  color: #5877be;
  background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active,
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.show>.btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #5877be;
  border-color: #5877be;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 119, 190, 0.5);
}

.btn-outline-shamrock {
  color: #25ce95;
  border-color: #25ce95;
}

.btn-outline-shamrock:hover {
  color: #fff;
  background-color: #25ce95;
  border-color: #25ce95;
}

.btn-outline-shamrock:focus,
.btn-outline-shamrock.focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 206, 149, 0.5);
}

.btn-outline-shamrock.disabled,
.btn-outline-shamrock:disabled {
  color: #25ce95;
  background-color: transparent;
}

.btn-outline-shamrock:not(:disabled):not(.disabled):active,
.btn-outline-shamrock:not(:disabled):not(.disabled).active,
.show>.btn-outline-shamrock.dropdown-toggle {
  color: #fff;
  background-color: #25ce95;
  border-color: #25ce95;
}

.btn-outline-shamrock:not(:disabled):not(.disabled):active:focus,
.btn-outline-shamrock:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-shamrock.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 206, 149, 0.5);
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:focus,
.btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show>.btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black-2 {
  color: #2b3940;
  border-color: #2b3940;
}

.btn-outline-black-2:hover {
  color: #fff;
  background-color: #2b3940;
  border-color: #2b3940;
}

.btn-outline-black-2:focus,
.btn-outline-black-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 57, 64, 0.5);
}

.btn-outline-black-2.disabled,
.btn-outline-black-2:disabled {
  color: #2b3940;
  background-color: transparent;
}

.btn-outline-black-2:not(:disabled):not(.disabled):active,
.btn-outline-black-2:not(:disabled):not(.disabled).active,
.show>.btn-outline-black-2.dropdown-toggle {
  color: #fff;
  background-color: #2b3940;
  border-color: #2b3940;
}

.btn-outline-black-2:not(:disabled):not(.disabled):active:focus,
.btn-outline-black-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-black-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 57, 64, 0.5);
}

.btn-outline-gray {
  color: #6b6e6f;
  border-color: #6b6e6f;
}

.btn-outline-gray:hover {
  color: #fff;
  background-color: #6b6e6f;
  border-color: #6b6e6f;
}

.btn-outline-gray:focus,
.btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 110, 111, 0.5);
}

.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #6b6e6f;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled):active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.show>.btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #6b6e6f;
  border-color: #6b6e6f;
}

.btn-outline-gray:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 110, 111, 0.5);
}

.btn-outline-smoke {
  color: #7e8989;
  border-color: #7e8989;
}

.btn-outline-smoke:hover {
  color: #fff;
  background-color: #7e8989;
  border-color: #7e8989;
}

.btn-outline-smoke:focus,
.btn-outline-smoke.focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 137, 137, 0.5);
}

.btn-outline-smoke.disabled,
.btn-outline-smoke:disabled {
  color: #7e8989;
  background-color: transparent;
}

.btn-outline-smoke:not(:disabled):not(.disabled):active,
.btn-outline-smoke:not(:disabled):not(.disabled).active,
.show>.btn-outline-smoke.dropdown-toggle {
  color: #fff;
  background-color: #7e8989;
  border-color: #7e8989;
}

.btn-outline-smoke:not(:disabled):not(.disabled):active:focus,
.btn-outline-smoke:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-smoke.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 137, 137, 0.5);
}

.btn-outline-pink {
  color: #fc4980;
  border-color: #fc4980;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #fc4980;
  border-color: #fc4980;
}

.btn-outline-pink:focus,
.btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 73, 128, 0.5);
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #fc4980;
  background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show>.btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #fc4980;
  border-color: #fc4980;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 73, 128, 0.5);
}

.btn-outline-violet {
  color: #9c5fff;
  border-color: #9c5fff;
}

.btn-outline-violet:hover {
  color: #fff;
  background-color: #9c5fff;
  border-color: #9c5fff;
}

.btn-outline-violet:focus,
.btn-outline-violet.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 95, 255, 0.5);
}

.btn-outline-violet.disabled,
.btn-outline-violet:disabled {
  color: #9c5fff;
  background-color: transparent;
}

.btn-outline-violet:not(:disabled):not(.disabled):active,
.btn-outline-violet:not(:disabled):not(.disabled).active,
.show>.btn-outline-violet.dropdown-toggle {
  color: #fff;
  background-color: #9c5fff;
  border-color: #9c5fff;
}

.btn-outline-violet:not(:disabled):not(.disabled):active:focus,
.btn-outline-violet:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 95, 255, 0.5);
}

.btn-outline-mercury {
  color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-mercury:hover {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-mercury:focus,
.btn-outline-mercury.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.btn-outline-mercury.disabled,
.btn-outline-mercury:disabled {
  color: #e5e5e5;
  background-color: transparent;
}

.btn-outline-mercury:not(:disabled):not(.disabled):active,
.btn-outline-mercury:not(:disabled):not(.disabled).active,
.show>.btn-outline-mercury.dropdown-toggle {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-mercury:not(:disabled):not(.disabled):active:focus,
.btn-outline-mercury:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-mercury.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.btn-outline-ebony-clay {
  color: #1d292e;
  border-color: #1d292e;
}

.btn-outline-ebony-clay:hover {
  color: #fff;
  background-color: #1d292e;
  border-color: #1d292e;
}

.btn-outline-ebony-clay:focus,
.btn-outline-ebony-clay.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 41, 46, 0.5);
}

.btn-outline-ebony-clay.disabled,
.btn-outline-ebony-clay:disabled {
  color: #1d292e;
  background-color: transparent;
}

.btn-outline-ebony-clay:not(:disabled):not(.disabled):active,
.btn-outline-ebony-clay:not(:disabled):not(.disabled).active,
.show>.btn-outline-ebony-clay.dropdown-toggle {
  color: #fff;
  background-color: #1d292e;
  border-color: #1d292e;
}

.btn-outline-ebony-clay:not(:disabled):not(.disabled):active:focus,
.btn-outline-ebony-clay:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-ebony-clay.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 41, 46, 0.5);
}

.btn-outline-hit-gray {
  color: #adb4b7;
  border-color: #adb4b7;
}

.btn-outline-hit-gray:hover {
  color: #212529;
  background-color: #adb4b7;
  border-color: #adb4b7;
}

.btn-outline-hit-gray:focus,
.btn-outline-hit-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 180, 183, 0.5);
}

.btn-outline-hit-gray.disabled,
.btn-outline-hit-gray:disabled {
  color: #adb4b7;
  background-color: transparent;
}

.btn-outline-hit-gray:not(:disabled):not(.disabled):active,
.btn-outline-hit-gray:not(:disabled):not(.disabled).active,
.show>.btn-outline-hit-gray.dropdown-toggle {
  color: #212529;
  background-color: #adb4b7;
  border-color: #adb4b7;
}

.btn-outline-hit-gray:not(:disabled):not(.disabled):active:focus,
.btn-outline-hit-gray:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-hit-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 180, 183, 0.5);
}

.btn-outline-gallery {
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-gallery:hover {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-gallery:focus,
.btn-outline-gallery.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}

.btn-outline-gallery.disabled,
.btn-outline-gallery:disabled {
  color: #f0f0f0;
  background-color: transparent;
}

.btn-outline-gallery:not(:disabled):not(.disabled):active,
.btn-outline-gallery:not(:disabled):not(.disabled).active,
.show>.btn-outline-gallery.dropdown-toggle {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-gallery:not(:disabled):not(.disabled):active:focus,
.btn-outline-gallery:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-gallery.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}

.btn-outline-squeeze {
  color: #EDF8F5;
  border-color: #EDF8F5;
}

.btn-outline-squeeze:hover {
  color: #212529;
  background-color: #EDF8F5;
  border-color: #EDF8F5;
}

.btn-outline-squeeze:focus,
.btn-outline-squeeze.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 248, 245, 0.5);
}

.btn-outline-squeeze.disabled,
.btn-outline-squeeze:disabled {
  color: #EDF8F5;
  background-color: transparent;
}

.btn-outline-squeeze:not(:disabled):not(.disabled):active,
.btn-outline-squeeze:not(:disabled):not(.disabled).active,
.show>.btn-outline-squeeze.dropdown-toggle {
  color: #212529;
  background-color: #EDF8F5;
  border-color: #EDF8F5;
}

.btn-outline-squeeze:not(:disabled):not(.disabled):active:focus,
.btn-outline-squeeze:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-squeeze.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 248, 245, 0.5);
}

.btn-outline-helio {
  color: #9C5FFF;
  border-color: #9C5FFF;
}

.btn-outline-helio:hover {
  color: #fff;
  background-color: #9C5FFF;
  border-color: #9C5FFF;
}

.btn-outline-helio:focus,
.btn-outline-helio.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 95, 255, 0.5);
}

.btn-outline-helio.disabled,
.btn-outline-helio:disabled {
  color: #9C5FFF;
  background-color: transparent;
}

.btn-outline-helio:not(:disabled):not(.disabled):active,
.btn-outline-helio:not(:disabled):not(.disabled).active,
.show>.btn-outline-helio.dropdown-toggle {
  color: #fff;
  background-color: #9C5FFF;
  border-color: #9C5FFF;
}

.btn-outline-helio:not(:disabled):not(.disabled):active:focus,
.btn-outline-helio:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-helio.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 95, 255, 0.5);
}

.btn-outline-athens {
  color: #F4F5F8;
  border-color: #F4F5F8;
}

.btn-outline-athens:hover {
  color: #212529;
  background-color: #F4F5F8;
  border-color: #F4F5F8;
}

.btn-outline-athens:focus,
.btn-outline-athens.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-athens.disabled,
.btn-outline-athens:disabled {
  color: #F4F5F8;
  background-color: transparent;
}

.btn-outline-athens:not(:disabled):not(.disabled):active,
.btn-outline-athens:not(:disabled):not(.disabled).active,
.show>.btn-outline-athens.dropdown-toggle {
  color: #212529;
  background-color: #F4F5F8;
  border-color: #F4F5F8;
}

.btn-outline-athens:not(:disabled):not(.disabled):active:focus,
.btn-outline-athens:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-athens.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-concrete {
  color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-outline-concrete:hover {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-outline-concrete:focus,
.btn-outline-concrete.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-concrete.disabled,
.btn-outline-concrete:disabled {
  color: #F2F2F2;
  background-color: transparent;
}

.btn-outline-concrete:not(:disabled):not(.disabled):active,
.btn-outline-concrete:not(:disabled):not(.disabled).active,
.show>.btn-outline-concrete.dropdown-toggle {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-outline-concrete:not(:disabled):not(.disabled):active:focus,
.btn-outline-concrete:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-concrete.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-allports {
  color: #0070ac;
  border-color: #0070ac;
}

.btn-outline-allports:hover {
  color: #fff;
  background-color: #0070ac;
  border-color: #0070ac;
}

.btn-outline-allports:focus,
.btn-outline-allports.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 112, 172, 0.5);
}

.btn-outline-allports.disabled,
.btn-outline-allports:disabled {
  color: #0070ac;
  background-color: transparent;
}

.btn-outline-allports:not(:disabled):not(.disabled):active,
.btn-outline-allports:not(:disabled):not(.disabled).active,
.show>.btn-outline-allports.dropdown-toggle {
  color: #fff;
  background-color: #0070ac;
  border-color: #0070ac;
}

.btn-outline-allports:not(:disabled):not(.disabled):active:focus,
.btn-outline-allports:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-allports.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 112, 172, 0.5);
}

.btn-outline-marino {
  color: #4064AD;
  border-color: #4064AD;
}

.btn-outline-marino:hover {
  color: #fff;
  background-color: #4064AD;
  border-color: #4064AD;
}

.btn-outline-marino:focus,
.btn-outline-marino.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 100, 173, 0.5);
}

.btn-outline-marino.disabled,
.btn-outline-marino:disabled {
  color: #4064AD;
  background-color: transparent;
}

.btn-outline-marino:not(:disabled):not(.disabled):active,
.btn-outline-marino:not(:disabled):not(.disabled).active,
.show>.btn-outline-marino.dropdown-toggle {
  color: #fff;
  background-color: #4064AD;
  border-color: #4064AD;
}

.btn-outline-marino:not(:disabled):not(.disabled):active:focus,
.btn-outline-marino:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-marino.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 100, 173, 0.5);
}

.btn-outline-poppy {
  color: #BE392B;
  border-color: #BE392B;
}

.btn-outline-poppy:hover {
  color: #fff;
  background-color: #BE392B;
  border-color: #BE392B;
}

.btn-outline-poppy:focus,
.btn-outline-poppy.focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 57, 43, 0.5);
}

.btn-outline-poppy.disabled,
.btn-outline-poppy:disabled {
  color: #BE392B;
  background-color: transparent;
}

.btn-outline-poppy:not(:disabled):not(.disabled):active,
.btn-outline-poppy:not(:disabled):not(.disabled).active,
.show>.btn-outline-poppy.dropdown-toggle {
  color: #fff;
  background-color: #BE392B;
  border-color: #BE392B;
}

.btn-outline-poppy:not(:disabled):not(.disabled):active:focus,
.btn-outline-poppy:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-poppy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 57, 43, 0.5);
}

.btn-outline-polar {
  color: #F3F9FC;
  border-color: #F3F9FC;
}

.btn-outline-polar:hover {
  color: #212529;
  background-color: #F3F9FC;
  border-color: #F3F9FC;
}

.btn-outline-polar:focus,
.btn-outline-polar.focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 249, 252, 0.5);
}

.btn-outline-polar.disabled,
.btn-outline-polar:disabled {
  color: #F3F9FC;
  background-color: transparent;
}

.btn-outline-polar:not(:disabled):not(.disabled):active,
.btn-outline-polar:not(:disabled):not(.disabled).active,
.show>.btn-outline-polar.dropdown-toggle {
  color: #212529;
  background-color: #F3F9FC;
  border-color: #F3F9FC;
}

.btn-outline-polar:not(:disabled):not(.disabled):active:focus,
.btn-outline-polar:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-polar.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 249, 252, 0.5);
}

.btn-outline-egg-blue {
  color: #02BFD5;
  border-color: #02BFD5;
}

.btn-outline-egg-blue:hover {
  color: #fff;
  background-color: #02BFD5;
  border-color: #02BFD5;
}

.btn-outline-egg-blue:focus,
.btn-outline-egg-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 191, 213, 0.5);
}

.btn-outline-egg-blue.disabled,
.btn-outline-egg-blue:disabled {
  color: #02BFD5;
  background-color: transparent;
}

.btn-outline-egg-blue:not(:disabled):not(.disabled):active,
.btn-outline-egg-blue:not(:disabled):not(.disabled).active,
.show>.btn-outline-egg-blue.dropdown-toggle {
  color: #fff;
  background-color: #02BFD5;
  border-color: #02BFD5;
}

.btn-outline-egg-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-egg-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-egg-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 191, 213, 0.5);
}

.btn-outline-conch {
  color: #D2DCD6;
  border-color: #D2DCD6;
}

.btn-outline-conch:hover {
  color: #212529;
  background-color: #D2DCD6;
  border-color: #D2DCD6;
}

.btn-outline-conch:focus,
.btn-outline-conch.focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 220, 214, 0.5);
}

.btn-outline-conch.disabled,
.btn-outline-conch:disabled {
  color: #D2DCD6;
  background-color: transparent;
}

.btn-outline-conch:not(:disabled):not(.disabled):active,
.btn-outline-conch:not(:disabled):not(.disabled).active,
.show>.btn-outline-conch.dropdown-toggle {
  color: #212529;
  background-color: #D2DCD6;
  border-color: #D2DCD6;
}

.btn-outline-conch:not(:disabled):not(.disabled):active:focus,
.btn-outline-conch:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-conch.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 220, 214, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #8B3300;
  text-decoration: none;
}

.btn-link:hover {
  color: #994000;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 12px 16px;
  font-size: 0.8125rem;
  line-height: 1;
  border-radius: 5px;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 12px 16px;
  font-size: 0.8125rem;
  line-height: 1;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: var(--color-texts);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-xs-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xs-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1366px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #8B3300;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.17188rem;
  padding-left: 1.17188rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: flex;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.9375rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.88;
  color: #6b6e6f;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #e5e5e5;
  border-radius: 0.3125rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
  height: 4.4375rem;
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: 0.5rem 1.5625rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.625rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
  height: calc(1.2em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.2;
  border-radius: 0.3125rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.9375rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #8B3300;
  background-color: #8B3300;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #e5e5e5;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.625rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #8B3300;
  background-color: #8B3300;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 176, 116, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0, 176, 116, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 176, 116, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 176, 116, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 1.9375rem 0.75rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.88;
  color: #6b6e6f;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.9375rem center/8px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 0.625rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #e5e5e5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.custom-select:focus::-ms-value {
  color: #6b6e6f;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.9375rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6b6e6f;
}

.custom-select-sm {
  height: calc(1.2em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem;
}

.custom-select-lg {
  height: 4.4375rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5625rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #e5e5e5;
  box-shadow: 0;
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3rem;
  padding: 0.75rem 0.9375rem;
  font-weight: 400;
  line-height: 1.88;
  color: #6b6e6f;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.3125rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.88em + 1.5rem);
  padding: 0.75rem 0.9375rem;
  line-height: 1.88;
  color: #6b6e6f;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px var(--bg), 0;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px var(--bg), 0;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px var(--bg), 0;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #8B3300;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #64ffca;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #8B3300;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #64ffca;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #8B3300;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #64ffca;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: var(--bg);
  border-color: #dee2e6 #dee2e6 var(--bg);
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.625rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #8B3300;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 479.98px) {

  .navbar-expand-xs>.container,
  .navbar-expand-xs>.container-fluid,
  .navbar-expand-xs>.container-sm,
  .navbar-expand-xs>.container-md,
  .navbar-expand-xs>.container-lg,
  .navbar-expand-xs>.container-xl,
  .navbar-expand-xs>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 480px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xs .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xs .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xs .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xs>.container,
  .navbar-expand-xs>.container-fluid,
  .navbar-expand-xs>.container-sm,
  .navbar-expand-xs>.container-md,
  .navbar-expand-xs>.container-lg,
  .navbar-expand-xs>.container-xl,
  .navbar-expand-xs>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xs .navbar-toggler {
    display: none;
  }
}

@media (max-width: 575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl,
  .navbar-expand-sm>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl,
  .navbar-expand-sm>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl,
  .navbar-expand-md>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl,
  .navbar-expand-md>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl,
  .navbar-expand-lg>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl,
  .navbar-expand-lg>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl,
  .navbar-expand-xl>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl,
  .navbar-expand-xl>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1365.98px) {

  .navbar-expand-xxl>.container,
  .navbar-expand-xxl>.container-fluid,
  .navbar-expand-xxl>.container-sm,
  .navbar-expand-xxl>.container-md,
  .navbar-expand-xxl>.container-lg,
  .navbar-expand-xxl>.container-xl,
  .navbar-expand-xxl>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1366px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl>.container,
  .navbar-expand-xxl>.container-fluid,
  .navbar-expand-xxl>.container-sm,
  .navbar-expand-xxl>.container-md,
  .navbar-expand-xxl>.container-lg,
  .navbar-expand-xxl>.container-xl,
  .navbar-expand-xxl>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1599.98px) {

  .navbar-expand-xxxl>.container,
  .navbar-expand-xxxl>.container-fluid,
  .navbar-expand-xxxl>.container-sm,
  .navbar-expand-xxxl>.container-md,
  .navbar-expand-xxxl>.container-lg,
  .navbar-expand-xxxl>.container-xl,
  .navbar-expand-xxxl>.container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxxl>.container,
  .navbar-expand-xxxl>.container-fluid,
  .navbar-expand-xxxl>.container-sm,
  .navbar-expand-xxxl>.container-md,
  .navbar-expand-xxxl>.container-lg,
  .navbar-expand-xxxl>.container-xl,
  .navbar-expand-xxxl>.container-xxl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl,
.navbar-expand>.container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl,
.navbar-expand>.container-xxl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.625rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.625rem - 1px);
  border-bottom-left-radius: calc(0.625rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.625rem - 1px) calc(0.625rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.625rem - 1px) calc(0.625rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.625rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.625rem - 1px);
  border-bottom-left-radius: calc(0.625rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.625rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.625rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6b6e6f;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #994000;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #8B3300;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #8B3300;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #4743db;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #2a26c5;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 67, 219, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #8B3300;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #8B3300;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffb300;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #cc8f00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #f71e3f;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #da0828;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-white {
  color: #212529;
  background-color: #fff;
}

a.badge-white:hover,
a.badge-white:focus {
  color: #212529;
  background-color: #e6e6e6;
}

a.badge-white:focus,
a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #8B3300;
}

a.badge-green:hover,
a.badge-green:focus {
  color: #fff;
  background-color: #8B3300;
}

a.badge-green:focus,
a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 116, 0.5);
}

.badge-blue {
  color: #fff;
  background-color: #4743db;
}

a.badge-blue:hover,
a.badge-blue:focus {
  color: #fff;
  background-color: #2a26c5;
}

a.badge-blue:focus,
a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 67, 219, 0.5);
}

.badge-eastern {
  color: #fff;
  background-color: #2397af;
}

a.badge-eastern:hover,
a.badge-eastern:focus {
  color: #fff;
  background-color: #1b7285;
}

a.badge-eastern:focus,
a.badge-eastern.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 151, 175, 0.5);
}

.badge-denim {
  color: #fff;
  background-color: #1a5aeb;
}

a.badge-denim:hover,
a.badge-denim:focus {
  color: #fff;
  background-color: #1147c1;
}

a.badge-denim:focus,
a.badge-denim.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 90, 235, 0.5);
}

.badge-dodger {
  color: #fff;
  background-color: #336ef7;
}

a.badge-dodger:hover,
a.badge-dodger:focus {
  color: #fff;
  background-color: #094eee;
}

a.badge-dodger:focus,
a.badge-dodger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 110, 247, 0.5);
}

.badge-dodger-2 {
  color: #fff;
  background-color: #4D83FF;
}

a.badge-dodger-2:hover,
a.badge-dodger-2:focus {
  color: #fff;
  background-color: #1a5fff;
}

a.badge-dodger-2:focus,
a.badge-dodger-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 131, 255, 0.5);
}

.badge-spray {
  color: #212529;
  background-color: #65efe3;
}

a.badge-spray:hover,
a.badge-spray:focus {
  color: #212529;
  background-color: #37eadb;
}

a.badge-spray:focus,
a.badge-spray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(101, 239, 227, 0.5);
}

.badge-turquoise {
  color: #212529;
  background-color: #50D7E5;
}

a.badge-turquoise:hover,
a.badge-turquoise:focus {
  color: #212529;
  background-color: #24cdde;
}

a.badge-turquoise:focus,
a.badge-turquoise.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(80, 215, 229, 0.5);
}

.badge-regent {
  color: #212529;
  background-color: #b0d5e8;
}

a.badge-regent:hover,
a.badge-regent:focus {
  color: #212529;
  background-color: #89c0dd;
}

a.badge-regent:focus,
a.badge-regent.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(176, 213, 232, 0.5);
}

.badge-coral {
  color: #212529;
  background-color: #fa8557;
}

a.badge-coral:hover,
a.badge-coral:focus {
  color: #212529;
  background-color: #f96125;
}

a.badge-coral:focus,
a.badge-coral.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 133, 87, 0.5);
}

.badge-orange {
  color: #fff;
  background-color: #fa5f1c;
}

a.badge-orange:hover,
a.badge-orange:focus {
  color: #fff;
  background-color: #de4605;
}

a.badge-orange:focus,
a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 95, 28, 0.5);
}

.badge-orange-2 {
  color: #fff;
  background-color: #fa6032;
}

a.badge-orange-2:hover,
a.badge-orange-2:focus {
  color: #fff;
  background-color: #f33c06;
}

a.badge-orange-2:focus,
a.badge-orange-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 96, 50, 0.5);
}

.badge-red {
  color: #fff;
  background-color: #f71e3f;
}

a.badge-red:hover,
a.badge-red:focus {
  color: #fff;
  background-color: #da0828;
}

a.badge-red:focus,
a.badge-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 30, 63, 0.5);
}

.badge-red-2 {
  color: #fff;
  background-color: #FC3F3F;
}

a.badge-red-2:hover,
a.badge-red-2:focus {
  color: #fff;
  background-color: #fb0d0d;
}

a.badge-red-2:focus,
a.badge-red-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 63, 63, 0.5);
}

.badge-yellow {
  color: #212529;
  background-color: #ffb300;
}

a.badge-yellow:hover,
a.badge-yellow:focus {
  color: #212529;
  background-color: #cc8f00;
}

a.badge-yellow:focus,
a.badge-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.5);
}

.badge-yellow-2 {
  color: #212529;
  background-color: #facf32;
}

a.badge-yellow-2:hover,
a.badge-yellow-2:focus {
  color: #212529;
  background-color: #f3c006;
}

a.badge-yellow-2:focus,
a.badge-yellow-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 207, 50, 0.5);
}

.badge-casablanca {
  color: #212529;
  background-color: #f8c546;
}

a.badge-casablanca:hover,
a.badge-casablanca:focus {
  color: #212529;
  background-color: #f6b615;
}

a.badge-casablanca:focus,
a.badge-casablanca.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 197, 70, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #5877be;
}

a.badge-indigo:hover,
a.badge-indigo:focus {
  color: #fff;
  background-color: #405ea3;
}

a.badge-indigo:focus,
a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(88, 119, 190, 0.5);
}

.badge-shamrock {
  color: #fff;
  background-color: #25ce95;
}

a.badge-shamrock:hover,
a.badge-shamrock:focus {
  color: #fff;
  background-color: #1da376;
}

a.badge-shamrock:focus,
a.badge-shamrock.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(37, 206, 149, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #000;
}

a.badge-black:hover,
a.badge-black:focus {
  color: #fff;
  background-color: black;
}

a.badge-black:focus,
a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-black-2 {
  color: #fff;
  background-color: #2b3940;
}

a.badge-black-2:hover,
a.badge-black-2:focus {
  color: #fff;
  background-color: #171e21;
}

a.badge-black-2:focus,
a.badge-black-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(43, 57, 64, 0.5);
}

.badge-gray {
  color: #fff;
  background-color: #6b6e6f;
}

a.badge-gray:hover,
a.badge-gray:focus {
  color: #fff;
  background-color: #525455;
}

a.badge-gray:focus,
a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(107, 110, 111, 0.5);
}

.badge-smoke {
  color: #fff;
  background-color: #7e8989;
}

a.badge-smoke:hover,
a.badge-smoke:focus {
  color: #fff;
  background-color: #656f6f;
}

a.badge-smoke:focus,
a.badge-smoke.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(126, 137, 137, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #fc4980;
}

a.badge-pink:hover,
a.badge-pink:focus {
  color: #fff;
  background-color: #fb175d;
}

a.badge-pink:focus,
a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 73, 128, 0.5);
}

.badge-violet {
  color: #fff;
  background-color: #9c5fff;
}

a.badge-violet:hover,
a.badge-violet:focus {
  color: #fff;
  background-color: #7c2cff;
}

a.badge-violet:focus,
a.badge-violet.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 95, 255, 0.5);
}

.badge-mercury {
  color: #212529;
  background-color: #e5e5e5;
}

a.badge-mercury:hover,
a.badge-mercury:focus {
  color: #212529;
  background-color: #cccccc;
}

a.badge-mercury:focus,
a.badge-mercury.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.badge-ebony-clay {
  color: #fff;
  background-color: #1d292e;
}

a.badge-ebony-clay:hover,
a.badge-ebony-clay:focus {
  color: #fff;
  background-color: #090d0f;
}

a.badge-ebony-clay:focus,
a.badge-ebony-clay.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 41, 46, 0.5);
}

.badge-hit-gray {
  color: #212529;
  background-color: #adb4b7;
}

a.badge-hit-gray:hover,
a.badge-hit-gray:focus {
  color: #212529;
  background-color: #929b9f;
}

a.badge-hit-gray:focus,
a.badge-hit-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(173, 180, 183, 0.5);
}

.badge-gallery {
  color: #212529;
  background-color: #f0f0f0;
}

a.badge-gallery:hover,
a.badge-gallery:focus {
  color: #212529;
  background-color: #d7d7d7;
}

a.badge-gallery:focus,
a.badge-gallery.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}

.badge-squeeze {
  color: #212529;
  background-color: #EDF8F5;
}

a.badge-squeeze:hover,
a.badge-squeeze:focus {
  color: #212529;
  background-color: #c8eae1;
}

a.badge-squeeze:focus,
a.badge-squeeze.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 248, 245, 0.5);
}

.badge-helio {
  color: #fff;
  background-color: #9C5FFF;
}

a.badge-helio:hover,
a.badge-helio:focus {
  color: #fff;
  background-color: #7c2cff;
}

a.badge-helio:focus,
a.badge-helio.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 95, 255, 0.5);
}

.badge-athens {
  color: #212529;
  background-color: #F4F5F8;
}

a.badge-athens:hover,
a.badge-athens:focus {
  color: #212529;
  background-color: #d5d9e4;
}

a.badge-athens:focus,
a.badge-athens.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.badge-concrete {
  color: #212529;
  background-color: #F2F2F2;
}

a.badge-concrete:hover,
a.badge-concrete:focus {
  color: #212529;
  background-color: #d9d9d9;
}

a.badge-concrete:focus,
a.badge-concrete.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.badge-allports {
  color: #fff;
  background-color: #0070ac;
}

a.badge-allports:hover,
a.badge-allports:focus {
  color: #fff;
  background-color: #004f79;
}

a.badge-allports:focus,
a.badge-allports.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 112, 172, 0.5);
}

.badge-marino {
  color: #fff;
  background-color: #4064AD;
}

a.badge-marino:hover,
a.badge-marino:focus {
  color: #fff;
  background-color: #324e88;
}

a.badge-marino:focus,
a.badge-marino.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(64, 100, 173, 0.5);
}

.badge-poppy {
  color: #fff;
  background-color: #BE392B;
}

a.badge-poppy:hover,
a.badge-poppy:focus {
  color: #fff;
  background-color: #942d22;
}

a.badge-poppy:focus,
a.badge-poppy.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(190, 57, 43, 0.5);
}

.badge-polar {
  color: #212529;
  background-color: #F3F9FC;
}

a.badge-polar:hover,
a.badge-polar:focus {
  color: #212529;
  background-color: #cae5f2;
}

a.badge-polar:focus,
a.badge-polar.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(243, 249, 252, 0.5);
}

.badge-egg-blue {
  color: #fff;
  background-color: #02BFD5;
}

a.badge-egg-blue:hover,
a.badge-egg-blue:focus {
  color: #fff;
  background-color: #0292a2;
}

a.badge-egg-blue:focus,
a.badge-egg-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(2, 191, 213, 0.5);
}

.badge-conch {
  color: #212529;
  background-color: #D2DCD6;
}

a.badge-conch:hover,
a.badge-conch:focus {
  color: #212529;
  background-color: #b5c6bc;
}

a.badge-conch:focus,
a.badge-conch.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(210, 220, 214, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.625rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.625rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #005c3c;
  background-color: #ccefe3;
  border-color: #b8e9d8;
}

.alert-primary hr {
  border-top-color: #a5e3cd;
}

.alert-primary .alert-link {
  color: #00291b;
}

.alert-secondary {
  color: #252372;
  background-color: #dad9f8;
  border-color: #cbcaf5;
}

.alert-secondary hr {
  border-top-color: #b6b5f1;
}

.alert-secondary .alert-link {
  color: #18174b;
}

.alert-success {
  color: #005c3c;
  background-color: #ccefe3;
  border-color: #b8e9d8;
}

.alert-success hr {
  border-top-color: #a5e3cd;
}

.alert-success .alert-link {
  color: #00291b;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #855d00;
  background-color: #fff0cc;
  border-color: #ffeab8;
}

.alert-warning hr {
  border-top-color: #ffe29f;
}

.alert-warning .alert-link {
  color: #523900;
}

.alert-danger {
  color: #801021;
  background-color: #fdd2d9;
  border-color: #fdc0c9;
}

.alert-danger hr {
  border-top-color: #fca7b4;
}

.alert-danger .alert-link {
  color: #530a15;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-green {
  color: #005c3c;
  background-color: #ccefe3;
  border-color: #b8e9d8;
}

.alert-green hr {
  border-top-color: #a5e3cd;
}

.alert-green .alert-link {
  color: #00291b;
}

.alert-blue {
  color: #252372;
  background-color: #dad9f8;
  border-color: #cbcaf5;
}

.alert-blue hr {
  border-top-color: #b6b5f1;
}

.alert-blue .alert-link {
  color: #18174b;
}

.alert-eastern {
  color: #124f5b;
  background-color: #d3eaef;
  border-color: #c1e2e9;
}

.alert-eastern hr {
  border-top-color: #aed9e2;
}

.alert-eastern .alert-link {
  color: #0a2a30;
}

.alert-denim {
  color: #0e2f7a;
  background-color: #d1defb;
  border-color: #bfd1f9;
}

.alert-denim hr {
  border-top-color: #a8c0f7;
}

.alert-denim .alert-link {
  color: #091d4c;
}

.alert-dodger {
  color: #1b3980;
  background-color: #d6e2fd;
  border-color: #c6d6fd;
}

.alert-dodger hr {
  border-top-color: #adc4fc;
}

.alert-dodger .alert-link {
  color: #122656;
}

.alert-dodger-2 {
  color: #284485;
  background-color: #dbe6ff;
  border-color: #cddcff;
}

.alert-dodger-2 hr {
  border-top-color: #b4caff;
}

.alert-dodger-2 .alert-link {
  color: #1c305e;
}

.alert-spray {
  color: #357c76;
  background-color: #e0fcf9;
  border-color: #d4fbf7;
}

.alert-spray hr {
  border-top-color: #bdf9f3;
}

.alert-spray .alert-link {
  color: #265854;
}

.alert-turquoise {
  color: #2a7077;
  background-color: #dcf7fa;
  border-color: #cef4f8;
}

.alert-turquoise hr {
  border-top-color: #b8eff5;
}

.alert-turquoise .alert-link {
  color: #1d4d51;
}

.alert-regent {
  color: #5c6f79;
  background-color: #eff7fa;
  border-color: #e9f3f9;
}

.alert-regent hr {
  border-top-color: #d5e8f4;
}

.alert-regent .alert-link {
  color: #46545c;
}

.alert-coral {
  color: #82452d;
  background-color: #fee7dd;
  border-color: #feddd0;
}

.alert-coral hr {
  border-top-color: #fdcbb7;
}

.alert-coral .alert-link {
  color: #5c3120;
}

.alert-orange {
  color: #82310f;
  background-color: #fedfd2;
  border-color: #fed2bf;
}

.alert-orange hr {
  border-top-color: #fec0a6;
}

.alert-orange .alert-link {
  color: #54200a;
}

.alert-orange-2 {
  color: #82321a;
  background-color: #fedfd6;
  border-color: #fed2c6;
}

.alert-orange-2 hr {
  border-top-color: #febead;
}

.alert-orange-2 .alert-link {
  color: #582212;
}

.alert-red {
  color: #801021;
  background-color: #fdd2d9;
  border-color: #fdc0c9;
}

.alert-red hr {
  border-top-color: #fca7b4;
}

.alert-red .alert-link {
  color: #530a15;
}

.alert-red-2 {
  color: #832121;
  background-color: #fed9d9;
  border-color: #fec9c9;
}

.alert-red-2 hr {
  border-top-color: #feb0b0;
}

.alert-red-2 .alert-link {
  color: #5a1717;
}

.alert-yellow {
  color: #855d00;
  background-color: #fff0cc;
  border-color: #ffeab8;
}

.alert-yellow hr {
  border-top-color: #ffe29f;
}

.alert-yellow .alert-link {
  color: #523900;
}

.alert-yellow-2 {
  color: #826c1a;
  background-color: #fef5d6;
  border-color: #fef2c6;
}

.alert-yellow-2 hr {
  border-top-color: #feecad;
}

.alert-yellow-2 .alert-link {
  color: #584912;
}

.alert-casablanca {
  color: #816624;
  background-color: #fef3da;
  border-color: #fdefcb;
}

.alert-casablanca hr {
  border-top-color: #fce7b2;
}

.alert-casablanca .alert-link {
  color: #594619;
}

.alert-indigo {
  color: #2e3e63;
  background-color: #dee4f2;
  border-color: #d0d9ed;
}

.alert-indigo hr {
  border-top-color: #becae6;
}

.alert-indigo .alert-link {
  color: #1e2840;
}

.alert-shamrock {
  color: #136b4d;
  background-color: #d3f5ea;
  border-color: #c2f1e1;
}

.alert-shamrock hr {
  border-top-color: #adecd7;
}

.alert-shamrock .alert-link {
  color: #0b402e;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-black hr {
  border-top-color: #ababab;
}

.alert-black .alert-link {
  color: black;
}

.alert-black-2 {
  color: #161e21;
  background-color: #d5d7d9;
  border-color: #c4c8ca;
}

.alert-black-2 hr {
  border-top-color: #b7bbbe;
}

.alert-black-2 .alert-link {
  color: #020202;
}

.alert-gray {
  color: #38393a;
  background-color: #e1e2e2;
  border-color: #d6d6d7;
}

.alert-gray hr {
  border-top-color: #c9c9ca;
}

.alert-gray .alert-link {
  color: #1f2020;
}

.alert-smoke {
  color: #424747;
  background-color: #e5e7e7;
  border-color: #dbdede;
}

.alert-smoke hr {
  border-top-color: #ced2d2;
}

.alert-smoke .alert-link {
  color: #292d2d;
}

.alert-pink {
  color: #832643;
  background-color: #fedbe6;
  border-color: #feccdb;
}

.alert-pink hr {
  border-top-color: #feb3c9;
}

.alert-pink .alert-link {
  color: #5b1b2f;
}

.alert-violet {
  color: #513185;
  background-color: #ebdfff;
  border-color: #e3d2ff;
}

.alert-violet hr {
  border-top-color: #d3b9ff;
}

.alert-violet .alert-link {
  color: #3a2360;
}

.alert-mercury {
  color: #777777;
  background-color: #fafafa;
  border-color: #f8f8f8;
}

.alert-mercury hr {
  border-top-color: #ebebeb;
}

.alert-mercury .alert-link {
  color: #5e5e5e;
}

.alert-ebony-clay {
  color: #0f1518;
  background-color: #d2d4d5;
  border-color: #c0c3c4;
}

.alert-ebony-clay hr {
  border-top-color: #b3b6b8;
}

.alert-ebony-clay .alert-link {
  color: black;
}

.alert-hit-gray {
  color: #5a5e5f;
  background-color: #eff0f1;
  border-color: #e8eaeb;
}

.alert-hit-gray hr {
  border-top-color: #dadedf;
}

.alert-hit-gray .alert-link {
  color: #414445;
}

.alert-gallery {
  color: #7d7d7d;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}

.alert-gallery hr {
  border-top-color: #eeeeee;
}

.alert-gallery .alert-link {
  color: #646464;
}

.alert-squeeze {
  color: #7b817f;
  background-color: #fbfefd;
  border-color: #fafdfc;
}

.alert-squeeze hr {
  border-top-color: #e8f6f1;
}

.alert-squeeze .alert-link {
  color: #626765;
}

.alert-helio {
  color: #513185;
  background-color: #ebdfff;
  border-color: #e3d2ff;
}

.alert-helio hr {
  border-top-color: #d3b9ff;
}

.alert-helio .alert-link {
  color: #3a2360;
}

.alert-athens {
  color: #7f7f81;
  background-color: #fdfdfe;
  border-color: #fcfcfd;
}

.alert-athens hr {
  border-top-color: #ededf3;
}

.alert-athens .alert-link {
  color: #666667;
}

.alert-concrete {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}

.alert-concrete hr {
  border-top-color: #eeeeee;
}

.alert-concrete .alert-link {
  color: #656565;
}

.alert-allports {
  color: #003a59;
  background-color: #cce2ee;
  border-color: #b8d7e8;
}

.alert-allports hr {
  border-top-color: #a5cce2;
}

.alert-allports .alert-link {
  color: #001926;
}

.alert-marino {
  color: #21345a;
  background-color: #d9e0ef;
  border-color: #cad4e8;
}

.alert-marino hr {
  border-top-color: #b8c6e0;
}

.alert-marino .alert-link {
  color: #131e35;
}

.alert-poppy {
  color: #631e16;
  background-color: #f2d7d5;
  border-color: #edc8c4;
}

.alert-poppy hr {
  border-top-color: #e7b6b0;
}

.alert-poppy .alert-link {
  color: #39110d;
}

.alert-polar {
  color: #7e8183;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}

.alert-polar hr {
  border-top-color: #e9f0f8;
}

.alert-polar .alert-link {
  color: #656769;
}

.alert-egg-blue {
  color: #01636f;
  background-color: #ccf2f7;
  border-color: #b8edf3;
}

.alert-egg-blue hr {
  border-top-color: #a2e7ef;
}

.alert-egg-blue .alert-link {
  color: #01363c;
}

.alert-conch {
  color: #6d726f;
  background-color: #f6f8f7;
  border-color: #f2f5f4;
}

.alert-conch hr {
  border-top-color: #e4eae8;
}

.alert-conch .alert-link {
  color: #545856;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.625rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #8B3300;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.625rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: var(--color-texts);
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item-active {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #F2F2F2;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #8B3300;
  border-color: #8B3300;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.625rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: 0.625rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 480px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }

  .list-group-horizontal-xs>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xs>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xs>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xs>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xs>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1366px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxxl>.list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #005c3c;
  background-color: #b8e9d8;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #005c3c;
  background-color: #a5e3cd;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #005c3c;
  border-color: #005c3c;
}

.list-group-item-secondary {
  color: #252372;
  background-color: #cbcaf5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #252372;
  background-color: #b6b5f1;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #252372;
  border-color: #252372;
}

.list-group-item-success {
  color: #005c3c;
  background-color: #b8e9d8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #005c3c;
  background-color: #a5e3cd;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #005c3c;
  border-color: #005c3c;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #855d00;
  background-color: #ffeab8;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #855d00;
  background-color: #ffe29f;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #855d00;
  border-color: #855d00;
}

.list-group-item-danger {
  color: #801021;
  background-color: #fdc0c9;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #801021;
  background-color: #fca7b4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #801021;
  border-color: #801021;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-green {
  color: #005c3c;
  background-color: #b8e9d8;
}

.list-group-item-green.list-group-item-action:hover,
.list-group-item-green.list-group-item-action:focus {
  color: #005c3c;
  background-color: #a5e3cd;
}

.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #005c3c;
  border-color: #005c3c;
}

.list-group-item-blue {
  color: #252372;
  background-color: #cbcaf5;
}

.list-group-item-blue.list-group-item-action:hover,
.list-group-item-blue.list-group-item-action:focus {
  color: #252372;
  background-color: #b6b5f1;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #252372;
  border-color: #252372;
}

.list-group-item-eastern {
  color: #124f5b;
  background-color: #c1e2e9;
}

.list-group-item-eastern.list-group-item-action:hover,
.list-group-item-eastern.list-group-item-action:focus {
  color: #124f5b;
  background-color: #aed9e2;
}

.list-group-item-eastern.list-group-item-action.active {
  color: #fff;
  background-color: #124f5b;
  border-color: #124f5b;
}

.list-group-item-denim {
  color: #0e2f7a;
  background-color: #bfd1f9;
}

.list-group-item-denim.list-group-item-action:hover,
.list-group-item-denim.list-group-item-action:focus {
  color: #0e2f7a;
  background-color: #a8c0f7;
}

.list-group-item-denim.list-group-item-action.active {
  color: #fff;
  background-color: #0e2f7a;
  border-color: #0e2f7a;
}

.list-group-item-dodger {
  color: #1b3980;
  background-color: #c6d6fd;
}

.list-group-item-dodger.list-group-item-action:hover,
.list-group-item-dodger.list-group-item-action:focus {
  color: #1b3980;
  background-color: #adc4fc;
}

.list-group-item-dodger.list-group-item-action.active {
  color: #fff;
  background-color: #1b3980;
  border-color: #1b3980;
}

.list-group-item-dodger-2 {
  color: #284485;
  background-color: #cddcff;
}

.list-group-item-dodger-2.list-group-item-action:hover,
.list-group-item-dodger-2.list-group-item-action:focus {
  color: #284485;
  background-color: #b4caff;
}

.list-group-item-dodger-2.list-group-item-action.active {
  color: #fff;
  background-color: #284485;
  border-color: #284485;
}

.list-group-item-spray {
  color: #357c76;
  background-color: #d4fbf7;
}

.list-group-item-spray.list-group-item-action:hover,
.list-group-item-spray.list-group-item-action:focus {
  color: #357c76;
  background-color: #bdf9f3;
}

.list-group-item-spray.list-group-item-action.active {
  color: #fff;
  background-color: #357c76;
  border-color: #357c76;
}

.list-group-item-turquoise {
  color: #2a7077;
  background-color: #cef4f8;
}

.list-group-item-turquoise.list-group-item-action:hover,
.list-group-item-turquoise.list-group-item-action:focus {
  color: #2a7077;
  background-color: #b8eff5;
}

.list-group-item-turquoise.list-group-item-action.active {
  color: #fff;
  background-color: #2a7077;
  border-color: #2a7077;
}

.list-group-item-regent {
  color: #5c6f79;
  background-color: #e9f3f9;
}

.list-group-item-regent.list-group-item-action:hover,
.list-group-item-regent.list-group-item-action:focus {
  color: #5c6f79;
  background-color: #d5e8f4;
}

.list-group-item-regent.list-group-item-action.active {
  color: #fff;
  background-color: #5c6f79;
  border-color: #5c6f79;
}

.list-group-item-coral {
  color: #82452d;
  background-color: #feddd0;
}

.list-group-item-coral.list-group-item-action:hover,
.list-group-item-coral.list-group-item-action:focus {
  color: #82452d;
  background-color: #fdcbb7;
}

.list-group-item-coral.list-group-item-action.active {
  color: #fff;
  background-color: #82452d;
  border-color: #82452d;
}

.list-group-item-orange {
  color: #82310f;
  background-color: #fed2bf;
}

.list-group-item-orange.list-group-item-action:hover,
.list-group-item-orange.list-group-item-action:focus {
  color: #82310f;
  background-color: #fec0a6;
}

.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #82310f;
  border-color: #82310f;
}

.list-group-item-orange-2 {
  color: #82321a;
  background-color: #fed2c6;
}

.list-group-item-orange-2.list-group-item-action:hover,
.list-group-item-orange-2.list-group-item-action:focus {
  color: #82321a;
  background-color: #febead;
}

.list-group-item-orange-2.list-group-item-action.active {
  color: #fff;
  background-color: #82321a;
  border-color: #82321a;
}

.list-group-item-red {
  color: #801021;
  background-color: #fdc0c9;
}

.list-group-item-red.list-group-item-action:hover,
.list-group-item-red.list-group-item-action:focus {
  color: #801021;
  background-color: #fca7b4;
}

.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #801021;
  border-color: #801021;
}

.list-group-item-red-2 {
  color: #832121;
  background-color: #fec9c9;
}

.list-group-item-red-2.list-group-item-action:hover,
.list-group-item-red-2.list-group-item-action:focus {
  color: #832121;
  background-color: #feb0b0;
}

.list-group-item-red-2.list-group-item-action.active {
  color: #fff;
  background-color: #832121;
  border-color: #832121;
}

.list-group-item-yellow {
  color: #855d00;
  background-color: #ffeab8;
}

.list-group-item-yellow.list-group-item-action:hover,
.list-group-item-yellow.list-group-item-action:focus {
  color: #855d00;
  background-color: #ffe29f;
}

.list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #855d00;
  border-color: #855d00;
}

.list-group-item-yellow-2 {
  color: #826c1a;
  background-color: #fef2c6;
}

.list-group-item-yellow-2.list-group-item-action:hover,
.list-group-item-yellow-2.list-group-item-action:focus {
  color: #826c1a;
  background-color: #feecad;
}

.list-group-item-yellow-2.list-group-item-action.active {
  color: #fff;
  background-color: #826c1a;
  border-color: #826c1a;
}

.list-group-item-casablanca {
  color: #816624;
  background-color: #fdefcb;
}

.list-group-item-casablanca.list-group-item-action:hover,
.list-group-item-casablanca.list-group-item-action:focus {
  color: #816624;
  background-color: #fce7b2;
}

.list-group-item-casablanca.list-group-item-action.active {
  color: #fff;
  background-color: #816624;
  border-color: #816624;
}

.list-group-item-indigo {
  color: #2e3e63;
  background-color: #d0d9ed;
}

.list-group-item-indigo.list-group-item-action:hover,
.list-group-item-indigo.list-group-item-action:focus {
  color: #2e3e63;
  background-color: #becae6;
}

.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #2e3e63;
  border-color: #2e3e63;
}

.list-group-item-shamrock {
  color: #136b4d;
  background-color: #c2f1e1;
}

.list-group-item-shamrock.list-group-item-action:hover,
.list-group-item-shamrock.list-group-item-action:focus {
  color: #136b4d;
  background-color: #adecd7;
}

.list-group-item-shamrock.list-group-item-action.active {
  color: #fff;
  background-color: #136b4d;
  border-color: #136b4d;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover,
.list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-black-2 {
  color: #161e21;
  background-color: #c4c8ca;
}

.list-group-item-black-2.list-group-item-action:hover,
.list-group-item-black-2.list-group-item-action:focus {
  color: #161e21;
  background-color: #b7bbbe;
}

.list-group-item-black-2.list-group-item-action.active {
  color: #fff;
  background-color: #161e21;
  border-color: #161e21;
}

.list-group-item-gray {
  color: #38393a;
  background-color: #d6d6d7;
}

.list-group-item-gray.list-group-item-action:hover,
.list-group-item-gray.list-group-item-action:focus {
  color: #38393a;
  background-color: #c9c9ca;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #38393a;
  border-color: #38393a;
}

.list-group-item-smoke {
  color: #424747;
  background-color: #dbdede;
}

.list-group-item-smoke.list-group-item-action:hover,
.list-group-item-smoke.list-group-item-action:focus {
  color: #424747;
  background-color: #ced2d2;
}

.list-group-item-smoke.list-group-item-action.active {
  color: #fff;
  background-color: #424747;
  border-color: #424747;
}

.list-group-item-pink {
  color: #832643;
  background-color: #feccdb;
}

.list-group-item-pink.list-group-item-action:hover,
.list-group-item-pink.list-group-item-action:focus {
  color: #832643;
  background-color: #feb3c9;
}

.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #832643;
  border-color: #832643;
}

.list-group-item-violet {
  color: #513185;
  background-color: #e3d2ff;
}

.list-group-item-violet.list-group-item-action:hover,
.list-group-item-violet.list-group-item-action:focus {
  color: #513185;
  background-color: #d3b9ff;
}

.list-group-item-violet.list-group-item-action.active {
  color: #fff;
  background-color: #513185;
  border-color: #513185;
}

.list-group-item-mercury {
  color: #777777;
  background-color: #f8f8f8;
}

.list-group-item-mercury.list-group-item-action:hover,
.list-group-item-mercury.list-group-item-action:focus {
  color: #777777;
  background-color: #ebebeb;
}

.list-group-item-mercury.list-group-item-action.active {
  color: #fff;
  background-color: #777777;
  border-color: #777777;
}

.list-group-item-ebony-clay {
  color: #0f1518;
  background-color: #c0c3c4;
}

.list-group-item-ebony-clay.list-group-item-action:hover,
.list-group-item-ebony-clay.list-group-item-action:focus {
  color: #0f1518;
  background-color: #b3b6b8;
}

.list-group-item-ebony-clay.list-group-item-action.active {
  color: #fff;
  background-color: #0f1518;
  border-color: #0f1518;
}

.list-group-item-hit-gray {
  color: #5a5e5f;
  background-color: #e8eaeb;
}

.list-group-item-hit-gray.list-group-item-action:hover,
.list-group-item-hit-gray.list-group-item-action:focus {
  color: #5a5e5f;
  background-color: #dadedf;
}

.list-group-item-hit-gray.list-group-item-action.active {
  color: #fff;
  background-color: #5a5e5f;
  border-color: #5a5e5f;
}

.list-group-item-gallery {
  color: #7d7d7d;
  background-color: #fbfbfb;
}

.list-group-item-gallery.list-group-item-action:hover,
.list-group-item-gallery.list-group-item-action:focus {
  color: #7d7d7d;
  background-color: #eeeeee;
}

.list-group-item-gallery.list-group-item-action.active {
  color: #fff;
  background-color: #7d7d7d;
  border-color: #7d7d7d;
}

.list-group-item-squeeze {
  color: #7b817f;
  background-color: #fafdfc;
}

.list-group-item-squeeze.list-group-item-action:hover,
.list-group-item-squeeze.list-group-item-action:focus {
  color: #7b817f;
  background-color: #e8f6f1;
}

.list-group-item-squeeze.list-group-item-action.active {
  color: #fff;
  background-color: #7b817f;
  border-color: #7b817f;
}

.list-group-item-helio {
  color: #513185;
  background-color: #e3d2ff;
}

.list-group-item-helio.list-group-item-action:hover,
.list-group-item-helio.list-group-item-action:focus {
  color: #513185;
  background-color: #d3b9ff;
}

.list-group-item-helio.list-group-item-action.active {
  color: #fff;
  background-color: #513185;
  border-color: #513185;
}

.list-group-item-athens {
  color: #7f7f81;
  background-color: #fcfcfd;
}

.list-group-item-athens.list-group-item-action:hover,
.list-group-item-athens.list-group-item-action:focus {
  color: #7f7f81;
  background-color: #ededf3;
}

.list-group-item-athens.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f81;
  border-color: #7f7f81;
}

.list-group-item-concrete {
  color: #7e7e7e;
  background-color: #fbfbfb;
}

.list-group-item-concrete.list-group-item-action:hover,
.list-group-item-concrete.list-group-item-action:focus {
  color: #7e7e7e;
  background-color: #eeeeee;
}

.list-group-item-concrete.list-group-item-action.active {
  color: #fff;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.list-group-item-allports {
  color: #003a59;
  background-color: #b8d7e8;
}

.list-group-item-allports.list-group-item-action:hover,
.list-group-item-allports.list-group-item-action:focus {
  color: #003a59;
  background-color: #a5cce2;
}

.list-group-item-allports.list-group-item-action.active {
  color: #fff;
  background-color: #003a59;
  border-color: #003a59;
}

.list-group-item-marino {
  color: #21345a;
  background-color: #cad4e8;
}

.list-group-item-marino.list-group-item-action:hover,
.list-group-item-marino.list-group-item-action:focus {
  color: #21345a;
  background-color: #b8c6e0;
}

.list-group-item-marino.list-group-item-action.active {
  color: #fff;
  background-color: #21345a;
  border-color: #21345a;
}

.list-group-item-poppy {
  color: #631e16;
  background-color: #edc8c4;
}

.list-group-item-poppy.list-group-item-action:hover,
.list-group-item-poppy.list-group-item-action:focus {
  color: #631e16;
  background-color: #e7b6b0;
}

.list-group-item-poppy.list-group-item-action.active {
  color: #fff;
  background-color: #631e16;
  border-color: #631e16;
}

.list-group-item-polar {
  color: #7e8183;
  background-color: #fcfdfe;
}

.list-group-item-polar.list-group-item-action:hover,
.list-group-item-polar.list-group-item-action:focus {
  color: #7e8183;
  background-color: #e9f0f8;
}

.list-group-item-polar.list-group-item-action.active {
  color: #fff;
  background-color: #7e8183;
  border-color: #7e8183;
}

.list-group-item-egg-blue {
  color: #01636f;
  background-color: #b8edf3;
}

.list-group-item-egg-blue.list-group-item-action:hover,
.list-group-item-egg-blue.list-group-item-action:focus {
  color: #01636f;
  background-color: #a2e7ef;
}

.list-group-item-egg-blue.list-group-item-action.active {
  color: #fff;
  background-color: #01636f;
  border-color: #01636f;
}

.list-group-item-conch {
  color: #6d726f;
  background-color: #f2f5f4;
}

.list-group-item-conch.list-group-item-action:hover,
.list-group-item-conch.list-group-item-action:focus {
  color: #6d726f;
  background-color: #e4eae8;
}

.list-group-item-conch.list-group-item-action.active {
  color: #fff;
  background-color: #6d726f;
  border-color: #6d726f;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.625rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--border-color);
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid var(--border-color);
  border-bottom-right-radius: calc(0.625rem - 1px);
  border-bottom-left-radius: calc(0.625rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.625rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.625rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.625rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.625rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.625rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: var(--color-headings);
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: var(--color-texts);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #8B3300 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #8B3300 !important;
}

.bg-secondary {
  background-color: #4743db !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #2a26c5 !important;
}

.bg-success {
  background-color: #8B3300 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #8B3300 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffb300 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc8f00 !important;
}

.bg-danger {
  background-color: #f71e3f !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #da0828 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-green {
  background-color: #8B3300 !important;
}

a.bg-green:hover,
a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #8B3300 !important;
}

.bg-blue {
  background-color: #4743db !important;
}

a.bg-blue:hover,
a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #2a26c5 !important;
}

.bg-eastern {
  background-color: #2397af !important;
}

a.bg-eastern:hover,
a.bg-eastern:focus,
button.bg-eastern:hover,
button.bg-eastern:focus {
  background-color: #1b7285 !important;
}

.bg-denim {
  background-color: #1a5aeb !important;
}

a.bg-denim:hover,
a.bg-denim:focus,
button.bg-denim:hover,
button.bg-denim:focus {
  background-color: #1147c1 !important;
}

.bg-dodger {
  background-color: #336ef7 !important;
}

a.bg-dodger:hover,
a.bg-dodger:focus,
button.bg-dodger:hover,
button.bg-dodger:focus {
  background-color: #094eee !important;
}

.bg-dodger-2 {
  background-color: #4D83FF !important;
}

a.bg-dodger-2:hover,
a.bg-dodger-2:focus,
button.bg-dodger-2:hover,
button.bg-dodger-2:focus {
  background-color: #1a5fff !important;
}

.bg-spray {
  background-color: #65efe3 !important;
}

a.bg-spray:hover,
a.bg-spray:focus,
button.bg-spray:hover,
button.bg-spray:focus {
  background-color: #37eadb !important;
}

.bg-olive {
  background-color: #1AAF76 !important;
}

a.bg-olive:hover,
a.bg-olive:focus,
button.bg-olive:hover,
button.bg-olive:focus {
  background-color: #0aa067 !important;
}

.bg-turquoise {
  background-color: #50D7E5 !important;
}

a.bg-turquoise:hover,
a.bg-turquoise:focus,
button.bg-turquoise:hover,
button.bg-turquoise:focus {
  background-color: #24cdde !important;
}

.bg-regent {
  background-color: #b0d5e8 !important;
}

a.bg-regent:hover,
a.bg-regent:focus,
button.bg-regent:hover,
button.bg-regent:focus {
  background-color: #89c0dd !important;
}

.bg-coral {
  background-color: #fa8557 !important;
}

a.bg-coral:hover,
a.bg-coral:focus,
button.bg-coral:hover,
button.bg-coral:focus {
  background-color: #f96125 !important;
}

.bg-orange {
  background-color: #fa5f1c !important;
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #de4605 !important;
}

.bg-orange-2 {
  background-color: #fa6032 !important;
}

a.bg-orange-2:hover,
a.bg-orange-2:focus,
button.bg-orange-2:hover,
button.bg-orange-2:focus {
  background-color: #f33c06 !important;
}

.bg-red {
  background-color: #f71e3f !important;
}

a.bg-red:hover,
a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #da0828 !important;
}

.bg-red-2 {
  background-color: #FC3F3F !important;
}

a.bg-red-2:hover,
a.bg-red-2:focus,
button.bg-red-2:hover,
button.bg-red-2:focus {
  background-color: #fb0d0d !important;
}

.bg-yellow {
  background-color: #ffb300 !important;
}

a.bg-yellow:hover,
a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #cc8f00 !important;
}

.bg-yellow-2 {
  background-color: #facf32 !important;
}

a.bg-yellow-2:hover,
a.bg-yellow-2:focus,
button.bg-yellow-2:hover,
button.bg-yellow-2:focus {
  background-color: #f3c006 !important;
}

.bg-casablanca {
  background-color: #f8c546 !important;
}

a.bg-casablanca:hover,
a.bg-casablanca:focus,
button.bg-casablanca:hover,
button.bg-casablanca:focus {
  background-color: #f6b615 !important;
}

.bg-indigo {
  background-color: #5877be !important;
}

a.bg-indigo:hover,
a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #405ea3 !important;
}

.bg-shamrock {
  background-color: #25ce95 !important;
}

a.bg-shamrock:hover,
a.bg-shamrock:focus,
button.bg-shamrock:hover,
button.bg-shamrock:focus {
  background-color: #1da376 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-black-2 {
  background-color: #2b3940 !important;
}

a.bg-black-2:hover,
a.bg-black-2:focus,
button.bg-black-2:hover,
button.bg-black-2:focus {
  background-color: #171e21 !important;
}

.bg-gray {
  background-color: #6b6e6f !important;
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #525455 !important;
}

.bg-smoke {
  background-color: #7e8989 !important;
}

a.bg-smoke:hover,
a.bg-smoke:focus,
button.bg-smoke:hover,
button.bg-smoke:focus {
  background-color: #656f6f !important;
}

.bg-pink {
  background-color: #fc4980 !important;
}

a.bg-pink:hover,
a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #fb175d !important;
}

.bg-violet {
  background-color: #9c5fff !important;
}

a.bg-violet:hover,
a.bg-violet:focus,
button.bg-violet:hover,
button.bg-violet:focus {
  background-color: #7c2cff !important;
}

.bg-mercury {
  background-color: #e5e5e5 !important;
}

a.bg-mercury:hover,
a.bg-mercury:focus,
button.bg-mercury:hover,
button.bg-mercury:focus {
  background-color: #cccccc !important;
}

.bg-ebony-clay {
  background-color: #000000 !important;
}

a.bg-ebony-clay:hover,
a.bg-ebony-clay:focus,
button.bg-ebony-clay:hover,
button.bg-ebony-clay:focus {
  background-color: #090d0f !important;
}

.bg-hit-gray {
  background-color: #adb4b7 !important;
}

a.bg-hit-gray:hover,
a.bg-hit-gray:focus,
button.bg-hit-gray:hover,
button.bg-hit-gray:focus {
  background-color: #929b9f !important;
}

.bg-gallery {
  background-color: #f0f0f0 !important;
}

a.bg-gallery:hover,
a.bg-gallery:focus,
button.bg-gallery:hover,
button.bg-gallery:focus {
  background-color: #d7d7d7 !important;
}

.bg-squeeze {
  background-color: #EDF8F5 !important;
}

a.bg-squeeze:hover,
a.bg-squeeze:focus,
button.bg-squeeze:hover,
button.bg-squeeze:focus {
  background-color: #c8eae1 !important;
}

.bg-helio {
  background-color: #9C5FFF !important;
}

a.bg-helio:hover,
a.bg-helio:focus,
button.bg-helio:hover,
button.bg-helio:focus {
  background-color: #7c2cff !important;
}

.bg-athens {
  background-color: #F4F5F8 !important;
}

a.bg-athens:hover,
a.bg-athens:focus,
button.bg-athens:hover,
button.bg-athens:focus {
  background-color: #d5d9e4 !important;
}

.bg-concrete {
  background-color: #F2F2F2 !important;
}

a.bg-concrete:hover,
a.bg-concrete:focus,
button.bg-concrete:hover,
button.bg-concrete:focus {
  background-color: #d9d9d9 !important;
}

.bg-allports {
  background-color: #0070ac !important;
}

a.bg-allports:hover,
a.bg-allports:focus,
button.bg-allports:hover,
button.bg-allports:focus {
  background-color: #004f79 !important;
}

.bg-marino {
  background-color: #4064AD !important;
}

a.bg-marino:hover,
a.bg-marino:focus,
button.bg-marino:hover,
button.bg-marino:focus {
  background-color: #324e88 !important;
}

.bg-poppy {
  background-color: #BE392B !important;
}

a.bg-poppy:hover,
a.bg-poppy:focus,
button.bg-poppy:hover,
button.bg-poppy:focus {
  background-color: #942d22 !important;
}

.bg-polar {
  background-color: #F3F9FC !important;
}

a.bg-polar:hover,
a.bg-polar:focus,
button.bg-polar:hover,
button.bg-polar:focus {
  background-color: #cae5f2 !important;
}

.bg-egg-blue {
  background-color: #02BFD5 !important;
}

a.bg-egg-blue:hover,
a.bg-egg-blue:focus,
button.bg-egg-blue:hover,
button.bg-egg-blue:focus {
  background-color: #0292a2 !important;
}

.bg-conch {
  background-color: #D2DCD6 !important;
}

a.bg-conch:hover,
a.bg-conch:focus,
button.bg-conch:hover,
button.bg-conch:focus {
  background-color: #b5c6bc !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid var(--border-color) !important;
}

.border-top {
  border-top: 1px solid var(--border-color) !important;
}

.border-right {
  border-right: 1px solid var(--border-color) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--border-color) !important;
}

.border-left {
  border-left: 1px solid var(--border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #8B3300 !important;
}

.border-secondary {
  border-color: #4743db !important;
}

.border-success {
  border-color: #8B3300 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffb300 !important;
}

.border-danger {
  border-color: #f71e3f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-green {
  border-color: #8B3300 !important;
}

.border-blue {
  border-color: #4743db !important;
}

.border-eastern {
  border-color: #2397af !important;
}

.border-denim {
  border-color: #1a5aeb !important;
}

.border-dodger {
  border-color: #336ef7 !important;
}

.border-dodger-2 {
  border-color: #4D83FF !important;
}

.border-spray {
  border-color: #65efe3 !important;
}

.border-turquoise {
  border-color: #50D7E5 !important;
}

.border-regent {
  border-color: #b0d5e8 !important;
}

.border-coral {
  border-color: #fa8557 !important;
}

.border-orange {
  border-color: #fa5f1c !important;
}

.border-orange-2 {
  border-color: #fa6032 !important;
}

.border-red {
  border-color: #f71e3f !important;
}

.border-red-2 {
  border-color: #FC3F3F !important;
}

.border-yellow {
  border-color: #ffb300 !important;
}

.border-yellow-2 {
  border-color: #facf32 !important;
}

.border-casablanca {
  border-color: #f8c546 !important;
}

.border-indigo {
  border-color: #5877be !important;
}

.border-shamrock {
  border-color: #25ce95 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-black-2 {
  border-color: #2b3940 !important;
}

.border-gray {
  border-color: #6b6e6f !important;
}

.border-smoke {
  border-color: #7e8989 !important;
}

.border-pink {
  border-color: #fc4980 !important;
}

.border-violet {
  border-color: #9c5fff !important;
}

.border-mercury {
  border-color: #e5e5e5 !important;
}

.border-ebony-clay {
  border-color: #1d292e !important;
}

.border-hit-gray {
  border-color: #adb4b7 !important;
}

.border-gallery {
  border-color: #f0f0f0 !important;
}

.border-squeeze {
  border-color: #EDF8F5 !important;
}

.border-helio {
  border-color: #9C5FFF !important;
}

.border-athens {
  border-color: #F4F5F8 !important;
}

.border-concrete {
  border-color: #F2F2F2 !important;
}

.border-allports {
  border-color: #0070ac !important;
}

.border-marino {
  border-color: #4064AD !important;
}

.border-poppy {
  border-color: #BE392B !important;
}

.border-polar {
  border-color: #F3F9FC !important;
}

.border-egg-blue {
  border-color: #02BFD5 !important;
}

.border-conch {
  border-color: #D2DCD6 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.3125rem !important;
}

.rounded {
  border-radius: 0.625rem !important;
}

.rounded-top {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}

.rounded-right {
  border-top-right-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}

.rounded-left {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}

.rounded-lg {
  border-radius: 0.625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-xs-none {
    display: none !important;
  }

  .d-xs-inline {
    display: inline !important;
  }

  .d-xs-inline-block {
    display: inline-block !important;
  }

  .d-xs-block {
    display: block !important;
  }

  .d-xs-table {
    display: table !important;
  }

  .d-xs-table-row {
    display: table-row !important;
  }

  .d-xs-table-cell {
    display: table-cell !important;
  }

  .d-xs-flex {
    display: flex !important;
  }

  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1366px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxxl-none {
    display: none !important;
  }

  .d-xxxl-inline {
    display: inline !important;
  }

  .d-xxxl-inline-block {
    display: inline-block !important;
  }

  .d-xxxl-block {
    display: block !important;
  }

  .d-xxxl-table {
    display: table !important;
  }

  .d-xxxl-table-row {
    display: table-row !important;
  }

  .d-xxxl-table-cell {
    display: table-cell !important;
  }

  .d-xxxl-flex {
    display: flex !important;
  }

  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-xs-row {
    flex-direction: row !important;
  }

  .flex-xs-column {
    flex-direction: column !important;
  }

  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xs-fill {
    flex: 1 1 auto !important;
  }

  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xs-start {
    justify-content: flex-start !important;
  }

  .justify-content-xs-end {
    justify-content: flex-end !important;
  }

  .justify-content-xs-center {
    justify-content: center !important;
  }

  .justify-content-xs-between {
    justify-content: space-between !important;
  }

  .justify-content-xs-around {
    justify-content: space-around !important;
  }

  .align-items-xs-start {
    align-items: flex-start !important;
  }

  .align-items-xs-end {
    align-items: flex-end !important;
  }

  .align-items-xs-center {
    align-items: center !important;
  }

  .align-items-xs-baseline {
    align-items: baseline !important;
  }

  .align-items-xs-stretch {
    align-items: stretch !important;
  }

  .align-content-xs-start {
    align-content: flex-start !important;
  }

  .align-content-xs-end {
    align-content: flex-end !important;
  }

  .align-content-xs-center {
    align-content: center !important;
  }

  .align-content-xs-between {
    align-content: space-between !important;
  }

  .align-content-xs-around {
    align-content: space-around !important;
  }

  .align-content-xs-stretch {
    align-content: stretch !important;
  }

  .align-self-xs-auto {
    align-self: auto !important;
  }

  .align-self-xs-start {
    align-self: flex-start !important;
  }

  .align-self-xs-end {
    align-self: flex-end !important;
  }

  .align-self-xs-center {
    align-self: center !important;
  }

  .align-self-xs-baseline {
    align-self: baseline !important;
  }

  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1366px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xxxl-row {
    flex-direction: row !important;
  }

  .flex-xxxl-column {
    flex-direction: column !important;
  }

  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxxl-center {
    justify-content: center !important;
  }

  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxxl-center {
    align-items: center !important;
  }

  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxxl-center {
    align-content: center !important;
  }

  .align-content-xxxl-between {
    align-content: space-between !important;
  }

  .align-content-xxxl-around {
    align-content: space-around !important;
  }

  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxxl-auto {
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-xs-left {
    float: left !important;
  }

  .float-xs-right {
    float: right !important;
  }

  .float-xs-none {
    float: none !important;
  }
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1366px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xxxl-left {
    float: left !important;
  }

  .float-xxxl-right {
    float: right !important;
  }

  .float-xxxl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-px-50 {
  width: 50px !important;
}

.w-px-33 {
  width: 33px !important;
}

.w-px-48 {
  width: 48px !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-100vh {
  width: 100vh !important;
}

.w-63px {
  width: 63px !important;
}

.w-79px {
  width: 79px !important;
}

.w-px-144 {
  width: 144px !important;
}

.w-180 {
  width: 180px !important;
}

.w-px-180 {
  width: 180px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-px-50 {
  height: 50px !important;
}

.h-px-33 {
  height: 33px !important;
}

.h-px-48 {
  height: 48px !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-63px {
  height: 63px !important;
}

.h-79px {
  height: 79px !important;
}

.h-px-144 {
  height: 144px !important;
}

.h-180 {
  height: 180px !important;
}

.h-px-180 {
  height: 180px !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.375rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.375rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.375rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.375rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.375rem !important;
}

.m-3 {
  margin: 0.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.5rem !important;
}

.m-4 {
  margin: 0.625rem !important;
}

.mt-4,
.my-4 {
  margin-top: 0.625rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 0.625rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 0.625rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 0.625rem !important;
}

.m-5 {
  margin: 0.75rem !important;
}

.mt-5,
.my-5 {
  margin-top: 0.75rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 0.75rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 0.75rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 0.75rem !important;
}

.m-6 {
  margin: 1rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1rem !important;
}

.m-7 {
  margin: 1.25rem !important;
}

.mt-7,
.my-7 {
  margin-top: 1.25rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 1.25rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 1.25rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 1.25rem !important;
}

.m-8 {
  margin: 1.5625rem !important;
}

.mt-8,
.my-8 {
  margin-top: 1.5625rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 1.5625rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 1.5625rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 1.5625rem !important;
}

.m-9 {
  margin: 1.875rem !important;
}

.mt-9,
.my-9 {
  margin-top: 1.875rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 1.875rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 1.875rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 1.875rem !important;
}

.m-10 {
  margin: 2.1875rem !important;
}

.mt-10,
.my-10 {
  margin-top: 2.1875rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 2.1875rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 2.1875rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 2.1875rem !important;
}

.m-11 {
  margin: 2.5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 2.5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 2.5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 2.5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 2.5rem !important;
}

.m-12 {
  margin: 2.8125rem !important;
}

.mt-12,
.my-12 {
  margin-top: 2.8125rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 2.8125rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 2.8125rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 2.8125rem !important;
}

.m-13 {
  margin: 3.125rem !important;
}

.mt-13,
.my-13 {
  margin-top: 3.125rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 3.125rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 3.125rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 3.125rem !important;
}

.m-14 {
  margin: 3.4375rem !important;
}

.mt-14,
.my-14 {
  margin-top: 3.4375rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 3.4375rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 3.4375rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 3.4375rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.mt-15,
.my-15 {
  margin-top: 3.75rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 3.75rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 3.75rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 3.75rem !important;
}

.m-16 {
  margin: 4.0625rem !important;
}

.mt-16,
.my-16 {
  margin-top: 4.0625rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 4.0625rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 4.0625rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 4.0625rem !important;
}

.m-17 {
  margin: 4.375rem !important;
}

.mt-17,
.my-17 {
  margin-top: 4.375rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 4.375rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 4.375rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 4.375rem !important;
}

.m-18 {
  margin: 4.6875rem !important;
}

.mt-18,
.my-18 {
  margin-top: 4.6875rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 4.6875rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 4.6875rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 4.6875rem !important;
}

.m-19 {
  margin: 5rem !important;
}

.mt-19,
.my-19 {
  margin-top: 5rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 5rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 5rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 5rem !important;
}

.m-20 {
  margin: 5.3125rem !important;
}

.mt-20,
.my-20 {
  margin-top: 5.3125rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 5.3125rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 5.3125rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 5.3125rem !important;
}

.m-21 {
  margin: 5.625rem !important;
}

.mt-21,
.my-21 {
  margin-top: 5.625rem !important;
}

.mr-21,
.mx-21 {
  margin-right: 5.625rem !important;
}

.mb-21,
.my-21 {
  margin-bottom: 5.625rem !important;
}

.ml-21,
.mx-21 {
  margin-left: 5.625rem !important;
}

.m-22 {
  margin: 5.9375rem !important;
}

.mt-22,
.my-22 {
  margin-top: 5.9375rem !important;
}

.mr-22,
.mx-22 {
  margin-right: 5.9375rem !important;
}

.mb-22,
.my-22 {
  margin-bottom: 5.9375rem !important;
}

.ml-22,
.mx-22 {
  margin-left: 5.9375rem !important;
}

.m-23 {
  margin: 6.25rem !important;
}

.mt-23,
.my-23 {
  margin-top: 6.25rem !important;
}

.mr-23,
.mx-23 {
  margin-right: 6.25rem !important;
}

.mb-23,
.my-23 {
  margin-bottom: 6.25rem !important;
}

.ml-23,
.mx-23 {
  margin-left: 6.25rem !important;
}

.m-24 {
  margin: 6.875rem !important;
}

.mt-24,
.my-24 {
  margin-top: 6.875rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 6.875rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 6.875rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 6.875rem !important;
}

.m-25 {
  margin: 7.5rem !important;
}

.mt-25,
.my-25 {
  margin-top: 7.5rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 7.5rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 7.5rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 7.5rem !important;
}

.m-26 {
  margin: 8.125rem !important;
}

.mt-26,
.my-26 {
  margin-top: 8.125rem !important;
}

.mr-26,
.mx-26 {
  margin-right: 8.125rem !important;
}

.mb-26,
.my-26 {
  margin-bottom: 8.125rem !important;
}

.ml-26,
.mx-26 {
  margin-left: 8.125rem !important;
}

.m-27 {
  margin: 8.4375rem !important;
}

.mt-27,
.my-27 {
  margin-top: 8.4375rem !important;
}

.mr-27,
.mx-27 {
  margin-right: 8.4375rem !important;
}

.mb-27,
.my-27 {
  margin-bottom: 8.4375rem !important;
}

.ml-27,
.mx-27 {
  margin-left: 8.4375rem !important;
}

.m-28 {
  margin: 9.0625rem !important;
}

.mt-28,
.my-28 {
  margin-top: 9.0625rem !important;
}

.mr-28,
.mx-28 {
  margin-right: 9.0625rem !important;
}

.mb-28,
.my-28 {
  margin-bottom: 9.0625rem !important;
}

.ml-28,
.mx-28 {
  margin-left: 9.0625rem !important;
}

.m-29 {
  margin: 9.375rem !important;
}

.mt-29,
.my-29 {
  margin-top: 9.375rem !important;
}

.mr-29,
.mx-29 {
  margin-right: 9.375rem !important;
}

.mb-29,
.my-29 {
  margin-bottom: 9.375rem !important;
}

.ml-29,
.mx-29 {
  margin-left: 9.375rem !important;
}

.m-30 {
  margin: 9.6875rem !important;
}

.mt-30,
.my-30 {
  margin-top: 9.6875rem !important;
}

.mr-30,
.mx-30 {
  margin-right: 9.6875rem !important;
}

.mb-30,
.my-30 {
  margin-bottom: 9.6875rem !important;
}

.ml-30,
.mx-30 {
  margin-left: 9.6875rem !important;
}

.m-31 {
  margin: 10.625rem !important;
}

.mt-31,
.my-31 {
  margin-top: 10.625rem !important;
}

.mr-31,
.mx-31 {
  margin-right: 10.625rem !important;
}

.mb-31,
.my-31 {
  margin-bottom: 10.625rem !important;
}

.ml-31,
.mx-31 {
  margin-left: 10.625rem !important;
}

.m-32 {
  margin: 11.25rem !important;
}

.mt-32,
.my-32 {
  margin-top: 11.25rem !important;
}

.mr-32,
.mx-32 {
  margin-right: 11.25rem !important;
}

.mb-32,
.my-32 {
  margin-bottom: 11.25rem !important;
}

.ml-32,
.mx-32 {
  margin-left: 11.25rem !important;
}

.m-33 {
  margin: 12.5rem !important;
}

.mt-33,
.my-33 {
  margin-top: 12.5rem !important;
}

.mr-33,
.mx-33 {
  margin-right: 12.5rem !important;
}

.mb-33,
.my-33 {
  margin-bottom: 12.5rem !important;
}

.ml-33,
.mx-33 {
  margin-left: 12.5rem !important;
}

.m-34 {
  margin: 14.0625rem !important;
}

.mt-34,
.my-34 {
  margin-top: 14.0625rem !important;
}

.mr-34,
.mx-34 {
  margin-right: 14.0625rem !important;
}

.mb-34,
.my-34 {
  margin-bottom: 14.0625rem !important;
}

.ml-34,
.mx-34 {
  margin-left: 14.0625rem !important;
}

.m-35 {
  margin: 15.625rem !important;
}

.mt-35,
.my-35 {
  margin-top: 15.625rem !important;
}

.mr-35,
.mx-35 {
  margin-right: 15.625rem !important;
}

.mb-35,
.my-35 {
  margin-bottom: 15.625rem !important;
}

.ml-35,
.mx-35 {
  margin-left: 15.625rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.375rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.375rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.375rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.375rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.375rem !important;
}

.p-3 {
  padding: 0.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.5rem !important;
}

.p-4 {
  padding: 0.625rem !important;
}

.pt-4,
.py-4 {
  padding-top: 0.625rem !important;
}

.pr-4,
.px-4 {
  padding-right: 0.625rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 0.625rem !important;
}

.pl-4,
.px-4 {
  padding-left: 0.625rem !important;
}

.p-5 {
  padding: 0.75rem !important;
}

.pt-5,
.py-5 {
  padding-top: 0.75rem !important;
}

.pr-5,
.px-5 {
  padding-right: 0.75rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 0.75rem !important;
}

.pl-5,
.px-5 {
  padding-left: 0.75rem !important;
}

.p-6 {
  padding: 1rem !important;
}

.pt-6,
.py-6 {
  padding-top: 1rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 1rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1rem !important;
}

.p-7 {
  padding: 1.25rem !important;
}

.pt-7,
.py-7 {
  padding-top: 1.25rem !important;
}

.pr-7,
.px-7 {
  padding-right: 1.25rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 1.25rem !important;
}

.pl-7,
.px-7 {
  padding-left: 1.25rem !important;
}

.p-8 {
  padding: 1.5625rem !important;
}

.pt-8,
.py-8 {
  padding-top: 1.5625rem !important;
}

.pr-8,
.px-8 {
  padding-right: 1.5625rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 1.5625rem !important;
}

.pl-8,
.px-8 {
  padding-left: 1.5625rem !important;
}

.p-9 {
  padding: 1.875rem !important;
}

.pt-9,
.py-9 {
  padding-top: 1.875rem !important;
}

.pr-9,
.px-9 {
  padding-right: 1.875rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 1.875rem !important;
}

.pl-9,
.px-9 {
  padding-left: 1.875rem !important;
}

.p-10 {
  padding: 2.1875rem !important;
}

.pt-10,
.py-10 {
  padding-top: 2.1875rem !important;
}

.pr-10,
.px-10 {
  padding-right: 2.1875rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 2.1875rem !important;
}

.pl-10,
.px-10 {
  padding-left: 2.1875rem !important;
}

.p-11 {
  padding: 2.5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 2.5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 2.5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 2.5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 2.5rem !important;
}

.p-12 {
  padding: 2.8125rem !important;
}

.pt-12,
.py-12 {
  padding-top: 2.8125rem !important;
}

.pr-12,
.px-12 {
  padding-right: 2.8125rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 2.8125rem !important;
}

.pl-12,
.px-12 {
  padding-left: 2.8125rem !important;
}

.p-13 {
  padding: 3.125rem !important;
}

.pt-13,
.py-13 {
  padding-top: 3.125rem !important;
}

.pr-13,
.px-13 {
  padding-right: 3.125rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 3.125rem !important;
}

.pl-13,
.px-13 {
  padding-left: 3.125rem !important;
}

.p-14 {
  padding: 3.4375rem !important;
}

.pt-14,
.py-14 {
  padding-top: 3.4375rem !important;
}

.pr-14,
.px-14 {
  padding-right: 3.4375rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 3.4375rem !important;
}

.pl-14,
.px-14 {
  padding-left: 3.4375rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.pt-15,
.py-15 {
  padding-top: 3.75rem !important;
}

.pr-15,
.px-15 {
  padding-right: 3.75rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 3.75rem !important;
}

.pl-15,
.px-15 {
  padding-left: 3.75rem !important;
}

.p-16 {
  padding: 4.0625rem !important;
}

.pt-16,
.py-16 {
  padding-top: 4.0625rem !important;
}

.pr-16,
.px-16 {
  padding-right: 4.0625rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 4.0625rem !important;
}

.pl-16,
.px-16 {
  padding-left: 4.0625rem !important;
}

.p-17 {
  padding: 4.375rem !important;
}

.pt-17,
.py-17 {
  padding-top: 4.375rem !important;
}

.pr-17,
.px-17 {
  padding-right: 4.375rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 4.375rem !important;
}

.pl-17,
.px-17 {
  padding-left: 4.375rem !important;
}

.p-18 {
  padding: 4.6875rem !important;
}

.pt-18,
.py-18 {
  padding-top: 4.6875rem !important;
}

.pr-18,
.px-18 {
  padding-right: 4.6875rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 4.6875rem !important;
}

.pl-18,
.px-18 {
  padding-left: 4.6875rem !important;
}

.p-19 {
  padding: 5rem !important;
}

.pt-19,
.py-19 {
  padding-top: 5rem !important;
}

.pr-19,
.px-19 {
  padding-right: 5rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 5rem !important;
}

.pl-19,
.px-19 {
  padding-left: 5rem !important;
}

.p-20 {
  padding: 5.3125rem !important;
}

.pt-20,
.py-20 {
  padding-top: 5.3125rem !important;
}

.pr-20,
.px-20 {
  padding-right: 5.3125rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 5.3125rem !important;
}

.pl-20,
.px-20 {
  padding-left: 5.3125rem !important;
}

.p-21 {
  padding: 5.625rem !important;
}

.pt-21,
.py-21 {
  padding-top: 5.625rem !important;
}

.pr-21,
.px-21 {
  padding-right: 5.625rem !important;
}

.pb-21,
.py-21 {
  padding-bottom: 5.625rem !important;
}

.pl-21,
.px-21 {
  padding-left: 5.625rem !important;
}

.p-22 {
  padding: 5.9375rem !important;
}

.pt-22,
.py-22 {
  padding-top: 5.9375rem !important;
}

.pr-22,
.px-22 {
  padding-right: 5.9375rem !important;
}

.pb-22,
.py-22 {
  padding-bottom: 5.9375rem !important;
}

.pl-22,
.px-22 {
  padding-left: 5.9375rem !important;
}

.p-23 {
  padding: 6.25rem !important;
}

.pt-23,
.py-23 {
  padding-top: 6.25rem !important;
}

.pr-23,
.px-23 {
  padding-right: 6.25rem !important;
}

.pb-23,
.py-23 {
  padding-bottom: 6.25rem !important;
}

.pl-23,
.px-23 {
  padding-left: 6.25rem !important;
}

.p-24 {
  padding: 6.875rem !important;
}

.pt-24,
.py-24 {
  padding-top: 6.875rem !important;
}

.pr-24,
.px-24 {
  padding-right: 6.875rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 6.875rem !important;
}

.pl-24,
.px-24 {
  padding-left: 6.875rem !important;
}

.p-25 {
  padding: 7.5rem !important;
}

.pt-25,
.py-25 {
  padding-top: 7.5rem !important;
}

.pr-25,
.px-25 {
  padding-right: 7.5rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 7.5rem !important;
}

.pl-25,
.px-25 {
  padding-left: 7.5rem !important;
}

.p-26 {
  padding: 8.125rem !important;
}

.pt-26,
.py-26 {
  padding-top: 8.125rem !important;
}

.pr-26,
.px-26 {
  padding-right: 8.125rem !important;
}

.pb-26,
.py-26 {
  padding-bottom: 8.125rem !important;
}

.pl-26,
.px-26 {
  padding-left: 8.125rem !important;
}

.p-27 {
  padding: 8.4375rem !important;
}

.pt-27,
.py-27 {
  padding-top: 8.4375rem !important;
}

.pr-27,
.px-27 {
  padding-right: 8.4375rem !important;
}

.pb-27,
.py-27 {
  padding-bottom: 8.4375rem !important;
}

.pl-27,
.px-27 {
  padding-left: 8.4375rem !important;
}

.p-28 {
  padding: 9.0625rem !important;
}

.pt-28,
.py-28 {
  padding-top: 9.0625rem !important;
}

.pr-28,
.px-28 {
  padding-right: 9.0625rem !important;
}

.pb-28,
.py-28 {
  padding-bottom: 9.0625rem !important;
}

.pl-28,
.px-28 {
  padding-left: 9.0625rem !important;
}

.p-29 {
  padding: 9.375rem !important;
}

.pt-29,
.py-29 {
  padding-top: 9.375rem !important;
}

.pr-29,
.px-29 {
  padding-right: 9.375rem !important;
}

.pb-29,
.py-29 {
  padding-bottom: 9.375rem !important;
}

.pl-29,
.px-29 {
  padding-left: 9.375rem !important;
}

.p-30 {
  padding: 9.6875rem !important;
}

.pt-30,
.py-30 {
  padding-top: 9.6875rem !important;
}

.pr-30,
.px-30 {
  padding-right: 9.6875rem !important;
}

.pb-30,
.py-30 {
  padding-bottom: 9.6875rem !important;
}

.pl-30,
.px-30 {
  padding-left: 9.6875rem !important;
}

.p-31 {
  padding: 10.625rem !important;
}

.pt-31,
.py-31 {
  padding-top: 10.625rem !important;
}

.pr-31,
.px-31 {
  padding-right: 10.625rem !important;
}

.pb-31,
.py-31 {
  padding-bottom: 10.625rem !important;
}

.pl-31,
.px-31 {
  padding-left: 10.625rem !important;
}

.p-32 {
  padding: 11.25rem !important;
}

.pt-32,
.py-32 {
  padding-top: 11.25rem !important;
}

.pr-32,
.px-32 {
  padding-right: 11.25rem !important;
}

.pb-32,
.py-32 {
  padding-bottom: 11.25rem !important;
}

.pl-32,
.px-32 {
  padding-left: 11.25rem !important;
}

.p-33 {
  padding: 12.5rem !important;
}

.pt-33,
.py-33 {
  padding-top: 12.5rem !important;
}

.pr-33,
.px-33 {
  padding-right: 12.5rem !important;
}

.pb-33,
.py-33 {
  padding-bottom: 12.5rem !important;
}

.pl-33,
.px-33 {
  padding-left: 12.5rem !important;
}

.p-34 {
  padding: 14.0625rem !important;
}

.pt-34,
.py-34 {
  padding-top: 14.0625rem !important;
}

.pr-34,
.px-34 {
  padding-right: 14.0625rem !important;
}

.pb-34,
.py-34 {
  padding-bottom: 14.0625rem !important;
}

.pl-34,
.px-34 {
  padding-left: 14.0625rem !important;
}

.p-35 {
  padding: 15.625rem !important;
}

.pt-35,
.py-35 {
  padding-top: 15.625rem !important;
}

.pr-35,
.px-35 {
  padding-right: 15.625rem !important;
}

.pb-35,
.py-35 {
  padding-bottom: 15.625rem !important;
}

.pl-35,
.px-35 {
  padding-left: 15.625rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.375rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.375rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.375rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.375rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.375rem !important;
}

.m-n3 {
  margin: -0.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.5rem !important;
}

.m-n4 {
  margin: -0.625rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -0.625rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -0.625rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -0.625rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -0.625rem !important;
}

.m-n5 {
  margin: -0.75rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -0.75rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -0.75rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -0.75rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -0.75rem !important;
}

.m-n6 {
  margin: -1rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -1rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -1rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -1rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -1rem !important;
}

.m-n7 {
  margin: -1.25rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -1.25rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -1.25rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -1.25rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -1.25rem !important;
}

.m-n8 {
  margin: -1.5625rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -1.5625rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -1.5625rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -1.5625rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -1.5625rem !important;
}

.m-n9 {
  margin: -1.875rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -1.875rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -1.875rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -1.875rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -1.875rem !important;
}

.m-n10 {
  margin: -2.1875rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -2.1875rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -2.1875rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -2.1875rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -2.1875rem !important;
}

.m-n11 {
  margin: -2.5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -2.5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -2.5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -2.5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -2.5rem !important;
}

.m-n12 {
  margin: -2.8125rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -2.8125rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -2.8125rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -2.8125rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -2.8125rem !important;
}

.m-n13 {
  margin: -3.125rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -3.125rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -3.125rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -3.125rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -3.125rem !important;
}

.m-n14 {
  margin: -3.4375rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -3.4375rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -3.4375rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -3.4375rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -3.4375rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -3.75rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -3.75rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -3.75rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -3.75rem !important;
}

.m-n16 {
  margin: -4.0625rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -4.0625rem !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -4.0625rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -4.0625rem !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -4.0625rem !important;
}

.m-n17 {
  margin: -4.375rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -4.375rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -4.375rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -4.375rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -4.375rem !important;
}

.m-n18 {
  margin: -4.6875rem !important;
}

.mt-n18,
.my-n18 {
  margin-top: -4.6875rem !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -4.6875rem !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -4.6875rem !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -4.6875rem !important;
}

.m-n19 {
  margin: -5rem !important;
}

.mt-n19,
.my-n19 {
  margin-top: -5rem !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -5rem !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -5rem !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -5rem !important;
}

.m-n20 {
  margin: -5.3125rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -5.3125rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -5.3125rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -5.3125rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -5.3125rem !important;
}

.m-n21 {
  margin: -5.625rem !important;
}

.mt-n21,
.my-n21 {
  margin-top: -5.625rem !important;
}

.mr-n21,
.mx-n21 {
  margin-right: -5.625rem !important;
}

.mb-n21,
.my-n21 {
  margin-bottom: -5.625rem !important;
}

.ml-n21,
.mx-n21 {
  margin-left: -5.625rem !important;
}

.m-n22 {
  margin: -5.9375rem !important;
}

.mt-n22,
.my-n22 {
  margin-top: -5.9375rem !important;
}

.mr-n22,
.mx-n22 {
  margin-right: -5.9375rem !important;
}

.mb-n22,
.my-n22 {
  margin-bottom: -5.9375rem !important;
}

.ml-n22,
.mx-n22 {
  margin-left: -5.9375rem !important;
}

.m-n23 {
  margin: -6.25rem !important;
}

.mt-n23,
.my-n23 {
  margin-top: -6.25rem !important;
}

.mr-n23,
.mx-n23 {
  margin-right: -6.25rem !important;
}

.mb-n23,
.my-n23 {
  margin-bottom: -6.25rem !important;
}

.ml-n23,
.mx-n23 {
  margin-left: -6.25rem !important;
}

.m-n24 {
  margin: -6.875rem !important;
}

.mt-n24,
.my-n24 {
  margin-top: -6.875rem !important;
}

.mr-n24,
.mx-n24 {
  margin-right: -6.875rem !important;
}

.mb-n24,
.my-n24 {
  margin-bottom: -6.875rem !important;
}

.ml-n24,
.mx-n24 {
  margin-left: -6.875rem !important;
}

.m-n25 {
  margin: -7.5rem !important;
}

.mt-n25,
.my-n25 {
  margin-top: -7.5rem !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -7.5rem !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -7.5rem !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -7.5rem !important;
}

.m-n26 {
  margin: -8.125rem !important;
}

.mt-n26,
.my-n26 {
  margin-top: -8.125rem !important;
}

.mr-n26,
.mx-n26 {
  margin-right: -8.125rem !important;
}

.mb-n26,
.my-n26 {
  margin-bottom: -8.125rem !important;
}

.ml-n26,
.mx-n26 {
  margin-left: -8.125rem !important;
}

.m-n27 {
  margin: -8.4375rem !important;
}

.mt-n27,
.my-n27 {
  margin-top: -8.4375rem !important;
}

.mr-n27,
.mx-n27 {
  margin-right: -8.4375rem !important;
}

.mb-n27,
.my-n27 {
  margin-bottom: -8.4375rem !important;
}

.ml-n27,
.mx-n27 {
  margin-left: -8.4375rem !important;
}

.m-n28 {
  margin: -9.0625rem !important;
}

.mt-n28,
.my-n28 {
  margin-top: -9.0625rem !important;
}

.mr-n28,
.mx-n28 {
  margin-right: -9.0625rem !important;
}

.mb-n28,
.my-n28 {
  margin-bottom: -9.0625rem !important;
}

.ml-n28,
.mx-n28 {
  margin-left: -9.0625rem !important;
}

.m-n29 {
  margin: -9.375rem !important;
}

.mt-n29,
.my-n29 {
  margin-top: -9.375rem !important;
}

.mr-n29,
.mx-n29 {
  margin-right: -9.375rem !important;
}

.mb-n29,
.my-n29 {
  margin-bottom: -9.375rem !important;
}

.ml-n29,
.mx-n29 {
  margin-left: -9.375rem !important;
}

.m-n30 {
  margin: -9.6875rem !important;
}

.mt-n30,
.my-n30 {
  margin-top: -9.6875rem !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -9.6875rem !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -9.6875rem !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -9.6875rem !important;
}

.m-n31 {
  margin: -10.625rem !important;
}

.mt-n31,
.my-n31 {
  margin-top: -10.625rem !important;
}

.mr-n31,
.mx-n31 {
  margin-right: -10.625rem !important;
}

.mb-n31,
.my-n31 {
  margin-bottom: -10.625rem !important;
}

.ml-n31,
.mx-n31 {
  margin-left: -10.625rem !important;
}

.m-n32 {
  margin: -11.25rem !important;
}

.mt-n32,
.my-n32 {
  margin-top: -11.25rem !important;
}

.mr-n32,
.mx-n32 {
  margin-right: -11.25rem !important;
}

.mb-n32,
.my-n32 {
  margin-bottom: -11.25rem !important;
}

.ml-n32,
.mx-n32 {
  margin-left: -11.25rem !important;
}

.m-n33 {
  margin: -12.5rem !important;
}

.mt-n33,
.my-n33 {
  margin-top: -12.5rem !important;
}

.mr-n33,
.mx-n33 {
  margin-right: -12.5rem !important;
}

.mb-n33,
.my-n33 {
  margin-bottom: -12.5rem !important;
}

.ml-n33,
.mx-n33 {
  margin-left: -12.5rem !important;
}

.m-n34 {
  margin: -14.0625rem !important;
}

.mt-n34,
.my-n34 {
  margin-top: -14.0625rem !important;
}

.mr-n34,
.mx-n34 {
  margin-right: -14.0625rem !important;
}

.mb-n34,
.my-n34 {
  margin-bottom: -14.0625rem !important;
}

.ml-n34,
.mx-n34 {
  margin-left: -14.0625rem !important;
}

.m-n35 {
  margin: -15.625rem !important;
}

.mt-n35,
.my-n35 {
  margin-top: -15.625rem !important;
}

.mr-n35,
.mx-n35 {
  margin-right: -15.625rem !important;
}

.mb-n35,
.my-n35 {
  margin-bottom: -15.625rem !important;
}

.ml-n35,
.mx-n35 {
  margin-left: -15.625rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-xs-0 {
    margin: 0 !important;
  }

  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }

  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }

  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }

  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }

  .m-xs-1 {
    margin: 0.25rem !important;
  }

  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }

  .m-xs-2 {
    margin: 0.375rem !important;
  }

  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.375rem !important;
  }

  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.375rem !important;
  }

  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.375rem !important;
  }

  .m-xs-3 {
    margin: 0.5rem !important;
  }

  .mt-xs-3,
  .my-xs-3 {
    margin-top: 0.5rem !important;
  }

  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 0.5rem !important;
  }

  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 0.5rem !important;
  }

  .m-xs-4 {
    margin: 0.625rem !important;
  }

  .mt-xs-4,
  .my-xs-4 {
    margin-top: 0.625rem !important;
  }

  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 0.625rem !important;
  }

  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 0.625rem !important;
  }

  .m-xs-5 {
    margin: 0.75rem !important;
  }

  .mt-xs-5,
  .my-xs-5 {
    margin-top: 0.75rem !important;
  }

  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 0.75rem !important;
  }

  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 0.75rem !important;
  }

  .m-xs-6 {
    margin: 1rem !important;
  }

  .mt-xs-6,
  .my-xs-6 {
    margin-top: 1rem !important;
  }

  .mr-xs-6,
  .mx-xs-6 {
    margin-right: 1rem !important;
  }

  .mb-xs-6,
  .my-xs-6 {
    margin-bottom: 1rem !important;
  }

  .ml-xs-6,
  .mx-xs-6 {
    margin-left: 1rem !important;
  }

  .m-xs-7 {
    margin: 1.25rem !important;
  }

  .mt-xs-7,
  .my-xs-7 {
    margin-top: 1.25rem !important;
  }

  .mr-xs-7,
  .mx-xs-7 {
    margin-right: 1.25rem !important;
  }

  .mb-xs-7,
  .my-xs-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-xs-7,
  .mx-xs-7 {
    margin-left: 1.25rem !important;
  }

  .m-xs-8 {
    margin: 1.5625rem !important;
  }

  .mt-xs-8,
  .my-xs-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-xs-8,
  .mx-xs-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-xs-8,
  .my-xs-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-xs-8,
  .mx-xs-8 {
    margin-left: 1.5625rem !important;
  }

  .m-xs-9 {
    margin: 1.875rem !important;
  }

  .mt-xs-9,
  .my-xs-9 {
    margin-top: 1.875rem !important;
  }

  .mr-xs-9,
  .mx-xs-9 {
    margin-right: 1.875rem !important;
  }

  .mb-xs-9,
  .my-xs-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-xs-9,
  .mx-xs-9 {
    margin-left: 1.875rem !important;
  }

  .m-xs-10 {
    margin: 2.1875rem !important;
  }

  .mt-xs-10,
  .my-xs-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-xs-10,
  .mx-xs-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-xs-10,
  .my-xs-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-xs-10,
  .mx-xs-10 {
    margin-left: 2.1875rem !important;
  }

  .m-xs-11 {
    margin: 2.5rem !important;
  }

  .mt-xs-11,
  .my-xs-11 {
    margin-top: 2.5rem !important;
  }

  .mr-xs-11,
  .mx-xs-11 {
    margin-right: 2.5rem !important;
  }

  .mb-xs-11,
  .my-xs-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xs-11,
  .mx-xs-11 {
    margin-left: 2.5rem !important;
  }

  .m-xs-12 {
    margin: 2.8125rem !important;
  }

  .mt-xs-12,
  .my-xs-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-xs-12,
  .mx-xs-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-xs-12,
  .my-xs-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-xs-12,
  .mx-xs-12 {
    margin-left: 2.8125rem !important;
  }

  .m-xs-13 {
    margin: 3.125rem !important;
  }

  .mt-xs-13,
  .my-xs-13 {
    margin-top: 3.125rem !important;
  }

  .mr-xs-13,
  .mx-xs-13 {
    margin-right: 3.125rem !important;
  }

  .mb-xs-13,
  .my-xs-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-xs-13,
  .mx-xs-13 {
    margin-left: 3.125rem !important;
  }

  .m-xs-14 {
    margin: 3.4375rem !important;
  }

  .mt-xs-14,
  .my-xs-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-xs-14,
  .mx-xs-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-xs-14,
  .my-xs-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-xs-14,
  .mx-xs-14 {
    margin-left: 3.4375rem !important;
  }

  .m-xs-15 {
    margin: 3.75rem !important;
  }

  .mt-xs-15,
  .my-xs-15 {
    margin-top: 3.75rem !important;
  }

  .mr-xs-15,
  .mx-xs-15 {
    margin-right: 3.75rem !important;
  }

  .mb-xs-15,
  .my-xs-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-xs-15,
  .mx-xs-15 {
    margin-left: 3.75rem !important;
  }

  .m-xs-16 {
    margin: 4.0625rem !important;
  }

  .mt-xs-16,
  .my-xs-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-xs-16,
  .mx-xs-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-xs-16,
  .my-xs-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-xs-16,
  .mx-xs-16 {
    margin-left: 4.0625rem !important;
  }

  .m-xs-17 {
    margin: 4.375rem !important;
  }

  .mt-xs-17,
  .my-xs-17 {
    margin-top: 4.375rem !important;
  }

  .mr-xs-17,
  .mx-xs-17 {
    margin-right: 4.375rem !important;
  }

  .mb-xs-17,
  .my-xs-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-xs-17,
  .mx-xs-17 {
    margin-left: 4.375rem !important;
  }

  .m-xs-18 {
    margin: 4.6875rem !important;
  }

  .mt-xs-18,
  .my-xs-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-xs-18,
  .mx-xs-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-xs-18,
  .my-xs-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-xs-18,
  .mx-xs-18 {
    margin-left: 4.6875rem !important;
  }

  .m-xs-19 {
    margin: 5rem !important;
  }

  .mt-xs-19,
  .my-xs-19 {
    margin-top: 5rem !important;
  }

  .mr-xs-19,
  .mx-xs-19 {
    margin-right: 5rem !important;
  }

  .mb-xs-19,
  .my-xs-19 {
    margin-bottom: 5rem !important;
  }

  .ml-xs-19,
  .mx-xs-19 {
    margin-left: 5rem !important;
  }

  .m-xs-20 {
    margin: 5.3125rem !important;
  }

  .mt-xs-20,
  .my-xs-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-xs-20,
  .mx-xs-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-xs-20,
  .my-xs-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-xs-20,
  .mx-xs-20 {
    margin-left: 5.3125rem !important;
  }

  .m-xs-21 {
    margin: 5.625rem !important;
  }

  .mt-xs-21,
  .my-xs-21 {
    margin-top: 5.625rem !important;
  }

  .mr-xs-21,
  .mx-xs-21 {
    margin-right: 5.625rem !important;
  }

  .mb-xs-21,
  .my-xs-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-xs-21,
  .mx-xs-21 {
    margin-left: 5.625rem !important;
  }

  .m-xs-22 {
    margin: 5.9375rem !important;
  }

  .mt-xs-22,
  .my-xs-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-xs-22,
  .mx-xs-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-xs-22,
  .my-xs-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-xs-22,
  .mx-xs-22 {
    margin-left: 5.9375rem !important;
  }

  .m-xs-23 {
    margin: 6.25rem !important;
  }

  .mt-xs-23,
  .my-xs-23 {
    margin-top: 6.25rem !important;
  }

  .mr-xs-23,
  .mx-xs-23 {
    margin-right: 6.25rem !important;
  }

  .mb-xs-23,
  .my-xs-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-xs-23,
  .mx-xs-23 {
    margin-left: 6.25rem !important;
  }

  .m-xs-24 {
    margin: 6.875rem !important;
  }

  .mt-xs-24,
  .my-xs-24 {
    margin-top: 6.875rem !important;
  }

  .mr-xs-24,
  .mx-xs-24 {
    margin-right: 6.875rem !important;
  }

  .mb-xs-24,
  .my-xs-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-xs-24,
  .mx-xs-24 {
    margin-left: 6.875rem !important;
  }

  .m-xs-25 {
    margin: 7.5rem !important;
  }

  .mt-xs-25,
  .my-xs-25 {
    margin-top: 7.5rem !important;
  }

  .mr-xs-25,
  .mx-xs-25 {
    margin-right: 7.5rem !important;
  }

  .mb-xs-25,
  .my-xs-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-xs-25,
  .mx-xs-25 {
    margin-left: 7.5rem !important;
  }

  .m-xs-26 {
    margin: 8.125rem !important;
  }

  .mt-xs-26,
  .my-xs-26 {
    margin-top: 8.125rem !important;
  }

  .mr-xs-26,
  .mx-xs-26 {
    margin-right: 8.125rem !important;
  }

  .mb-xs-26,
  .my-xs-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-xs-26,
  .mx-xs-26 {
    margin-left: 8.125rem !important;
  }

  .m-xs-27 {
    margin: 8.4375rem !important;
  }

  .mt-xs-27,
  .my-xs-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-xs-27,
  .mx-xs-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-xs-27,
  .my-xs-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-xs-27,
  .mx-xs-27 {
    margin-left: 8.4375rem !important;
  }

  .m-xs-28 {
    margin: 9.0625rem !important;
  }

  .mt-xs-28,
  .my-xs-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-xs-28,
  .mx-xs-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-xs-28,
  .my-xs-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-xs-28,
  .mx-xs-28 {
    margin-left: 9.0625rem !important;
  }

  .m-xs-29 {
    margin: 9.375rem !important;
  }

  .mt-xs-29,
  .my-xs-29 {
    margin-top: 9.375rem !important;
  }

  .mr-xs-29,
  .mx-xs-29 {
    margin-right: 9.375rem !important;
  }

  .mb-xs-29,
  .my-xs-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-xs-29,
  .mx-xs-29 {
    margin-left: 9.375rem !important;
  }

  .m-xs-30 {
    margin: 9.6875rem !important;
  }

  .mt-xs-30,
  .my-xs-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-xs-30,
  .mx-xs-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-xs-30,
  .my-xs-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-xs-30,
  .mx-xs-30 {
    margin-left: 9.6875rem !important;
  }

  .m-xs-31 {
    margin: 10.625rem !important;
  }

  .mt-xs-31,
  .my-xs-31 {
    margin-top: 10.625rem !important;
  }

  .mr-xs-31,
  .mx-xs-31 {
    margin-right: 10.625rem !important;
  }

  .mb-xs-31,
  .my-xs-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-xs-31,
  .mx-xs-31 {
    margin-left: 10.625rem !important;
  }

  .m-xs-32 {
    margin: 11.25rem !important;
  }

  .mt-xs-32,
  .my-xs-32 {
    margin-top: 11.25rem !important;
  }

  .mr-xs-32,
  .mx-xs-32 {
    margin-right: 11.25rem !important;
  }

  .mb-xs-32,
  .my-xs-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-xs-32,
  .mx-xs-32 {
    margin-left: 11.25rem !important;
  }

  .m-xs-33 {
    margin: 12.5rem !important;
  }

  .mt-xs-33,
  .my-xs-33 {
    margin-top: 12.5rem !important;
  }

  .mr-xs-33,
  .mx-xs-33 {
    margin-right: 12.5rem !important;
  }

  .mb-xs-33,
  .my-xs-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-xs-33,
  .mx-xs-33 {
    margin-left: 12.5rem !important;
  }

  .m-xs-34 {
    margin: 14.0625rem !important;
  }

  .mt-xs-34,
  .my-xs-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-xs-34,
  .mx-xs-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-xs-34,
  .my-xs-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-xs-34,
  .mx-xs-34 {
    margin-left: 14.0625rem !important;
  }

  .m-xs-35 {
    margin: 15.625rem !important;
  }

  .mt-xs-35,
  .my-xs-35 {
    margin-top: 15.625rem !important;
  }

  .mr-xs-35,
  .mx-xs-35 {
    margin-right: 15.625rem !important;
  }

  .mb-xs-35,
  .my-xs-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-xs-35,
  .mx-xs-35 {
    margin-left: 15.625rem !important;
  }

  .p-xs-0 {
    padding: 0 !important;
  }

  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }

  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }

  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }

  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }

  .p-xs-1 {
    padding: 0.25rem !important;
  }

  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }

  .p-xs-2 {
    padding: 0.375rem !important;
  }

  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.375rem !important;
  }

  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.375rem !important;
  }

  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.375rem !important;
  }

  .p-xs-3 {
    padding: 0.5rem !important;
  }

  .pt-xs-3,
  .py-xs-3 {
    padding-top: 0.5rem !important;
  }

  .pr-xs-3,
  .px-xs-3 {
    padding-right: 0.5rem !important;
  }

  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xs-3,
  .px-xs-3 {
    padding-left: 0.5rem !important;
  }

  .p-xs-4 {
    padding: 0.625rem !important;
  }

  .pt-xs-4,
  .py-xs-4 {
    padding-top: 0.625rem !important;
  }

  .pr-xs-4,
  .px-xs-4 {
    padding-right: 0.625rem !important;
  }

  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-xs-4,
  .px-xs-4 {
    padding-left: 0.625rem !important;
  }

  .p-xs-5 {
    padding: 0.75rem !important;
  }

  .pt-xs-5,
  .py-xs-5 {
    padding-top: 0.75rem !important;
  }

  .pr-xs-5,
  .px-xs-5 {
    padding-right: 0.75rem !important;
  }

  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xs-5,
  .px-xs-5 {
    padding-left: 0.75rem !important;
  }

  .p-xs-6 {
    padding: 1rem !important;
  }

  .pt-xs-6,
  .py-xs-6 {
    padding-top: 1rem !important;
  }

  .pr-xs-6,
  .px-xs-6 {
    padding-right: 1rem !important;
  }

  .pb-xs-6,
  .py-xs-6 {
    padding-bottom: 1rem !important;
  }

  .pl-xs-6,
  .px-xs-6 {
    padding-left: 1rem !important;
  }

  .p-xs-7 {
    padding: 1.25rem !important;
  }

  .pt-xs-7,
  .py-xs-7 {
    padding-top: 1.25rem !important;
  }

  .pr-xs-7,
  .px-xs-7 {
    padding-right: 1.25rem !important;
  }

  .pb-xs-7,
  .py-xs-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-xs-7,
  .px-xs-7 {
    padding-left: 1.25rem !important;
  }

  .p-xs-8 {
    padding: 1.5625rem !important;
  }

  .pt-xs-8,
  .py-xs-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-xs-8,
  .px-xs-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-xs-8,
  .py-xs-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-xs-8,
  .px-xs-8 {
    padding-left: 1.5625rem !important;
  }

  .p-xs-9 {
    padding: 1.875rem !important;
  }

  .pt-xs-9,
  .py-xs-9 {
    padding-top: 1.875rem !important;
  }

  .pr-xs-9,
  .px-xs-9 {
    padding-right: 1.875rem !important;
  }

  .pb-xs-9,
  .py-xs-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-xs-9,
  .px-xs-9 {
    padding-left: 1.875rem !important;
  }

  .p-xs-10 {
    padding: 2.1875rem !important;
  }

  .pt-xs-10,
  .py-xs-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-xs-10,
  .px-xs-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-xs-10,
  .py-xs-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-xs-10,
  .px-xs-10 {
    padding-left: 2.1875rem !important;
  }

  .p-xs-11 {
    padding: 2.5rem !important;
  }

  .pt-xs-11,
  .py-xs-11 {
    padding-top: 2.5rem !important;
  }

  .pr-xs-11,
  .px-xs-11 {
    padding-right: 2.5rem !important;
  }

  .pb-xs-11,
  .py-xs-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xs-11,
  .px-xs-11 {
    padding-left: 2.5rem !important;
  }

  .p-xs-12 {
    padding: 2.8125rem !important;
  }

  .pt-xs-12,
  .py-xs-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-xs-12,
  .px-xs-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-xs-12,
  .py-xs-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-xs-12,
  .px-xs-12 {
    padding-left: 2.8125rem !important;
  }

  .p-xs-13 {
    padding: 3.125rem !important;
  }

  .pt-xs-13,
  .py-xs-13 {
    padding-top: 3.125rem !important;
  }

  .pr-xs-13,
  .px-xs-13 {
    padding-right: 3.125rem !important;
  }

  .pb-xs-13,
  .py-xs-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-xs-13,
  .px-xs-13 {
    padding-left: 3.125rem !important;
  }

  .p-xs-14 {
    padding: 3.4375rem !important;
  }

  .pt-xs-14,
  .py-xs-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-xs-14,
  .px-xs-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-xs-14,
  .py-xs-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-xs-14,
  .px-xs-14 {
    padding-left: 3.4375rem !important;
  }

  .p-xs-15 {
    padding: 3.75rem !important;
  }

  .pt-xs-15,
  .py-xs-15 {
    padding-top: 3.75rem !important;
  }

  .pr-xs-15,
  .px-xs-15 {
    padding-right: 3.75rem !important;
  }

  .pb-xs-15,
  .py-xs-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-xs-15,
  .px-xs-15 {
    padding-left: 3.75rem !important;
  }

  .p-xs-16 {
    padding: 4.0625rem !important;
  }

  .pt-xs-16,
  .py-xs-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-xs-16,
  .px-xs-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-xs-16,
  .py-xs-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-xs-16,
  .px-xs-16 {
    padding-left: 4.0625rem !important;
  }

  .p-xs-17 {
    padding: 4.375rem !important;
  }

  .pt-xs-17,
  .py-xs-17 {
    padding-top: 4.375rem !important;
  }

  .pr-xs-17,
  .px-xs-17 {
    padding-right: 4.375rem !important;
  }

  .pb-xs-17,
  .py-xs-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-xs-17,
  .px-xs-17 {
    padding-left: 4.375rem !important;
  }

  .p-xs-18 {
    padding: 4.6875rem !important;
  }

  .pt-xs-18,
  .py-xs-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-xs-18,
  .px-xs-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-xs-18,
  .py-xs-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-xs-18,
  .px-xs-18 {
    padding-left: 4.6875rem !important;
  }

  .p-xs-19 {
    padding: 5rem !important;
  }

  .pt-xs-19,
  .py-xs-19 {
    padding-top: 5rem !important;
  }

  .pr-xs-19,
  .px-xs-19 {
    padding-right: 5rem !important;
  }

  .pb-xs-19,
  .py-xs-19 {
    padding-bottom: 5rem !important;
  }

  .pl-xs-19,
  .px-xs-19 {
    padding-left: 5rem !important;
  }

  .p-xs-20 {
    padding: 5.3125rem !important;
  }

  .pt-xs-20,
  .py-xs-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-xs-20,
  .px-xs-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-xs-20,
  .py-xs-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-xs-20,
  .px-xs-20 {
    padding-left: 5.3125rem !important;
  }

  .p-xs-21 {
    padding: 5.625rem !important;
  }

  .pt-xs-21,
  .py-xs-21 {
    padding-top: 5.625rem !important;
  }

  .pr-xs-21,
  .px-xs-21 {
    padding-right: 5.625rem !important;
  }

  .pb-xs-21,
  .py-xs-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-xs-21,
  .px-xs-21 {
    padding-left: 5.625rem !important;
  }

  .p-xs-22 {
    padding: 5.9375rem !important;
  }

  .pt-xs-22,
  .py-xs-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-xs-22,
  .px-xs-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-xs-22,
  .py-xs-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-xs-22,
  .px-xs-22 {
    padding-left: 5.9375rem !important;
  }

  .p-xs-23 {
    padding: 6.25rem !important;
  }

  .pt-xs-23,
  .py-xs-23 {
    padding-top: 6.25rem !important;
  }

  .pr-xs-23,
  .px-xs-23 {
    padding-right: 6.25rem !important;
  }

  .pb-xs-23,
  .py-xs-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-xs-23,
  .px-xs-23 {
    padding-left: 6.25rem !important;
  }

  .p-xs-24 {
    padding: 6.875rem !important;
  }

  .pt-xs-24,
  .py-xs-24 {
    padding-top: 6.875rem !important;
  }

  .pr-xs-24,
  .px-xs-24 {
    padding-right: 6.875rem !important;
  }

  .pb-xs-24,
  .py-xs-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-xs-24,
  .px-xs-24 {
    padding-left: 6.875rem !important;
  }

  .p-xs-25 {
    padding: 7.5rem !important;
  }

  .pt-xs-25,
  .py-xs-25 {
    padding-top: 7.5rem !important;
  }

  .pr-xs-25,
  .px-xs-25 {
    padding-right: 7.5rem !important;
  }

  .pb-xs-25,
  .py-xs-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-xs-25,
  .px-xs-25 {
    padding-left: 7.5rem !important;
  }

  .p-xs-26 {
    padding: 8.125rem !important;
  }

  .pt-xs-26,
  .py-xs-26 {
    padding-top: 8.125rem !important;
  }

  .pr-xs-26,
  .px-xs-26 {
    padding-right: 8.125rem !important;
  }

  .pb-xs-26,
  .py-xs-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-xs-26,
  .px-xs-26 {
    padding-left: 8.125rem !important;
  }

  .p-xs-27 {
    padding: 8.4375rem !important;
  }

  .pt-xs-27,
  .py-xs-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-xs-27,
  .px-xs-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-xs-27,
  .py-xs-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-xs-27,
  .px-xs-27 {
    padding-left: 8.4375rem !important;
  }

  .p-xs-28 {
    padding: 9.0625rem !important;
  }

  .pt-xs-28,
  .py-xs-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-xs-28,
  .px-xs-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-xs-28,
  .py-xs-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-xs-28,
  .px-xs-28 {
    padding-left: 9.0625rem !important;
  }

  .p-xs-29 {
    padding: 9.375rem !important;
  }

  .pt-xs-29,
  .py-xs-29 {
    padding-top: 9.375rem !important;
  }

  .pr-xs-29,
  .px-xs-29 {
    padding-right: 9.375rem !important;
  }

  .pb-xs-29,
  .py-xs-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-xs-29,
  .px-xs-29 {
    padding-left: 9.375rem !important;
  }

  .p-xs-30 {
    padding: 9.6875rem !important;
  }

  .pt-xs-30,
  .py-xs-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-xs-30,
  .px-xs-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-xs-30,
  .py-xs-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-xs-30,
  .px-xs-30 {
    padding-left: 9.6875rem !important;
  }

  .p-xs-31 {
    padding: 10.625rem !important;
  }

  .pt-xs-31,
  .py-xs-31 {
    padding-top: 10.625rem !important;
  }

  .pr-xs-31,
  .px-xs-31 {
    padding-right: 10.625rem !important;
  }

  .pb-xs-31,
  .py-xs-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-xs-31,
  .px-xs-31 {
    padding-left: 10.625rem !important;
  }

  .p-xs-32 {
    padding: 11.25rem !important;
  }

  .pt-xs-32,
  .py-xs-32 {
    padding-top: 11.25rem !important;
  }

  .pr-xs-32,
  .px-xs-32 {
    padding-right: 11.25rem !important;
  }

  .pb-xs-32,
  .py-xs-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-xs-32,
  .px-xs-32 {
    padding-left: 11.25rem !important;
  }

  .p-xs-33 {
    padding: 12.5rem !important;
  }

  .pt-xs-33,
  .py-xs-33 {
    padding-top: 12.5rem !important;
  }

  .pr-xs-33,
  .px-xs-33 {
    padding-right: 12.5rem !important;
  }

  .pb-xs-33,
  .py-xs-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-xs-33,
  .px-xs-33 {
    padding-left: 12.5rem !important;
  }

  .p-xs-34 {
    padding: 14.0625rem !important;
  }

  .pt-xs-34,
  .py-xs-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-xs-34,
  .px-xs-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-xs-34,
  .py-xs-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-xs-34,
  .px-xs-34 {
    padding-left: 14.0625rem !important;
  }

  .p-xs-35 {
    padding: 15.625rem !important;
  }

  .pt-xs-35,
  .py-xs-35 {
    padding-top: 15.625rem !important;
  }

  .pr-xs-35,
  .px-xs-35 {
    padding-right: 15.625rem !important;
  }

  .pb-xs-35,
  .py-xs-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-xs-35,
  .px-xs-35 {
    padding-left: 15.625rem !important;
  }

  .m-xs-n1 {
    margin: -0.25rem !important;
  }

  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xs-n2 {
    margin: -0.375rem !important;
  }

  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.375rem !important;
  }

  .m-xs-n3 {
    margin: -0.5rem !important;
  }

  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -0.5rem !important;
  }

  .m-xs-n4 {
    margin: -0.625rem !important;
  }

  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -0.625rem !important;
  }

  .m-xs-n5 {
    margin: -0.75rem !important;
  }

  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -0.75rem !important;
  }

  .m-xs-n6 {
    margin: -1rem !important;
  }

  .mt-xs-n6,
  .my-xs-n6 {
    margin-top: -1rem !important;
  }

  .mr-xs-n6,
  .mx-xs-n6 {
    margin-right: -1rem !important;
  }

  .mb-xs-n6,
  .my-xs-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-xs-n6,
  .mx-xs-n6 {
    margin-left: -1rem !important;
  }

  .m-xs-n7 {
    margin: -1.25rem !important;
  }

  .mt-xs-n7,
  .my-xs-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-xs-n7,
  .mx-xs-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-xs-n7,
  .my-xs-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-xs-n7,
  .mx-xs-n7 {
    margin-left: -1.25rem !important;
  }

  .m-xs-n8 {
    margin: -1.5625rem !important;
  }

  .mt-xs-n8,
  .my-xs-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-xs-n8,
  .mx-xs-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-xs-n8,
  .my-xs-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-xs-n8,
  .mx-xs-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-xs-n9 {
    margin: -1.875rem !important;
  }

  .mt-xs-n9,
  .my-xs-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-xs-n9,
  .mx-xs-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-xs-n9,
  .my-xs-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-xs-n9,
  .mx-xs-n9 {
    margin-left: -1.875rem !important;
  }

  .m-xs-n10 {
    margin: -2.1875rem !important;
  }

  .mt-xs-n10,
  .my-xs-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-xs-n10,
  .mx-xs-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-xs-n10,
  .my-xs-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-xs-n10,
  .mx-xs-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-xs-n11 {
    margin: -2.5rem !important;
  }

  .mt-xs-n11,
  .my-xs-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-xs-n11,
  .mx-xs-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-xs-n11,
  .my-xs-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xs-n11,
  .mx-xs-n11 {
    margin-left: -2.5rem !important;
  }

  .m-xs-n12 {
    margin: -2.8125rem !important;
  }

  .mt-xs-n12,
  .my-xs-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-xs-n12,
  .mx-xs-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-xs-n12,
  .my-xs-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-xs-n12,
  .mx-xs-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-xs-n13 {
    margin: -3.125rem !important;
  }

  .mt-xs-n13,
  .my-xs-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-xs-n13,
  .mx-xs-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-xs-n13,
  .my-xs-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-xs-n13,
  .mx-xs-n13 {
    margin-left: -3.125rem !important;
  }

  .m-xs-n14 {
    margin: -3.4375rem !important;
  }

  .mt-xs-n14,
  .my-xs-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-xs-n14,
  .mx-xs-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-xs-n14,
  .my-xs-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-xs-n14,
  .mx-xs-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-xs-n15 {
    margin: -3.75rem !important;
  }

  .mt-xs-n15,
  .my-xs-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-xs-n15,
  .mx-xs-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-xs-n15,
  .my-xs-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-xs-n15,
  .mx-xs-n15 {
    margin-left: -3.75rem !important;
  }

  .m-xs-n16 {
    margin: -4.0625rem !important;
  }

  .mt-xs-n16,
  .my-xs-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-xs-n16,
  .mx-xs-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-xs-n16,
  .my-xs-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-xs-n16,
  .mx-xs-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-xs-n17 {
    margin: -4.375rem !important;
  }

  .mt-xs-n17,
  .my-xs-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-xs-n17,
  .mx-xs-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-xs-n17,
  .my-xs-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-xs-n17,
  .mx-xs-n17 {
    margin-left: -4.375rem !important;
  }

  .m-xs-n18 {
    margin: -4.6875rem !important;
  }

  .mt-xs-n18,
  .my-xs-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-xs-n18,
  .mx-xs-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-xs-n18,
  .my-xs-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-xs-n18,
  .mx-xs-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-xs-n19 {
    margin: -5rem !important;
  }

  .mt-xs-n19,
  .my-xs-n19 {
    margin-top: -5rem !important;
  }

  .mr-xs-n19,
  .mx-xs-n19 {
    margin-right: -5rem !important;
  }

  .mb-xs-n19,
  .my-xs-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-xs-n19,
  .mx-xs-n19 {
    margin-left: -5rem !important;
  }

  .m-xs-n20 {
    margin: -5.3125rem !important;
  }

  .mt-xs-n20,
  .my-xs-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-xs-n20,
  .mx-xs-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-xs-n20,
  .my-xs-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-xs-n20,
  .mx-xs-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-xs-n21 {
    margin: -5.625rem !important;
  }

  .mt-xs-n21,
  .my-xs-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-xs-n21,
  .mx-xs-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-xs-n21,
  .my-xs-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-xs-n21,
  .mx-xs-n21 {
    margin-left: -5.625rem !important;
  }

  .m-xs-n22 {
    margin: -5.9375rem !important;
  }

  .mt-xs-n22,
  .my-xs-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-xs-n22,
  .mx-xs-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-xs-n22,
  .my-xs-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-xs-n22,
  .mx-xs-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-xs-n23 {
    margin: -6.25rem !important;
  }

  .mt-xs-n23,
  .my-xs-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-xs-n23,
  .mx-xs-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-xs-n23,
  .my-xs-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-xs-n23,
  .mx-xs-n23 {
    margin-left: -6.25rem !important;
  }

  .m-xs-n24 {
    margin: -6.875rem !important;
  }

  .mt-xs-n24,
  .my-xs-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-xs-n24,
  .mx-xs-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-xs-n24,
  .my-xs-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-xs-n24,
  .mx-xs-n24 {
    margin-left: -6.875rem !important;
  }

  .m-xs-n25 {
    margin: -7.5rem !important;
  }

  .mt-xs-n25,
  .my-xs-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-xs-n25,
  .mx-xs-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-xs-n25,
  .my-xs-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-xs-n25,
  .mx-xs-n25 {
    margin-left: -7.5rem !important;
  }

  .m-xs-n26 {
    margin: -8.125rem !important;
  }

  .mt-xs-n26,
  .my-xs-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-xs-n26,
  .mx-xs-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-xs-n26,
  .my-xs-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-xs-n26,
  .mx-xs-n26 {
    margin-left: -8.125rem !important;
  }

  .m-xs-n27 {
    margin: -8.4375rem !important;
  }

  .mt-xs-n27,
  .my-xs-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-xs-n27,
  .mx-xs-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-xs-n27,
  .my-xs-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-xs-n27,
  .mx-xs-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-xs-n28 {
    margin: -9.0625rem !important;
  }

  .mt-xs-n28,
  .my-xs-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-xs-n28,
  .mx-xs-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-xs-n28,
  .my-xs-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-xs-n28,
  .mx-xs-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-xs-n29 {
    margin: -9.375rem !important;
  }

  .mt-xs-n29,
  .my-xs-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-xs-n29,
  .mx-xs-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-xs-n29,
  .my-xs-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-xs-n29,
  .mx-xs-n29 {
    margin-left: -9.375rem !important;
  }

  .m-xs-n30 {
    margin: -9.6875rem !important;
  }

  .mt-xs-n30,
  .my-xs-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-xs-n30,
  .mx-xs-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-xs-n30,
  .my-xs-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-xs-n30,
  .mx-xs-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-xs-n31 {
    margin: -10.625rem !important;
  }

  .mt-xs-n31,
  .my-xs-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-xs-n31,
  .mx-xs-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-xs-n31,
  .my-xs-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-xs-n31,
  .mx-xs-n31 {
    margin-left: -10.625rem !important;
  }

  .m-xs-n32 {
    margin: -11.25rem !important;
  }

  .mt-xs-n32,
  .my-xs-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-xs-n32,
  .mx-xs-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-xs-n32,
  .my-xs-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-xs-n32,
  .mx-xs-n32 {
    margin-left: -11.25rem !important;
  }

  .m-xs-n33 {
    margin: -12.5rem !important;
  }

  .mt-xs-n33,
  .my-xs-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-xs-n33,
  .mx-xs-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-xs-n33,
  .my-xs-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-xs-n33,
  .mx-xs-n33 {
    margin-left: -12.5rem !important;
  }

  .m-xs-n34 {
    margin: -14.0625rem !important;
  }

  .mt-xs-n34,
  .my-xs-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-xs-n34,
  .mx-xs-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-xs-n34,
  .my-xs-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-xs-n34,
  .mx-xs-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-xs-n35 {
    margin: -15.625rem !important;
  }

  .mt-xs-n35,
  .my-xs-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-xs-n35,
  .mx-xs-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-xs-n35,
  .my-xs-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-xs-n35,
  .mx-xs-n35 {
    margin-left: -15.625rem !important;
  }

  .m-xs-auto {
    margin: auto !important;
  }

  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }

  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }

  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }

  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.375rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.375rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.375rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.375rem !important;
  }

  .m-sm-3 {
    margin: 0.5rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.5rem !important;
  }

  .m-sm-4 {
    margin: 0.625rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 0.625rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 0.625rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 0.625rem !important;
  }

  .m-sm-5 {
    margin: 0.75rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 0.75rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 0.75rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 0.75rem !important;
  }

  .m-sm-6 {
    margin: 1rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1rem !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1rem !important;
  }

  .m-sm-7 {
    margin: 1.25rem !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 1.25rem !important;
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 1.25rem !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 1.25rem !important;
  }

  .m-sm-8 {
    margin: 1.5625rem !important;
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 1.5625rem !important;
  }

  .m-sm-9 {
    margin: 1.875rem !important;
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 1.875rem !important;
  }

  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 1.875rem !important;
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 1.875rem !important;
  }

  .m-sm-10 {
    margin: 2.1875rem !important;
  }

  .mt-sm-10,
  .my-sm-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 2.1875rem !important;
  }

  .m-sm-11 {
    margin: 2.5rem !important;
  }

  .mt-sm-11,
  .my-sm-11 {
    margin-top: 2.5rem !important;
  }

  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 2.5rem !important;
  }

  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 2.5rem !important;
  }

  .m-sm-12 {
    margin: 2.8125rem !important;
  }

  .mt-sm-12,
  .my-sm-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 2.8125rem !important;
  }

  .m-sm-13 {
    margin: 3.125rem !important;
  }

  .mt-sm-13,
  .my-sm-13 {
    margin-top: 3.125rem !important;
  }

  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 3.125rem !important;
  }

  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 3.125rem !important;
  }

  .m-sm-14 {
    margin: 3.4375rem !important;
  }

  .mt-sm-14,
  .my-sm-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 3.4375rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .mt-sm-15,
  .my-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 3.75rem !important;
  }

  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4.0625rem !important;
  }

  .mt-sm-16,
  .my-sm-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 4.0625rem !important;
  }

  .m-sm-17 {
    margin: 4.375rem !important;
  }

  .mt-sm-17,
  .my-sm-17 {
    margin-top: 4.375rem !important;
  }

  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 4.375rem !important;
  }

  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 4.375rem !important;
  }

  .m-sm-18 {
    margin: 4.6875rem !important;
  }

  .mt-sm-18,
  .my-sm-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 4.6875rem !important;
  }

  .m-sm-19 {
    margin: 5rem !important;
  }

  .mt-sm-19,
  .my-sm-19 {
    margin-top: 5rem !important;
  }

  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 5rem !important;
  }

  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 5rem !important;
  }

  .m-sm-20 {
    margin: 5.3125rem !important;
  }

  .mt-sm-20,
  .my-sm-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 5.3125rem !important;
  }

  .m-sm-21 {
    margin: 5.625rem !important;
  }

  .mt-sm-21,
  .my-sm-21 {
    margin-top: 5.625rem !important;
  }

  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 5.625rem !important;
  }

  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 5.625rem !important;
  }

  .m-sm-22 {
    margin: 5.9375rem !important;
  }

  .mt-sm-22,
  .my-sm-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 5.9375rem !important;
  }

  .m-sm-23 {
    margin: 6.25rem !important;
  }

  .mt-sm-23,
  .my-sm-23 {
    margin-top: 6.25rem !important;
  }

  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 6.25rem !important;
  }

  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 6.25rem !important;
  }

  .m-sm-24 {
    margin: 6.875rem !important;
  }

  .mt-sm-24,
  .my-sm-24 {
    margin-top: 6.875rem !important;
  }

  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 6.875rem !important;
  }

  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 6.875rem !important;
  }

  .m-sm-25 {
    margin: 7.5rem !important;
  }

  .mt-sm-25,
  .my-sm-25 {
    margin-top: 7.5rem !important;
  }

  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 7.5rem !important;
  }

  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 7.5rem !important;
  }

  .m-sm-26 {
    margin: 8.125rem !important;
  }

  .mt-sm-26,
  .my-sm-26 {
    margin-top: 8.125rem !important;
  }

  .mr-sm-26,
  .mx-sm-26 {
    margin-right: 8.125rem !important;
  }

  .mb-sm-26,
  .my-sm-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-sm-26,
  .mx-sm-26 {
    margin-left: 8.125rem !important;
  }

  .m-sm-27 {
    margin: 8.4375rem !important;
  }

  .mt-sm-27,
  .my-sm-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-sm-27,
  .mx-sm-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-sm-27,
  .my-sm-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-sm-27,
  .mx-sm-27 {
    margin-left: 8.4375rem !important;
  }

  .m-sm-28 {
    margin: 9.0625rem !important;
  }

  .mt-sm-28,
  .my-sm-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-sm-28,
  .mx-sm-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-sm-28,
  .my-sm-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-sm-28,
  .mx-sm-28 {
    margin-left: 9.0625rem !important;
  }

  .m-sm-29 {
    margin: 9.375rem !important;
  }

  .mt-sm-29,
  .my-sm-29 {
    margin-top: 9.375rem !important;
  }

  .mr-sm-29,
  .mx-sm-29 {
    margin-right: 9.375rem !important;
  }

  .mb-sm-29,
  .my-sm-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-sm-29,
  .mx-sm-29 {
    margin-left: 9.375rem !important;
  }

  .m-sm-30 {
    margin: 9.6875rem !important;
  }

  .mt-sm-30,
  .my-sm-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 9.6875rem !important;
  }

  .m-sm-31 {
    margin: 10.625rem !important;
  }

  .mt-sm-31,
  .my-sm-31 {
    margin-top: 10.625rem !important;
  }

  .mr-sm-31,
  .mx-sm-31 {
    margin-right: 10.625rem !important;
  }

  .mb-sm-31,
  .my-sm-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-sm-31,
  .mx-sm-31 {
    margin-left: 10.625rem !important;
  }

  .m-sm-32 {
    margin: 11.25rem !important;
  }

  .mt-sm-32,
  .my-sm-32 {
    margin-top: 11.25rem !important;
  }

  .mr-sm-32,
  .mx-sm-32 {
    margin-right: 11.25rem !important;
  }

  .mb-sm-32,
  .my-sm-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-sm-32,
  .mx-sm-32 {
    margin-left: 11.25rem !important;
  }

  .m-sm-33 {
    margin: 12.5rem !important;
  }

  .mt-sm-33,
  .my-sm-33 {
    margin-top: 12.5rem !important;
  }

  .mr-sm-33,
  .mx-sm-33 {
    margin-right: 12.5rem !important;
  }

  .mb-sm-33,
  .my-sm-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-sm-33,
  .mx-sm-33 {
    margin-left: 12.5rem !important;
  }

  .m-sm-34 {
    margin: 14.0625rem !important;
  }

  .mt-sm-34,
  .my-sm-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-sm-34,
  .mx-sm-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-sm-34,
  .my-sm-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-sm-34,
  .mx-sm-34 {
    margin-left: 14.0625rem !important;
  }

  .m-sm-35 {
    margin: 15.625rem !important;
  }

  .mt-sm-35,
  .my-sm-35 {
    margin-top: 15.625rem !important;
  }

  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 15.625rem !important;
  }

  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 15.625rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.375rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.375rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.375rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.375rem !important;
  }

  .p-sm-3 {
    padding: 0.5rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.5rem !important;
  }

  .p-sm-4 {
    padding: 0.625rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 0.625rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 0.625rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 0.625rem !important;
  }

  .p-sm-5 {
    padding: 0.75rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 0.75rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 0.75rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 0.75rem !important;
  }

  .p-sm-6 {
    padding: 1rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1rem !important;
  }

  .p-sm-7 {
    padding: 1.25rem !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 1.25rem !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 1.25rem !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 1.25rem !important;
  }

  .p-sm-8 {
    padding: 1.5625rem !important;
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 1.5625rem !important;
  }

  .p-sm-9 {
    padding: 1.875rem !important;
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 1.875rem !important;
  }

  .pr-sm-9,
  .px-sm-9 {
    padding-right: 1.875rem !important;
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-sm-9,
  .px-sm-9 {
    padding-left: 1.875rem !important;
  }

  .p-sm-10 {
    padding: 2.1875rem !important;
  }

  .pt-sm-10,
  .py-sm-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-sm-10,
  .px-sm-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-sm-10,
  .px-sm-10 {
    padding-left: 2.1875rem !important;
  }

  .p-sm-11 {
    padding: 2.5rem !important;
  }

  .pt-sm-11,
  .py-sm-11 {
    padding-top: 2.5rem !important;
  }

  .pr-sm-11,
  .px-sm-11 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-sm-11,
  .px-sm-11 {
    padding-left: 2.5rem !important;
  }

  .p-sm-12 {
    padding: 2.8125rem !important;
  }

  .pt-sm-12,
  .py-sm-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-sm-12,
  .px-sm-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-sm-12,
  .px-sm-12 {
    padding-left: 2.8125rem !important;
  }

  .p-sm-13 {
    padding: 3.125rem !important;
  }

  .pt-sm-13,
  .py-sm-13 {
    padding-top: 3.125rem !important;
  }

  .pr-sm-13,
  .px-sm-13 {
    padding-right: 3.125rem !important;
  }

  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-sm-13,
  .px-sm-13 {
    padding-left: 3.125rem !important;
  }

  .p-sm-14 {
    padding: 3.4375rem !important;
  }

  .pt-sm-14,
  .py-sm-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-sm-14,
  .px-sm-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-sm-14,
  .px-sm-14 {
    padding-left: 3.4375rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .pt-sm-15,
  .py-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pr-sm-15,
  .px-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-sm-15,
  .px-sm-15 {
    padding-left: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4.0625rem !important;
  }

  .pt-sm-16,
  .py-sm-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-sm-16,
  .px-sm-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-sm-16,
  .px-sm-16 {
    padding-left: 4.0625rem !important;
  }

  .p-sm-17 {
    padding: 4.375rem !important;
  }

  .pt-sm-17,
  .py-sm-17 {
    padding-top: 4.375rem !important;
  }

  .pr-sm-17,
  .px-sm-17 {
    padding-right: 4.375rem !important;
  }

  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-sm-17,
  .px-sm-17 {
    padding-left: 4.375rem !important;
  }

  .p-sm-18 {
    padding: 4.6875rem !important;
  }

  .pt-sm-18,
  .py-sm-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-sm-18,
  .px-sm-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-sm-18,
  .px-sm-18 {
    padding-left: 4.6875rem !important;
  }

  .p-sm-19 {
    padding: 5rem !important;
  }

  .pt-sm-19,
  .py-sm-19 {
    padding-top: 5rem !important;
  }

  .pr-sm-19,
  .px-sm-19 {
    padding-right: 5rem !important;
  }

  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-19,
  .px-sm-19 {
    padding-left: 5rem !important;
  }

  .p-sm-20 {
    padding: 5.3125rem !important;
  }

  .pt-sm-20,
  .py-sm-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-sm-20,
  .px-sm-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-sm-20,
  .px-sm-20 {
    padding-left: 5.3125rem !important;
  }

  .p-sm-21 {
    padding: 5.625rem !important;
  }

  .pt-sm-21,
  .py-sm-21 {
    padding-top: 5.625rem !important;
  }

  .pr-sm-21,
  .px-sm-21 {
    padding-right: 5.625rem !important;
  }

  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-sm-21,
  .px-sm-21 {
    padding-left: 5.625rem !important;
  }

  .p-sm-22 {
    padding: 5.9375rem !important;
  }

  .pt-sm-22,
  .py-sm-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-sm-22,
  .px-sm-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-sm-22,
  .px-sm-22 {
    padding-left: 5.9375rem !important;
  }

  .p-sm-23 {
    padding: 6.25rem !important;
  }

  .pt-sm-23,
  .py-sm-23 {
    padding-top: 6.25rem !important;
  }

  .pr-sm-23,
  .px-sm-23 {
    padding-right: 6.25rem !important;
  }

  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-sm-23,
  .px-sm-23 {
    padding-left: 6.25rem !important;
  }

  .p-sm-24 {
    padding: 6.875rem !important;
  }

  .pt-sm-24,
  .py-sm-24 {
    padding-top: 6.875rem !important;
  }

  .pr-sm-24,
  .px-sm-24 {
    padding-right: 6.875rem !important;
  }

  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-sm-24,
  .px-sm-24 {
    padding-left: 6.875rem !important;
  }

  .p-sm-25 {
    padding: 7.5rem !important;
  }

  .pt-sm-25,
  .py-sm-25 {
    padding-top: 7.5rem !important;
  }

  .pr-sm-25,
  .px-sm-25 {
    padding-right: 7.5rem !important;
  }

  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-sm-25,
  .px-sm-25 {
    padding-left: 7.5rem !important;
  }

  .p-sm-26 {
    padding: 8.125rem !important;
  }

  .pt-sm-26,
  .py-sm-26 {
    padding-top: 8.125rem !important;
  }

  .pr-sm-26,
  .px-sm-26 {
    padding-right: 8.125rem !important;
  }

  .pb-sm-26,
  .py-sm-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-sm-26,
  .px-sm-26 {
    padding-left: 8.125rem !important;
  }

  .p-sm-27 {
    padding: 8.4375rem !important;
  }

  .pt-sm-27,
  .py-sm-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-sm-27,
  .px-sm-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-sm-27,
  .py-sm-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-sm-27,
  .px-sm-27 {
    padding-left: 8.4375rem !important;
  }

  .p-sm-28 {
    padding: 9.0625rem !important;
  }

  .pt-sm-28,
  .py-sm-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-sm-28,
  .px-sm-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-sm-28,
  .py-sm-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-sm-28,
  .px-sm-28 {
    padding-left: 9.0625rem !important;
  }

  .p-sm-29 {
    padding: 9.375rem !important;
  }

  .pt-sm-29,
  .py-sm-29 {
    padding-top: 9.375rem !important;
  }

  .pr-sm-29,
  .px-sm-29 {
    padding-right: 9.375rem !important;
  }

  .pb-sm-29,
  .py-sm-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-sm-29,
  .px-sm-29 {
    padding-left: 9.375rem !important;
  }

  .p-sm-30 {
    padding: 9.6875rem !important;
  }

  .pt-sm-30,
  .py-sm-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-sm-30,
  .px-sm-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-sm-30,
  .px-sm-30 {
    padding-left: 9.6875rem !important;
  }

  .p-sm-31 {
    padding: 10.625rem !important;
  }

  .pt-sm-31,
  .py-sm-31 {
    padding-top: 10.625rem !important;
  }

  .pr-sm-31,
  .px-sm-31 {
    padding-right: 10.625rem !important;
  }

  .pb-sm-31,
  .py-sm-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-sm-31,
  .px-sm-31 {
    padding-left: 10.625rem !important;
  }

  .p-sm-32 {
    padding: 11.25rem !important;
  }

  .pt-sm-32,
  .py-sm-32 {
    padding-top: 11.25rem !important;
  }

  .pr-sm-32,
  .px-sm-32 {
    padding-right: 11.25rem !important;
  }

  .pb-sm-32,
  .py-sm-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-sm-32,
  .px-sm-32 {
    padding-left: 11.25rem !important;
  }

  .p-sm-33 {
    padding: 12.5rem !important;
  }

  .pt-sm-33,
  .py-sm-33 {
    padding-top: 12.5rem !important;
  }

  .pr-sm-33,
  .px-sm-33 {
    padding-right: 12.5rem !important;
  }

  .pb-sm-33,
  .py-sm-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-sm-33,
  .px-sm-33 {
    padding-left: 12.5rem !important;
  }

  .p-sm-34 {
    padding: 14.0625rem !important;
  }

  .pt-sm-34,
  .py-sm-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-sm-34,
  .px-sm-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-sm-34,
  .py-sm-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-sm-34,
  .px-sm-34 {
    padding-left: 14.0625rem !important;
  }

  .p-sm-35 {
    padding: 15.625rem !important;
  }

  .pt-sm-35,
  .py-sm-35 {
    padding-top: 15.625rem !important;
  }

  .pr-sm-35,
  .px-sm-35 {
    padding-right: 15.625rem !important;
  }

  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-sm-35,
  .px-sm-35 {
    padding-left: 15.625rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.375rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.375rem !important;
  }

  .m-sm-n3 {
    margin: -0.5rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n4 {
    margin: -0.625rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.625rem !important;
  }

  .m-sm-n5 {
    margin: -0.75rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.75rem !important;
  }

  .m-sm-n6 {
    margin: -1rem !important;
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1rem !important;
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1rem !important;
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1rem !important;
  }

  .m-sm-n7 {
    margin: -1.25rem !important;
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -1.25rem !important;
  }

  .m-sm-n8 {
    margin: -1.5625rem !important;
  }

  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-sm-n9 {
    margin: -1.875rem !important;
  }

  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -1.875rem !important;
  }

  .m-sm-n10 {
    margin: -2.1875rem !important;
  }

  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-sm-n11 {
    margin: -2.5rem !important;
  }

  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -2.5rem !important;
  }

  .m-sm-n12 {
    margin: -2.8125rem !important;
  }

  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-sm-n13 {
    margin: -3.125rem !important;
  }

  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -3.125rem !important;
  }

  .m-sm-n14 {
    margin: -3.4375rem !important;
  }

  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4.0625rem !important;
  }

  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-sm-n17 {
    margin: -4.375rem !important;
  }

  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -4.375rem !important;
  }

  .m-sm-n18 {
    margin: -4.6875rem !important;
  }

  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-sm-n19 {
    margin: -5rem !important;
  }

  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -5rem !important;
  }

  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -5rem !important;
  }

  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -5rem !important;
  }

  .m-sm-n20 {
    margin: -5.3125rem !important;
  }

  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-sm-n21 {
    margin: -5.625rem !important;
  }

  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -5.625rem !important;
  }

  .m-sm-n22 {
    margin: -5.9375rem !important;
  }

  .mt-sm-n22,
  .my-sm-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-sm-n22,
  .mx-sm-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-sm-n22,
  .my-sm-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-sm-n22,
  .mx-sm-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-sm-n23 {
    margin: -6.25rem !important;
  }

  .mt-sm-n23,
  .my-sm-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-sm-n23,
  .mx-sm-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-sm-n23,
  .my-sm-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-sm-n23,
  .mx-sm-n23 {
    margin-left: -6.25rem !important;
  }

  .m-sm-n24 {
    margin: -6.875rem !important;
  }

  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -6.875rem !important;
  }

  .m-sm-n25 {
    margin: -7.5rem !important;
  }

  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -7.5rem !important;
  }

  .m-sm-n26 {
    margin: -8.125rem !important;
  }

  .mt-sm-n26,
  .my-sm-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-sm-n26,
  .mx-sm-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-sm-n26,
  .my-sm-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-sm-n26,
  .mx-sm-n26 {
    margin-left: -8.125rem !important;
  }

  .m-sm-n27 {
    margin: -8.4375rem !important;
  }

  .mt-sm-n27,
  .my-sm-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-sm-n27,
  .mx-sm-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-sm-n27,
  .my-sm-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-sm-n27,
  .mx-sm-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-sm-n28 {
    margin: -9.0625rem !important;
  }

  .mt-sm-n28,
  .my-sm-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-sm-n28,
  .mx-sm-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-sm-n28,
  .my-sm-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-sm-n28,
  .mx-sm-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-sm-n29 {
    margin: -9.375rem !important;
  }

  .mt-sm-n29,
  .my-sm-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-sm-n29,
  .mx-sm-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-sm-n29,
  .my-sm-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-sm-n29,
  .mx-sm-n29 {
    margin-left: -9.375rem !important;
  }

  .m-sm-n30 {
    margin: -9.6875rem !important;
  }

  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-sm-n31 {
    margin: -10.625rem !important;
  }

  .mt-sm-n31,
  .my-sm-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-sm-n31,
  .mx-sm-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-sm-n31,
  .my-sm-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-sm-n31,
  .mx-sm-n31 {
    margin-left: -10.625rem !important;
  }

  .m-sm-n32 {
    margin: -11.25rem !important;
  }

  .mt-sm-n32,
  .my-sm-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-sm-n32,
  .mx-sm-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-sm-n32,
  .my-sm-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-sm-n32,
  .mx-sm-n32 {
    margin-left: -11.25rem !important;
  }

  .m-sm-n33 {
    margin: -12.5rem !important;
  }

  .mt-sm-n33,
  .my-sm-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-sm-n33,
  .mx-sm-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-sm-n33,
  .my-sm-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-sm-n33,
  .mx-sm-n33 {
    margin-left: -12.5rem !important;
  }

  .m-sm-n34 {
    margin: -14.0625rem !important;
  }

  .mt-sm-n34,
  .my-sm-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-sm-n34,
  .mx-sm-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-sm-n34,
  .my-sm-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-sm-n34,
  .mx-sm-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-sm-n35 {
    margin: -15.625rem !important;
  }

  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -15.625rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.375rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.375rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.375rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.375rem !important;
  }

  .m-md-3 {
    margin: 0.5rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 0.5rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.5rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.5rem !important;
  }

  .m-md-4 {
    margin: 0.625rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 0.625rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 0.625rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 0.625rem !important;
  }

  .m-md-5 {
    margin: 0.75rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 0.75rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 0.75rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 0.75rem !important;
  }

  .m-md-6 {
    margin: 1rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 1rem !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 1rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1rem !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 1rem !important;
  }

  .m-md-7 {
    margin: 1.25rem !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 1.25rem !important;
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 1.25rem !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 1.25rem !important;
  }

  .m-md-8 {
    margin: 1.5625rem !important;
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 1.5625rem !important;
  }

  .m-md-9 {
    margin: 1.875rem !important;
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 1.875rem !important;
  }

  .mr-md-9,
  .mx-md-9 {
    margin-right: 1.875rem !important;
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-md-9,
  .mx-md-9 {
    margin-left: 1.875rem !important;
  }

  .m-md-10 {
    margin: 2.1875rem !important;
  }

  .mt-md-10,
  .my-md-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-md-10,
  .mx-md-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-md-10,
  .my-md-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-md-10,
  .mx-md-10 {
    margin-left: 2.1875rem !important;
  }

  .m-md-11 {
    margin: 2.5rem !important;
  }

  .mt-md-11,
  .my-md-11 {
    margin-top: 2.5rem !important;
  }

  .mr-md-11,
  .mx-md-11 {
    margin-right: 2.5rem !important;
  }

  .mb-md-11,
  .my-md-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-md-11,
  .mx-md-11 {
    margin-left: 2.5rem !important;
  }

  .m-md-12 {
    margin: 2.8125rem !important;
  }

  .mt-md-12,
  .my-md-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-md-12,
  .mx-md-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-md-12,
  .my-md-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-md-12,
  .mx-md-12 {
    margin-left: 2.8125rem !important;
  }

  .m-md-13 {
    margin: 3.125rem !important;
  }

  .mt-md-13,
  .my-md-13 {
    margin-top: 3.125rem !important;
  }

  .mr-md-13,
  .mx-md-13 {
    margin-right: 3.125rem !important;
  }

  .mb-md-13,
  .my-md-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-md-13,
  .mx-md-13 {
    margin-left: 3.125rem !important;
  }

  .m-md-14 {
    margin: 3.4375rem !important;
  }

  .mt-md-14,
  .my-md-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-md-14,
  .mx-md-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-md-14,
  .my-md-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-md-14,
  .mx-md-14 {
    margin-left: 3.4375rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .mt-md-15,
  .my-md-15 {
    margin-top: 3.75rem !important;
  }

  .mr-md-15,
  .mx-md-15 {
    margin-right: 3.75rem !important;
  }

  .mb-md-15,
  .my-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-md-15,
  .mx-md-15 {
    margin-left: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4.0625rem !important;
  }

  .mt-md-16,
  .my-md-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-md-16,
  .mx-md-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-md-16,
  .my-md-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-md-16,
  .mx-md-16 {
    margin-left: 4.0625rem !important;
  }

  .m-md-17 {
    margin: 4.375rem !important;
  }

  .mt-md-17,
  .my-md-17 {
    margin-top: 4.375rem !important;
  }

  .mr-md-17,
  .mx-md-17 {
    margin-right: 4.375rem !important;
  }

  .mb-md-17,
  .my-md-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-md-17,
  .mx-md-17 {
    margin-left: 4.375rem !important;
  }

  .m-md-18 {
    margin: 4.6875rem !important;
  }

  .mt-md-18,
  .my-md-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-md-18,
  .mx-md-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-md-18,
  .my-md-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-md-18,
  .mx-md-18 {
    margin-left: 4.6875rem !important;
  }

  .m-md-19 {
    margin: 5rem !important;
  }

  .mt-md-19,
  .my-md-19 {
    margin-top: 5rem !important;
  }

  .mr-md-19,
  .mx-md-19 {
    margin-right: 5rem !important;
  }

  .mb-md-19,
  .my-md-19 {
    margin-bottom: 5rem !important;
  }

  .ml-md-19,
  .mx-md-19 {
    margin-left: 5rem !important;
  }

  .m-md-20 {
    margin: 5.3125rem !important;
  }

  .mt-md-20,
  .my-md-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-md-20,
  .mx-md-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-md-20,
  .my-md-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-md-20,
  .mx-md-20 {
    margin-left: 5.3125rem !important;
  }

  .m-md-21 {
    margin: 5.625rem !important;
  }

  .mt-md-21,
  .my-md-21 {
    margin-top: 5.625rem !important;
  }

  .mr-md-21,
  .mx-md-21 {
    margin-right: 5.625rem !important;
  }

  .mb-md-21,
  .my-md-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-md-21,
  .mx-md-21 {
    margin-left: 5.625rem !important;
  }

  .m-md-22 {
    margin: 5.9375rem !important;
  }

  .mt-md-22,
  .my-md-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-md-22,
  .mx-md-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-md-22,
  .my-md-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-md-22,
  .mx-md-22 {
    margin-left: 5.9375rem !important;
  }

  .m-md-23 {
    margin: 6.25rem !important;
  }

  .mt-md-23,
  .my-md-23 {
    margin-top: 6.25rem !important;
  }

  .mr-md-23,
  .mx-md-23 {
    margin-right: 6.25rem !important;
  }

  .mb-md-23,
  .my-md-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-md-23,
  .mx-md-23 {
    margin-left: 6.25rem !important;
  }

  .m-md-24 {
    margin: 6.875rem !important;
  }

  .mt-md-24,
  .my-md-24 {
    margin-top: 6.875rem !important;
  }

  .mr-md-24,
  .mx-md-24 {
    margin-right: 6.875rem !important;
  }

  .mb-md-24,
  .my-md-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-md-24,
  .mx-md-24 {
    margin-left: 6.875rem !important;
  }

  .m-md-25 {
    margin: 7.5rem !important;
  }

  .mt-md-25,
  .my-md-25 {
    margin-top: 7.5rem !important;
  }

  .mr-md-25,
  .mx-md-25 {
    margin-right: 7.5rem !important;
  }

  .mb-md-25,
  .my-md-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-md-25,
  .mx-md-25 {
    margin-left: 7.5rem !important;
  }

  .m-md-26 {
    margin: 8.125rem !important;
  }

  .mt-md-26,
  .my-md-26 {
    margin-top: 8.125rem !important;
  }

  .mr-md-26,
  .mx-md-26 {
    margin-right: 8.125rem !important;
  }

  .mb-md-26,
  .my-md-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-md-26,
  .mx-md-26 {
    margin-left: 8.125rem !important;
  }

  .m-md-27 {
    margin: 8.4375rem !important;
  }

  .mt-md-27,
  .my-md-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-md-27,
  .mx-md-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-md-27,
  .my-md-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-md-27,
  .mx-md-27 {
    margin-left: 8.4375rem !important;
  }

  .m-md-28 {
    margin: 9.0625rem !important;
  }

  .mt-md-28,
  .my-md-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-md-28,
  .mx-md-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-md-28,
  .my-md-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-md-28,
  .mx-md-28 {
    margin-left: 9.0625rem !important;
  }

  .m-md-29 {
    margin: 9.375rem !important;
  }

  .mt-md-29,
  .my-md-29 {
    margin-top: 9.375rem !important;
  }

  .mr-md-29,
  .mx-md-29 {
    margin-right: 9.375rem !important;
  }

  .mb-md-29,
  .my-md-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-md-29,
  .mx-md-29 {
    margin-left: 9.375rem !important;
  }

  .m-md-30 {
    margin: 9.6875rem !important;
  }

  .mt-md-30,
  .my-md-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-md-30,
  .mx-md-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-md-30,
  .my-md-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-md-30,
  .mx-md-30 {
    margin-left: 9.6875rem !important;
  }

  .m-md-31 {
    margin: 10.625rem !important;
  }

  .mt-md-31,
  .my-md-31 {
    margin-top: 10.625rem !important;
  }

  .mr-md-31,
  .mx-md-31 {
    margin-right: 10.625rem !important;
  }

  .mb-md-31,
  .my-md-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-md-31,
  .mx-md-31 {
    margin-left: 10.625rem !important;
  }

  .m-md-32 {
    margin: 11.25rem !important;
  }

  .mt-md-32,
  .my-md-32 {
    margin-top: 11.25rem !important;
  }

  .mr-md-32,
  .mx-md-32 {
    margin-right: 11.25rem !important;
  }

  .mb-md-32,
  .my-md-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-md-32,
  .mx-md-32 {
    margin-left: 11.25rem !important;
  }

  .m-md-33 {
    margin: 12.5rem !important;
  }

  .mt-md-33,
  .my-md-33 {
    margin-top: 12.5rem !important;
  }

  .mr-md-33,
  .mx-md-33 {
    margin-right: 12.5rem !important;
  }

  .mb-md-33,
  .my-md-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-md-33,
  .mx-md-33 {
    margin-left: 12.5rem !important;
  }

  .m-md-34 {
    margin: 14.0625rem !important;
  }

  .mt-md-34,
  .my-md-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-md-34,
  .mx-md-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-md-34,
  .my-md-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-md-34,
  .mx-md-34 {
    margin-left: 14.0625rem !important;
  }

  .m-md-35 {
    margin: 15.625rem !important;
  }

  .mt-md-35,
  .my-md-35 {
    margin-top: 15.625rem !important;
  }

  .mr-md-35,
  .mx-md-35 {
    margin-right: 15.625rem !important;
  }

  .mb-md-35,
  .my-md-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-md-35,
  .mx-md-35 {
    margin-left: 15.625rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.375rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.375rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.375rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.375rem !important;
  }

  .p-md-3 {
    padding: 0.5rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 0.5rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 0.5rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 0.5rem !important;
  }

  .p-md-4 {
    padding: 0.625rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 0.625rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 0.625rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 0.625rem !important;
  }

  .p-md-5 {
    padding: 0.75rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 0.75rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 0.75rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 0.75rem !important;
  }

  .p-md-6 {
    padding: 1rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 1rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 1rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 1rem !important;
  }

  .p-md-7 {
    padding: 1.25rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 1.25rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 1.25rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 1.25rem !important;
  }

  .p-md-8 {
    padding: 1.5625rem !important;
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-md-8,
  .px-md-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-md-8,
  .px-md-8 {
    padding-left: 1.5625rem !important;
  }

  .p-md-9 {
    padding: 1.875rem !important;
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 1.875rem !important;
  }

  .pr-md-9,
  .px-md-9 {
    padding-right: 1.875rem !important;
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-md-9,
  .px-md-9 {
    padding-left: 1.875rem !important;
  }

  .p-md-10 {
    padding: 2.1875rem !important;
  }

  .pt-md-10,
  .py-md-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-md-10,
  .px-md-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-md-10,
  .py-md-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-md-10,
  .px-md-10 {
    padding-left: 2.1875rem !important;
  }

  .p-md-11 {
    padding: 2.5rem !important;
  }

  .pt-md-11,
  .py-md-11 {
    padding-top: 2.5rem !important;
  }

  .pr-md-11,
  .px-md-11 {
    padding-right: 2.5rem !important;
  }

  .pb-md-11,
  .py-md-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-md-11,
  .px-md-11 {
    padding-left: 2.5rem !important;
  }

  .p-md-12 {
    padding: 2.8125rem !important;
  }

  .pt-md-12,
  .py-md-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-md-12,
  .px-md-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-md-12,
  .py-md-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-md-12,
  .px-md-12 {
    padding-left: 2.8125rem !important;
  }

  .p-md-13 {
    padding: 3.125rem !important;
  }

  .pt-md-13,
  .py-md-13 {
    padding-top: 3.125rem !important;
  }

  .pr-md-13,
  .px-md-13 {
    padding-right: 3.125rem !important;
  }

  .pb-md-13,
  .py-md-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-md-13,
  .px-md-13 {
    padding-left: 3.125rem !important;
  }

  .p-md-14 {
    padding: 3.4375rem !important;
  }

  .pt-md-14,
  .py-md-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-md-14,
  .px-md-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-md-14,
  .py-md-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-md-14,
  .px-md-14 {
    padding-left: 3.4375rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .pt-md-15,
  .py-md-15 {
    padding-top: 3.75rem !important;
  }

  .pr-md-15,
  .px-md-15 {
    padding-right: 3.75rem !important;
  }

  .pb-md-15,
  .py-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-md-15,
  .px-md-15 {
    padding-left: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4.0625rem !important;
  }

  .pt-md-16,
  .py-md-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-md-16,
  .px-md-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-md-16,
  .py-md-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-md-16,
  .px-md-16 {
    padding-left: 4.0625rem !important;
  }

  .p-md-17 {
    padding: 4.375rem !important;
  }

  .pt-md-17,
  .py-md-17 {
    padding-top: 4.375rem !important;
  }

  .pr-md-17,
  .px-md-17 {
    padding-right: 4.375rem !important;
  }

  .pb-md-17,
  .py-md-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-md-17,
  .px-md-17 {
    padding-left: 4.375rem !important;
  }

  .p-md-18 {
    padding: 4.6875rem !important;
  }

  .pt-md-18,
  .py-md-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-md-18,
  .px-md-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-md-18,
  .py-md-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-md-18,
  .px-md-18 {
    padding-left: 4.6875rem !important;
  }

  .p-md-19 {
    padding: 5rem !important;
  }

  .pt-md-19,
  .py-md-19 {
    padding-top: 5rem !important;
  }

  .pr-md-19,
  .px-md-19 {
    padding-right: 5rem !important;
  }

  .pb-md-19,
  .py-md-19 {
    padding-bottom: 5rem !important;
  }

  .pl-md-19,
  .px-md-19 {
    padding-left: 5rem !important;
  }

  .p-md-20 {
    padding: 5.3125rem !important;
  }

  .pt-md-20,
  .py-md-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-md-20,
  .px-md-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-md-20,
  .py-md-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-md-20,
  .px-md-20 {
    padding-left: 5.3125rem !important;
  }

  .p-md-21 {
    padding: 5.625rem !important;
  }

  .pt-md-21,
  .py-md-21 {
    padding-top: 5.625rem !important;
  }

  .pr-md-21,
  .px-md-21 {
    padding-right: 5.625rem !important;
  }

  .pb-md-21,
  .py-md-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-md-21,
  .px-md-21 {
    padding-left: 5.625rem !important;
  }

  .p-md-22 {
    padding: 5.9375rem !important;
  }

  .pt-md-22,
  .py-md-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-md-22,
  .px-md-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-md-22,
  .py-md-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-md-22,
  .px-md-22 {
    padding-left: 5.9375rem !important;
  }

  .p-md-23 {
    padding: 6.25rem !important;
  }

  .pt-md-23,
  .py-md-23 {
    padding-top: 6.25rem !important;
  }

  .pr-md-23,
  .px-md-23 {
    padding-right: 6.25rem !important;
  }

  .pb-md-23,
  .py-md-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-md-23,
  .px-md-23 {
    padding-left: 6.25rem !important;
  }

  .p-md-24 {
    padding: 6.875rem !important;
  }

  .pt-md-24,
  .py-md-24 {
    padding-top: 6.875rem !important;
  }

  .pr-md-24,
  .px-md-24 {
    padding-right: 6.875rem !important;
  }

  .pb-md-24,
  .py-md-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-md-24,
  .px-md-24 {
    padding-left: 6.875rem !important;
  }

  .p-md-25 {
    padding: 7.5rem !important;
  }

  .pt-md-25,
  .py-md-25 {
    padding-top: 7.5rem !important;
  }

  .pr-md-25,
  .px-md-25 {
    padding-right: 7.5rem !important;
  }

  .pb-md-25,
  .py-md-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-md-25,
  .px-md-25 {
    padding-left: 7.5rem !important;
  }

  .p-md-26 {
    padding: 8.125rem !important;
  }

  .pt-md-26,
  .py-md-26 {
    padding-top: 8.125rem !important;
  }

  .pr-md-26,
  .px-md-26 {
    padding-right: 8.125rem !important;
  }

  .pb-md-26,
  .py-md-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-md-26,
  .px-md-26 {
    padding-left: 8.125rem !important;
  }

  .p-md-27 {
    padding: 8.4375rem !important;
  }

  .pt-md-27,
  .py-md-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-md-27,
  .px-md-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-md-27,
  .py-md-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-md-27,
  .px-md-27 {
    padding-left: 8.4375rem !important;
  }

  .p-md-28 {
    padding: 9.0625rem !important;
  }

  .pt-md-28,
  .py-md-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-md-28,
  .px-md-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-md-28,
  .py-md-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-md-28,
  .px-md-28 {
    padding-left: 9.0625rem !important;
  }

  .p-md-29 {
    padding: 9.375rem !important;
  }

  .pt-md-29,
  .py-md-29 {
    padding-top: 9.375rem !important;
  }

  .pr-md-29,
  .px-md-29 {
    padding-right: 9.375rem !important;
  }

  .pb-md-29,
  .py-md-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-md-29,
  .px-md-29 {
    padding-left: 9.375rem !important;
  }

  .p-md-30 {
    padding: 9.6875rem !important;
  }

  .pt-md-30,
  .py-md-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-md-30,
  .px-md-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-md-30,
  .py-md-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-md-30,
  .px-md-30 {
    padding-left: 9.6875rem !important;
  }

  .p-md-31 {
    padding: 10.625rem !important;
  }

  .pt-md-31,
  .py-md-31 {
    padding-top: 10.625rem !important;
  }

  .pr-md-31,
  .px-md-31 {
    padding-right: 10.625rem !important;
  }

  .pb-md-31,
  .py-md-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-md-31,
  .px-md-31 {
    padding-left: 10.625rem !important;
  }

  .p-md-32 {
    padding: 11.25rem !important;
  }

  .pt-md-32,
  .py-md-32 {
    padding-top: 11.25rem !important;
  }

  .pr-md-32,
  .px-md-32 {
    padding-right: 11.25rem !important;
  }

  .pb-md-32,
  .py-md-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-md-32,
  .px-md-32 {
    padding-left: 11.25rem !important;
  }

  .p-md-33 {
    padding: 12.5rem !important;
  }

  .pt-md-33,
  .py-md-33 {
    padding-top: 12.5rem !important;
  }

  .pr-md-33,
  .px-md-33 {
    padding-right: 12.5rem !important;
  }

  .pb-md-33,
  .py-md-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-md-33,
  .px-md-33 {
    padding-left: 12.5rem !important;
  }

  .p-md-34 {
    padding: 14.0625rem !important;
  }

  .pt-md-34,
  .py-md-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-md-34,
  .px-md-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-md-34,
  .py-md-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-md-34,
  .px-md-34 {
    padding-left: 14.0625rem !important;
  }

  .p-md-35 {
    padding: 15.625rem !important;
  }

  .pt-md-35,
  .py-md-35 {
    padding-top: 15.625rem !important;
  }

  .pr-md-35,
  .px-md-35 {
    padding-right: 15.625rem !important;
  }

  .pb-md-35,
  .py-md-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-md-35,
  .px-md-35 {
    padding-left: 15.625rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.375rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.375rem !important;
  }

  .m-md-n3 {
    margin: -0.5rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.5rem !important;
  }

  .m-md-n4 {
    margin: -0.625rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.625rem !important;
  }

  .m-md-n5 {
    margin: -0.75rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.75rem !important;
  }

  .m-md-n6 {
    margin: -1rem !important;
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1rem !important;
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1rem !important;
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1rem !important;
  }

  .m-md-n7 {
    margin: -1.25rem !important;
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -1.25rem !important;
  }

  .m-md-n8 {
    margin: -1.5625rem !important;
  }

  .mt-md-n8,
  .my-md-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-md-n9 {
    margin: -1.875rem !important;
  }

  .mt-md-n9,
  .my-md-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -1.875rem !important;
  }

  .m-md-n10 {
    margin: -2.1875rem !important;
  }

  .mt-md-n10,
  .my-md-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-md-n11 {
    margin: -2.5rem !important;
  }

  .mt-md-n11,
  .my-md-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -2.5rem !important;
  }

  .m-md-n12 {
    margin: -2.8125rem !important;
  }

  .mt-md-n12,
  .my-md-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-md-n13 {
    margin: -3.125rem !important;
  }

  .mt-md-n13,
  .my-md-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -3.125rem !important;
  }

  .m-md-n14 {
    margin: -3.4375rem !important;
  }

  .mt-md-n14,
  .my-md-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .mt-md-n15,
  .my-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4.0625rem !important;
  }

  .mt-md-n16,
  .my-md-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-md-n17 {
    margin: -4.375rem !important;
  }

  .mt-md-n17,
  .my-md-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -4.375rem !important;
  }

  .m-md-n18 {
    margin: -4.6875rem !important;
  }

  .mt-md-n18,
  .my-md-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-md-n19 {
    margin: -5rem !important;
  }

  .mt-md-n19,
  .my-md-n19 {
    margin-top: -5rem !important;
  }

  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -5rem !important;
  }

  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -5rem !important;
  }

  .m-md-n20 {
    margin: -5.3125rem !important;
  }

  .mt-md-n20,
  .my-md-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-md-n21 {
    margin: -5.625rem !important;
  }

  .mt-md-n21,
  .my-md-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -5.625rem !important;
  }

  .m-md-n22 {
    margin: -5.9375rem !important;
  }

  .mt-md-n22,
  .my-md-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-md-n22,
  .mx-md-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-md-n22,
  .my-md-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-md-n22,
  .mx-md-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-md-n23 {
    margin: -6.25rem !important;
  }

  .mt-md-n23,
  .my-md-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-md-n23,
  .mx-md-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-md-n23,
  .my-md-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-md-n23,
  .mx-md-n23 {
    margin-left: -6.25rem !important;
  }

  .m-md-n24 {
    margin: -6.875rem !important;
  }

  .mt-md-n24,
  .my-md-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -6.875rem !important;
  }

  .m-md-n25 {
    margin: -7.5rem !important;
  }

  .mt-md-n25,
  .my-md-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -7.5rem !important;
  }

  .m-md-n26 {
    margin: -8.125rem !important;
  }

  .mt-md-n26,
  .my-md-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-md-n26,
  .mx-md-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-md-n26,
  .my-md-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-md-n26,
  .mx-md-n26 {
    margin-left: -8.125rem !important;
  }

  .m-md-n27 {
    margin: -8.4375rem !important;
  }

  .mt-md-n27,
  .my-md-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-md-n27,
  .mx-md-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-md-n27,
  .my-md-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-md-n27,
  .mx-md-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-md-n28 {
    margin: -9.0625rem !important;
  }

  .mt-md-n28,
  .my-md-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-md-n28,
  .mx-md-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-md-n28,
  .my-md-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-md-n28,
  .mx-md-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-md-n29 {
    margin: -9.375rem !important;
  }

  .mt-md-n29,
  .my-md-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-md-n29,
  .mx-md-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-md-n29,
  .my-md-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-md-n29,
  .mx-md-n29 {
    margin-left: -9.375rem !important;
  }

  .m-md-n30 {
    margin: -9.6875rem !important;
  }

  .mt-md-n30,
  .my-md-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-md-n31 {
    margin: -10.625rem !important;
  }

  .mt-md-n31,
  .my-md-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-md-n31,
  .mx-md-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-md-n31,
  .my-md-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-md-n31,
  .mx-md-n31 {
    margin-left: -10.625rem !important;
  }

  .m-md-n32 {
    margin: -11.25rem !important;
  }

  .mt-md-n32,
  .my-md-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-md-n32,
  .mx-md-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-md-n32,
  .my-md-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-md-n32,
  .mx-md-n32 {
    margin-left: -11.25rem !important;
  }

  .m-md-n33 {
    margin: -12.5rem !important;
  }

  .mt-md-n33,
  .my-md-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-md-n33,
  .mx-md-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-md-n33,
  .my-md-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-md-n33,
  .mx-md-n33 {
    margin-left: -12.5rem !important;
  }

  .m-md-n34 {
    margin: -14.0625rem !important;
  }

  .mt-md-n34,
  .my-md-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-md-n34,
  .mx-md-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-md-n34,
  .my-md-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-md-n34,
  .mx-md-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-md-n35 {
    margin: -15.625rem !important;
  }

  .mt-md-n35,
  .my-md-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -15.625rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.375rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.375rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.375rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.375rem !important;
  }

  .m-lg-3 {
    margin: 0.5rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.5rem !important;
  }

  .m-lg-4 {
    margin: 0.625rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 0.625rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 0.625rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 0.625rem !important;
  }

  .m-lg-5 {
    margin: 0.75rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 0.75rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 0.75rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 0.75rem !important;
  }

  .m-lg-6 {
    margin: 1rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1rem !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1rem !important;
  }

  .m-lg-7 {
    margin: 1.25rem !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 1.25rem !important;
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 1.25rem !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 1.25rem !important;
  }

  .m-lg-8 {
    margin: 1.5625rem !important;
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 1.5625rem !important;
  }

  .m-lg-9 {
    margin: 1.875rem !important;
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 1.875rem !important;
  }

  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 1.875rem !important;
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 1.875rem !important;
  }

  .m-lg-10 {
    margin: 2.1875rem !important;
  }

  .mt-lg-10,
  .my-lg-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 2.1875rem !important;
  }

  .m-lg-11 {
    margin: 2.5rem !important;
  }

  .mt-lg-11,
  .my-lg-11 {
    margin-top: 2.5rem !important;
  }

  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 2.5rem !important;
  }

  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 2.5rem !important;
  }

  .m-lg-12 {
    margin: 2.8125rem !important;
  }

  .mt-lg-12,
  .my-lg-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 2.8125rem !important;
  }

  .m-lg-13 {
    margin: 3.125rem !important;
  }

  .mt-lg-13,
  .my-lg-13 {
    margin-top: 3.125rem !important;
  }

  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 3.125rem !important;
  }

  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 3.125rem !important;
  }

  .m-lg-14 {
    margin: 3.4375rem !important;
  }

  .mt-lg-14,
  .my-lg-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 3.4375rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .mt-lg-15,
  .my-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 3.75rem !important;
  }

  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4.0625rem !important;
  }

  .mt-lg-16,
  .my-lg-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 4.0625rem !important;
  }

  .m-lg-17 {
    margin: 4.375rem !important;
  }

  .mt-lg-17,
  .my-lg-17 {
    margin-top: 4.375rem !important;
  }

  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 4.375rem !important;
  }

  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 4.375rem !important;
  }

  .m-lg-18 {
    margin: 4.6875rem !important;
  }

  .mt-lg-18,
  .my-lg-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 4.6875rem !important;
  }

  .m-lg-19 {
    margin: 5rem !important;
  }

  .mt-lg-19,
  .my-lg-19 {
    margin-top: 5rem !important;
  }

  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 5rem !important;
  }

  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 5rem !important;
  }

  .m-lg-20 {
    margin: 5.3125rem !important;
  }

  .mt-lg-20,
  .my-lg-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 5.3125rem !important;
  }

  .m-lg-21 {
    margin: 5.625rem !important;
  }

  .mt-lg-21,
  .my-lg-21 {
    margin-top: 5.625rem !important;
  }

  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 5.625rem !important;
  }

  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 5.625rem !important;
  }

  .m-lg-22 {
    margin: 5.9375rem !important;
  }

  .mt-lg-22,
  .my-lg-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 5.9375rem !important;
  }

  .m-lg-23 {
    margin: 6.25rem !important;
  }

  .mt-lg-23,
  .my-lg-23 {
    margin-top: 6.25rem !important;
  }

  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 6.25rem !important;
  }

  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 6.25rem !important;
  }

  .m-lg-24 {
    margin: 6.875rem !important;
  }

  .mt-lg-24,
  .my-lg-24 {
    margin-top: 6.875rem !important;
  }

  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 6.875rem !important;
  }

  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 6.875rem !important;
  }

  .m-lg-25 {
    margin: 7.5rem !important;
  }

  .mt-lg-25,
  .my-lg-25 {
    margin-top: 7.5rem !important;
  }

  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 7.5rem !important;
  }

  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 7.5rem !important;
  }

  .m-lg-26 {
    margin: 8.125rem !important;
  }

  .mt-lg-26,
  .my-lg-26 {
    margin-top: 8.125rem !important;
  }

  .mr-lg-26,
  .mx-lg-26 {
    margin-right: 8.125rem !important;
  }

  .mb-lg-26,
  .my-lg-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-lg-26,
  .mx-lg-26 {
    margin-left: 8.125rem !important;
  }

  .m-lg-27 {
    margin: 8.4375rem !important;
  }

  .mt-lg-27,
  .my-lg-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-lg-27,
  .mx-lg-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-lg-27,
  .my-lg-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-lg-27,
  .mx-lg-27 {
    margin-left: 8.4375rem !important;
  }

  .m-lg-28 {
    margin: 9.0625rem !important;
  }

  .mt-lg-28,
  .my-lg-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-lg-28,
  .mx-lg-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-lg-28,
  .my-lg-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-lg-28,
  .mx-lg-28 {
    margin-left: 9.0625rem !important;
  }

  .m-lg-29 {
    margin: 9.375rem !important;
  }

  .mt-lg-29,
  .my-lg-29 {
    margin-top: 9.375rem !important;
  }

  .mr-lg-29,
  .mx-lg-29 {
    margin-right: 9.375rem !important;
  }

  .mb-lg-29,
  .my-lg-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-lg-29,
  .mx-lg-29 {
    margin-left: 9.375rem !important;
  }

  .m-lg-30 {
    margin: 9.6875rem !important;
  }

  .mt-lg-30,
  .my-lg-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 9.6875rem !important;
  }

  .m-lg-31 {
    margin: 10.625rem !important;
  }

  .mt-lg-31,
  .my-lg-31 {
    margin-top: 10.625rem !important;
  }

  .mr-lg-31,
  .mx-lg-31 {
    margin-right: 10.625rem !important;
  }

  .mb-lg-31,
  .my-lg-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-lg-31,
  .mx-lg-31 {
    margin-left: 10.625rem !important;
  }

  .m-lg-32 {
    margin: 11.25rem !important;
  }

  .mt-lg-32,
  .my-lg-32 {
    margin-top: 11.25rem !important;
  }

  .mr-lg-32,
  .mx-lg-32 {
    margin-right: 11.25rem !important;
  }

  .mb-lg-32,
  .my-lg-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-lg-32,
  .mx-lg-32 {
    margin-left: 11.25rem !important;
  }

  .m-lg-33 {
    margin: 12.5rem !important;
  }

  .mt-lg-33,
  .my-lg-33 {
    margin-top: 12.5rem !important;
  }

  .mr-lg-33,
  .mx-lg-33 {
    margin-right: 12.5rem !important;
  }

  .mb-lg-33,
  .my-lg-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-lg-33,
  .mx-lg-33 {
    margin-left: 12.5rem !important;
  }

  .m-lg-34 {
    margin: 14.0625rem !important;
  }

  .mt-lg-34,
  .my-lg-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-lg-34,
  .mx-lg-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-lg-34,
  .my-lg-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-lg-34,
  .mx-lg-34 {
    margin-left: 14.0625rem !important;
  }

  .m-lg-35 {
    margin: 15.625rem !important;
  }

  .mt-lg-35,
  .my-lg-35 {
    margin-top: 15.625rem !important;
  }

  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 15.625rem !important;
  }

  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 15.625rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.375rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.375rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.375rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.375rem !important;
  }

  .p-lg-3 {
    padding: 0.5rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.5rem !important;
  }

  .p-lg-4 {
    padding: 0.625rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 0.625rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 0.625rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 0.625rem !important;
  }

  .p-lg-5 {
    padding: 0.75rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 0.75rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 0.75rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 0.75rem !important;
  }

  .p-lg-6 {
    padding: 1rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1rem !important;
  }

  .p-lg-7 {
    padding: 1.25rem !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 1.25rem !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 1.25rem !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 1.25rem !important;
  }

  .p-lg-8 {
    padding: 1.5625rem !important;
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 1.5625rem !important;
  }

  .p-lg-9 {
    padding: 1.875rem !important;
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 1.875rem !important;
  }

  .pr-lg-9,
  .px-lg-9 {
    padding-right: 1.875rem !important;
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-lg-9,
  .px-lg-9 {
    padding-left: 1.875rem !important;
  }

  .p-lg-10 {
    padding: 2.1875rem !important;
  }

  .pt-lg-10,
  .py-lg-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-lg-10,
  .px-lg-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-lg-10,
  .px-lg-10 {
    padding-left: 2.1875rem !important;
  }

  .p-lg-11 {
    padding: 2.5rem !important;
  }

  .pt-lg-11,
  .py-lg-11 {
    padding-top: 2.5rem !important;
  }

  .pr-lg-11,
  .px-lg-11 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-lg-11,
  .px-lg-11 {
    padding-left: 2.5rem !important;
  }

  .p-lg-12 {
    padding: 2.8125rem !important;
  }

  .pt-lg-12,
  .py-lg-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-lg-12,
  .px-lg-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-lg-12,
  .px-lg-12 {
    padding-left: 2.8125rem !important;
  }

  .p-lg-13 {
    padding: 3.125rem !important;
  }

  .pt-lg-13,
  .py-lg-13 {
    padding-top: 3.125rem !important;
  }

  .pr-lg-13,
  .px-lg-13 {
    padding-right: 3.125rem !important;
  }

  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-lg-13,
  .px-lg-13 {
    padding-left: 3.125rem !important;
  }

  .p-lg-14 {
    padding: 3.4375rem !important;
  }

  .pt-lg-14,
  .py-lg-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-lg-14,
  .px-lg-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-lg-14,
  .px-lg-14 {
    padding-left: 3.4375rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .pt-lg-15,
  .py-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pr-lg-15,
  .px-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-lg-15,
  .px-lg-15 {
    padding-left: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4.0625rem !important;
  }

  .pt-lg-16,
  .py-lg-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-lg-16,
  .px-lg-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-lg-16,
  .px-lg-16 {
    padding-left: 4.0625rem !important;
  }

  .p-lg-17 {
    padding: 4.375rem !important;
  }

  .pt-lg-17,
  .py-lg-17 {
    padding-top: 4.375rem !important;
  }

  .pr-lg-17,
  .px-lg-17 {
    padding-right: 4.375rem !important;
  }

  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-lg-17,
  .px-lg-17 {
    padding-left: 4.375rem !important;
  }

  .p-lg-18 {
    padding: 4.6875rem !important;
  }

  .pt-lg-18,
  .py-lg-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-lg-18,
  .px-lg-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-lg-18,
  .px-lg-18 {
    padding-left: 4.6875rem !important;
  }

  .p-lg-19 {
    padding: 5rem !important;
  }

  .pt-lg-19,
  .py-lg-19 {
    padding-top: 5rem !important;
  }

  .pr-lg-19,
  .px-lg-19 {
    padding-right: 5rem !important;
  }

  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-19,
  .px-lg-19 {
    padding-left: 5rem !important;
  }

  .p-lg-20 {
    padding: 5.3125rem !important;
  }

  .pt-lg-20,
  .py-lg-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-lg-20,
  .px-lg-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-lg-20,
  .px-lg-20 {
    padding-left: 5.3125rem !important;
  }

  .p-lg-21 {
    padding: 5.625rem !important;
  }

  .pt-lg-21,
  .py-lg-21 {
    padding-top: 5.625rem !important;
  }

  .pr-lg-21,
  .px-lg-21 {
    padding-right: 5.625rem !important;
  }

  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-lg-21,
  .px-lg-21 {
    padding-left: 5.625rem !important;
  }

  .p-lg-22 {
    padding: 5.9375rem !important;
  }

  .pt-lg-22,
  .py-lg-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-lg-22,
  .px-lg-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-lg-22,
  .px-lg-22 {
    padding-left: 5.9375rem !important;
  }

  .p-lg-23 {
    padding: 6.25rem !important;
  }

  .pt-lg-23,
  .py-lg-23 {
    padding-top: 6.25rem !important;
  }

  .pr-lg-23,
  .px-lg-23 {
    padding-right: 6.25rem !important;
  }

  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-lg-23,
  .px-lg-23 {
    padding-left: 6.25rem !important;
  }

  .p-lg-24 {
    padding: 6.875rem !important;
  }

  .pt-lg-24,
  .py-lg-24 {
    padding-top: 6.875rem !important;
  }

  .pr-lg-24,
  .px-lg-24 {
    padding-right: 6.875rem !important;
  }

  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-lg-24,
  .px-lg-24 {
    padding-left: 6.875rem !important;
  }

  .p-lg-25 {
    padding: 7.5rem !important;
  }

  .pt-lg-25,
  .py-lg-25 {
    padding-top: 7.5rem !important;
  }

  .pr-lg-25,
  .px-lg-25 {
    padding-right: 7.5rem !important;
  }

  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-lg-25,
  .px-lg-25 {
    padding-left: 7.5rem !important;
  }

  .p-lg-26 {
    padding: 8.125rem !important;
  }

  .pt-lg-26,
  .py-lg-26 {
    padding-top: 8.125rem !important;
  }

  .pr-lg-26,
  .px-lg-26 {
    padding-right: 8.125rem !important;
  }

  .pb-lg-26,
  .py-lg-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-lg-26,
  .px-lg-26 {
    padding-left: 8.125rem !important;
  }

  .p-lg-27 {
    padding: 8.4375rem !important;
  }

  .pt-lg-27,
  .py-lg-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-lg-27,
  .px-lg-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-lg-27,
  .py-lg-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-lg-27,
  .px-lg-27 {
    padding-left: 8.4375rem !important;
  }

  .p-lg-28 {
    padding: 9.0625rem !important;
  }

  .pt-lg-28,
  .py-lg-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-lg-28,
  .px-lg-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-lg-28,
  .py-lg-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-lg-28,
  .px-lg-28 {
    padding-left: 9.0625rem !important;
  }

  .p-lg-29 {
    padding: 9.375rem !important;
  }

  .pt-lg-29,
  .py-lg-29 {
    padding-top: 9.375rem !important;
  }

  .pr-lg-29,
  .px-lg-29 {
    padding-right: 9.375rem !important;
  }

  .pb-lg-29,
  .py-lg-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-lg-29,
  .px-lg-29 {
    padding-left: 9.375rem !important;
  }

  .p-lg-30 {
    padding: 9.6875rem !important;
  }

  .pt-lg-30,
  .py-lg-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-lg-30,
  .px-lg-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-lg-30,
  .px-lg-30 {
    padding-left: 9.6875rem !important;
  }

  .p-lg-31 {
    padding: 10.625rem !important;
  }

  .pt-lg-31,
  .py-lg-31 {
    padding-top: 10.625rem !important;
  }

  .pr-lg-31,
  .px-lg-31 {
    padding-right: 10.625rem !important;
  }

  .pb-lg-31,
  .py-lg-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-lg-31,
  .px-lg-31 {
    padding-left: 10.625rem !important;
  }

  .p-lg-32 {
    padding: 11.25rem !important;
  }

  .pt-lg-32,
  .py-lg-32 {
    padding-top: 11.25rem !important;
  }

  .pr-lg-32,
  .px-lg-32 {
    padding-right: 11.25rem !important;
  }

  .pb-lg-32,
  .py-lg-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-lg-32,
  .px-lg-32 {
    padding-left: 11.25rem !important;
  }

  .p-lg-33 {
    padding: 12.5rem !important;
  }

  .pt-lg-33,
  .py-lg-33 {
    padding-top: 12.5rem !important;
  }

  .pr-lg-33,
  .px-lg-33 {
    padding-right: 12.5rem !important;
  }

  .pb-lg-33,
  .py-lg-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-lg-33,
  .px-lg-33 {
    padding-left: 12.5rem !important;
  }

  .p-lg-34 {
    padding: 14.0625rem !important;
  }

  .pt-lg-34,
  .py-lg-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-lg-34,
  .px-lg-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-lg-34,
  .py-lg-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-lg-34,
  .px-lg-34 {
    padding-left: 14.0625rem !important;
  }

  .p-lg-35 {
    padding: 15.625rem !important;
  }

  .pt-lg-35,
  .py-lg-35 {
    padding-top: 15.625rem !important;
  }

  .pr-lg-35,
  .px-lg-35 {
    padding-right: 15.625rem !important;
  }

  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-lg-35,
  .px-lg-35 {
    padding-left: 15.625rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.375rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.375rem !important;
  }

  .m-lg-n3 {
    margin: -0.5rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n4 {
    margin: -0.625rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.625rem !important;
  }

  .m-lg-n5 {
    margin: -0.75rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.75rem !important;
  }

  .m-lg-n6 {
    margin: -1rem !important;
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1rem !important;
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1rem !important;
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1rem !important;
  }

  .m-lg-n7 {
    margin: -1.25rem !important;
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -1.25rem !important;
  }

  .m-lg-n8 {
    margin: -1.5625rem !important;
  }

  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-lg-n9 {
    margin: -1.875rem !important;
  }

  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -1.875rem !important;
  }

  .m-lg-n10 {
    margin: -2.1875rem !important;
  }

  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-lg-n11 {
    margin: -2.5rem !important;
  }

  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -2.5rem !important;
  }

  .m-lg-n12 {
    margin: -2.8125rem !important;
  }

  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-lg-n13 {
    margin: -3.125rem !important;
  }

  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -3.125rem !important;
  }

  .m-lg-n14 {
    margin: -3.4375rem !important;
  }

  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4.0625rem !important;
  }

  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-lg-n17 {
    margin: -4.375rem !important;
  }

  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -4.375rem !important;
  }

  .m-lg-n18 {
    margin: -4.6875rem !important;
  }

  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-lg-n19 {
    margin: -5rem !important;
  }

  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -5rem !important;
  }

  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -5rem !important;
  }

  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -5rem !important;
  }

  .m-lg-n20 {
    margin: -5.3125rem !important;
  }

  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-lg-n21 {
    margin: -5.625rem !important;
  }

  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -5.625rem !important;
  }

  .m-lg-n22 {
    margin: -5.9375rem !important;
  }

  .mt-lg-n22,
  .my-lg-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-lg-n22,
  .mx-lg-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-lg-n22,
  .my-lg-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-lg-n22,
  .mx-lg-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-lg-n23 {
    margin: -6.25rem !important;
  }

  .mt-lg-n23,
  .my-lg-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-lg-n23,
  .mx-lg-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-lg-n23,
  .my-lg-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-lg-n23,
  .mx-lg-n23 {
    margin-left: -6.25rem !important;
  }

  .m-lg-n24 {
    margin: -6.875rem !important;
  }

  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -6.875rem !important;
  }

  .m-lg-n25 {
    margin: -7.5rem !important;
  }

  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -7.5rem !important;
  }

  .m-lg-n26 {
    margin: -8.125rem !important;
  }

  .mt-lg-n26,
  .my-lg-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-lg-n26,
  .mx-lg-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-lg-n26,
  .my-lg-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-lg-n26,
  .mx-lg-n26 {
    margin-left: -8.125rem !important;
  }

  .m-lg-n27 {
    margin: -8.4375rem !important;
  }

  .mt-lg-n27,
  .my-lg-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-lg-n27,
  .mx-lg-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-lg-n27,
  .my-lg-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-lg-n27,
  .mx-lg-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-lg-n28 {
    margin: -9.0625rem !important;
  }

  .mt-lg-n28,
  .my-lg-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-lg-n28,
  .mx-lg-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-lg-n28,
  .my-lg-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-lg-n28,
  .mx-lg-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-lg-n29 {
    margin: -9.375rem !important;
  }

  .mt-lg-n29,
  .my-lg-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-lg-n29,
  .mx-lg-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-lg-n29,
  .my-lg-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-lg-n29,
  .mx-lg-n29 {
    margin-left: -9.375rem !important;
  }

  .m-lg-n30 {
    margin: -9.6875rem !important;
  }

  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-lg-n31 {
    margin: -10.625rem !important;
  }

  .mt-lg-n31,
  .my-lg-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-lg-n31,
  .mx-lg-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-lg-n31,
  .my-lg-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-lg-n31,
  .mx-lg-n31 {
    margin-left: -10.625rem !important;
  }

  .m-lg-n32 {
    margin: -11.25rem !important;
  }

  .mt-lg-n32,
  .my-lg-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-lg-n32,
  .mx-lg-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-lg-n32,
  .my-lg-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-lg-n32,
  .mx-lg-n32 {
    margin-left: -11.25rem !important;
  }

  .m-lg-n33 {
    margin: -12.5rem !important;
  }

  .mt-lg-n33,
  .my-lg-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-lg-n33,
  .mx-lg-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-lg-n33,
  .my-lg-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-lg-n33,
  .mx-lg-n33 {
    margin-left: -12.5rem !important;
  }

  .m-lg-n34 {
    margin: -14.0625rem !important;
  }

  .mt-lg-n34,
  .my-lg-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-lg-n34,
  .mx-lg-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-lg-n34,
  .my-lg-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-lg-n34,
  .mx-lg-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-lg-n35 {
    margin: -15.625rem !important;
  }

  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -15.625rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.375rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.375rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.375rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.375rem !important;
  }

  .m-xl-3 {
    margin: 0.5rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.5rem !important;
  }

  .m-xl-4 {
    margin: 0.625rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 0.625rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 0.625rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 0.625rem !important;
  }

  .m-xl-5 {
    margin: 0.75rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 0.75rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 0.75rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 0.75rem !important;
  }

  .m-xl-6 {
    margin: 1rem !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1rem !important;
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1rem !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1rem !important;
  }

  .m-xl-7 {
    margin: 1.25rem !important;
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 1.25rem !important;
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 1.25rem !important;
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 1.25rem !important;
  }

  .m-xl-8 {
    margin: 1.5625rem !important;
  }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 1.5625rem !important;
  }

  .m-xl-9 {
    margin: 1.875rem !important;
  }

  .mt-xl-9,
  .my-xl-9 {
    margin-top: 1.875rem !important;
  }

  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 1.875rem !important;
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 1.875rem !important;
  }

  .m-xl-10 {
    margin: 2.1875rem !important;
  }

  .mt-xl-10,
  .my-xl-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 2.1875rem !important;
  }

  .m-xl-11 {
    margin: 2.5rem !important;
  }

  .mt-xl-11,
  .my-xl-11 {
    margin-top: 2.5rem !important;
  }

  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 2.5rem !important;
  }

  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 2.5rem !important;
  }

  .m-xl-12 {
    margin: 2.8125rem !important;
  }

  .mt-xl-12,
  .my-xl-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 2.8125rem !important;
  }

  .m-xl-13 {
    margin: 3.125rem !important;
  }

  .mt-xl-13,
  .my-xl-13 {
    margin-top: 3.125rem !important;
  }

  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 3.125rem !important;
  }

  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 3.125rem !important;
  }

  .m-xl-14 {
    margin: 3.4375rem !important;
  }

  .mt-xl-14,
  .my-xl-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 3.4375rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .mt-xl-15,
  .my-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 3.75rem !important;
  }

  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4.0625rem !important;
  }

  .mt-xl-16,
  .my-xl-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 4.0625rem !important;
  }

  .m-xl-17 {
    margin: 4.375rem !important;
  }

  .mt-xl-17,
  .my-xl-17 {
    margin-top: 4.375rem !important;
  }

  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 4.375rem !important;
  }

  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 4.375rem !important;
  }

  .m-xl-18 {
    margin: 4.6875rem !important;
  }

  .mt-xl-18,
  .my-xl-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 4.6875rem !important;
  }

  .m-xl-19 {
    margin: 5rem !important;
  }

  .mt-xl-19,
  .my-xl-19 {
    margin-top: 5rem !important;
  }

  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 5rem !important;
  }

  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 5rem !important;
  }

  .m-xl-20 {
    margin: 5.3125rem !important;
  }

  .mt-xl-20,
  .my-xl-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 5.3125rem !important;
  }

  .m-xl-21 {
    margin: 5.625rem !important;
  }

  .mt-xl-21,
  .my-xl-21 {
    margin-top: 5.625rem !important;
  }

  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 5.625rem !important;
  }

  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 5.625rem !important;
  }

  .m-xl-22 {
    margin: 5.9375rem !important;
  }

  .mt-xl-22,
  .my-xl-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 5.9375rem !important;
  }

  .m-xl-23 {
    margin: 6.25rem !important;
  }

  .mt-xl-23,
  .my-xl-23 {
    margin-top: 6.25rem !important;
  }

  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 6.25rem !important;
  }

  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 6.25rem !important;
  }

  .m-xl-24 {
    margin: 6.875rem !important;
  }

  .mt-xl-24,
  .my-xl-24 {
    margin-top: 6.875rem !important;
  }

  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 6.875rem !important;
  }

  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 6.875rem !important;
  }

  .m-xl-25 {
    margin: 7.5rem !important;
  }

  .mt-xl-25,
  .my-xl-25 {
    margin-top: 7.5rem !important;
  }

  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 7.5rem !important;
  }

  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 7.5rem !important;
  }

  .m-xl-26 {
    margin: 8.125rem !important;
  }

  .mt-xl-26,
  .my-xl-26 {
    margin-top: 8.125rem !important;
  }

  .mr-xl-26,
  .mx-xl-26 {
    margin-right: 8.125rem !important;
  }

  .mb-xl-26,
  .my-xl-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-xl-26,
  .mx-xl-26 {
    margin-left: 8.125rem !important;
  }

  .m-xl-27 {
    margin: 8.4375rem !important;
  }

  .mt-xl-27,
  .my-xl-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-xl-27,
  .mx-xl-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-xl-27,
  .my-xl-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-xl-27,
  .mx-xl-27 {
    margin-left: 8.4375rem !important;
  }

  .m-xl-28 {
    margin: 9.0625rem !important;
  }

  .mt-xl-28,
  .my-xl-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-xl-28,
  .mx-xl-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-xl-28,
  .my-xl-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-xl-28,
  .mx-xl-28 {
    margin-left: 9.0625rem !important;
  }

  .m-xl-29 {
    margin: 9.375rem !important;
  }

  .mt-xl-29,
  .my-xl-29 {
    margin-top: 9.375rem !important;
  }

  .mr-xl-29,
  .mx-xl-29 {
    margin-right: 9.375rem !important;
  }

  .mb-xl-29,
  .my-xl-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-xl-29,
  .mx-xl-29 {
    margin-left: 9.375rem !important;
  }

  .m-xl-30 {
    margin: 9.6875rem !important;
  }

  .mt-xl-30,
  .my-xl-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 9.6875rem !important;
  }

  .m-xl-31 {
    margin: 10.625rem !important;
  }

  .mt-xl-31,
  .my-xl-31 {
    margin-top: 10.625rem !important;
  }

  .mr-xl-31,
  .mx-xl-31 {
    margin-right: 10.625rem !important;
  }

  .mb-xl-31,
  .my-xl-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-xl-31,
  .mx-xl-31 {
    margin-left: 10.625rem !important;
  }

  .m-xl-32 {
    margin: 11.25rem !important;
  }

  .mt-xl-32,
  .my-xl-32 {
    margin-top: 11.25rem !important;
  }

  .mr-xl-32,
  .mx-xl-32 {
    margin-right: 11.25rem !important;
  }

  .mb-xl-32,
  .my-xl-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-xl-32,
  .mx-xl-32 {
    margin-left: 11.25rem !important;
  }

  .m-xl-33 {
    margin: 12.5rem !important;
  }

  .mt-xl-33,
  .my-xl-33 {
    margin-top: 12.5rem !important;
  }

  .mr-xl-33,
  .mx-xl-33 {
    margin-right: 12.5rem !important;
  }

  .mb-xl-33,
  .my-xl-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-xl-33,
  .mx-xl-33 {
    margin-left: 12.5rem !important;
  }

  .m-xl-34 {
    margin: 14.0625rem !important;
  }

  .mt-xl-34,
  .my-xl-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-xl-34,
  .mx-xl-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-xl-34,
  .my-xl-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-xl-34,
  .mx-xl-34 {
    margin-left: 14.0625rem !important;
  }

  .m-xl-35 {
    margin: 15.625rem !important;
  }

  .mt-xl-35,
  .my-xl-35 {
    margin-top: 15.625rem !important;
  }

  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 15.625rem !important;
  }

  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 15.625rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.375rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.375rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.375rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.375rem !important;
  }

  .p-xl-3 {
    padding: 0.5rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.5rem !important;
  }

  .p-xl-4 {
    padding: 0.625rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 0.625rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 0.625rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 0.625rem !important;
  }

  .p-xl-5 {
    padding: 0.75rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 0.75rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 0.75rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 0.75rem !important;
  }

  .p-xl-6 {
    padding: 1rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1rem !important;
  }

  .p-xl-7 {
    padding: 1.25rem !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 1.25rem !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 1.25rem !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 1.25rem !important;
  }

  .p-xl-8 {
    padding: 1.5625rem !important;
  }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-xl-8,
  .px-xl-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-xl-8,
  .px-xl-8 {
    padding-left: 1.5625rem !important;
  }

  .p-xl-9 {
    padding: 1.875rem !important;
  }

  .pt-xl-9,
  .py-xl-9 {
    padding-top: 1.875rem !important;
  }

  .pr-xl-9,
  .px-xl-9 {
    padding-right: 1.875rem !important;
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-xl-9,
  .px-xl-9 {
    padding-left: 1.875rem !important;
  }

  .p-xl-10 {
    padding: 2.1875rem !important;
  }

  .pt-xl-10,
  .py-xl-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-xl-10,
  .px-xl-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-xl-10,
  .px-xl-10 {
    padding-left: 2.1875rem !important;
  }

  .p-xl-11 {
    padding: 2.5rem !important;
  }

  .pt-xl-11,
  .py-xl-11 {
    padding-top: 2.5rem !important;
  }

  .pr-xl-11,
  .px-xl-11 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xl-11,
  .px-xl-11 {
    padding-left: 2.5rem !important;
  }

  .p-xl-12 {
    padding: 2.8125rem !important;
  }

  .pt-xl-12,
  .py-xl-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-xl-12,
  .px-xl-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-xl-12,
  .px-xl-12 {
    padding-left: 2.8125rem !important;
  }

  .p-xl-13 {
    padding: 3.125rem !important;
  }

  .pt-xl-13,
  .py-xl-13 {
    padding-top: 3.125rem !important;
  }

  .pr-xl-13,
  .px-xl-13 {
    padding-right: 3.125rem !important;
  }

  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-xl-13,
  .px-xl-13 {
    padding-left: 3.125rem !important;
  }

  .p-xl-14 {
    padding: 3.4375rem !important;
  }

  .pt-xl-14,
  .py-xl-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-xl-14,
  .px-xl-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-xl-14,
  .px-xl-14 {
    padding-left: 3.4375rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .pt-xl-15,
  .py-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pr-xl-15,
  .px-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-xl-15,
  .px-xl-15 {
    padding-left: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4.0625rem !important;
  }

  .pt-xl-16,
  .py-xl-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-xl-16,
  .px-xl-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-xl-16,
  .px-xl-16 {
    padding-left: 4.0625rem !important;
  }

  .p-xl-17 {
    padding: 4.375rem !important;
  }

  .pt-xl-17,
  .py-xl-17 {
    padding-top: 4.375rem !important;
  }

  .pr-xl-17,
  .px-xl-17 {
    padding-right: 4.375rem !important;
  }

  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-xl-17,
  .px-xl-17 {
    padding-left: 4.375rem !important;
  }

  .p-xl-18 {
    padding: 4.6875rem !important;
  }

  .pt-xl-18,
  .py-xl-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-xl-18,
  .px-xl-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-xl-18,
  .px-xl-18 {
    padding-left: 4.6875rem !important;
  }

  .p-xl-19 {
    padding: 5rem !important;
  }

  .pt-xl-19,
  .py-xl-19 {
    padding-top: 5rem !important;
  }

  .pr-xl-19,
  .px-xl-19 {
    padding-right: 5rem !important;
  }

  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-19,
  .px-xl-19 {
    padding-left: 5rem !important;
  }

  .p-xl-20 {
    padding: 5.3125rem !important;
  }

  .pt-xl-20,
  .py-xl-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-xl-20,
  .px-xl-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-xl-20,
  .px-xl-20 {
    padding-left: 5.3125rem !important;
  }

  .p-xl-21 {
    padding: 5.625rem !important;
  }

  .pt-xl-21,
  .py-xl-21 {
    padding-top: 5.625rem !important;
  }

  .pr-xl-21,
  .px-xl-21 {
    padding-right: 5.625rem !important;
  }

  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-xl-21,
  .px-xl-21 {
    padding-left: 5.625rem !important;
  }

  .p-xl-22 {
    padding: 5.9375rem !important;
  }

  .pt-xl-22,
  .py-xl-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-xl-22,
  .px-xl-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-xl-22,
  .px-xl-22 {
    padding-left: 5.9375rem !important;
  }

  .p-xl-23 {
    padding: 6.25rem !important;
  }

  .pt-xl-23,
  .py-xl-23 {
    padding-top: 6.25rem !important;
  }

  .pr-xl-23,
  .px-xl-23 {
    padding-right: 6.25rem !important;
  }

  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-xl-23,
  .px-xl-23 {
    padding-left: 6.25rem !important;
  }

  .p-xl-24 {
    padding: 6.875rem !important;
  }

  .pt-xl-24,
  .py-xl-24 {
    padding-top: 6.875rem !important;
  }

  .pr-xl-24,
  .px-xl-24 {
    padding-right: 6.875rem !important;
  }

  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-xl-24,
  .px-xl-24 {
    padding-left: 6.875rem !important;
  }

  .p-xl-25 {
    padding: 7.5rem !important;
  }

  .pt-xl-25,
  .py-xl-25 {
    padding-top: 7.5rem !important;
  }

  .pr-xl-25,
  .px-xl-25 {
    padding-right: 7.5rem !important;
  }

  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-xl-25,
  .px-xl-25 {
    padding-left: 7.5rem !important;
  }

  .p-xl-26 {
    padding: 8.125rem !important;
  }

  .pt-xl-26,
  .py-xl-26 {
    padding-top: 8.125rem !important;
  }

  .pr-xl-26,
  .px-xl-26 {
    padding-right: 8.125rem !important;
  }

  .pb-xl-26,
  .py-xl-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-xl-26,
  .px-xl-26 {
    padding-left: 8.125rem !important;
  }

  .p-xl-27 {
    padding: 8.4375rem !important;
  }

  .pt-xl-27,
  .py-xl-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-xl-27,
  .px-xl-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-xl-27,
  .py-xl-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-xl-27,
  .px-xl-27 {
    padding-left: 8.4375rem !important;
  }

  .p-xl-28 {
    padding: 9.0625rem !important;
  }

  .pt-xl-28,
  .py-xl-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-xl-28,
  .px-xl-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-xl-28,
  .py-xl-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-xl-28,
  .px-xl-28 {
    padding-left: 9.0625rem !important;
  }

  .p-xl-29 {
    padding: 9.375rem !important;
  }

  .pt-xl-29,
  .py-xl-29 {
    padding-top: 9.375rem !important;
  }

  .pr-xl-29,
  .px-xl-29 {
    padding-right: 9.375rem !important;
  }

  .pb-xl-29,
  .py-xl-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-xl-29,
  .px-xl-29 {
    padding-left: 9.375rem !important;
  }

  .p-xl-30 {
    padding: 9.6875rem !important;
  }

  .pt-xl-30,
  .py-xl-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-xl-30,
  .px-xl-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-xl-30,
  .px-xl-30 {
    padding-left: 9.6875rem !important;
  }

  .p-xl-31 {
    padding: 10.625rem !important;
  }

  .pt-xl-31,
  .py-xl-31 {
    padding-top: 10.625rem !important;
  }

  .pr-xl-31,
  .px-xl-31 {
    padding-right: 10.625rem !important;
  }

  .pb-xl-31,
  .py-xl-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-xl-31,
  .px-xl-31 {
    padding-left: 10.625rem !important;
  }

  .p-xl-32 {
    padding: 11.25rem !important;
  }

  .pt-xl-32,
  .py-xl-32 {
    padding-top: 11.25rem !important;
  }

  .pr-xl-32,
  .px-xl-32 {
    padding-right: 11.25rem !important;
  }

  .pb-xl-32,
  .py-xl-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-xl-32,
  .px-xl-32 {
    padding-left: 11.25rem !important;
  }

  .p-xl-33 {
    padding: 12.5rem !important;
  }

  .pt-xl-33,
  .py-xl-33 {
    padding-top: 12.5rem !important;
  }

  .pr-xl-33,
  .px-xl-33 {
    padding-right: 12.5rem !important;
  }

  .pb-xl-33,
  .py-xl-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-xl-33,
  .px-xl-33 {
    padding-left: 12.5rem !important;
  }

  .p-xl-34 {
    padding: 14.0625rem !important;
  }

  .pt-xl-34,
  .py-xl-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-xl-34,
  .px-xl-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-xl-34,
  .py-xl-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-xl-34,
  .px-xl-34 {
    padding-left: 14.0625rem !important;
  }

  .p-xl-35 {
    padding: 15.625rem !important;
  }

  .pt-xl-35,
  .py-xl-35 {
    padding-top: 15.625rem !important;
  }

  .pr-xl-35,
  .px-xl-35 {
    padding-right: 15.625rem !important;
  }

  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-xl-35,
  .px-xl-35 {
    padding-left: 15.625rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.375rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.375rem !important;
  }

  .m-xl-n3 {
    margin: -0.5rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n4 {
    margin: -0.625rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.625rem !important;
  }

  .m-xl-n5 {
    margin: -0.75rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.75rem !important;
  }

  .m-xl-n6 {
    margin: -1rem !important;
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1rem !important;
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1rem !important;
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1rem !important;
  }

  .m-xl-n7 {
    margin: -1.25rem !important;
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -1.25rem !important;
  }

  .m-xl-n8 {
    margin: -1.5625rem !important;
  }

  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-xl-n9 {
    margin: -1.875rem !important;
  }

  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -1.875rem !important;
  }

  .m-xl-n10 {
    margin: -2.1875rem !important;
  }

  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-xl-n11 {
    margin: -2.5rem !important;
  }

  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -2.5rem !important;
  }

  .m-xl-n12 {
    margin: -2.8125rem !important;
  }

  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-xl-n13 {
    margin: -3.125rem !important;
  }

  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -3.125rem !important;
  }

  .m-xl-n14 {
    margin: -3.4375rem !important;
  }

  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4.0625rem !important;
  }

  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-xl-n17 {
    margin: -4.375rem !important;
  }

  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -4.375rem !important;
  }

  .m-xl-n18 {
    margin: -4.6875rem !important;
  }

  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-xl-n19 {
    margin: -5rem !important;
  }

  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -5rem !important;
  }

  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -5rem !important;
  }

  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -5rem !important;
  }

  .m-xl-n20 {
    margin: -5.3125rem !important;
  }

  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-xl-n21 {
    margin: -5.625rem !important;
  }

  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -5.625rem !important;
  }

  .m-xl-n22 {
    margin: -5.9375rem !important;
  }

  .mt-xl-n22,
  .my-xl-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-xl-n22,
  .mx-xl-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-xl-n22,
  .my-xl-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-xl-n22,
  .mx-xl-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-xl-n23 {
    margin: -6.25rem !important;
  }

  .mt-xl-n23,
  .my-xl-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-xl-n23,
  .mx-xl-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-xl-n23,
  .my-xl-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-xl-n23,
  .mx-xl-n23 {
    margin-left: -6.25rem !important;
  }

  .m-xl-n24 {
    margin: -6.875rem !important;
  }

  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -6.875rem !important;
  }

  .m-xl-n25 {
    margin: -7.5rem !important;
  }

  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -7.5rem !important;
  }

  .m-xl-n26 {
    margin: -8.125rem !important;
  }

  .mt-xl-n26,
  .my-xl-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-xl-n26,
  .mx-xl-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-xl-n26,
  .my-xl-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-xl-n26,
  .mx-xl-n26 {
    margin-left: -8.125rem !important;
  }

  .m-xl-n27 {
    margin: -8.4375rem !important;
  }

  .mt-xl-n27,
  .my-xl-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-xl-n27,
  .mx-xl-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-xl-n27,
  .my-xl-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-xl-n27,
  .mx-xl-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-xl-n28 {
    margin: -9.0625rem !important;
  }

  .mt-xl-n28,
  .my-xl-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-xl-n28,
  .mx-xl-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-xl-n28,
  .my-xl-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-xl-n28,
  .mx-xl-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-xl-n29 {
    margin: -9.375rem !important;
  }

  .mt-xl-n29,
  .my-xl-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-xl-n29,
  .mx-xl-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-xl-n29,
  .my-xl-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-xl-n29,
  .mx-xl-n29 {
    margin-left: -9.375rem !important;
  }

  .m-xl-n30 {
    margin: -9.6875rem !important;
  }

  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-xl-n31 {
    margin: -10.625rem !important;
  }

  .mt-xl-n31,
  .my-xl-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-xl-n31,
  .mx-xl-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-xl-n31,
  .my-xl-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-xl-n31,
  .mx-xl-n31 {
    margin-left: -10.625rem !important;
  }

  .m-xl-n32 {
    margin: -11.25rem !important;
  }

  .mt-xl-n32,
  .my-xl-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-xl-n32,
  .mx-xl-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-xl-n32,
  .my-xl-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-xl-n32,
  .mx-xl-n32 {
    margin-left: -11.25rem !important;
  }

  .m-xl-n33 {
    margin: -12.5rem !important;
  }

  .mt-xl-n33,
  .my-xl-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-xl-n33,
  .mx-xl-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-xl-n33,
  .my-xl-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-xl-n33,
  .mx-xl-n33 {
    margin-left: -12.5rem !important;
  }

  .m-xl-n34 {
    margin: -14.0625rem !important;
  }

  .mt-xl-n34,
  .my-xl-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-xl-n34,
  .mx-xl-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-xl-n34,
  .my-xl-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-xl-n34,
  .mx-xl-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-xl-n35 {
    margin: -15.625rem !important;
  }

  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -15.625rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1366px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.375rem !important;
  }

  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.375rem !important;
  }

  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.375rem !important;
  }

  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.375rem !important;
  }

  .m-xxl-3 {
    margin: 0.5rem !important;
  }

  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-4 {
    margin: 0.625rem !important;
  }

  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 0.625rem !important;
  }

  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 0.625rem !important;
  }

  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 0.625rem !important;
  }

  .m-xxl-5 {
    margin: 0.75rem !important;
  }

  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 0.75rem !important;
  }

  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 0.75rem !important;
  }

  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 0.75rem !important;
  }

  .m-xxl-6 {
    margin: 1rem !important;
  }

  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 1rem !important;
  }

  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 1rem !important;
  }

  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 1rem !important;
  }

  .m-xxl-7 {
    margin: 1.25rem !important;
  }

  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 1.25rem !important;
  }

  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 1.25rem !important;
  }

  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 1.25rem !important;
  }

  .m-xxl-8 {
    margin: 1.5625rem !important;
  }

  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 1.5625rem !important;
  }

  .m-xxl-9 {
    margin: 1.875rem !important;
  }

  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 1.875rem !important;
  }

  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 1.875rem !important;
  }

  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 1.875rem !important;
  }

  .m-xxl-10 {
    margin: 2.1875rem !important;
  }

  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 2.1875rem !important;
  }

  .m-xxl-11 {
    margin: 2.5rem !important;
  }

  .mt-xxl-11,
  .my-xxl-11 {
    margin-top: 2.5rem !important;
  }

  .mr-xxl-11,
  .mx-xxl-11 {
    margin-right: 2.5rem !important;
  }

  .mb-xxl-11,
  .my-xxl-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xxl-11,
  .mx-xxl-11 {
    margin-left: 2.5rem !important;
  }

  .m-xxl-12 {
    margin: 2.8125rem !important;
  }

  .mt-xxl-12,
  .my-xxl-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-xxl-12,
  .mx-xxl-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-xxl-12,
  .my-xxl-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-xxl-12,
  .mx-xxl-12 {
    margin-left: 2.8125rem !important;
  }

  .m-xxl-13 {
    margin: 3.125rem !important;
  }

  .mt-xxl-13,
  .my-xxl-13 {
    margin-top: 3.125rem !important;
  }

  .mr-xxl-13,
  .mx-xxl-13 {
    margin-right: 3.125rem !important;
  }

  .mb-xxl-13,
  .my-xxl-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-xxl-13,
  .mx-xxl-13 {
    margin-left: 3.125rem !important;
  }

  .m-xxl-14 {
    margin: 3.4375rem !important;
  }

  .mt-xxl-14,
  .my-xxl-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-xxl-14,
  .mx-xxl-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-xxl-14,
  .my-xxl-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-xxl-14,
  .mx-xxl-14 {
    margin-left: 3.4375rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .mt-xxl-15,
  .my-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mr-xxl-15,
  .mx-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .mb-xxl-15,
  .my-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-xxl-15,
  .mx-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4.0625rem !important;
  }

  .mt-xxl-16,
  .my-xxl-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-xxl-16,
  .mx-xxl-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-xxl-16,
  .my-xxl-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-xxl-16,
  .mx-xxl-16 {
    margin-left: 4.0625rem !important;
  }

  .m-xxl-17 {
    margin: 4.375rem !important;
  }

  .mt-xxl-17,
  .my-xxl-17 {
    margin-top: 4.375rem !important;
  }

  .mr-xxl-17,
  .mx-xxl-17 {
    margin-right: 4.375rem !important;
  }

  .mb-xxl-17,
  .my-xxl-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-xxl-17,
  .mx-xxl-17 {
    margin-left: 4.375rem !important;
  }

  .m-xxl-18 {
    margin: 4.6875rem !important;
  }

  .mt-xxl-18,
  .my-xxl-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-xxl-18,
  .mx-xxl-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-xxl-18,
  .my-xxl-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-xxl-18,
  .mx-xxl-18 {
    margin-left: 4.6875rem !important;
  }

  .m-xxl-19 {
    margin: 5rem !important;
  }

  .mt-xxl-19,
  .my-xxl-19 {
    margin-top: 5rem !important;
  }

  .mr-xxl-19,
  .mx-xxl-19 {
    margin-right: 5rem !important;
  }

  .mb-xxl-19,
  .my-xxl-19 {
    margin-bottom: 5rem !important;
  }

  .ml-xxl-19,
  .mx-xxl-19 {
    margin-left: 5rem !important;
  }

  .m-xxl-20 {
    margin: 5.3125rem !important;
  }

  .mt-xxl-20,
  .my-xxl-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-xxl-20,
  .mx-xxl-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-xxl-20,
  .my-xxl-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-xxl-20,
  .mx-xxl-20 {
    margin-left: 5.3125rem !important;
  }

  .m-xxl-21 {
    margin: 5.625rem !important;
  }

  .mt-xxl-21,
  .my-xxl-21 {
    margin-top: 5.625rem !important;
  }

  .mr-xxl-21,
  .mx-xxl-21 {
    margin-right: 5.625rem !important;
  }

  .mb-xxl-21,
  .my-xxl-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-xxl-21,
  .mx-xxl-21 {
    margin-left: 5.625rem !important;
  }

  .m-xxl-22 {
    margin: 5.9375rem !important;
  }

  .mt-xxl-22,
  .my-xxl-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-xxl-22,
  .mx-xxl-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-xxl-22,
  .my-xxl-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-xxl-22,
  .mx-xxl-22 {
    margin-left: 5.9375rem !important;
  }

  .m-xxl-23 {
    margin: 6.25rem !important;
  }

  .mt-xxl-23,
  .my-xxl-23 {
    margin-top: 6.25rem !important;
  }

  .mr-xxl-23,
  .mx-xxl-23 {
    margin-right: 6.25rem !important;
  }

  .mb-xxl-23,
  .my-xxl-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-xxl-23,
  .mx-xxl-23 {
    margin-left: 6.25rem !important;
  }

  .m-xxl-24 {
    margin: 6.875rem !important;
  }

  .mt-xxl-24,
  .my-xxl-24 {
    margin-top: 6.875rem !important;
  }

  .mr-xxl-24,
  .mx-xxl-24 {
    margin-right: 6.875rem !important;
  }

  .mb-xxl-24,
  .my-xxl-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-xxl-24,
  .mx-xxl-24 {
    margin-left: 6.875rem !important;
  }

  .m-xxl-25 {
    margin: 7.5rem !important;
  }

  .mt-xxl-25,
  .my-xxl-25 {
    margin-top: 7.5rem !important;
  }

  .mr-xxl-25,
  .mx-xxl-25 {
    margin-right: 7.5rem !important;
  }

  .mb-xxl-25,
  .my-xxl-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-xxl-25,
  .mx-xxl-25 {
    margin-left: 7.5rem !important;
  }

  .m-xxl-26 {
    margin: 8.125rem !important;
  }

  .mt-xxl-26,
  .my-xxl-26 {
    margin-top: 8.125rem !important;
  }

  .mr-xxl-26,
  .mx-xxl-26 {
    margin-right: 8.125rem !important;
  }

  .mb-xxl-26,
  .my-xxl-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-xxl-26,
  .mx-xxl-26 {
    margin-left: 8.125rem !important;
  }

  .m-xxl-27 {
    margin: 8.4375rem !important;
  }

  .mt-xxl-27,
  .my-xxl-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-xxl-27,
  .mx-xxl-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-xxl-27,
  .my-xxl-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-xxl-27,
  .mx-xxl-27 {
    margin-left: 8.4375rem !important;
  }

  .m-xxl-28 {
    margin: 9.0625rem !important;
  }

  .mt-xxl-28,
  .my-xxl-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-xxl-28,
  .mx-xxl-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-xxl-28,
  .my-xxl-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-xxl-28,
  .mx-xxl-28 {
    margin-left: 9.0625rem !important;
  }

  .m-xxl-29 {
    margin: 9.375rem !important;
  }

  .mt-xxl-29,
  .my-xxl-29 {
    margin-top: 9.375rem !important;
  }

  .mr-xxl-29,
  .mx-xxl-29 {
    margin-right: 9.375rem !important;
  }

  .mb-xxl-29,
  .my-xxl-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-xxl-29,
  .mx-xxl-29 {
    margin-left: 9.375rem !important;
  }

  .m-xxl-30 {
    margin: 9.6875rem !important;
  }

  .mt-xxl-30,
  .my-xxl-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-xxl-30,
  .mx-xxl-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-xxl-30,
  .my-xxl-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-xxl-30,
  .mx-xxl-30 {
    margin-left: 9.6875rem !important;
  }

  .m-xxl-31 {
    margin: 10.625rem !important;
  }

  .mt-xxl-31,
  .my-xxl-31 {
    margin-top: 10.625rem !important;
  }

  .mr-xxl-31,
  .mx-xxl-31 {
    margin-right: 10.625rem !important;
  }

  .mb-xxl-31,
  .my-xxl-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-xxl-31,
  .mx-xxl-31 {
    margin-left: 10.625rem !important;
  }

  .m-xxl-32 {
    margin: 11.25rem !important;
  }

  .mt-xxl-32,
  .my-xxl-32 {
    margin-top: 11.25rem !important;
  }

  .mr-xxl-32,
  .mx-xxl-32 {
    margin-right: 11.25rem !important;
  }

  .mb-xxl-32,
  .my-xxl-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-xxl-32,
  .mx-xxl-32 {
    margin-left: 11.25rem !important;
  }

  .m-xxl-33 {
    margin: 12.5rem !important;
  }

  .mt-xxl-33,
  .my-xxl-33 {
    margin-top: 12.5rem !important;
  }

  .mr-xxl-33,
  .mx-xxl-33 {
    margin-right: 12.5rem !important;
  }

  .mb-xxl-33,
  .my-xxl-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-xxl-33,
  .mx-xxl-33 {
    margin-left: 12.5rem !important;
  }

  .m-xxl-34 {
    margin: 14.0625rem !important;
  }

  .mt-xxl-34,
  .my-xxl-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-xxl-34,
  .mx-xxl-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-xxl-34,
  .my-xxl-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-xxl-34,
  .mx-xxl-34 {
    margin-left: 14.0625rem !important;
  }

  .m-xxl-35 {
    margin: 15.625rem !important;
  }

  .mt-xxl-35,
  .my-xxl-35 {
    margin-top: 15.625rem !important;
  }

  .mr-xxl-35,
  .mx-xxl-35 {
    margin-right: 15.625rem !important;
  }

  .mb-xxl-35,
  .my-xxl-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-xxl-35,
  .mx-xxl-35 {
    margin-left: 15.625rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.375rem !important;
  }

  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.375rem !important;
  }

  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.375rem !important;
  }

  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.375rem !important;
  }

  .p-xxl-3 {
    padding: 0.5rem !important;
  }

  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-4 {
    padding: 0.625rem !important;
  }

  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 0.625rem !important;
  }

  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 0.625rem !important;
  }

  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 0.625rem !important;
  }

  .p-xxl-5 {
    padding: 0.75rem !important;
  }

  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 0.75rem !important;
  }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 0.75rem !important;
  }

  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 0.75rem !important;
  }

  .p-xxl-6 {
    padding: 1rem !important;
  }

  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 1rem !important;
  }

  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 1rem !important;
  }

  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 1rem !important;
  }

  .p-xxl-7 {
    padding: 1.25rem !important;
  }

  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 1.25rem !important;
  }

  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 1.25rem !important;
  }

  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 1.25rem !important;
  }

  .p-xxl-8 {
    padding: 1.5625rem !important;
  }

  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 1.5625rem !important;
  }

  .p-xxl-9 {
    padding: 1.875rem !important;
  }

  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 1.875rem !important;
  }

  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 1.875rem !important;
  }

  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 1.875rem !important;
  }

  .p-xxl-10 {
    padding: 2.1875rem !important;
  }

  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 2.1875rem !important;
  }

  .p-xxl-11 {
    padding: 2.5rem !important;
  }

  .pt-xxl-11,
  .py-xxl-11 {
    padding-top: 2.5rem !important;
  }

  .pr-xxl-11,
  .px-xxl-11 {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-11,
  .py-xxl-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xxl-11,
  .px-xxl-11 {
    padding-left: 2.5rem !important;
  }

  .p-xxl-12 {
    padding: 2.8125rem !important;
  }

  .pt-xxl-12,
  .py-xxl-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-xxl-12,
  .px-xxl-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-xxl-12,
  .py-xxl-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-xxl-12,
  .px-xxl-12 {
    padding-left: 2.8125rem !important;
  }

  .p-xxl-13 {
    padding: 3.125rem !important;
  }

  .pt-xxl-13,
  .py-xxl-13 {
    padding-top: 3.125rem !important;
  }

  .pr-xxl-13,
  .px-xxl-13 {
    padding-right: 3.125rem !important;
  }

  .pb-xxl-13,
  .py-xxl-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-xxl-13,
  .px-xxl-13 {
    padding-left: 3.125rem !important;
  }

  .p-xxl-14 {
    padding: 3.4375rem !important;
  }

  .pt-xxl-14,
  .py-xxl-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-xxl-14,
  .px-xxl-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-xxl-14,
  .py-xxl-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-xxl-14,
  .px-xxl-14 {
    padding-left: 3.4375rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .pt-xxl-15,
  .py-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pr-xxl-15,
  .px-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pb-xxl-15,
  .py-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-xxl-15,
  .px-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4.0625rem !important;
  }

  .pt-xxl-16,
  .py-xxl-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-xxl-16,
  .px-xxl-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-xxl-16,
  .py-xxl-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-xxl-16,
  .px-xxl-16 {
    padding-left: 4.0625rem !important;
  }

  .p-xxl-17 {
    padding: 4.375rem !important;
  }

  .pt-xxl-17,
  .py-xxl-17 {
    padding-top: 4.375rem !important;
  }

  .pr-xxl-17,
  .px-xxl-17 {
    padding-right: 4.375rem !important;
  }

  .pb-xxl-17,
  .py-xxl-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-xxl-17,
  .px-xxl-17 {
    padding-left: 4.375rem !important;
  }

  .p-xxl-18 {
    padding: 4.6875rem !important;
  }

  .pt-xxl-18,
  .py-xxl-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-xxl-18,
  .px-xxl-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-xxl-18,
  .py-xxl-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-xxl-18,
  .px-xxl-18 {
    padding-left: 4.6875rem !important;
  }

  .p-xxl-19 {
    padding: 5rem !important;
  }

  .pt-xxl-19,
  .py-xxl-19 {
    padding-top: 5rem !important;
  }

  .pr-xxl-19,
  .px-xxl-19 {
    padding-right: 5rem !important;
  }

  .pb-xxl-19,
  .py-xxl-19 {
    padding-bottom: 5rem !important;
  }

  .pl-xxl-19,
  .px-xxl-19 {
    padding-left: 5rem !important;
  }

  .p-xxl-20 {
    padding: 5.3125rem !important;
  }

  .pt-xxl-20,
  .py-xxl-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-xxl-20,
  .px-xxl-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-xxl-20,
  .py-xxl-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-xxl-20,
  .px-xxl-20 {
    padding-left: 5.3125rem !important;
  }

  .p-xxl-21 {
    padding: 5.625rem !important;
  }

  .pt-xxl-21,
  .py-xxl-21 {
    padding-top: 5.625rem !important;
  }

  .pr-xxl-21,
  .px-xxl-21 {
    padding-right: 5.625rem !important;
  }

  .pb-xxl-21,
  .py-xxl-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-xxl-21,
  .px-xxl-21 {
    padding-left: 5.625rem !important;
  }

  .p-xxl-22 {
    padding: 5.9375rem !important;
  }

  .pt-xxl-22,
  .py-xxl-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-xxl-22,
  .px-xxl-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-xxl-22,
  .py-xxl-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-xxl-22,
  .px-xxl-22 {
    padding-left: 5.9375rem !important;
  }

  .p-xxl-23 {
    padding: 6.25rem !important;
  }

  .pt-xxl-23,
  .py-xxl-23 {
    padding-top: 6.25rem !important;
  }

  .pr-xxl-23,
  .px-xxl-23 {
    padding-right: 6.25rem !important;
  }

  .pb-xxl-23,
  .py-xxl-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-xxl-23,
  .px-xxl-23 {
    padding-left: 6.25rem !important;
  }

  .p-xxl-24 {
    padding: 6.875rem !important;
  }

  .pt-xxl-24,
  .py-xxl-24 {
    padding-top: 6.875rem !important;
  }

  .pr-xxl-24,
  .px-xxl-24 {
    padding-right: 6.875rem !important;
  }

  .pb-xxl-24,
  .py-xxl-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-xxl-24,
  .px-xxl-24 {
    padding-left: 6.875rem !important;
  }

  .p-xxl-25 {
    padding: 7.5rem !important;
  }

  .pt-xxl-25,
  .py-xxl-25 {
    padding-top: 7.5rem !important;
  }

  .pr-xxl-25,
  .px-xxl-25 {
    padding-right: 7.5rem !important;
  }

  .pb-xxl-25,
  .py-xxl-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-xxl-25,
  .px-xxl-25 {
    padding-left: 7.5rem !important;
  }

  .p-xxl-26 {
    padding: 8.125rem !important;
  }

  .pt-xxl-26,
  .py-xxl-26 {
    padding-top: 8.125rem !important;
  }

  .pr-xxl-26,
  .px-xxl-26 {
    padding-right: 8.125rem !important;
  }

  .pb-xxl-26,
  .py-xxl-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-xxl-26,
  .px-xxl-26 {
    padding-left: 8.125rem !important;
  }

  .p-xxl-27 {
    padding: 8.4375rem !important;
  }

  .pt-xxl-27,
  .py-xxl-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-xxl-27,
  .px-xxl-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-xxl-27,
  .py-xxl-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-xxl-27,
  .px-xxl-27 {
    padding-left: 8.4375rem !important;
  }

  .p-xxl-28 {
    padding: 9.0625rem !important;
  }

  .pt-xxl-28,
  .py-xxl-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-xxl-28,
  .px-xxl-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-xxl-28,
  .py-xxl-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-xxl-28,
  .px-xxl-28 {
    padding-left: 9.0625rem !important;
  }

  .p-xxl-29 {
    padding: 9.375rem !important;
  }

  .pt-xxl-29,
  .py-xxl-29 {
    padding-top: 9.375rem !important;
  }

  .pr-xxl-29,
  .px-xxl-29 {
    padding-right: 9.375rem !important;
  }

  .pb-xxl-29,
  .py-xxl-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-xxl-29,
  .px-xxl-29 {
    padding-left: 9.375rem !important;
  }

  .p-xxl-30 {
    padding: 9.6875rem !important;
  }

  .pt-xxl-30,
  .py-xxl-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-xxl-30,
  .px-xxl-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-xxl-30,
  .py-xxl-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-xxl-30,
  .px-xxl-30 {
    padding-left: 9.6875rem !important;
  }

  .p-xxl-31 {
    padding: 10.625rem !important;
  }

  .pt-xxl-31,
  .py-xxl-31 {
    padding-top: 10.625rem !important;
  }

  .pr-xxl-31,
  .px-xxl-31 {
    padding-right: 10.625rem !important;
  }

  .pb-xxl-31,
  .py-xxl-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-xxl-31,
  .px-xxl-31 {
    padding-left: 10.625rem !important;
  }

  .p-xxl-32 {
    padding: 11.25rem !important;
  }

  .pt-xxl-32,
  .py-xxl-32 {
    padding-top: 11.25rem !important;
  }

  .pr-xxl-32,
  .px-xxl-32 {
    padding-right: 11.25rem !important;
  }

  .pb-xxl-32,
  .py-xxl-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-xxl-32,
  .px-xxl-32 {
    padding-left: 11.25rem !important;
  }

  .p-xxl-33 {
    padding: 12.5rem !important;
  }

  .pt-xxl-33,
  .py-xxl-33 {
    padding-top: 12.5rem !important;
  }

  .pr-xxl-33,
  .px-xxl-33 {
    padding-right: 12.5rem !important;
  }

  .pb-xxl-33,
  .py-xxl-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-xxl-33,
  .px-xxl-33 {
    padding-left: 12.5rem !important;
  }

  .p-xxl-34 {
    padding: 14.0625rem !important;
  }

  .pt-xxl-34,
  .py-xxl-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-xxl-34,
  .px-xxl-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-xxl-34,
  .py-xxl-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-xxl-34,
  .px-xxl-34 {
    padding-left: 14.0625rem !important;
  }

  .p-xxl-35 {
    padding: 15.625rem !important;
  }

  .pt-xxl-35,
  .py-xxl-35 {
    padding-top: 15.625rem !important;
  }

  .pr-xxl-35,
  .px-xxl-35 {
    padding-right: 15.625rem !important;
  }

  .pb-xxl-35,
  .py-xxl-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-xxl-35,
  .px-xxl-35 {
    padding-left: 15.625rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.375rem !important;
  }

  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.375rem !important;
  }

  .m-xxl-n3 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n4 {
    margin: -0.625rem !important;
  }

  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -0.625rem !important;
  }

  .m-xxl-n5 {
    margin: -0.75rem !important;
  }

  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -0.75rem !important;
  }

  .m-xxl-n6 {
    margin: -1rem !important;
  }

  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -1rem !important;
  }

  .m-xxl-n7 {
    margin: -1.25rem !important;
  }

  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -1.25rem !important;
  }

  .m-xxl-n8 {
    margin: -1.5625rem !important;
  }

  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-xxl-n9 {
    margin: -1.875rem !important;
  }

  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -1.875rem !important;
  }

  .m-xxl-n10 {
    margin: -2.1875rem !important;
  }

  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-xxl-n11 {
    margin: -2.5rem !important;
  }

  .mt-xxl-n11,
  .my-xxl-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-xxl-n11,
  .mx-xxl-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n11,
  .my-xxl-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xxl-n11,
  .mx-xxl-n11 {
    margin-left: -2.5rem !important;
  }

  .m-xxl-n12 {
    margin: -2.8125rem !important;
  }

  .mt-xxl-n12,
  .my-xxl-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-xxl-n12,
  .mx-xxl-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-xxl-n12,
  .my-xxl-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-xxl-n12,
  .mx-xxl-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-xxl-n13 {
    margin: -3.125rem !important;
  }

  .mt-xxl-n13,
  .my-xxl-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-xxl-n13,
  .mx-xxl-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-xxl-n13,
  .my-xxl-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-xxl-n13,
  .mx-xxl-n13 {
    margin-left: -3.125rem !important;
  }

  .m-xxl-n14 {
    margin: -3.4375rem !important;
  }

  .mt-xxl-n14,
  .my-xxl-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-xxl-n14,
  .mx-xxl-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-xxl-n14,
  .my-xxl-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-xxl-n14,
  .mx-xxl-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .mt-xxl-n15,
  .my-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-xxl-n15,
  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-xxl-n15,
  .my-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-xxl-n15,
  .mx-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4.0625rem !important;
  }

  .mt-xxl-n16,
  .my-xxl-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-xxl-n16,
  .mx-xxl-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-xxl-n16,
  .my-xxl-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-xxl-n16,
  .mx-xxl-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-xxl-n17 {
    margin: -4.375rem !important;
  }

  .mt-xxl-n17,
  .my-xxl-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-xxl-n17,
  .mx-xxl-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-xxl-n17,
  .my-xxl-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-xxl-n17,
  .mx-xxl-n17 {
    margin-left: -4.375rem !important;
  }

  .m-xxl-n18 {
    margin: -4.6875rem !important;
  }

  .mt-xxl-n18,
  .my-xxl-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-xxl-n18,
  .mx-xxl-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-xxl-n18,
  .my-xxl-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-xxl-n18,
  .mx-xxl-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-xxl-n19 {
    margin: -5rem !important;
  }

  .mt-xxl-n19,
  .my-xxl-n19 {
    margin-top: -5rem !important;
  }

  .mr-xxl-n19,
  .mx-xxl-n19 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n19,
  .my-xxl-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-xxl-n19,
  .mx-xxl-n19 {
    margin-left: -5rem !important;
  }

  .m-xxl-n20 {
    margin: -5.3125rem !important;
  }

  .mt-xxl-n20,
  .my-xxl-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-xxl-n20,
  .mx-xxl-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-xxl-n20,
  .my-xxl-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-xxl-n20,
  .mx-xxl-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-xxl-n21 {
    margin: -5.625rem !important;
  }

  .mt-xxl-n21,
  .my-xxl-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-xxl-n21,
  .mx-xxl-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-xxl-n21,
  .my-xxl-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-xxl-n21,
  .mx-xxl-n21 {
    margin-left: -5.625rem !important;
  }

  .m-xxl-n22 {
    margin: -5.9375rem !important;
  }

  .mt-xxl-n22,
  .my-xxl-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-xxl-n22,
  .mx-xxl-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-xxl-n22,
  .my-xxl-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-xxl-n22,
  .mx-xxl-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-xxl-n23 {
    margin: -6.25rem !important;
  }

  .mt-xxl-n23,
  .my-xxl-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-xxl-n23,
  .mx-xxl-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-xxl-n23,
  .my-xxl-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-xxl-n23,
  .mx-xxl-n23 {
    margin-left: -6.25rem !important;
  }

  .m-xxl-n24 {
    margin: -6.875rem !important;
  }

  .mt-xxl-n24,
  .my-xxl-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-xxl-n24,
  .mx-xxl-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-xxl-n24,
  .my-xxl-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-xxl-n24,
  .mx-xxl-n24 {
    margin-left: -6.875rem !important;
  }

  .m-xxl-n25 {
    margin: -7.5rem !important;
  }

  .mt-xxl-n25,
  .my-xxl-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-xxl-n25,
  .mx-xxl-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-xxl-n25,
  .my-xxl-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-xxl-n25,
  .mx-xxl-n25 {
    margin-left: -7.5rem !important;
  }

  .m-xxl-n26 {
    margin: -8.125rem !important;
  }

  .mt-xxl-n26,
  .my-xxl-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-xxl-n26,
  .mx-xxl-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-xxl-n26,
  .my-xxl-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-xxl-n26,
  .mx-xxl-n26 {
    margin-left: -8.125rem !important;
  }

  .m-xxl-n27 {
    margin: -8.4375rem !important;
  }

  .mt-xxl-n27,
  .my-xxl-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-xxl-n27,
  .mx-xxl-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-xxl-n27,
  .my-xxl-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-xxl-n27,
  .mx-xxl-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-xxl-n28 {
    margin: -9.0625rem !important;
  }

  .mt-xxl-n28,
  .my-xxl-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-xxl-n28,
  .mx-xxl-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-xxl-n28,
  .my-xxl-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-xxl-n28,
  .mx-xxl-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-xxl-n29 {
    margin: -9.375rem !important;
  }

  .mt-xxl-n29,
  .my-xxl-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-xxl-n29,
  .mx-xxl-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-xxl-n29,
  .my-xxl-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-xxl-n29,
  .mx-xxl-n29 {
    margin-left: -9.375rem !important;
  }

  .m-xxl-n30 {
    margin: -9.6875rem !important;
  }

  .mt-xxl-n30,
  .my-xxl-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-xxl-n30,
  .mx-xxl-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-xxl-n30,
  .my-xxl-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-xxl-n30,
  .mx-xxl-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-xxl-n31 {
    margin: -10.625rem !important;
  }

  .mt-xxl-n31,
  .my-xxl-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-xxl-n31,
  .mx-xxl-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-xxl-n31,
  .my-xxl-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-xxl-n31,
  .mx-xxl-n31 {
    margin-left: -10.625rem !important;
  }

  .m-xxl-n32 {
    margin: -11.25rem !important;
  }

  .mt-xxl-n32,
  .my-xxl-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-xxl-n32,
  .mx-xxl-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-xxl-n32,
  .my-xxl-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-xxl-n32,
  .mx-xxl-n32 {
    margin-left: -11.25rem !important;
  }

  .m-xxl-n33 {
    margin: -12.5rem !important;
  }

  .mt-xxl-n33,
  .my-xxl-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-xxl-n33,
  .mx-xxl-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-xxl-n33,
  .my-xxl-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-xxl-n33,
  .mx-xxl-n33 {
    margin-left: -12.5rem !important;
  }

  .m-xxl-n34 {
    margin: -14.0625rem !important;
  }

  .mt-xxl-n34,
  .my-xxl-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-xxl-n34,
  .mx-xxl-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-xxl-n34,
  .my-xxl-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-xxl-n34,
  .mx-xxl-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-xxl-n35 {
    margin: -15.625rem !important;
  }

  .mt-xxl-n35,
  .my-xxl-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-xxl-n35,
  .mx-xxl-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-xxl-n35,
  .my-xxl-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-xxl-n35,
  .mx-xxl-n35 {
    margin-left: -15.625rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xxxl-0 {
    margin: 0 !important;
  }

  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important;
  }

  .m-xxxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxxl-2 {
    margin: 0.375rem !important;
  }

  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.375rem !important;
  }

  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.375rem !important;
  }

  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.375rem !important;
  }

  .m-xxxl-3 {
    margin: 0.5rem !important;
  }

  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 0.5rem !important;
  }

  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 0.5rem !important;
  }

  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 0.5rem !important;
  }

  .m-xxxl-4 {
    margin: 0.625rem !important;
  }

  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 0.625rem !important;
  }

  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 0.625rem !important;
  }

  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 0.625rem !important;
  }

  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 0.625rem !important;
  }

  .m-xxxl-5 {
    margin: 0.75rem !important;
  }

  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 0.75rem !important;
  }

  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 0.75rem !important;
  }

  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 0.75rem !important;
  }

  .m-xxxl-6 {
    margin: 1rem !important;
  }

  .mt-xxxl-6,
  .my-xxxl-6 {
    margin-top: 1rem !important;
  }

  .mr-xxxl-6,
  .mx-xxxl-6 {
    margin-right: 1rem !important;
  }

  .mb-xxxl-6,
  .my-xxxl-6 {
    margin-bottom: 1rem !important;
  }

  .ml-xxxl-6,
  .mx-xxxl-6 {
    margin-left: 1rem !important;
  }

  .m-xxxl-7 {
    margin: 1.25rem !important;
  }

  .mt-xxxl-7,
  .my-xxxl-7 {
    margin-top: 1.25rem !important;
  }

  .mr-xxxl-7,
  .mx-xxxl-7 {
    margin-right: 1.25rem !important;
  }

  .mb-xxxl-7,
  .my-xxxl-7 {
    margin-bottom: 1.25rem !important;
  }

  .ml-xxxl-7,
  .mx-xxxl-7 {
    margin-left: 1.25rem !important;
  }

  .m-xxxl-8 {
    margin: 1.5625rem !important;
  }

  .mt-xxxl-8,
  .my-xxxl-8 {
    margin-top: 1.5625rem !important;
  }

  .mr-xxxl-8,
  .mx-xxxl-8 {
    margin-right: 1.5625rem !important;
  }

  .mb-xxxl-8,
  .my-xxxl-8 {
    margin-bottom: 1.5625rem !important;
  }

  .ml-xxxl-8,
  .mx-xxxl-8 {
    margin-left: 1.5625rem !important;
  }

  .m-xxxl-9 {
    margin: 1.875rem !important;
  }

  .mt-xxxl-9,
  .my-xxxl-9 {
    margin-top: 1.875rem !important;
  }

  .mr-xxxl-9,
  .mx-xxxl-9 {
    margin-right: 1.875rem !important;
  }

  .mb-xxxl-9,
  .my-xxxl-9 {
    margin-bottom: 1.875rem !important;
  }

  .ml-xxxl-9,
  .mx-xxxl-9 {
    margin-left: 1.875rem !important;
  }

  .m-xxxl-10 {
    margin: 2.1875rem !important;
  }

  .mt-xxxl-10,
  .my-xxxl-10 {
    margin-top: 2.1875rem !important;
  }

  .mr-xxxl-10,
  .mx-xxxl-10 {
    margin-right: 2.1875rem !important;
  }

  .mb-xxxl-10,
  .my-xxxl-10 {
    margin-bottom: 2.1875rem !important;
  }

  .ml-xxxl-10,
  .mx-xxxl-10 {
    margin-left: 2.1875rem !important;
  }

  .m-xxxl-11 {
    margin: 2.5rem !important;
  }

  .mt-xxxl-11,
  .my-xxxl-11 {
    margin-top: 2.5rem !important;
  }

  .mr-xxxl-11,
  .mx-xxxl-11 {
    margin-right: 2.5rem !important;
  }

  .mb-xxxl-11,
  .my-xxxl-11 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xxxl-11,
  .mx-xxxl-11 {
    margin-left: 2.5rem !important;
  }

  .m-xxxl-12 {
    margin: 2.8125rem !important;
  }

  .mt-xxxl-12,
  .my-xxxl-12 {
    margin-top: 2.8125rem !important;
  }

  .mr-xxxl-12,
  .mx-xxxl-12 {
    margin-right: 2.8125rem !important;
  }

  .mb-xxxl-12,
  .my-xxxl-12 {
    margin-bottom: 2.8125rem !important;
  }

  .ml-xxxl-12,
  .mx-xxxl-12 {
    margin-left: 2.8125rem !important;
  }

  .m-xxxl-13 {
    margin: 3.125rem !important;
  }

  .mt-xxxl-13,
  .my-xxxl-13 {
    margin-top: 3.125rem !important;
  }

  .mr-xxxl-13,
  .mx-xxxl-13 {
    margin-right: 3.125rem !important;
  }

  .mb-xxxl-13,
  .my-xxxl-13 {
    margin-bottom: 3.125rem !important;
  }

  .ml-xxxl-13,
  .mx-xxxl-13 {
    margin-left: 3.125rem !important;
  }

  .m-xxxl-14 {
    margin: 3.4375rem !important;
  }

  .mt-xxxl-14,
  .my-xxxl-14 {
    margin-top: 3.4375rem !important;
  }

  .mr-xxxl-14,
  .mx-xxxl-14 {
    margin-right: 3.4375rem !important;
  }

  .mb-xxxl-14,
  .my-xxxl-14 {
    margin-bottom: 3.4375rem !important;
  }

  .ml-xxxl-14,
  .mx-xxxl-14 {
    margin-left: 3.4375rem !important;
  }

  .m-xxxl-15 {
    margin: 3.75rem !important;
  }

  .mt-xxxl-15,
  .my-xxxl-15 {
    margin-top: 3.75rem !important;
  }

  .mr-xxxl-15,
  .mx-xxxl-15 {
    margin-right: 3.75rem !important;
  }

  .mb-xxxl-15,
  .my-xxxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .ml-xxxl-15,
  .mx-xxxl-15 {
    margin-left: 3.75rem !important;
  }

  .m-xxxl-16 {
    margin: 4.0625rem !important;
  }

  .mt-xxxl-16,
  .my-xxxl-16 {
    margin-top: 4.0625rem !important;
  }

  .mr-xxxl-16,
  .mx-xxxl-16 {
    margin-right: 4.0625rem !important;
  }

  .mb-xxxl-16,
  .my-xxxl-16 {
    margin-bottom: 4.0625rem !important;
  }

  .ml-xxxl-16,
  .mx-xxxl-16 {
    margin-left: 4.0625rem !important;
  }

  .m-xxxl-17 {
    margin: 4.375rem !important;
  }

  .mt-xxxl-17,
  .my-xxxl-17 {
    margin-top: 4.375rem !important;
  }

  .mr-xxxl-17,
  .mx-xxxl-17 {
    margin-right: 4.375rem !important;
  }

  .mb-xxxl-17,
  .my-xxxl-17 {
    margin-bottom: 4.375rem !important;
  }

  .ml-xxxl-17,
  .mx-xxxl-17 {
    margin-left: 4.375rem !important;
  }

  .m-xxxl-18 {
    margin: 4.6875rem !important;
  }

  .mt-xxxl-18,
  .my-xxxl-18 {
    margin-top: 4.6875rem !important;
  }

  .mr-xxxl-18,
  .mx-xxxl-18 {
    margin-right: 4.6875rem !important;
  }

  .mb-xxxl-18,
  .my-xxxl-18 {
    margin-bottom: 4.6875rem !important;
  }

  .ml-xxxl-18,
  .mx-xxxl-18 {
    margin-left: 4.6875rem !important;
  }

  .m-xxxl-19 {
    margin: 5rem !important;
  }

  .mt-xxxl-19,
  .my-xxxl-19 {
    margin-top: 5rem !important;
  }

  .mr-xxxl-19,
  .mx-xxxl-19 {
    margin-right: 5rem !important;
  }

  .mb-xxxl-19,
  .my-xxxl-19 {
    margin-bottom: 5rem !important;
  }

  .ml-xxxl-19,
  .mx-xxxl-19 {
    margin-left: 5rem !important;
  }

  .m-xxxl-20 {
    margin: 5.3125rem !important;
  }

  .mt-xxxl-20,
  .my-xxxl-20 {
    margin-top: 5.3125rem !important;
  }

  .mr-xxxl-20,
  .mx-xxxl-20 {
    margin-right: 5.3125rem !important;
  }

  .mb-xxxl-20,
  .my-xxxl-20 {
    margin-bottom: 5.3125rem !important;
  }

  .ml-xxxl-20,
  .mx-xxxl-20 {
    margin-left: 5.3125rem !important;
  }

  .m-xxxl-21 {
    margin: 5.625rem !important;
  }

  .mt-xxxl-21,
  .my-xxxl-21 {
    margin-top: 5.625rem !important;
  }

  .mr-xxxl-21,
  .mx-xxxl-21 {
    margin-right: 5.625rem !important;
  }

  .mb-xxxl-21,
  .my-xxxl-21 {
    margin-bottom: 5.625rem !important;
  }

  .ml-xxxl-21,
  .mx-xxxl-21 {
    margin-left: 5.625rem !important;
  }

  .m-xxxl-22 {
    margin: 5.9375rem !important;
  }

  .mt-xxxl-22,
  .my-xxxl-22 {
    margin-top: 5.9375rem !important;
  }

  .mr-xxxl-22,
  .mx-xxxl-22 {
    margin-right: 5.9375rem !important;
  }

  .mb-xxxl-22,
  .my-xxxl-22 {
    margin-bottom: 5.9375rem !important;
  }

  .ml-xxxl-22,
  .mx-xxxl-22 {
    margin-left: 5.9375rem !important;
  }

  .m-xxxl-23 {
    margin: 6.25rem !important;
  }

  .mt-xxxl-23,
  .my-xxxl-23 {
    margin-top: 6.25rem !important;
  }

  .mr-xxxl-23,
  .mx-xxxl-23 {
    margin-right: 6.25rem !important;
  }

  .mb-xxxl-23,
  .my-xxxl-23 {
    margin-bottom: 6.25rem !important;
  }

  .ml-xxxl-23,
  .mx-xxxl-23 {
    margin-left: 6.25rem !important;
  }

  .m-xxxl-24 {
    margin: 6.875rem !important;
  }

  .mt-xxxl-24,
  .my-xxxl-24 {
    margin-top: 6.875rem !important;
  }

  .mr-xxxl-24,
  .mx-xxxl-24 {
    margin-right: 6.875rem !important;
  }

  .mb-xxxl-24,
  .my-xxxl-24 {
    margin-bottom: 6.875rem !important;
  }

  .ml-xxxl-24,
  .mx-xxxl-24 {
    margin-left: 6.875rem !important;
  }

  .m-xxxl-25 {
    margin: 7.5rem !important;
  }

  .mt-xxxl-25,
  .my-xxxl-25 {
    margin-top: 7.5rem !important;
  }

  .mr-xxxl-25,
  .mx-xxxl-25 {
    margin-right: 7.5rem !important;
  }

  .mb-xxxl-25,
  .my-xxxl-25 {
    margin-bottom: 7.5rem !important;
  }

  .ml-xxxl-25,
  .mx-xxxl-25 {
    margin-left: 7.5rem !important;
  }

  .m-xxxl-26 {
    margin: 8.125rem !important;
  }

  .mt-xxxl-26,
  .my-xxxl-26 {
    margin-top: 8.125rem !important;
  }

  .mr-xxxl-26,
  .mx-xxxl-26 {
    margin-right: 8.125rem !important;
  }

  .mb-xxxl-26,
  .my-xxxl-26 {
    margin-bottom: 8.125rem !important;
  }

  .ml-xxxl-26,
  .mx-xxxl-26 {
    margin-left: 8.125rem !important;
  }

  .m-xxxl-27 {
    margin: 8.4375rem !important;
  }

  .mt-xxxl-27,
  .my-xxxl-27 {
    margin-top: 8.4375rem !important;
  }

  .mr-xxxl-27,
  .mx-xxxl-27 {
    margin-right: 8.4375rem !important;
  }

  .mb-xxxl-27,
  .my-xxxl-27 {
    margin-bottom: 8.4375rem !important;
  }

  .ml-xxxl-27,
  .mx-xxxl-27 {
    margin-left: 8.4375rem !important;
  }

  .m-xxxl-28 {
    margin: 9.0625rem !important;
  }

  .mt-xxxl-28,
  .my-xxxl-28 {
    margin-top: 9.0625rem !important;
  }

  .mr-xxxl-28,
  .mx-xxxl-28 {
    margin-right: 9.0625rem !important;
  }

  .mb-xxxl-28,
  .my-xxxl-28 {
    margin-bottom: 9.0625rem !important;
  }

  .ml-xxxl-28,
  .mx-xxxl-28 {
    margin-left: 9.0625rem !important;
  }

  .m-xxxl-29 {
    margin: 9.375rem !important;
  }

  .mt-xxxl-29,
  .my-xxxl-29 {
    margin-top: 9.375rem !important;
  }

  .mr-xxxl-29,
  .mx-xxxl-29 {
    margin-right: 9.375rem !important;
  }

  .mb-xxxl-29,
  .my-xxxl-29 {
    margin-bottom: 9.375rem !important;
  }

  .ml-xxxl-29,
  .mx-xxxl-29 {
    margin-left: 9.375rem !important;
  }

  .m-xxxl-30 {
    margin: 9.6875rem !important;
  }

  .mt-xxxl-30,
  .my-xxxl-30 {
    margin-top: 9.6875rem !important;
  }

  .mr-xxxl-30,
  .mx-xxxl-30 {
    margin-right: 9.6875rem !important;
  }

  .mb-xxxl-30,
  .my-xxxl-30 {
    margin-bottom: 9.6875rem !important;
  }

  .ml-xxxl-30,
  .mx-xxxl-30 {
    margin-left: 9.6875rem !important;
  }

  .m-xxxl-31 {
    margin: 10.625rem !important;
  }

  .mt-xxxl-31,
  .my-xxxl-31 {
    margin-top: 10.625rem !important;
  }

  .mr-xxxl-31,
  .mx-xxxl-31 {
    margin-right: 10.625rem !important;
  }

  .mb-xxxl-31,
  .my-xxxl-31 {
    margin-bottom: 10.625rem !important;
  }

  .ml-xxxl-31,
  .mx-xxxl-31 {
    margin-left: 10.625rem !important;
  }

  .m-xxxl-32 {
    margin: 11.25rem !important;
  }

  .mt-xxxl-32,
  .my-xxxl-32 {
    margin-top: 11.25rem !important;
  }

  .mr-xxxl-32,
  .mx-xxxl-32 {
    margin-right: 11.25rem !important;
  }

  .mb-xxxl-32,
  .my-xxxl-32 {
    margin-bottom: 11.25rem !important;
  }

  .ml-xxxl-32,
  .mx-xxxl-32 {
    margin-left: 11.25rem !important;
  }

  .m-xxxl-33 {
    margin: 12.5rem !important;
  }

  .mt-xxxl-33,
  .my-xxxl-33 {
    margin-top: 12.5rem !important;
  }

  .mr-xxxl-33,
  .mx-xxxl-33 {
    margin-right: 12.5rem !important;
  }

  .mb-xxxl-33,
  .my-xxxl-33 {
    margin-bottom: 12.5rem !important;
  }

  .ml-xxxl-33,
  .mx-xxxl-33 {
    margin-left: 12.5rem !important;
  }

  .m-xxxl-34 {
    margin: 14.0625rem !important;
  }

  .mt-xxxl-34,
  .my-xxxl-34 {
    margin-top: 14.0625rem !important;
  }

  .mr-xxxl-34,
  .mx-xxxl-34 {
    margin-right: 14.0625rem !important;
  }

  .mb-xxxl-34,
  .my-xxxl-34 {
    margin-bottom: 14.0625rem !important;
  }

  .ml-xxxl-34,
  .mx-xxxl-34 {
    margin-left: 14.0625rem !important;
  }

  .m-xxxl-35 {
    margin: 15.625rem !important;
  }

  .mt-xxxl-35,
  .my-xxxl-35 {
    margin-top: 15.625rem !important;
  }

  .mr-xxxl-35,
  .mx-xxxl-35 {
    margin-right: 15.625rem !important;
  }

  .mb-xxxl-35,
  .my-xxxl-35 {
    margin-bottom: 15.625rem !important;
  }

  .ml-xxxl-35,
  .mx-xxxl-35 {
    margin-left: 15.625rem !important;
  }

  .p-xxxl-0 {
    padding: 0 !important;
  }

  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important;
  }

  .p-xxxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxxl-2 {
    padding: 0.375rem !important;
  }

  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.375rem !important;
  }

  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.375rem !important;
  }

  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.375rem !important;
  }

  .p-xxxl-3 {
    padding: 0.5rem !important;
  }

  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 0.5rem !important;
  }

  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 0.5rem !important;
  }

  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 0.5rem !important;
  }

  .p-xxxl-4 {
    padding: 0.625rem !important;
  }

  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 0.625rem !important;
  }

  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 0.625rem !important;
  }

  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 0.625rem !important;
  }

  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 0.625rem !important;
  }

  .p-xxxl-5 {
    padding: 0.75rem !important;
  }

  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 0.75rem !important;
  }

  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 0.75rem !important;
  }

  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 0.75rem !important;
  }

  .p-xxxl-6 {
    padding: 1rem !important;
  }

  .pt-xxxl-6,
  .py-xxxl-6 {
    padding-top: 1rem !important;
  }

  .pr-xxxl-6,
  .px-xxxl-6 {
    padding-right: 1rem !important;
  }

  .pb-xxxl-6,
  .py-xxxl-6 {
    padding-bottom: 1rem !important;
  }

  .pl-xxxl-6,
  .px-xxxl-6 {
    padding-left: 1rem !important;
  }

  .p-xxxl-7 {
    padding: 1.25rem !important;
  }

  .pt-xxxl-7,
  .py-xxxl-7 {
    padding-top: 1.25rem !important;
  }

  .pr-xxxl-7,
  .px-xxxl-7 {
    padding-right: 1.25rem !important;
  }

  .pb-xxxl-7,
  .py-xxxl-7 {
    padding-bottom: 1.25rem !important;
  }

  .pl-xxxl-7,
  .px-xxxl-7 {
    padding-left: 1.25rem !important;
  }

  .p-xxxl-8 {
    padding: 1.5625rem !important;
  }

  .pt-xxxl-8,
  .py-xxxl-8 {
    padding-top: 1.5625rem !important;
  }

  .pr-xxxl-8,
  .px-xxxl-8 {
    padding-right: 1.5625rem !important;
  }

  .pb-xxxl-8,
  .py-xxxl-8 {
    padding-bottom: 1.5625rem !important;
  }

  .pl-xxxl-8,
  .px-xxxl-8 {
    padding-left: 1.5625rem !important;
  }

  .p-xxxl-9 {
    padding: 1.875rem !important;
  }

  .pt-xxxl-9,
  .py-xxxl-9 {
    padding-top: 1.875rem !important;
  }

  .pr-xxxl-9,
  .px-xxxl-9 {
    padding-right: 1.875rem !important;
  }

  .pb-xxxl-9,
  .py-xxxl-9 {
    padding-bottom: 1.875rem !important;
  }

  .pl-xxxl-9,
  .px-xxxl-9 {
    padding-left: 1.875rem !important;
  }

  .p-xxxl-10 {
    padding: 2.1875rem !important;
  }

  .pt-xxxl-10,
  .py-xxxl-10 {
    padding-top: 2.1875rem !important;
  }

  .pr-xxxl-10,
  .px-xxxl-10 {
    padding-right: 2.1875rem !important;
  }

  .pb-xxxl-10,
  .py-xxxl-10 {
    padding-bottom: 2.1875rem !important;
  }

  .pl-xxxl-10,
  .px-xxxl-10 {
    padding-left: 2.1875rem !important;
  }

  .p-xxxl-11 {
    padding: 2.5rem !important;
  }

  .pt-xxxl-11,
  .py-xxxl-11 {
    padding-top: 2.5rem !important;
  }

  .pr-xxxl-11,
  .px-xxxl-11 {
    padding-right: 2.5rem !important;
  }

  .pb-xxxl-11,
  .py-xxxl-11 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xxxl-11,
  .px-xxxl-11 {
    padding-left: 2.5rem !important;
  }

  .p-xxxl-12 {
    padding: 2.8125rem !important;
  }

  .pt-xxxl-12,
  .py-xxxl-12 {
    padding-top: 2.8125rem !important;
  }

  .pr-xxxl-12,
  .px-xxxl-12 {
    padding-right: 2.8125rem !important;
  }

  .pb-xxxl-12,
  .py-xxxl-12 {
    padding-bottom: 2.8125rem !important;
  }

  .pl-xxxl-12,
  .px-xxxl-12 {
    padding-left: 2.8125rem !important;
  }

  .p-xxxl-13 {
    padding: 3.125rem !important;
  }

  .pt-xxxl-13,
  .py-xxxl-13 {
    padding-top: 3.125rem !important;
  }

  .pr-xxxl-13,
  .px-xxxl-13 {
    padding-right: 3.125rem !important;
  }

  .pb-xxxl-13,
  .py-xxxl-13 {
    padding-bottom: 3.125rem !important;
  }

  .pl-xxxl-13,
  .px-xxxl-13 {
    padding-left: 3.125rem !important;
  }

  .p-xxxl-14 {
    padding: 3.4375rem !important;
  }

  .pt-xxxl-14,
  .py-xxxl-14 {
    padding-top: 3.4375rem !important;
  }

  .pr-xxxl-14,
  .px-xxxl-14 {
    padding-right: 3.4375rem !important;
  }

  .pb-xxxl-14,
  .py-xxxl-14 {
    padding-bottom: 3.4375rem !important;
  }

  .pl-xxxl-14,
  .px-xxxl-14 {
    padding-left: 3.4375rem !important;
  }

  .p-xxxl-15 {
    padding: 3.75rem !important;
  }

  .pt-xxxl-15,
  .py-xxxl-15 {
    padding-top: 3.75rem !important;
  }

  .pr-xxxl-15,
  .px-xxxl-15 {
    padding-right: 3.75rem !important;
  }

  .pb-xxxl-15,
  .py-xxxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pl-xxxl-15,
  .px-xxxl-15 {
    padding-left: 3.75rem !important;
  }

  .p-xxxl-16 {
    padding: 4.0625rem !important;
  }

  .pt-xxxl-16,
  .py-xxxl-16 {
    padding-top: 4.0625rem !important;
  }

  .pr-xxxl-16,
  .px-xxxl-16 {
    padding-right: 4.0625rem !important;
  }

  .pb-xxxl-16,
  .py-xxxl-16 {
    padding-bottom: 4.0625rem !important;
  }

  .pl-xxxl-16,
  .px-xxxl-16 {
    padding-left: 4.0625rem !important;
  }

  .p-xxxl-17 {
    padding: 4.375rem !important;
  }

  .pt-xxxl-17,
  .py-xxxl-17 {
    padding-top: 4.375rem !important;
  }

  .pr-xxxl-17,
  .px-xxxl-17 {
    padding-right: 4.375rem !important;
  }

  .pb-xxxl-17,
  .py-xxxl-17 {
    padding-bottom: 4.375rem !important;
  }

  .pl-xxxl-17,
  .px-xxxl-17 {
    padding-left: 4.375rem !important;
  }

  .p-xxxl-18 {
    padding: 4.6875rem !important;
  }

  .pt-xxxl-18,
  .py-xxxl-18 {
    padding-top: 4.6875rem !important;
  }

  .pr-xxxl-18,
  .px-xxxl-18 {
    padding-right: 4.6875rem !important;
  }

  .pb-xxxl-18,
  .py-xxxl-18 {
    padding-bottom: 4.6875rem !important;
  }

  .pl-xxxl-18,
  .px-xxxl-18 {
    padding-left: 4.6875rem !important;
  }

  .p-xxxl-19 {
    padding: 5rem !important;
  }

  .pt-xxxl-19,
  .py-xxxl-19 {
    padding-top: 5rem !important;
  }

  .pr-xxxl-19,
  .px-xxxl-19 {
    padding-right: 5rem !important;
  }

  .pb-xxxl-19,
  .py-xxxl-19 {
    padding-bottom: 5rem !important;
  }

  .pl-xxxl-19,
  .px-xxxl-19 {
    padding-left: 5rem !important;
  }

  .p-xxxl-20 {
    padding: 5.3125rem !important;
  }

  .pt-xxxl-20,
  .py-xxxl-20 {
    padding-top: 5.3125rem !important;
  }

  .pr-xxxl-20,
  .px-xxxl-20 {
    padding-right: 5.3125rem !important;
  }

  .pb-xxxl-20,
  .py-xxxl-20 {
    padding-bottom: 5.3125rem !important;
  }

  .pl-xxxl-20,
  .px-xxxl-20 {
    padding-left: 5.3125rem !important;
  }

  .p-xxxl-21 {
    padding: 5.625rem !important;
  }

  .pt-xxxl-21,
  .py-xxxl-21 {
    padding-top: 5.625rem !important;
  }

  .pr-xxxl-21,
  .px-xxxl-21 {
    padding-right: 5.625rem !important;
  }

  .pb-xxxl-21,
  .py-xxxl-21 {
    padding-bottom: 5.625rem !important;
  }

  .pl-xxxl-21,
  .px-xxxl-21 {
    padding-left: 5.625rem !important;
  }

  .p-xxxl-22 {
    padding: 5.9375rem !important;
  }

  .pt-xxxl-22,
  .py-xxxl-22 {
    padding-top: 5.9375rem !important;
  }

  .pr-xxxl-22,
  .px-xxxl-22 {
    padding-right: 5.9375rem !important;
  }

  .pb-xxxl-22,
  .py-xxxl-22 {
    padding-bottom: 5.9375rem !important;
  }

  .pl-xxxl-22,
  .px-xxxl-22 {
    padding-left: 5.9375rem !important;
  }

  .p-xxxl-23 {
    padding: 6.25rem !important;
  }

  .pt-xxxl-23,
  .py-xxxl-23 {
    padding-top: 6.25rem !important;
  }

  .pr-xxxl-23,
  .px-xxxl-23 {
    padding-right: 6.25rem !important;
  }

  .pb-xxxl-23,
  .py-xxxl-23 {
    padding-bottom: 6.25rem !important;
  }

  .pl-xxxl-23,
  .px-xxxl-23 {
    padding-left: 6.25rem !important;
  }

  .p-xxxl-24 {
    padding: 6.875rem !important;
  }

  .pt-xxxl-24,
  .py-xxxl-24 {
    padding-top: 6.875rem !important;
  }

  .pr-xxxl-24,
  .px-xxxl-24 {
    padding-right: 6.875rem !important;
  }

  .pb-xxxl-24,
  .py-xxxl-24 {
    padding-bottom: 6.875rem !important;
  }

  .pl-xxxl-24,
  .px-xxxl-24 {
    padding-left: 6.875rem !important;
  }

  .p-xxxl-25 {
    padding: 7.5rem !important;
  }

  .pt-xxxl-25,
  .py-xxxl-25 {
    padding-top: 7.5rem !important;
  }

  .pr-xxxl-25,
  .px-xxxl-25 {
    padding-right: 7.5rem !important;
  }

  .pb-xxxl-25,
  .py-xxxl-25 {
    padding-bottom: 7.5rem !important;
  }

  .pl-xxxl-25,
  .px-xxxl-25 {
    padding-left: 7.5rem !important;
  }

  .p-xxxl-26 {
    padding: 8.125rem !important;
  }

  .pt-xxxl-26,
  .py-xxxl-26 {
    padding-top: 8.125rem !important;
  }

  .pr-xxxl-26,
  .px-xxxl-26 {
    padding-right: 8.125rem !important;
  }

  .pb-xxxl-26,
  .py-xxxl-26 {
    padding-bottom: 8.125rem !important;
  }

  .pl-xxxl-26,
  .px-xxxl-26 {
    padding-left: 8.125rem !important;
  }

  .p-xxxl-27 {
    padding: 8.4375rem !important;
  }

  .pt-xxxl-27,
  .py-xxxl-27 {
    padding-top: 8.4375rem !important;
  }

  .pr-xxxl-27,
  .px-xxxl-27 {
    padding-right: 8.4375rem !important;
  }

  .pb-xxxl-27,
  .py-xxxl-27 {
    padding-bottom: 8.4375rem !important;
  }

  .pl-xxxl-27,
  .px-xxxl-27 {
    padding-left: 8.4375rem !important;
  }

  .p-xxxl-28 {
    padding: 9.0625rem !important;
  }

  .pt-xxxl-28,
  .py-xxxl-28 {
    padding-top: 9.0625rem !important;
  }

  .pr-xxxl-28,
  .px-xxxl-28 {
    padding-right: 9.0625rem !important;
  }

  .pb-xxxl-28,
  .py-xxxl-28 {
    padding-bottom: 9.0625rem !important;
  }

  .pl-xxxl-28,
  .px-xxxl-28 {
    padding-left: 9.0625rem !important;
  }

  .p-xxxl-29 {
    padding: 9.375rem !important;
  }

  .pt-xxxl-29,
  .py-xxxl-29 {
    padding-top: 9.375rem !important;
  }

  .pr-xxxl-29,
  .px-xxxl-29 {
    padding-right: 9.375rem !important;
  }

  .pb-xxxl-29,
  .py-xxxl-29 {
    padding-bottom: 9.375rem !important;
  }

  .pl-xxxl-29,
  .px-xxxl-29 {
    padding-left: 9.375rem !important;
  }

  .p-xxxl-30 {
    padding: 9.6875rem !important;
  }

  .pt-xxxl-30,
  .py-xxxl-30 {
    padding-top: 9.6875rem !important;
  }

  .pr-xxxl-30,
  .px-xxxl-30 {
    padding-right: 9.6875rem !important;
  }

  .pb-xxxl-30,
  .py-xxxl-30 {
    padding-bottom: 9.6875rem !important;
  }

  .pl-xxxl-30,
  .px-xxxl-30 {
    padding-left: 9.6875rem !important;
  }

  .p-xxxl-31 {
    padding: 10.625rem !important;
  }

  .pt-xxxl-31,
  .py-xxxl-31 {
    padding-top: 10.625rem !important;
  }

  .pr-xxxl-31,
  .px-xxxl-31 {
    padding-right: 10.625rem !important;
  }

  .pb-xxxl-31,
  .py-xxxl-31 {
    padding-bottom: 10.625rem !important;
  }

  .pl-xxxl-31,
  .px-xxxl-31 {
    padding-left: 10.625rem !important;
  }

  .p-xxxl-32 {
    padding: 11.25rem !important;
  }

  .pt-xxxl-32,
  .py-xxxl-32 {
    padding-top: 11.25rem !important;
  }

  .pr-xxxl-32,
  .px-xxxl-32 {
    padding-right: 11.25rem !important;
  }

  .pb-xxxl-32,
  .py-xxxl-32 {
    padding-bottom: 11.25rem !important;
  }

  .pl-xxxl-32,
  .px-xxxl-32 {
    padding-left: 11.25rem !important;
  }

  .p-xxxl-33 {
    padding: 12.5rem !important;
  }

  .pt-xxxl-33,
  .py-xxxl-33 {
    padding-top: 12.5rem !important;
  }

  .pr-xxxl-33,
  .px-xxxl-33 {
    padding-right: 12.5rem !important;
  }

  .pb-xxxl-33,
  .py-xxxl-33 {
    padding-bottom: 12.5rem !important;
  }

  .pl-xxxl-33,
  .px-xxxl-33 {
    padding-left: 12.5rem !important;
  }

  .p-xxxl-34 {
    padding: 14.0625rem !important;
  }

  .pt-xxxl-34,
  .py-xxxl-34 {
    padding-top: 14.0625rem !important;
  }

  .pr-xxxl-34,
  .px-xxxl-34 {
    padding-right: 14.0625rem !important;
  }

  .pb-xxxl-34,
  .py-xxxl-34 {
    padding-bottom: 14.0625rem !important;
  }

  .pl-xxxl-34,
  .px-xxxl-34 {
    padding-left: 14.0625rem !important;
  }

  .p-xxxl-35 {
    padding: 15.625rem !important;
  }

  .pt-xxxl-35,
  .py-xxxl-35 {
    padding-top: 15.625rem !important;
  }

  .pr-xxxl-35,
  .px-xxxl-35 {
    padding-right: 15.625rem !important;
  }

  .pb-xxxl-35,
  .py-xxxl-35 {
    padding-bottom: 15.625rem !important;
  }

  .pl-xxxl-35,
  .px-xxxl-35 {
    padding-left: 15.625rem !important;
  }

  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxxl-n2 {
    margin: -0.375rem !important;
  }

  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.375rem !important;
  }

  .m-xxxl-n3 {
    margin: -0.5rem !important;
  }

  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -0.5rem !important;
  }

  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -0.5rem !important;
  }

  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -0.5rem !important;
  }

  .m-xxxl-n4 {
    margin: -0.625rem !important;
  }

  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -0.625rem !important;
  }

  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -0.625rem !important;
  }

  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -0.625rem !important;
  }

  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -0.625rem !important;
  }

  .m-xxxl-n5 {
    margin: -0.75rem !important;
  }

  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -0.75rem !important;
  }

  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -0.75rem !important;
  }

  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -0.75rem !important;
  }

  .m-xxxl-n6 {
    margin: -1rem !important;
  }

  .mt-xxxl-n6,
  .my-xxxl-n6 {
    margin-top: -1rem !important;
  }

  .mr-xxxl-n6,
  .mx-xxxl-n6 {
    margin-right: -1rem !important;
  }

  .mb-xxxl-n6,
  .my-xxxl-n6 {
    margin-bottom: -1rem !important;
  }

  .ml-xxxl-n6,
  .mx-xxxl-n6 {
    margin-left: -1rem !important;
  }

  .m-xxxl-n7 {
    margin: -1.25rem !important;
  }

  .mt-xxxl-n7,
  .my-xxxl-n7 {
    margin-top: -1.25rem !important;
  }

  .mr-xxxl-n7,
  .mx-xxxl-n7 {
    margin-right: -1.25rem !important;
  }

  .mb-xxxl-n7,
  .my-xxxl-n7 {
    margin-bottom: -1.25rem !important;
  }

  .ml-xxxl-n7,
  .mx-xxxl-n7 {
    margin-left: -1.25rem !important;
  }

  .m-xxxl-n8 {
    margin: -1.5625rem !important;
  }

  .mt-xxxl-n8,
  .my-xxxl-n8 {
    margin-top: -1.5625rem !important;
  }

  .mr-xxxl-n8,
  .mx-xxxl-n8 {
    margin-right: -1.5625rem !important;
  }

  .mb-xxxl-n8,
  .my-xxxl-n8 {
    margin-bottom: -1.5625rem !important;
  }

  .ml-xxxl-n8,
  .mx-xxxl-n8 {
    margin-left: -1.5625rem !important;
  }

  .m-xxxl-n9 {
    margin: -1.875rem !important;
  }

  .mt-xxxl-n9,
  .my-xxxl-n9 {
    margin-top: -1.875rem !important;
  }

  .mr-xxxl-n9,
  .mx-xxxl-n9 {
    margin-right: -1.875rem !important;
  }

  .mb-xxxl-n9,
  .my-xxxl-n9 {
    margin-bottom: -1.875rem !important;
  }

  .ml-xxxl-n9,
  .mx-xxxl-n9 {
    margin-left: -1.875rem !important;
  }

  .m-xxxl-n10 {
    margin: -2.1875rem !important;
  }

  .mt-xxxl-n10,
  .my-xxxl-n10 {
    margin-top: -2.1875rem !important;
  }

  .mr-xxxl-n10,
  .mx-xxxl-n10 {
    margin-right: -2.1875rem !important;
  }

  .mb-xxxl-n10,
  .my-xxxl-n10 {
    margin-bottom: -2.1875rem !important;
  }

  .ml-xxxl-n10,
  .mx-xxxl-n10 {
    margin-left: -2.1875rem !important;
  }

  .m-xxxl-n11 {
    margin: -2.5rem !important;
  }

  .mt-xxxl-n11,
  .my-xxxl-n11 {
    margin-top: -2.5rem !important;
  }

  .mr-xxxl-n11,
  .mx-xxxl-n11 {
    margin-right: -2.5rem !important;
  }

  .mb-xxxl-n11,
  .my-xxxl-n11 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xxxl-n11,
  .mx-xxxl-n11 {
    margin-left: -2.5rem !important;
  }

  .m-xxxl-n12 {
    margin: -2.8125rem !important;
  }

  .mt-xxxl-n12,
  .my-xxxl-n12 {
    margin-top: -2.8125rem !important;
  }

  .mr-xxxl-n12,
  .mx-xxxl-n12 {
    margin-right: -2.8125rem !important;
  }

  .mb-xxxl-n12,
  .my-xxxl-n12 {
    margin-bottom: -2.8125rem !important;
  }

  .ml-xxxl-n12,
  .mx-xxxl-n12 {
    margin-left: -2.8125rem !important;
  }

  .m-xxxl-n13 {
    margin: -3.125rem !important;
  }

  .mt-xxxl-n13,
  .my-xxxl-n13 {
    margin-top: -3.125rem !important;
  }

  .mr-xxxl-n13,
  .mx-xxxl-n13 {
    margin-right: -3.125rem !important;
  }

  .mb-xxxl-n13,
  .my-xxxl-n13 {
    margin-bottom: -3.125rem !important;
  }

  .ml-xxxl-n13,
  .mx-xxxl-n13 {
    margin-left: -3.125rem !important;
  }

  .m-xxxl-n14 {
    margin: -3.4375rem !important;
  }

  .mt-xxxl-n14,
  .my-xxxl-n14 {
    margin-top: -3.4375rem !important;
  }

  .mr-xxxl-n14,
  .mx-xxxl-n14 {
    margin-right: -3.4375rem !important;
  }

  .mb-xxxl-n14,
  .my-xxxl-n14 {
    margin-bottom: -3.4375rem !important;
  }

  .ml-xxxl-n14,
  .mx-xxxl-n14 {
    margin-left: -3.4375rem !important;
  }

  .m-xxxl-n15 {
    margin: -3.75rem !important;
  }

  .mt-xxxl-n15,
  .my-xxxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mr-xxxl-n15,
  .mx-xxxl-n15 {
    margin-right: -3.75rem !important;
  }

  .mb-xxxl-n15,
  .my-xxxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .ml-xxxl-n15,
  .mx-xxxl-n15 {
    margin-left: -3.75rem !important;
  }

  .m-xxxl-n16 {
    margin: -4.0625rem !important;
  }

  .mt-xxxl-n16,
  .my-xxxl-n16 {
    margin-top: -4.0625rem !important;
  }

  .mr-xxxl-n16,
  .mx-xxxl-n16 {
    margin-right: -4.0625rem !important;
  }

  .mb-xxxl-n16,
  .my-xxxl-n16 {
    margin-bottom: -4.0625rem !important;
  }

  .ml-xxxl-n16,
  .mx-xxxl-n16 {
    margin-left: -4.0625rem !important;
  }

  .m-xxxl-n17 {
    margin: -4.375rem !important;
  }

  .mt-xxxl-n17,
  .my-xxxl-n17 {
    margin-top: -4.375rem !important;
  }

  .mr-xxxl-n17,
  .mx-xxxl-n17 {
    margin-right: -4.375rem !important;
  }

  .mb-xxxl-n17,
  .my-xxxl-n17 {
    margin-bottom: -4.375rem !important;
  }

  .ml-xxxl-n17,
  .mx-xxxl-n17 {
    margin-left: -4.375rem !important;
  }

  .m-xxxl-n18 {
    margin: -4.6875rem !important;
  }

  .mt-xxxl-n18,
  .my-xxxl-n18 {
    margin-top: -4.6875rem !important;
  }

  .mr-xxxl-n18,
  .mx-xxxl-n18 {
    margin-right: -4.6875rem !important;
  }

  .mb-xxxl-n18,
  .my-xxxl-n18 {
    margin-bottom: -4.6875rem !important;
  }

  .ml-xxxl-n18,
  .mx-xxxl-n18 {
    margin-left: -4.6875rem !important;
  }

  .m-xxxl-n19 {
    margin: -5rem !important;
  }

  .mt-xxxl-n19,
  .my-xxxl-n19 {
    margin-top: -5rem !important;
  }

  .mr-xxxl-n19,
  .mx-xxxl-n19 {
    margin-right: -5rem !important;
  }

  .mb-xxxl-n19,
  .my-xxxl-n19 {
    margin-bottom: -5rem !important;
  }

  .ml-xxxl-n19,
  .mx-xxxl-n19 {
    margin-left: -5rem !important;
  }

  .m-xxxl-n20 {
    margin: -5.3125rem !important;
  }

  .mt-xxxl-n20,
  .my-xxxl-n20 {
    margin-top: -5.3125rem !important;
  }

  .mr-xxxl-n20,
  .mx-xxxl-n20 {
    margin-right: -5.3125rem !important;
  }

  .mb-xxxl-n20,
  .my-xxxl-n20 {
    margin-bottom: -5.3125rem !important;
  }

  .ml-xxxl-n20,
  .mx-xxxl-n20 {
    margin-left: -5.3125rem !important;
  }

  .m-xxxl-n21 {
    margin: -5.625rem !important;
  }

  .mt-xxxl-n21,
  .my-xxxl-n21 {
    margin-top: -5.625rem !important;
  }

  .mr-xxxl-n21,
  .mx-xxxl-n21 {
    margin-right: -5.625rem !important;
  }

  .mb-xxxl-n21,
  .my-xxxl-n21 {
    margin-bottom: -5.625rem !important;
  }

  .ml-xxxl-n21,
  .mx-xxxl-n21 {
    margin-left: -5.625rem !important;
  }

  .m-xxxl-n22 {
    margin: -5.9375rem !important;
  }

  .mt-xxxl-n22,
  .my-xxxl-n22 {
    margin-top: -5.9375rem !important;
  }

  .mr-xxxl-n22,
  .mx-xxxl-n22 {
    margin-right: -5.9375rem !important;
  }

  .mb-xxxl-n22,
  .my-xxxl-n22 {
    margin-bottom: -5.9375rem !important;
  }

  .ml-xxxl-n22,
  .mx-xxxl-n22 {
    margin-left: -5.9375rem !important;
  }

  .m-xxxl-n23 {
    margin: -6.25rem !important;
  }

  .mt-xxxl-n23,
  .my-xxxl-n23 {
    margin-top: -6.25rem !important;
  }

  .mr-xxxl-n23,
  .mx-xxxl-n23 {
    margin-right: -6.25rem !important;
  }

  .mb-xxxl-n23,
  .my-xxxl-n23 {
    margin-bottom: -6.25rem !important;
  }

  .ml-xxxl-n23,
  .mx-xxxl-n23 {
    margin-left: -6.25rem !important;
  }

  .m-xxxl-n24 {
    margin: -6.875rem !important;
  }

  .mt-xxxl-n24,
  .my-xxxl-n24 {
    margin-top: -6.875rem !important;
  }

  .mr-xxxl-n24,
  .mx-xxxl-n24 {
    margin-right: -6.875rem !important;
  }

  .mb-xxxl-n24,
  .my-xxxl-n24 {
    margin-bottom: -6.875rem !important;
  }

  .ml-xxxl-n24,
  .mx-xxxl-n24 {
    margin-left: -6.875rem !important;
  }

  .m-xxxl-n25 {
    margin: -7.5rem !important;
  }

  .mt-xxxl-n25,
  .my-xxxl-n25 {
    margin-top: -7.5rem !important;
  }

  .mr-xxxl-n25,
  .mx-xxxl-n25 {
    margin-right: -7.5rem !important;
  }

  .mb-xxxl-n25,
  .my-xxxl-n25 {
    margin-bottom: -7.5rem !important;
  }

  .ml-xxxl-n25,
  .mx-xxxl-n25 {
    margin-left: -7.5rem !important;
  }

  .m-xxxl-n26 {
    margin: -8.125rem !important;
  }

  .mt-xxxl-n26,
  .my-xxxl-n26 {
    margin-top: -8.125rem !important;
  }

  .mr-xxxl-n26,
  .mx-xxxl-n26 {
    margin-right: -8.125rem !important;
  }

  .mb-xxxl-n26,
  .my-xxxl-n26 {
    margin-bottom: -8.125rem !important;
  }

  .ml-xxxl-n26,
  .mx-xxxl-n26 {
    margin-left: -8.125rem !important;
  }

  .m-xxxl-n27 {
    margin: -8.4375rem !important;
  }

  .mt-xxxl-n27,
  .my-xxxl-n27 {
    margin-top: -8.4375rem !important;
  }

  .mr-xxxl-n27,
  .mx-xxxl-n27 {
    margin-right: -8.4375rem !important;
  }

  .mb-xxxl-n27,
  .my-xxxl-n27 {
    margin-bottom: -8.4375rem !important;
  }

  .ml-xxxl-n27,
  .mx-xxxl-n27 {
    margin-left: -8.4375rem !important;
  }

  .m-xxxl-n28 {
    margin: -9.0625rem !important;
  }

  .mt-xxxl-n28,
  .my-xxxl-n28 {
    margin-top: -9.0625rem !important;
  }

  .mr-xxxl-n28,
  .mx-xxxl-n28 {
    margin-right: -9.0625rem !important;
  }

  .mb-xxxl-n28,
  .my-xxxl-n28 {
    margin-bottom: -9.0625rem !important;
  }

  .ml-xxxl-n28,
  .mx-xxxl-n28 {
    margin-left: -9.0625rem !important;
  }

  .m-xxxl-n29 {
    margin: -9.375rem !important;
  }

  .mt-xxxl-n29,
  .my-xxxl-n29 {
    margin-top: -9.375rem !important;
  }

  .mr-xxxl-n29,
  .mx-xxxl-n29 {
    margin-right: -9.375rem !important;
  }

  .mb-xxxl-n29,
  .my-xxxl-n29 {
    margin-bottom: -9.375rem !important;
  }

  .ml-xxxl-n29,
  .mx-xxxl-n29 {
    margin-left: -9.375rem !important;
  }

  .m-xxxl-n30 {
    margin: -9.6875rem !important;
  }

  .mt-xxxl-n30,
  .my-xxxl-n30 {
    margin-top: -9.6875rem !important;
  }

  .mr-xxxl-n30,
  .mx-xxxl-n30 {
    margin-right: -9.6875rem !important;
  }

  .mb-xxxl-n30,
  .my-xxxl-n30 {
    margin-bottom: -9.6875rem !important;
  }

  .ml-xxxl-n30,
  .mx-xxxl-n30 {
    margin-left: -9.6875rem !important;
  }

  .m-xxxl-n31 {
    margin: -10.625rem !important;
  }

  .mt-xxxl-n31,
  .my-xxxl-n31 {
    margin-top: -10.625rem !important;
  }

  .mr-xxxl-n31,
  .mx-xxxl-n31 {
    margin-right: -10.625rem !important;
  }

  .mb-xxxl-n31,
  .my-xxxl-n31 {
    margin-bottom: -10.625rem !important;
  }

  .ml-xxxl-n31,
  .mx-xxxl-n31 {
    margin-left: -10.625rem !important;
  }

  .m-xxxl-n32 {
    margin: -11.25rem !important;
  }

  .mt-xxxl-n32,
  .my-xxxl-n32 {
    margin-top: -11.25rem !important;
  }

  .mr-xxxl-n32,
  .mx-xxxl-n32 {
    margin-right: -11.25rem !important;
  }

  .mb-xxxl-n32,
  .my-xxxl-n32 {
    margin-bottom: -11.25rem !important;
  }

  .ml-xxxl-n32,
  .mx-xxxl-n32 {
    margin-left: -11.25rem !important;
  }

  .m-xxxl-n33 {
    margin: -12.5rem !important;
  }

  .mt-xxxl-n33,
  .my-xxxl-n33 {
    margin-top: -12.5rem !important;
  }

  .mr-xxxl-n33,
  .mx-xxxl-n33 {
    margin-right: -12.5rem !important;
  }

  .mb-xxxl-n33,
  .my-xxxl-n33 {
    margin-bottom: -12.5rem !important;
  }

  .ml-xxxl-n33,
  .mx-xxxl-n33 {
    margin-left: -12.5rem !important;
  }

  .m-xxxl-n34 {
    margin: -14.0625rem !important;
  }

  .mt-xxxl-n34,
  .my-xxxl-n34 {
    margin-top: -14.0625rem !important;
  }

  .mr-xxxl-n34,
  .mx-xxxl-n34 {
    margin-right: -14.0625rem !important;
  }

  .mb-xxxl-n34,
  .my-xxxl-n34 {
    margin-bottom: -14.0625rem !important;
  }

  .ml-xxxl-n34,
  .mx-xxxl-n34 {
    margin-left: -14.0625rem !important;
  }

  .m-xxxl-n35 {
    margin: -15.625rem !important;
  }

  .mt-xxxl-n35,
  .my-xxxl-n35 {
    margin-top: -15.625rem !important;
  }

  .mr-xxxl-n35,
  .mx-xxxl-n35 {
    margin-right: -15.625rem !important;
  }

  .mb-xxxl-n35,
  .my-xxxl-n35 {
    margin-bottom: -15.625rem !important;
  }

  .ml-xxxl-n35,
  .mx-xxxl-n35 {
    margin-left: -15.625rem !important;
  }

  .m-xxxl-auto {
    margin: auto !important;
  }

  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important;
  }

  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important;
  }

  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left !important;
  }

  .text-xs-right {
    text-align: right !important;
  }

  .text-xs-center {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1366px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xxxl-left {
    text-align: left !important;
  }

  .text-xxxl-right {
    text-align: right !important;
  }

  .text-xxxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #8B3300 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #994000 !important;
}

.text-secondary {
  color: #4743db !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #2522b0 !important;
}

.text-success {
  color: #8B3300 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #994000 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffb300 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b37d00 !important;
}

.text-danger {
  color: #f71e3f !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #c20723 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-green {
  color: #8B3300 !important;
}

a.text-green:hover,
a.text-green:focus {
  color: #994000 !important;
}

.text-blue {
  color: #4743db !important;
}

a.text-blue:hover,
a.text-blue:focus {
  color: #2522b0 !important;
}

.text-eastern {
  color: #2397af !important;
}

a.text-eastern:hover,
a.text-eastern:focus {
  color: #16606f !important;
}

.text-denim {
  color: #1a5aeb !important;
}

a.text-denim:hover,
a.text-denim:focus {
  color: #0f3eaa !important;
}

.text-dodger {
  color: #EA8A00 !important;
}

a.text-dodger:hover,
a.text-dodger:focus {
  color: #0846d5 !important;
}

.text-dodger-2 {
  color: #4D83FF !important;
}

a.text-dodger-2:hover,
a.text-dodger-2:focus {
  color: #014eff !important;
}

.text-spray {
  color: #65efe3 !important;
}

a.text-spray:hover,
a.text-spray:focus {
  color: #20e8d6 !important;
}

.text-olive {
  color: #1AAF76 !important;
}

a.text-olive:hover,
a.text-olive:focus {
  color: #0aa067 !important;
}

.text-turquoise {
  color: #50D7E5 !important;
}

a.text-turquoise:hover,
a.text-turquoise:focus {
  color: #1ebaca !important;
}

.text-regent {
  color: #b0d5e8 !important;
}

a.text-regent:hover,
a.text-regent:focus {
  color: #75b6d7 !important;
}

.text-coral {
  color: #fa8557 !important;
}

a.text-coral:hover,
a.text-coral:focus {
  color: #f84f0d !important;
}

.text-orange {
  color: #fa5f1c !important;
}

a.text-orange:hover,
a.text-orange:focus {
  color: #c53f04 !important;
}

.text-orange-2 {
  color: #fa6032 !important;
}

a.text-orange-2:hover,
a.text-orange-2:focus {
  color: #da3605 !important;
}

.text-red {
  color: #f71e3f !important;
}

a.text-red:hover,
a.text-red:focus {
  color: #c20723 !important;
}

.text-red-2 {
  color: #FC3F3F !important;
}

a.text-red-2:hover,
a.text-red-2:focus {
  color: #eb0404 !important;
}

.text-yellow {
  color: #ffb300 !important;
}

a.text-yellow:hover,
a.text-yellow:focus {
  color: #b37d00 !important;
}

.text-yellow-2 {
  color: #facf32 !important;
}

a.text-yellow-2:hover,
a.text-yellow-2:focus {
  color: #daac05 !important;
}

.text-casablanca {
  color: #f8c546 !important;
}

a.text-casablanca:hover,
a.text-casablanca:focus {
  color: #e9a909 !important;
}

.text-indigo {
  color: #5877be !important;
}

a.text-indigo:hover,
a.text-indigo:focus {
  color: #385391 !important;
}

.text-shamrock {
  color: #25ce95 !important;
}

a.text-shamrock:hover,
a.text-shamrock:focus {
  color: #198d66 !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover,
a.text-black:focus {
  color: black !important;
}

.text-black-2 {
  color: #2b3940 !important;
}

a.text-black-2:hover,
a.text-black-2:focus {
  color: #0c1012 !important;
}

.text-gray {
  color: #6b6e6f !important;
}

a.text-gray:hover,
a.text-gray:focus {
  color: #454748 !important;
}

.text-smoke {
  color: #7e8989 !important;
}

a.text-smoke:hover,
a.text-smoke:focus {
  color: #596161 !important;
}

.text-pink {
  color: #fc4980 !important;
}

a.text-pink:hover,
a.text-pink:focus {
  color: #f4044e !important;
}

.text-violet {
  color: #9c5fff !important;
}

a.text-violet:hover,
a.text-violet:focus {
  color: #6d13ff !important;
}

.text-mercury {
  color: #e5e5e5 !important;
}

a.text-mercury:hover,
a.text-mercury:focus {
  color: #bfbfbf !important;
}

.text-ebony-clay {
  color: #1d292e !important;
}

a.text-ebony-clay:hover,
a.text-ebony-clay:focus {
  color: black !important;
}

.text-hit-gray {
  color: #adb4b7 !important;
}

a.text-hit-gray:hover,
a.text-hit-gray:focus {
  color: #848f93 !important;
}

.text-gallery {
  color: #f0f0f0 !important;
}

a.text-gallery:hover,
a.text-gallery:focus {
  color: #cacaca !important;
}

.text-squeeze {
  color: #EDF8F5 !important;
}

a.text-squeeze:hover,
a.text-squeeze:focus {
  color: #b6e3d6 !important;
}

.text-helio {
  color: #9C5FFF !important;
}

a.text-helio:hover,
a.text-helio:focus {
  color: #6d13ff !important;
}

.text-athens {
  color: #F4F5F8 !important;
}

a.text-athens:hover,
a.text-athens:focus {
  color: #c5cbda !important;
}

.text-concrete {
  color: #F2F2F2 !important;
}

a.text-concrete:hover,
a.text-concrete:focus {
  color: #cccccc !important;
}

.text-allports {
  color: #0070ac !important;
}

a.text-allports:hover,
a.text-allports:focus {
  color: #003e60 !important;
}

.text-marino {
  color: #4064AD !important;
}

a.text-marino:hover,
a.text-marino:focus {
  color: #2b4475 !important;
}

.text-poppy {
  color: #BE392B !important;
}

a.text-poppy:hover,
a.text-poppy:focus {
  color: #80261d !important;
}

.text-polar {
  color: #F3F9FC !important;
}

a.text-polar:hover,
a.text-polar:focus {
  color: #b6daed !important;
}

.text-egg-blue {
  color: #02BFD5 !important;
}

a.text-egg-blue:hover,
a.text-egg-blue:focus {
  color: #017b89 !important;
}

.text-conch {
  color: #D2DCD6 !important;
}

a.text-conch:hover,
a.text-conch:focus {
  color: #a7bbaf !important;
}

.text-body {
  color: var(--color-texts) !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " ("attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody+tbody {
    border-color: var(--border-color);
  }

  .table .thead-dark th {
    color: inherit;
    border-color: var(--border-color);
  }
}

body[data-theme='dark'],
.dark-bg,
.dark-mode-texts {
  --bg: #19191b;
  --bg-1: #1e1e20;
  --bg-2: #1e1e20;
  --color-headings: #fff;
  --color-texts: rgba(255, 255, 255, 0.6);
  --color-texts: #6b6e6f;
  --color-texts-2: #adb4b7;
  --border-color: rgba(255, 255, 255, .7);
  --border-color-2: rgba(255, 255, 255, .07);
  --border-color-3: rgba(255, 255, 255, .07);
}

body[data-theme='light'],
.light-bg,
.light-mode-texts {
  --bg: #fff;
  --bg-1: #F2F2F2;
  --bg-2: #f4f5f8;
  --color-headings: #000000;
  --color-texts: #000000;
  --color-texts-2: #6B6E6F;
  --border-color: #e5e5e5;
  --border-color-2: #f0f0f0;
  --border-color-3: #e5e5e5;
}

body {
  --bg: #fff;
  --bg-1: #F2F2F2;
  --bg-2: #f4f5f8;
  --color-headings: #000000;
  --color-texts: #000000;
  --color-texts-2: #6B6E6F;
  --border-color: #e5e5e5;
  --border-color-2: #f0f0f0;
  --border-color-3: #e5e5e5;
}

.default-logo,
.default-shape {
  display: block;
}

.light-version-logo,
.light-shape {
  display: none;
}

.dark-version-logo,
.dark-shape {
  display: none;
}

.light-version-logo.default-logo {
  display: block;
}

.dark-version-logo.default-logo {
  display: block;
}

body[data-theme='dark'] .dark-version-logo,
body[data-theme='dark'] .dark-shape,
.dark-mode-texts .dark-version-logo,
.dark-mode-texts .dark-shape {
  display: block;
}

body[data-theme='dark'] .light-version-logo,
.dark-mode-texts .light-version-logo {
  display: none;
}

body[data-theme='dark'] .light-version-logo.default-logo,
.dark-mode-texts .light-version-logo.default-logo {
  display: none;
}

body[data-theme='dark'] .light-shape,
.dark-mode-texts .light-shape {
  display: none;
}

body[data-theme='dark'] .light-shape.default-shape,
.dark-mode-texts .light-shape.default-shape {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .light-version-logo,
body[data-theme='dark'] .light-mode-texts .light-shape,
.dark-mode-texts .light-mode-texts .light-version-logo,
.dark-mode-texts .light-mode-texts .light-shape {
  display: block;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo,
.dark-mode-texts .light-mode-texts .dark-version-logo {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo.default-logo,
.dark-mode-texts .light-mode-texts .dark-version-logo.default-logo {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape,
.dark-mode-texts .light-mode-texts .dark-shape {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape.default-shape,
.dark-mode-texts .light-mode-texts .dark-shape.default-shape {
  display: none;
}

body[data-theme='light'] .light-version-logo,
body[data-theme='light'] .light-shape,
.light-mode-texts .light-version-logo,
.light-mode-texts .light-shape {
  display: block;
}

body[data-theme='light'] .dark-version-logo,
.light-mode-texts .dark-version-logo {
  display: none;
}

body[data-theme='light'] .dark-version-logo.default-logo,
.light-mode-texts .dark-version-logo.default-logo {
  display: none;
}

body[data-theme='light'] .dark-shape,
.light-mode-texts .dark-shape {
  display: none;
}

body[data-theme='light'] .dark-shape.default-shape,
.light-mode-texts .dark-shape.default-shape {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .dark-version-logo,
body[data-theme='light'] .dark-mode-texts .dark-shape,
.light-mode-texts .dark-mode-texts .dark-version-logo,
.light-mode-texts .dark-mode-texts .dark-shape {
  display: block;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo,
.light-mode-texts .dark-mode-texts .light-version-logo {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo.default-logo,
.light-mode-texts .dark-mode-texts .light-version-logo.default-logo {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape,
.light-mode-texts .dark-mode-texts .light-shape {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape.default-shape,
.light-mode-texts .dark-mode-texts .light-shape.default-shape {
  display: none;
}

.bg-default {
  background: var(--bg);
}

.bg-default-1 {
  background: var(--bg);
}

.bg-default-2 {
  background: var(--bg-2);
}

.bg-default-3 {
  background: var(--bg-3);
}

.bg-default-4 {
  background: var(--bg-4);
}

.bg-default-5 {
  background: var(--bg-5);
}

.bg-default-6 {
  background: var(--bg-6);
}

.text-default-color {
  color: var(--color-texts);
}

.text-default-color-2 {
  color: var(--color-texts-2);
}

.heading-default-color {
  color: var(--color-headings);
}

.border-default-color {
  border-color: var(--border-color);
}

.border-default-color-2 {
  border-color: var(--border-color-2) !important;
}

/*=== Media Query ===*/
/* ---------------------------
  01.Component -> { Header } 
------------------------------*/
@media (min-width: 992px) {
  .header-btn {
    margin-left: 10px;
  }
}

.header-btn a {
  display: none;
}

@media (min-width: 360px) {
  .header-btn a {
    display: inline-flex;
    min-height: 35px;
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
  }
}

@media (min-width: 400px) {
  .header-btn a {
    min-height: 45px;
    min-width: 141px;
    font-size: 15px;
  }
}

@media (min-width: 576px) {
  .header-btn a {
    min-height: 50px;
  }
}

.header-btns .btn {
  min-width: 124px;
  height: 45px;
  font-size: 13px;
  letter-spacing: 0.26px;
}

.header-btn-devider {
  position: relative;
  z-index: 1;
}

.header-btn-devider:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  width: 1px;
  background: var(--border-color-3);
}

@media (min-width: 992px) {
  .header-btn-devider:before {
    content: "";
  }
}

.header-btn-devider.devider-pos-top:before {
  top: 0px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 22px;
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  border: none;
  background: transparent;
  font-weight: 700;
}

.btn-close i {
  color: #353638;
}

.site-header--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.site-header--sticky:not(.mobile-sticky-enable) {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

@media (min-width: 992px) {
  .site-header--sticky:not(.mobile-sticky-enable) {
    position: fixed !important;
    transition: .4s;
  }

  .site-header--sticky:not(.mobile-sticky-enable).scrolling {
    transform: translateY(-100%);
    transition: .4s;
  }

  .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 1000;
  }
}

.site-header--sticky.mobile-sticky-enable {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  transition: .4s;
}

.site-header--sticky.mobile-sticky-enable.scrolling {
  transform: translateY(-100%);
  transition: .4s;
}

.site-header--sticky.mobile-sticky-enable.reveal-header {
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  z-index: 9999;
}

@media (min-width: 992px) {
  .site-header--menu-center .navbar-nav-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .gr-megamenu-dropdown {
    left: 0%;
    transform: translateX(0%) translateY(10px);
  }
}

@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .nav-item.dropdown:hover>.gr-megamenu-dropdown.center {
    transform: translateX(0%) translateY(-10px);
    left: 0%;
  }
}

.site-header--menu-left .navbar-nav-wrapper .navbar-nav {
  justify-content: flex-start;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .site-header--menu-left .navbar-nav-wrapper {
    width: 100%;
  }
}

.site-header--menu-right .navbar-nav-wrapper {
  margin-left: auto;
}

@media (min-width: 992px) {
  .site-header--menu-right>.container-fluid .gr-megamenu-dropdown {
    left: 100%;
    transform: translateX(-100%) translateY(10px);
  }
}

@media (min-width: 992px) {
  .site-header--menu-right>.container-fluid .nav-item.dropdown:hover>.gr-megamenu-dropdown.center {
    transform: translateX(-100%) translateY(-10px);
    left: 100%;
  }
}

.single-div+.single-div:after {
  position: absolute;
  left: 0;
  top: 62px;
  bottom: 62px;
  width: 1px;
  content: "";
  background: red;
}

.single-div:after {
  position: absolute;
  right: 0;
  top: 62px;
  bottom: 62px;
  width: 1px;
  content: "";
  background: red;
}

.single-div:last-child:after {
  background: transparent;
}

.header-cart {
  position: relative;
  font-size: 20px;
  color: var(--color-texts);
  margin-left: auto;
  margin-right: 15px;
  margin-right: 15px;
}

@media (min-width: 992px) {
  .header-cart {
    margin-left: 10px;
  }
}

.header-cart span {
  height: 20px;
  width: 20px;
  font-size: 12px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: #8B3300;
  color: #fff;
}

.dynamic-sticky-bg.reveal-header {
  background: var(--bg);
}

.sticky-bg-white.reveal-header {
  background: #fff;
}

.sticky-bg-white.reveal-header {
  background: #5877be;
}

.sticky-bg-white.reveal-header {
  background: #fff;
}

.notification-block {
  position: relative;
}

.notification-block .count {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -4px;
  margin-right: -5px;
}

.show-gr-dropdown .gr-menu-dropdown {
  top: 110%;
}

.show-gr-dropdown .gr-menu-dropdown.dropdown-right {
  right: 0;
  left: auto;
}

.show-gr-dropdown .gr-menu-dropdown.dropdown-left {
  left: 0;
  right: auto;
}

.show-gr-dropdown:hover .gr-menu-dropdown,
.show-gr-dropdown.show .gr-menu-dropdown {
  opacity: 1;
  top: 95%;
  pointer-events: visible;
}

/* ---------------------------
  01.Component -> { Menu } 
------------------------------*/
.main-menu {
  /* ----------------------
  Custom toggle arrow 
------------------------*/
}

@media (min-width: 992px) {
  .main-menu {
    display: flex;
    justify-content: flex-end;
  }
}

.main-menu .gr-toggle-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-menu .gr-toggle-arrow:after {
  display: none;
}

.main-menu .gr-toggle-arrow i {
  font-size: 24px;
  width: auto;
  line-height: 1;
  height: auto;
  font-weight: 900;
  margin-left: 5px;
  transition: .4s;
}

.main-menu .gr-toggle-arrow:hover i {
  transform: rotate(-180deg);
}

.main-menu>li>.nav-link {
  color: var(--color-headings) !important;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 992px) {
  .main-menu>li>.nav-link {
    padding-top: 35px !important;
    padding-bottom: 33px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 18px !important;
    margin-right: 18px !important;
  }

  .main-menu>li>.nav-link:before {
    content: "";
    background: #8B3300;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    transition: transform .4s;
    will-change: transform;
    transform: scaleX(0);
    position: absolute;
  }
}

.main-menu>li>.nav-link:hover:before {
  transform: scaleX(1);
}

@media (min-width: 992px) {
  .main-menu>li>.nav-link.gr-toggle-arrow {
    padding-right: 30px !important;
  }

  .main-menu>li>.nav-link.gr-toggle-arrow:before {
    width: calc(100% - 8px);
  }

  .main-menu>li>.nav-link.gr-toggle-arrow i {
    position: absolute;
    right: -2px;
  }
}

.main-menu>li.active>.nav-link:before {
  transform: scaleX(1);
}

.main-menu>li:hover>.gr-toggle-arrow i {
  transform: rotate(-180deg);
}

/*----- Dropdown styles
-------------------------*/
.gr-menu-dropdown {
  background-color: transparent;
}

@media (min-width: 992px) {
  .gr-menu-dropdown {
    position: absolute;
    min-width: 227px;
    max-width: 227px;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border-radius: 8px;
    border: 1px solid var(--border-color-3);
    padding: 15px 0px;
    top: 100%;
    z-index: -99;
    opacity: 0;
    transition: opacity .4s, top .4s;
    pointer-events: none;
    left: 0;
    right: auto;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--border-color-3);
    background-color: #fff;
    display: block;
    border-top: 3px solid #8B3300;
  }
}

.gr-menu-dropdown>.drop-menu-item {
  color: var(--color-headings);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .gr-menu-dropdown>.drop-menu-item {
    color: #2b3940;
  }
}

.gr-menu-dropdown>.drop-menu-item>a {
  color: inherit;
}

@media (min-width: 992px) {
  .gr-menu-dropdown>.drop-menu-item>a {
    transition: .4s;
  }
}

.gr-menu-dropdown>.drop-menu-item:hover>a {
  color: #8B3300;
}

.gr-menu-dropdown>.drop-menu-item>.gr-menu-dropdown {
  border-top-color: #8B3300;
}

@media (min-width: 992px) {
  .gr-menu-dropdown>.drop-menu-item>.gr-menu-dropdown {
    top: 10px;
    left: auto;
    right: 0;
    opacity: 0;
    transform: translateX(110%);
    transition: .4s;
    pointer-events: none;
    will-change: transform;
  }
}

@media (min-width: 380px) {
  .gr-menu-dropdown>.drop-menu-item>.gr-menu-dropdown>.drop-menu-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .gr-menu-dropdown>.drop-menu-item>.gr-menu-dropdown>.drop-menu-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.gr-menu-dropdown.dropdown-right {
  left: auto;
  right: -90%;
}

@media (min-width: 992px) {
  .gr-menu-dropdown.dropdown-left {
    left: 0 !important;
    right: auto !important;
    transform: translateX(-110%) !important;
  }
}

/*----- Dropdown hover activation related styles
-------------------------------------------------*/
@media (min-width: 992px) {
  .nav-item.dropdown {
    position: relative;
    z-index: 99;
  }
}

@media (min-width: 992px) {
  .nav-item.dropdown:hover>.gr-menu-dropdown {
    top: 90%;
    opacity: 1;
    pointer-events: visible;
  }
}

@media (min-width: 992px) {
  .nav-item.dropdown:hover>.gr-megamenu-dropdown.center {
    transform: translateX(-50%) translateY(-10px);
    z-index: 99;
    opacity: 1;
    pointer-events: visible;
  }
}

@media (min-width: 992px) {

  .nav-item.dropdown:hover>.gr-megamenu-dropdown-2,
  .nav-item.dropdown:hover.triangle-shape:after,
  .nav-item.dropdown:hover.triangle-shape:before {
    transform: translateX(-50%) translateY(-10px);
    z-index: 99;
    opacity: 1;
    pointer-events: visible;
  }
}

.drop-menu-item.dropdown {
  position: relative;
}

.drop-menu-item.dropdown>.gr-toggle-arrow i {
  transform: rotate(-90deg);
}

@media (min-width: 992px) {
  .drop-menu-item.dropdown:hover>.gr-menu-dropdown {
    top: 10px;
    opacity: 1;
    pointer-events: visible;
    transform: translateX(100%);
  }

  .drop-menu-item.dropdown:hover>.gr-menu-dropdown.dropdown-left {
    transform: translateX(-100%) !important;
  }
}

/*-----Mega Dropdown styles
-------------------------*/
.nav-item.dropdown.dropdown-mega {
  z-index: 1;
}

@media (min-width: 992px) {
  .nav-item.dropdown.dropdown-mega {
    position: static;
  }
}

.gr-megamenu-dropdown {
  background-color: transparent;
}

@media (min-width: 992px) {
  .gr-megamenu-dropdown {
    background-color: #fff;
  }
}

.gr-megamenu-dropdown .dropdown-image-block {
  max-height: 336px;
}

@media (min-width: 320px) and (max-width: 992px) {
  .gr-megamenu-dropdown {
    border: 0;
  }
}

@media (min-width: 992px) {
  .gr-megamenu-dropdown {
    padding: 15px;
    min-width: 925px;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    will-change: transform;
    top: 100%;
    z-index: -99;
    opacity: 0;
    pointer-events: none;
    transition: .4s opacity, .4s transform;
    box-shadow: 0 42px 54px rgba(0, 0, 0, 0.09);
    border: 1px solid #e7e9ed;
    border-radius: 8px;
  }
}

@media (min-width: 1200px) {
  .gr-megamenu-dropdown {
    min-width: 1100px;
  }
}

.gr-megamenu-dropdown .single-dropdown-block .mega-drop-menu-item {
  padding-top: 14px;
  padding-bottom: 14px;
  display: block;
}

.gr-megamenu-dropdown .mega-drop-menu-item {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  transition: .4s;
  color: #2b3940;
}

@media (min-width: 320px) and (max-width: 992px) {
  .gr-megamenu-dropdown .mega-drop-menu-item {
    color: var(--color-headings);
    border-top: 1px solid var(--border-color-3);
    padding-left: 20px;
    padding-bottom: 13px;
    padding-top: 13px;
  }
}

@media (min-width: 992px) {
  .gr-megamenu-dropdown .mega-drop-menu-item {
    padding-left: 10px;
    padding-right: 10px;
  }

  .gr-megamenu-dropdown .mega-drop-menu-item:hover {
    color: #8B3300 !important;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .gr-megamenu-dropdown [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  .gr-megamenu-dropdown [class*="row-"] {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .gr-megamenu-dropdown-2 {
    border: 0;
  }
}

@media (min-width: 992px) {
  .gr-megamenu-dropdown-2 {
    box-shadow: 0 42px 54px rgba(0, 0, 0, 0.09);
    padding: 25px;
    min-width: 956px;
    left: 50%;
    will-change: transform;
    top: 100%;
    transform: translateX(-50%) translateY(10px);
    z-index: -99;
    opacity: 0;
    pointer-events: none;
    transition: .4s opacity, .4s transform;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border: 1px solid #e7e9ed;
  }
}

@media (min-width: 1200px) {
  .gr-megamenu-dropdown-2 {
    min-width: 1100px;
  }
}

.gr-megamenu-dropdown-2 .single-dropdown-block .mega-drop-menu-item {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block !important;
}

.gr-megamenu-dropdown-2 .mega-drop-menu-item {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 15px;
  font-weight: 700;
}

@media (min-width: 320px) and (max-width: 992px) {
  .gr-megamenu-dropdown-2 .mega-drop-menu-item {
    border-top: 1px solid var(--border-color-3);
    padding-left: 20px;
    padding-bottom: 13px;
    padding-top: 13px;
  }
}

@media (min-width: 992px) {
  .gr-megamenu-dropdown-2 .mega-drop-menu-item {
    padding-left: 20px;
    padding-right: 25px;
    border-radius: 11px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .gr-megamenu-dropdown-2 .mega-drop-menu-item:hover {
    background: #f4f7fa;
  }
}

@media (min-width: 992px) {
  .gr-megamenu-dropdown-2 .mega-drop-menu-item:hover {
    color: #8B3300 !important;
  }
}

.gr-megamenu-dropdown-2 .mega-drop-menu-item .single-menu-title {
  margin-bottom: 3px;
}

.gr-megamenu-dropdown-2 .mega-drop-menu-item p {
  margin-bottom: 0;
  font-weight: normal;
}

@media (min-width: 320px) and (max-width: 992px) {
  .gr-megamenu-dropdown-2 [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  .gr-megamenu-dropdown-2 [class*="row-"] {
    margin-left: 0;
    margin-right: 0;
  }
}

.dropdown-mega.triangle-shape {
  position: relative;
}

.dropdown-mega.triangle-shape:before {
  position: absolute;
  bottom: -7px;
  right: -2px;
  border: 15px solid;
  border-color: transparent transparent #fff transparent;
  transform: translateX(-50%) translateY(10px);
  z-index: 100 !important;
  opacity: 0;
  transition: .4s;
  pointer-events: none !important;
}



.dropdown-mega.triangle-shape:after {
  position: absolute;
  bottom: -4px;
  right: 0;
  border: 14px solid;
  border-color: transparent transparent #e7e9ed transparent;
  transform: translateX(-50%) translateY(10px);
  z-index: 99 !important;
  opacity: 0;
  transition: .4s;
  pointer-events: none !important;
}

@media (min-width: 992px) {
  .dropdown-mega.triangle-shape:before {
    content: "";
  }
}

@media (min-width: 992px) {
  .dropdown-mega.triangle-shape:after {
    content: "";
  }
}

@media (min-width: 992px) {
  .offcanvas-active.navbar-expand-lg .btn-close-off-canvas {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse {
    display: block;
    position: fixed;
    top: 0;
    background: var(--bg);
    left: -100%;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    transition: left .4s;
    z-index: 999999;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    padding-top: 50px;
    width: 250px;
    overflow-y: auto;
  }
}

@media (min-width: 320px) and (max-width: 992px) and (min-width: 380px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse {
    width: 300px;
  }
}

@media (min-width: 320px) and (max-width: 992px) and (min-width: 576px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse {
    width: 350px;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse.show {
    left: 0%;
  }

  .offcanvas-active.navbar-expand-lg .navbar-collapse.show~.btn-close-off-canvas .icon-burger {
    display: block;
  }

  .offcanvas-active.navbar-expand-lg .navbar-collapse.collapsing {
    transition: height 0s;
    height: 100%;
  }

  .offcanvas-active.navbar-expand-lg .navbar-collapse::-webkit-scrollbar {
    width: 8px;
  }

  .offcanvas-active.navbar-expand-lg .navbar-collapse::-webkit-scrollbar-track {
    background: #fff;
  }

  .offcanvas-active.navbar-expand-lg .navbar-collapse::-webkit-scrollbar-thumb {
    background-color: #8B3300;
    outline: 1px solid slategrey;
  }

  .offcanvas-active.navbar-expand-lg .btn-close-off-canvas .icon-burger {
    display: block;
  }

  .offcanvas-active.navbar-expand-lg .main-menu>li {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid var(--border-color-3);
  }

  .offcanvas-active.navbar-expand-lg .main-menu>li>.nav-link {
    padding-bottom: 13px;
    padding-top: 13px;
  }

  .offcanvas-active.navbar-expand-lg .main-menu>li .gr-menu-dropdown {
    border: 0;
    border-radius: 0;
    min-width: auto;
    padding: 0;
  }

  .offcanvas-active.navbar-expand-lg .main-menu>li .gr-menu-dropdown>li {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid var(--border-color-3);
    padding-left: 20px;
  }

  .offcanvas-active.navbar-expand-lg .main-menu>li .gr-menu-dropdown>li a {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .offcanvas-active.navbar-expand-lg .main-menu>li:last-child {
    border-bottom-color: transparent;
  }

  .offcanvas-active.navbar-expand-lg .main-menu li i {
    margin-left: 8px;
    position: relative;
    top: 3px;
  }

  .offcanvas-active.navbar-expand-lg .main-menu li:hover>a {
    color: #8B3300;
  }

  .offcanvas-active.navbar-expand-lg .main-menu a {
    display: flex;
  }
}

.navbar-toggler {
  color: var(--color-texts) !important;
  border-color: var(--color-texts) !important;
}

.hamburger-icon {
  border-radius: 5px;
  border-width: 2px;
  padding: 3px 10px;
}

.hamburger-icon .hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 2px 0px 0px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger-icon .hamburger .hamburger-box {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 15px;
}

.hamburger-icon .hamburger .hamburger-box .hamburger-inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms !important;
  position: absolute;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: var(--color-texts);
  top: 50%;
  display: block;
  margin-top: -2px;
  width: 20px;
  height: 3px;
}

.hamburger-icon .hamburger .hamburger-box .hamburger-inner:before {
  top: -6px;
  transition: top 75ms ease .12s, opacity 75ms ease;
}

.hamburger-icon .hamburger .hamburger-box .hamburger-inner:after {
  bottom: -6px;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-icon .hamburger .hamburger-box .hamburger-inner:after,
.hamburger-icon .hamburger .hamburger-box .hamburger-inner:before {
  display: block;
  content: "";
  position: absolute;
  width: 20px;
  height: 3px;
  border-radius: 4px;
  background-color: var(--color-texts);
}

.hamburger-icon[aria-expanded="true"] .hamburger .hamburger-box .hamburger-inner:after {
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}

.hamburger-icon[aria-expanded="true"] .hamburger .hamburger-box .hamburger-inner:before {
  top: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s !important;
  opacity: 0;
}

.hamburger-icon[aria-expanded="true"] .hamburger .hamburger-box .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}

.hamburger-icon:focus {
  outline: none;
  box-shadow: none;
}

.offcanvas-btn-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 22px;
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  border: none;
  background: transparent;
  font-weight: 700;
}

.offcanvas-btn-close i {
  color: var(--color-texts) !important;
}

.gr-cross-icon {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms !important;
  position: absolute;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: var(--color-texts);
  top: 50%;
  display: block;
  margin-top: -2px;
  height: 0;
  width: 0;
  left: 50%;
  transform: translateX(-6.5px) rotate(45deg);
}

.gr-cross-icon:before {
  top: 0;
  transition: top 75ms ease .12s, opacity 75ms ease;
}

.gr-cross-icon:after {
  bottom: -3px;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transform: rotate(-90deg);
}

.gr-cross-icon:after,
.gr-cross-icon:before {
  display: block;
  content: "";
  position: absolute;
  width: 20px;
  height: 3px;
  border-radius: 4px;
  background-color: var(--color-texts);
}

/* ---------------------------
  01.Component -> { Button } 
------------------------------*/
.btn {
  border-radius: 3px;
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  min-width: 130px;
  line-height: 28px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-medium {
  min-width: 160px;
  height: 48px;
  line-height: 36px;
}

.btn.btn-lg {
  min-width: 172px;
  height: 50px;
  border-radius: 5px;
}

.btn.btn-xl {
  min-width: 180px;
  height: 60px;
  border-radius: 5px;
}

.btn.with-icon i {
  margin-left: -5px;
  margin-right: 3px;
  font-size: 16px;
  margin-top: 2px;
}

.btn-reset {
  background: transparent;
  border: 0;
}

.bookmark-button:before {
  content: "\f02e";
  color: inherit;
  font-size: inherit;
  font-weight: 400;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
}

.bookmark-button:hover:before,
.bookmark-button.clicked:before {
  font-weight: 900;
  color: #8B3300;
}

/* ---------------------------
  01.Component -> { Lists } 
------------------------------*/
/* List components */
.list-overlapped-icon {
  display: flex;
}

.list-overlapped-icon li {
  transition: transform .4s, left .4s;
  will-change: transition;
  transform: scale(1);
  position: relative;
  margin-left: 5px;
}

.list-overlapped-icon li:first-child {
  z-index: 6;
}

.list-overlapped-icon li:nth-child(2) {
  z-index: 5;
}

.list-overlapped-icon li:nth-child(3) {
  z-index: 4;
}

.list-overlapped-icon li:nth-child(4) {
  z-index: 3;
}

.list-overlapped-icon li:nth-child(5) {
  z-index: 2;
}

.list-overlapped-icon li:nth-child(6) {
  z-index: 1;
}

.list-overlapped-icon li:nth-child(7) {
  z-index: 0;
}

.list-overlapped-icon li:nth-child(2) {
  left: -20px;
}

.list-overlapped-icon li:nth-child(3) {
  left: -40px;
}

.list-overlapped-icon li:nth-child(4) {
  left: -60px;
}

.list-overlapped-icon li:nth-child(5) {
  left: -80px;
}

.list-overlapped-icon li:nth-child(6) {
  left: -100px;
}

.list-overlapped-icon li:nth-child(7) {
  left: -120px;
}

.filter-check-list a {
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
}

[data-theme='dark'] .filter-check-list a,
.dark-mode-texts .filter-check-list a {
  color: #fff !important;
}

.filter-check-list a:before {
  content: "\f0c8";
  font-weight: 400;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  color: #6B6E6F;
  margin-right: 11px;
  margin-top: 2px;
}

.filter-check-list a.clicked {
  color: #2b3940 !important;
  font-weight: 600;
}

[data-theme='dark'] .filter-check-list a.clicked,
.dark-mode-texts .filter-check-list a.clicked {
  color: #fff !important;
}

.filter-check-list a.clicked:before {
  content: "\f14a";
  font-weight: 900;
  color: #8B3300;
}

/* ---------------------------
  01.Component -> { Background } 
------------------------------*/
.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pattern-1 {
  position: relative;
  z-index: 0;
}

.pattern-1:before {
  content: "";
  background-image: url(./components/image/patterns/pattern-1.png);
  left: -30px;
  top: -50%;
  transform: rotate(-180deg);
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  opacity: 0.1;
}

/* ---------------------------
  01.Component -> { Form } 
------------------------------*/
.gr-check-input {
  margin-bottom: 20px;
}

.gr-check-input input:checked~.checkbox {
  background: #8B3300 !important;
  border-color: #8B3300;
}

.gr-check-input input:checked~.checkbox::after {
  border-color: #8B3300;
  background-color: #8B3300;
}

.gr-check-input .checkbox {
  position: relative;
  line-height: 1;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  display: inline-block;
  cursor: pointer;
}

.gr-check-input .checkbox:after {
  content: "";
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
}

.filter-search-form-1 {
  width: 100%;
}

@media (min-width: 576px) {
  .filter-search-form-1 {
    display: flex;
  }
}

.filter-search-form-1 .filter-inputs {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 480px) {
  .filter-search-form-1 .filter-inputs {
    display: flex;
  }
}

@media (min-width: 576px) {
  .filter-search-form-1 .filter-inputs {
    width: calc(100% - 180px);
  }
}

.filter-search-form-1 .filter-inputs .form-group {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
}

.filter-search-form-1 .filter-inputs .form-group:last-child {
  border-right: 0;
  border-bottom: 0;
}

@media (min-width: 480px) {
  .filter-search-form-1 .filter-inputs .form-group {
    width: 50%;
    border-right: 1px solid #e5e5e5;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.filter-search-form-1 .filter-inputs .form-group>.form-control,
.filter-search-form-1 .filter-inputs .form-group>.nice-select {
  width: 100%;
}

.filter-search-form-1 .filter-inputs .form-control {
  border: 0;
  height: 40px;
  color: #6b6e6f;
}

.filter-search-form-1 .filter-inputs .form-control::-moz-placeholder {
  color: #6b6e6f;
}

.filter-search-form-1 .filter-inputs .form-control:-ms-input-placeholder {
  color: #6b6e6f;
}

.filter-search-form-1 .filter-inputs .form-control::placeholder {
  color: #6b6e6f;
}

.filter-search-form-1 .filter-inputs .nice-select {
  border: 0;
  display: flex;
  align-items: center;
  color: #6b6e6f;
}

.filter-search-form-1 .filter-inputs .nice-select:after {
  right: 25px;
}

.filter-search-form-1 .button-block {
  min-width: 180px;
  height: 50px;
}

@media (min-width: 576px) {
  .filter-search-form-1 .button-block {
    height: 60px;
  }
}

.filter-search-form-1 .btn-submit {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
}

@media (min-width: 576px) {
  .filter-search-form-1 .btn-submit {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

/* Filter Search Using Home 2, Home 3, Inner Search Pages 1 2 3 */
.filter-search-form-2 {
  width: 100%;
}

@media (min-width: 992px) {
  .filter-search-form-2 {
    display: flex;
  }
}

@media (min-width: 576px) {
  .filter-search-form-2 .filter-inputs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .filter-search-form-2 .filter-inputs {
    width: calc(100% - 160px);
  }
}

.filter-search-form-2 .filter-inputs .form-group {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
}

.filter-search-form-2 .filter-inputs .form-group:last-child {
  border-right: 0;
  border-bottom: 0;
}

@media (min-width: 768px) {
  .filter-search-form-2 .filter-inputs .form-group {
    width: 50%;
    border-right: 1px solid #e5e5e5;
    border-bottom: 0;
  }
}

@media (min-width: 992px) {
  .filter-search-form-2 .filter-inputs .form-group {
    margin-bottom: 0;
  }
}

.filter-search-form-2 .filter-inputs .form-group>.form-control,
.filter-search-form-2 .filter-inputs .form-group>.nice-select {
  width: 100%;
}

.filter-search-form-2 .filter-inputs .form-control {
  border: 0;
  height: 40px;
  color: #6b6e6f;
}

.filter-search-form-2 .filter-inputs .form-control::-moz-placeholder {
  color: #6b6e6f;
}

.filter-search-form-2 .filter-inputs .form-control:-ms-input-placeholder {
  color: #6b6e6f;
}

.filter-search-form-2 .filter-inputs .form-control::placeholder {
  color: #6b6e6f;
}

.filter-search-form-2 .filter-inputs .nice-select {
  border: 0;
  display: flex;
  align-items: center;
  color: #6b6e6f;
}

.filter-search-form-2 .filter-inputs .nice-select:after {
  right: 37px;
}

@media screen and (max-width: 480px) {
  .filter-search-form-2 .filter-inputs .nice-select .current {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.filter-search-form-2 .button-block {
  min-width: 160px;
  height: 50px;
  margin-top: 20px;
}

@media (min-width: 576px) {
  .filter-search-form-2 .button-block {
    height: 60px;
  }
}

@media (min-width: 768px) {
  .filter-search-form-2 .button-block {
    margin-top: 0;
  }
}

.filter-search-form-2 .btn-submit {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nice-select {
  display: flex;
  align-items: center;
}

.nice-select .current {
  line-height: 1;
}

.nice-select .list {
  width: 100%;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: rgba(0, 176, 116, 0.22) !important;
}

.nice-select.rounded-8 {
  border-radius: 8px !important;
}

.nice-select.arrow-2:after {
  border-width: 5px;
  border-color: #000 transparent transparent transparent;
  border-style: solid;
  display: block;
  height: 5px;
  width: 5px;
  margin-top: -2.5px;
  transform-origin: 40% 23%;
  transform: rotate(0deg);
}

.nice-select.arrow-2.open:after {
  transform: rotate(180deg);
}

.nice-select.arrow-3:after {
  right: 16px;
  height: 6px;
  width: 6px;
  border-color: #6B6E6F;
}

.nice-select.arrow-3.open:after {
  transform: rotate(225deg);
}

.nice-select.arrow-3-black:after {
  border-color: #2b3940;
}

.nice-select.arrow-4:after {
  right: 13px;
  height: 5px;
  width: 5px;
  font-size: 15px;
  border-color: #6B6E6F;
  top: 50%;
}

.nice-select.arrow-4.open:after {
  transform: rotate(225deg);
}

.nice-select.arrow-4-black:after {
  border-color: #2b3940;
}

.arrow-box-dropdown:after {
  border-width: 5px;
  border-color: #000 transparent transparent transparent;
  border-style: solid;
  display: block;
  height: 5px;
  width: 5px;
  margin-top: -2.5px;
  transform-origin: 40% 23%;
  transform: rotate(0deg);
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  content: '';
}

.arrow-box-dropdown.open:after {
  transform: rotate(180deg);
}

/* Login Modal form */
/* drag and drop css for Dashboard-settings page start */
.error {
  color: #B71C1C;
}


#userActions {
  flex-direction: column;
  text-align: center;
  color: #37474F;
  background: url(./components/image/svg/upload-file.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  position: relative;
}

#imgPrime {
  max-width: 140px;
  height: auto;
  margin: 0 auto;
  display: inline-block;
  margin-top: 20px;
}

#imgPrime {
  display: none;
}

/* drag and drop css for Dashboard-settings page end */
.show-password:before {
  content: "\f06e";
}

.show-password.show:before {
  content: "\f070";
}

.search-2-adjustment .nice-select .current {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ---------------------------
  01.Component -> { Cards } 
------------------------------*/
.feature-cardOne-adjustments .card-tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
}

.feature-cardOne-adjustments .card-tag-list li {
  min-width: 104px;
}

@media (min-width: 480px) {
  .feature-cardOne-adjustments .card-tag-list li {
    min-width: calc(33.3333% - 12px);
  }
}

@media (min-width: 992px) {
  .feature-cardOne-adjustments .card-tag-list li {
    min-width: 104px;
  }
}

@media (min-width: 1200px) {
  .feature-cardOne-adjustments .card-tag-list li {
    min-width: calc(33.3333% - 12px);
  }
}

.feature-cardOne-adjustments .card-tag-list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-bottom: 10px;
  margin-left: 6px;
  margin-right: 6px;
  height: 32px;
}

.feature-cardOne-adjustments .card-btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

.feature-cardOne-adjustments .card-btn-group .btn {
  margin-left: 8px;
  margin-right: 8px;
  min-width: calc(100% - 16px);
  margin-bottom: 10px;
}

@media (min-width: 370px) {
  .feature-cardOne-adjustments .card-btn-group .btn {
    min-width: calc(50% - 16px);
  }
}

@media (min-width: 992px) {
  .feature-cardOne-adjustments .card-btn-group .btn {
    min-width: calc(100% - 16px);
  }
}

@media (min-width: 1200px) {
  .feature-cardOne-adjustments .card-btn-group .btn {
    min-width: calc(50% - 16px);
  }
}

/* ---------------------------
  01.Component -> { Modal } 
------------------------------*/
.modal.show .modal-dialog {
  pointer-events: visible;
}

@media only screen and (min-height: 775px) {
  .form-modal.modal {
    align-items: center;
    display: flex !important;
    pointer-events: none;
  }

  .form-modal.modal.show {
    pointer-events: visible;
  }
}

/* ---------------------------
  01.Component -> { Tab } 
------------------------------*/
.search-nav-tab .active {
  background-color: transparent !important;
  border: 0;
}

.search-nav-tab .active>div {
  border-color: #8B3300;
}

/* ---------------------------
  01.Component -> { Acccordion } 
------------------------------*/
.accordion-trigger.arrow-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-trigger.arrow-icon:after {
  content: "\ea05";
  font-family: "Grayic";
  font-size: 20px;
  display: block;
  line-height: 1;
  transform: rotate(0deg);
  transition: .4s;
  color: #8B3300;
  top: 2px;
  position: relative;
}

.accordion-trigger.arrow-icon[aria-expanded="true"]:after {
  transform: rotate(-180deg);
}

/* ---------------------------
  01.Component -> { Sidebar } 
------------------------------*/
.dashboard-sidebar-wrapper {
  width: 290px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--bg);
  transform: translateX(-100%);
  transition: .4s transform;
  z-index: 800;
}

@media (min-width: 768px) {
  .dashboard-sidebar-wrapper {
    transform: none;
  }
}

.dashboard-sidebar-wrapper.show {
  transform: translateX(0%);
}

.dashboard-layout-sidebar li a {
  color: #6b6e6f;
  position: relative;
  transition: .4s;
}

[data-theme='dark'] .dashboard-layout-sidebar li a,
.dark-mode-texts .dashboard-layout-sidebar li a {
  color: #fff;
}

.dashboard-layout-sidebar li a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: #8B3300;
  border-radius: 7px;
  transform: scaleY(0);
  will-change: transform;
  transition: .4s;
}

.dashboard-layout-sidebar li a i {
  transition: .4s;
  color: #D2DCD6;
}

[data-theme='dark'] .dashboard-layout-sidebar li a i,
.dark-mode-texts .dashboard-layout-sidebar li a i {
  color: rgba(210, 220, 214, 0.5);
}

@media (min-width: 992px) {
  .dashboard-layout-sidebar li a:hover {
    color: #000;
  }

  [data-theme='dark'] .dashboard-layout-sidebar li a:hover,
  .dark-mode-texts .dashboard-layout-sidebar li a:hover {
    color: rgba(210, 220, 214, 0.5);
  }

  .dashboard-layout-sidebar li a:hover>i {
    color: #8B3300;
  }

  .dashboard-layout-sidebar li a:hover:before {
    transform: scaleY(1);
  }
}

.dashboard-layout-sidebar li.active a {
  color: #000;
}

[data-theme='dark'] .dashboard-layout-sidebar li.active a,
.dark-mode-texts .dashboard-layout-sidebar li.active a {
  color: rgba(210, 220, 214, 0.5);
}

.dashboard-layout-sidebar li.active a>i {
  color: #8B3300;
}

.dashboard-layout-sidebar li.active a:before {
  transform: scaleY(1);
}

.sidebar-mobile-button {
  min-height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  z-index: 801;
  background: #8B3300;
  color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .sidebar-mobile-button {
    transform: translateY(100%);
  }
}

.sidebar-mobile-button:after {
  content: "Sidebar Open";
  margin-left: 10px;
}

.sidebar-mobile-button[aria-expanded="true"]:after {
  content: "Sidebar Close";
}

/* ---------------------------
  02.Utility -> { Color } 
------------------------------*/
.bg-primary-opacity-visible {
  background-color: #8B3300;
}

.bg-primary-opacity-1 {
  background-color: rgba(0, 176, 116, 0.1);
}

.bg-primary-opacity-15 {
  background-color: rgba(0, 176, 116, 0.15);
}

.bg-primary-opacity-2 {
  background-color: rgba(0, 176, 116, 0.2);
}

.bg-primary-opacity-3 {
  background-color: rgba(0, 176, 116, 0.3);
}

.bg-primary-opacity-4 {
  background-color: rgba(0, 176, 116, 0.4);
}

.bg-primary-opacity-5 {
  background-color: rgba(0, 176, 116, 0.5);
}

.bg-primary-opacity-6 {
  background-color: rgba(0, 176, 116, 0.6);
}

.bg-primary-opacity-p6 {
  background-color: rgba(0, 176, 116, 0.06);
}

.bg-primary-opacity-7 {
  background-color: rgba(0, 176, 116, 0.7);
}

.bg-primary-opacity-8 {
  background-color: rgba(0, 176, 116, 0.8);
}

.bg-primary-opacity-9 {
  background-color: rgba(0, 176, 116, 0.9);
}

.gr-color-primary-opacity-visible {
  color: #8B3300;
}

.gr-color-primary-opacity-1 {
  color: rgba(0, 176, 116, 0.1);
}

.gr-color-primary-opacity-15 {
  color: rgba(0, 176, 116, 0.15);
}

.gr-color-primary-opacity-2 {
  color: rgba(0, 176, 116, 0.2);
}

.gr-color-primary-opacity-3 {
  color: rgba(0, 176, 116, 0.3);
}

.gr-color-primary-opacity-4 {
  color: rgba(0, 176, 116, 0.4);
}

.gr-color-primary-opacity-5 {
  color: rgba(0, 176, 116, 0.5);
}

.gr-color-primary-opacity-6 {
  color: rgba(0, 176, 116, 0.6);
}

.gr-color-primary-opacity-p6 {
  color: rgba(0, 176, 116, 0.06);
}

.gr-color-primary-opacity-7 {
  color: rgba(0, 176, 116, 0.7);
}

.gr-color-primary-opacity-8 {
  color: rgba(0, 176, 116, 0.8);
}

.gr-color-primary-opacity-9 {
  color: rgba(0, 176, 116, 0.9);
}

.bg-secondary-opacity-visible {
  background-color: #4743db;
}

.bg-secondary-opacity-1 {
  background-color: rgba(71, 67, 219, 0.1);
}

.bg-secondary-opacity-15 {
  background-color: rgba(71, 67, 219, 0.15);
}

.bg-secondary-opacity-2 {
  background-color: rgba(71, 67, 219, 0.2);
}

.bg-secondary-opacity-3 {
  background-color: rgba(71, 67, 219, 0.3);
}

.bg-secondary-opacity-4 {
  background-color: rgba(71, 67, 219, 0.4);
}

.bg-secondary-opacity-5 {
  background-color: rgba(71, 67, 219, 0.5);
}

.bg-secondary-opacity-6 {
  background-color: rgba(71, 67, 219, 0.6);
}

.bg-secondary-opacity-p6 {
  background-color: rgba(71, 67, 219, 0.06);
}

.bg-secondary-opacity-7 {
  background-color: rgba(71, 67, 219, 0.7);
}

.bg-secondary-opacity-8 {
  background-color: rgba(71, 67, 219, 0.8);
}

.bg-secondary-opacity-9 {
  background-color: rgba(71, 67, 219, 0.9);
}

.gr-color-secondary-opacity-visible {
  color: #4743db;
}

.gr-color-secondary-opacity-1 {
  color: rgba(71, 67, 219, 0.1);
}

.gr-color-secondary-opacity-15 {
  color: rgba(71, 67, 219, 0.15);
}

.gr-color-secondary-opacity-2 {
  color: rgba(71, 67, 219, 0.2);
}

.gr-color-secondary-opacity-3 {
  color: rgba(71, 67, 219, 0.3);
}

.gr-color-secondary-opacity-4 {
  color: rgba(71, 67, 219, 0.4);
}

.gr-color-secondary-opacity-5 {
  color: rgba(71, 67, 219, 0.5);
}

.gr-color-secondary-opacity-6 {
  color: rgba(71, 67, 219, 0.6);
}

.gr-color-secondary-opacity-p6 {
  color: rgba(71, 67, 219, 0.06);
}

.gr-color-secondary-opacity-7 {
  color: rgba(71, 67, 219, 0.7);
}

.gr-color-secondary-opacity-8 {
  color: rgba(71, 67, 219, 0.8);
}

.gr-color-secondary-opacity-9 {
  color: rgba(71, 67, 219, 0.9);
}

.bg-success-opacity-visible {
  background-color: #8B3300;
}

.bg-success-opacity-1 {
  background-color: rgba(0, 176, 116, 0.1);
}

.bg-success-opacity-15 {
  background-color: rgba(0, 176, 116, 0.15);
}

.bg-success-opacity-2 {
  background-color: rgba(0, 176, 116, 0.2);
}

.bg-success-opacity-3 {
  background-color: rgba(0, 176, 116, 0.3);
}

.bg-success-opacity-4 {
  background-color: rgba(0, 176, 116, 0.4);
}

.bg-success-opacity-5 {
  background-color: rgba(0, 176, 116, 0.5);
}

.bg-success-opacity-6 {
  background-color: rgba(0, 176, 116, 0.6);
}

.bg-success-opacity-p6 {
  background-color: rgba(0, 176, 116, 0.06);
}

.bg-success-opacity-7 {
  background-color: rgba(0, 176, 116, 0.7);
}

.bg-success-opacity-8 {
  background-color: rgba(0, 176, 116, 0.8);
}

.bg-success-opacity-9 {
  background-color: rgba(0, 176, 116, 0.9);
}

.gr-color-success-opacity-visible {
  color: #8B3300;
}

.gr-color-success-opacity-1 {
  color: rgba(0, 176, 116, 0.1);
}

.gr-color-success-opacity-15 {
  color: rgba(0, 176, 116, 0.15);
}

.gr-color-success-opacity-2 {
  color: rgba(0, 176, 116, 0.2);
}

.gr-color-success-opacity-3 {
  color: rgba(0, 176, 116, 0.3);
}

.gr-color-success-opacity-4 {
  color: rgba(0, 176, 116, 0.4);
}

.gr-color-success-opacity-5 {
  color: rgba(0, 176, 116, 0.5);
}

.gr-color-success-opacity-6 {
  color: rgba(0, 176, 116, 0.6);
}

.gr-color-success-opacity-p6 {
  color: rgba(0, 176, 116, 0.06);
}

.gr-color-success-opacity-7 {
  color: rgba(0, 176, 116, 0.7);
}

.gr-color-success-opacity-8 {
  color: rgba(0, 176, 116, 0.8);
}

.gr-color-success-opacity-9 {
  color: rgba(0, 176, 116, 0.9);
}

.bg-info-opacity-visible {
  background-color: #17a2b8;
}

.bg-info-opacity-1 {
  background-color: rgba(23, 162, 184, 0.1);
}

.bg-info-opacity-15 {
  background-color: rgba(23, 162, 184, 0.15);
}

.bg-info-opacity-2 {
  background-color: rgba(23, 162, 184, 0.2);
}

.bg-info-opacity-3 {
  background-color: rgba(23, 162, 184, 0.3);
}

.bg-info-opacity-4 {
  background-color: rgba(23, 162, 184, 0.4);
}

.bg-info-opacity-5 {
  background-color: rgba(23, 162, 184, 0.5);
}

.bg-info-opacity-6 {
  background-color: rgba(23, 162, 184, 0.6);
}

.bg-info-opacity-p6 {
  background-color: rgba(23, 162, 184, 0.06);
}

.bg-info-opacity-7 {
  background-color: rgba(23, 162, 184, 0.7);
}

.bg-info-opacity-8 {
  background-color: rgba(23, 162, 184, 0.8);
}

.bg-info-opacity-9 {
  background-color: rgba(23, 162, 184, 0.9);
}

.gr-color-info-opacity-visible {
  color: #17a2b8;
}

.gr-color-info-opacity-1 {
  color: rgba(23, 162, 184, 0.1);
}

.gr-color-info-opacity-15 {
  color: rgba(23, 162, 184, 0.15);
}

.gr-color-info-opacity-2 {
  color: rgba(23, 162, 184, 0.2);
}

.gr-color-info-opacity-3 {
  color: rgba(23, 162, 184, 0.3);
}

.gr-color-info-opacity-4 {
  color: rgba(23, 162, 184, 0.4);
}

.gr-color-info-opacity-5 {
  color: rgba(23, 162, 184, 0.5);
}

.gr-color-info-opacity-6 {
  color: rgba(23, 162, 184, 0.6);
}

.gr-color-info-opacity-p6 {
  color: rgba(23, 162, 184, 0.06);
}

.gr-color-info-opacity-7 {
  color: rgba(23, 162, 184, 0.7);
}

.gr-color-info-opacity-8 {
  color: rgba(23, 162, 184, 0.8);
}

.gr-color-info-opacity-9 {
  color: rgba(23, 162, 184, 0.9);
}

.bg-warning-opacity-visible {
  background-color: #ffb300;
}

.bg-warning-opacity-1 {
  background-color: rgba(255, 179, 0, 0.1);
}

.bg-warning-opacity-15 {
  background-color: rgba(255, 179, 0, 0.15);
}

.bg-warning-opacity-2 {
  background-color: rgba(255, 179, 0, 0.2);
}

.bg-warning-opacity-3 {
  background-color: rgba(255, 179, 0, 0.3);
}

.bg-warning-opacity-4 {
  background-color: rgba(255, 179, 0, 0.4);
}

.bg-warning-opacity-5 {
  background-color: rgba(255, 179, 0, 0.5);
}

.bg-warning-opacity-6 {
  background-color: rgba(255, 179, 0, 0.6);
}

.bg-warning-opacity-p6 {
  background-color: rgba(255, 179, 0, 0.06);
}

.bg-warning-opacity-7 {
  background-color: rgba(255, 179, 0, 0.7);
}

.bg-warning-opacity-8 {
  background-color: rgba(255, 179, 0, 0.8);
}

.bg-warning-opacity-9 {
  background-color: rgba(255, 179, 0, 0.9);
}

.gr-color-warning-opacity-visible {
  color: #ffb300;
}

.gr-color-warning-opacity-1 {
  color: rgba(255, 179, 0, 0.1);
}

.gr-color-warning-opacity-15 {
  color: rgba(255, 179, 0, 0.15);
}

.gr-color-warning-opacity-2 {
  color: rgba(255, 179, 0, 0.2);
}

.gr-color-warning-opacity-3 {
  color: rgba(255, 179, 0, 0.3);
}

.gr-color-warning-opacity-4 {
  color: rgba(255, 179, 0, 0.4);
}

.gr-color-warning-opacity-5 {
  color: rgba(255, 179, 0, 0.5);
}

.gr-color-warning-opacity-6 {
  color: rgba(255, 179, 0, 0.6);
}

.gr-color-warning-opacity-p6 {
  color: rgba(255, 179, 0, 0.06);
}

.gr-color-warning-opacity-7 {
  color: rgba(255, 179, 0, 0.7);
}

.gr-color-warning-opacity-8 {
  color: rgba(255, 179, 0, 0.8);
}

.gr-color-warning-opacity-9 {
  color: rgba(255, 179, 0, 0.9);
}

.bg-danger-opacity-visible {
  background-color: #f71e3f;
}

.bg-danger-opacity-1 {
  background-color: rgba(247, 30, 63, 0.1);
}

.bg-danger-opacity-15 {
  background-color: rgba(247, 30, 63, 0.15);
}

.bg-danger-opacity-2 {
  background-color: rgba(247, 30, 63, 0.2);
}

.bg-danger-opacity-3 {
  background-color: rgba(247, 30, 63, 0.3);
}

.bg-danger-opacity-4 {
  background-color: rgba(247, 30, 63, 0.4);
}

.bg-danger-opacity-5 {
  background-color: rgba(247, 30, 63, 0.5);
}

.bg-danger-opacity-6 {
  background-color: rgba(247, 30, 63, 0.6);
}

.bg-danger-opacity-p6 {
  background-color: rgba(247, 30, 63, 0.06);
}

.bg-danger-opacity-7 {
  background-color: rgba(247, 30, 63, 0.7);
}

.bg-danger-opacity-8 {
  background-color: rgba(247, 30, 63, 0.8);
}

.bg-danger-opacity-9 {
  background-color: rgba(247, 30, 63, 0.9);
}

.gr-color-danger-opacity-visible {
  color: #f71e3f;
}

.gr-color-danger-opacity-1 {
  color: rgba(247, 30, 63, 0.1);
}

.gr-color-danger-opacity-15 {
  color: rgba(247, 30, 63, 0.15);
}

.gr-color-danger-opacity-2 {
  color: rgba(247, 30, 63, 0.2);
}

.gr-color-danger-opacity-3 {
  color: rgba(247, 30, 63, 0.3);
}

.gr-color-danger-opacity-4 {
  color: rgba(247, 30, 63, 0.4);
}

.gr-color-danger-opacity-5 {
  color: rgba(247, 30, 63, 0.5);
}

.gr-color-danger-opacity-6 {
  color: rgba(247, 30, 63, 0.6);
}

.gr-color-danger-opacity-p6 {
  color: rgba(247, 30, 63, 0.06);
}

.gr-color-danger-opacity-7 {
  color: rgba(247, 30, 63, 0.7);
}

.gr-color-danger-opacity-8 {
  color: rgba(247, 30, 63, 0.8);
}

.gr-color-danger-opacity-9 {
  color: rgba(247, 30, 63, 0.9);
}

.bg-light-opacity-visible {
  background-color: #f8f9fa;
}

.bg-light-opacity-1 {
  background-color: rgba(248, 249, 250, 0.1);
}

.bg-light-opacity-15 {
  background-color: rgba(248, 249, 250, 0.15);
}

.bg-light-opacity-2 {
  background-color: rgba(248, 249, 250, 0.2);
}

.bg-light-opacity-3 {
  background-color: rgba(248, 249, 250, 0.3);
}

.bg-light-opacity-4 {
  background-color: rgba(248, 249, 250, 0.4);
}

.bg-light-opacity-5 {
  background-color: rgba(248, 249, 250, 0.5);
}

.bg-light-opacity-6 {
  background-color: rgba(248, 249, 250, 0.6);
}

.bg-light-opacity-p6 {
  background-color: rgba(248, 249, 250, 0.06);
}

.bg-light-opacity-7 {
  background-color: rgba(248, 249, 250, 0.7);
}

.bg-light-opacity-8 {
  background-color: rgba(248, 249, 250, 0.8);
}

.bg-light-opacity-9 {
  background-color: rgba(248, 249, 250, 0.9);
}

.gr-color-light-opacity-visible {
  color: #f8f9fa;
}

.gr-color-light-opacity-1 {
  color: rgba(248, 249, 250, 0.1);
}

.gr-color-light-opacity-15 {
  color: rgba(248, 249, 250, 0.15);
}

.gr-color-light-opacity-2 {
  color: rgba(248, 249, 250, 0.2);
}

.gr-color-light-opacity-3 {
  color: rgba(248, 249, 250, 0.3);
}

.gr-color-light-opacity-4 {
  color: rgba(248, 249, 250, 0.4);
}

.gr-color-light-opacity-5 {
  color: rgba(248, 249, 250, 0.5);
}

.gr-color-light-opacity-6 {
  color: rgba(248, 249, 250, 0.6);
}

.gr-color-light-opacity-p6 {
  color: rgba(248, 249, 250, 0.06);
}

.gr-color-light-opacity-7 {
  color: rgba(248, 249, 250, 0.7);
}

.gr-color-light-opacity-8 {
  color: rgba(248, 249, 250, 0.8);
}

.gr-color-light-opacity-9 {
  color: rgba(248, 249, 250, 0.9);
}

.bg-dark-opacity-visible {
  background-color: #343a40;
}

.bg-dark-opacity-1 {
  background-color: rgba(52, 58, 64, 0.1);
}

.bg-dark-opacity-15 {
  background-color: rgba(52, 58, 64, 0.15);
}

.bg-dark-opacity-2 {
  background-color: rgba(52, 58, 64, 0.2);
}

.bg-dark-opacity-3 {
  background-color: rgba(52, 58, 64, 0.3);
}

.bg-dark-opacity-4 {
  background-color: rgba(52, 58, 64, 0.4);
}

.bg-dark-opacity-5 {
  background-color: rgba(52, 58, 64, 0.5);
}

.bg-dark-opacity-6 {
  background-color: rgba(52, 58, 64, 0.6);
}

.bg-dark-opacity-p6 {
  background-color: rgba(52, 58, 64, 0.06);
}

.bg-dark-opacity-7 {
  background-color: rgba(52, 58, 64, 0.7);
}

.bg-dark-opacity-8 {
  background-color: rgba(52, 58, 64, 0.8);
}

.bg-dark-opacity-9 {
  background-color: rgba(52, 58, 64, 0.9);
}

.gr-color-dark-opacity-visible {
  color: #343a40;
}

.gr-color-dark-opacity-1 {
  color: rgba(52, 58, 64, 0.1);
}

.gr-color-dark-opacity-15 {
  color: rgba(52, 58, 64, 0.15);
}

.gr-color-dark-opacity-2 {
  color: rgba(52, 58, 64, 0.2);
}

.gr-color-dark-opacity-3 {
  color: rgba(52, 58, 64, 0.3);
}

.gr-color-dark-opacity-4 {
  color: rgba(52, 58, 64, 0.4);
}

.gr-color-dark-opacity-5 {
  color: rgba(52, 58, 64, 0.5);
}

.gr-color-dark-opacity-6 {
  color: rgba(52, 58, 64, 0.6);
}

.gr-color-dark-opacity-p6 {
  color: rgba(52, 58, 64, 0.06);
}

.gr-color-dark-opacity-7 {
  color: rgba(52, 58, 64, 0.7);
}

.gr-color-dark-opacity-8 {
  color: rgba(52, 58, 64, 0.8);
}

.gr-color-dark-opacity-9 {
  color: rgba(52, 58, 64, 0.9);
}

.bg-white-opacity-visible {
  background-color: white;
}

.bg-white-opacity-1 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-opacity-15 {
  background-color: rgba(255, 255, 255, 0.15);
}

.bg-white-opacity-2 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-opacity-3 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-opacity-4 {
  background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-opacity-5 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-opacity-6 {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-opacity-p6 {
  background-color: rgba(255, 255, 255, 0.06);
}

.bg-white-opacity-7 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-opacity-8 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-opacity-9 {
  background-color: rgba(255, 255, 255, 0.9);
}

.gr-color-white-opacity-visible {
  color: white;
}

.gr-color-white-opacity-1 {
  color: rgba(255, 255, 255, 0.1);
}

.gr-color-white-opacity-15 {
  color: rgba(255, 255, 255, 0.15);
}

.gr-color-white-opacity-2 {
  color: rgba(255, 255, 255, 0.2);
}

.gr-color-white-opacity-3 {
  color: rgba(255, 255, 255, 0.3);
}

.gr-color-white-opacity-4 {
  color: rgba(255, 255, 255, 0.4);
}

.gr-color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5);
}

.gr-color-white-opacity-6 {
  color: rgba(255, 255, 255, 0.6);
}

.gr-color-white-opacity-p6 {
  color: rgba(255, 255, 255, 0.06);
}

.gr-color-white-opacity-7 {
  color: rgba(255, 255, 255, 0.7);
}

.gr-color-white-opacity-8 {
  color: rgba(255, 255, 255, 0.8);
}

.gr-color-white-opacity-9 {
  color: rgba(255, 255, 255, 0.9);
}

.bg-green-opacity-visible {
  background-color: #8B3300;
}

.bg-green-opacity-1 {
  background-color: rgba(0, 176, 116, 0.1);
}

.bg-green-opacity-15 {
  background-color: rgba(0, 176, 116, 0.15);
}

.bg-green-opacity-2 {
  background-color: rgba(0, 176, 116, 0.2);
}

.bg-green-opacity-3 {
  background-color: rgba(0, 176, 116, 0.3);
}

.bg-green-opacity-4 {
  background-color: rgba(0, 176, 116, 0.4);
}

.bg-green-opacity-5 {
  background-color: rgba(0, 176, 116, 0.5);
}

.bg-green-opacity-6 {
  background-color: rgba(0, 176, 116, 0.6);
}

.bg-green-opacity-p6 {
  background-color: rgba(0, 176, 116, 0.06);
}

.bg-green-opacity-7 {
  background-color: rgba(0, 176, 116, 0.7);
}

.bg-green-opacity-8 {
  background-color: rgba(0, 176, 116, 0.8);
}

.bg-green-opacity-9 {
  background-color: rgba(0, 176, 116, 0.9);
}

.gr-color-green-opacity-visible {
  color: #8B3300;
}

.gr-color-green-opacity-1 {
  color: rgba(0, 176, 116, 0.1);
}

.gr-color-green-opacity-15 {
  color: rgba(0, 176, 116, 0.15);
}

.gr-color-green-opacity-2 {
  color: rgba(0, 176, 116, 0.2);
}

.gr-color-green-opacity-3 {
  color: rgba(0, 176, 116, 0.3);
}

.gr-color-green-opacity-4 {
  color: rgba(0, 176, 116, 0.4);
}

.gr-color-green-opacity-5 {
  color: rgba(0, 176, 116, 0.5);
}

.gr-color-green-opacity-6 {
  color: rgba(0, 176, 116, 0.6);
}

.gr-color-green-opacity-p6 {
  color: rgba(0, 176, 116, 0.06);
}

.gr-color-green-opacity-7 {
  color: rgba(0, 176, 116, 0.7);
}

.gr-color-green-opacity-8 {
  color: rgba(0, 176, 116, 0.8);
}

.gr-color-green-opacity-9 {
  color: rgba(0, 176, 116, 0.9);
}

.bg-blue-opacity-visible {
  background-color: #4743db;
}

.bg-blue-opacity-1 {
  background-color: rgba(71, 67, 219, 0.1);
}

.bg-blue-opacity-15 {
  background-color: rgba(71, 67, 219, 0.15);
}

.bg-blue-opacity-2 {
  background-color: rgba(71, 67, 219, 0.2);
}

.bg-blue-opacity-3 {
  background-color: rgba(71, 67, 219, 0.3);
}

.bg-blue-opacity-4 {
  background-color: rgba(71, 67, 219, 0.4);
}

.bg-blue-opacity-5 {
  background-color: rgba(71, 67, 219, 0.5);
}

.bg-blue-opacity-6 {
  background-color: rgba(71, 67, 219, 0.6);
}

.bg-blue-opacity-p6 {
  background-color: rgba(71, 67, 219, 0.06);
}

.bg-blue-opacity-7 {
  background-color: rgba(71, 67, 219, 0.7);
}

.bg-blue-opacity-8 {
  background-color: rgba(71, 67, 219, 0.8);
}

.bg-blue-opacity-9 {
  background-color: rgba(71, 67, 219, 0.9);
}

.gr-color-blue-opacity-visible {
  color: #4743db;
}

.gr-color-blue-opacity-1 {
  color: rgba(71, 67, 219, 0.1);
}

.gr-color-blue-opacity-15 {
  color: rgba(71, 67, 219, 0.15);
}

.gr-color-blue-opacity-2 {
  color: rgba(71, 67, 219, 0.2);
}

.gr-color-blue-opacity-3 {
  color: rgba(71, 67, 219, 0.3);
}

.gr-color-blue-opacity-4 {
  color: rgba(71, 67, 219, 0.4);
}

.gr-color-blue-opacity-5 {
  color: rgba(71, 67, 219, 0.5);
}

.gr-color-blue-opacity-6 {
  color: rgba(71, 67, 219, 0.6);
}

.gr-color-blue-opacity-p6 {
  color: rgba(71, 67, 219, 0.06);
}

.gr-color-blue-opacity-7 {
  color: rgba(71, 67, 219, 0.7);
}

.gr-color-blue-opacity-8 {
  color: rgba(71, 67, 219, 0.8);
}

.gr-color-blue-opacity-9 {
  color: rgba(71, 67, 219, 0.9);
}

.bg-eastern-opacity-visible {
  background-color: #2397af;
}

.bg-eastern-opacity-1 {
  background-color: rgba(35, 151, 175, 0.1);
}

.bg-eastern-opacity-15 {
  background-color: rgba(35, 151, 175, 0.15);
}

.bg-eastern-opacity-2 {
  background-color: rgba(35, 151, 175, 0.2);
}

.bg-eastern-opacity-3 {
  background-color: rgba(35, 151, 175, 0.3);
}

.bg-eastern-opacity-4 {
  background-color: rgba(35, 151, 175, 0.4);
}

.bg-eastern-opacity-5 {
  background-color: rgba(35, 151, 175, 0.5);
}

.bg-eastern-opacity-6 {
  background-color: rgba(35, 151, 175, 0.6);
}

.bg-eastern-opacity-p6 {
  background-color: rgba(35, 151, 175, 0.06);
}

.bg-eastern-opacity-7 {
  background-color: rgba(35, 151, 175, 0.7);
}

.bg-eastern-opacity-8 {
  background-color: rgba(35, 151, 175, 0.8);
}

.bg-eastern-opacity-9 {
  background-color: rgba(35, 151, 175, 0.9);
}

.gr-color-eastern-opacity-visible {
  color: #2397af;
}

.gr-color-eastern-opacity-1 {
  color: rgba(35, 151, 175, 0.1);
}

.gr-color-eastern-opacity-15 {
  color: rgba(35, 151, 175, 0.15);
}

.gr-color-eastern-opacity-2 {
  color: rgba(35, 151, 175, 0.2);
}

.gr-color-eastern-opacity-3 {
  color: rgba(35, 151, 175, 0.3);
}

.gr-color-eastern-opacity-4 {
  color: rgba(35, 151, 175, 0.4);
}

.gr-color-eastern-opacity-5 {
  color: rgba(35, 151, 175, 0.5);
}

.gr-color-eastern-opacity-6 {
  color: rgba(35, 151, 175, 0.6);
}

.gr-color-eastern-opacity-p6 {
  color: rgba(35, 151, 175, 0.06);
}

.gr-color-eastern-opacity-7 {
  color: rgba(35, 151, 175, 0.7);
}

.gr-color-eastern-opacity-8 {
  color: rgba(35, 151, 175, 0.8);
}

.gr-color-eastern-opacity-9 {
  color: rgba(35, 151, 175, 0.9);
}

.bg-denim-opacity-visible {
  background-color: #1a5aeb;
}

.bg-denim-opacity-1 {
  background-color: rgba(26, 90, 235, 0.1);
}

.bg-denim-opacity-15 {
  background-color: rgba(26, 90, 235, 0.15);
}

.bg-denim-opacity-2 {
  background-color: rgba(26, 90, 235, 0.2);
}

.bg-denim-opacity-3 {
  background-color: rgba(26, 90, 235, 0.3);
}

.bg-denim-opacity-4 {
  background-color: rgba(26, 90, 235, 0.4);
}

.bg-denim-opacity-5 {
  background-color: rgba(26, 90, 235, 0.5);
}

.bg-denim-opacity-6 {
  background-color: rgba(26, 90, 235, 0.6);
}

.bg-denim-opacity-p6 {
  background-color: rgba(26, 90, 235, 0.06);
}

.bg-denim-opacity-7 {
  background-color: rgba(26, 90, 235, 0.7);
}

.bg-denim-opacity-8 {
  background-color: rgba(26, 90, 235, 0.8);
}

.bg-denim-opacity-9 {
  background-color: rgba(26, 90, 235, 0.9);
}

.gr-color-denim-opacity-visible {
  color: #1a5aeb;
}

.gr-color-denim-opacity-1 {
  color: rgba(26, 90, 235, 0.1);
}

.gr-color-denim-opacity-15 {
  color: rgba(26, 90, 235, 0.15);
}

.gr-color-denim-opacity-2 {
  color: rgba(26, 90, 235, 0.2);
}

.gr-color-denim-opacity-3 {
  color: rgba(26, 90, 235, 0.3);
}

.gr-color-denim-opacity-4 {
  color: rgba(26, 90, 235, 0.4);
}

.gr-color-denim-opacity-5 {
  color: rgba(26, 90, 235, 0.5);
}

.gr-color-denim-opacity-6 {
  color: rgba(26, 90, 235, 0.6);
}

.gr-color-denim-opacity-p6 {
  color: rgba(26, 90, 235, 0.06);
}

.gr-color-denim-opacity-7 {
  color: rgba(26, 90, 235, 0.7);
}

.gr-color-denim-opacity-8 {
  color: rgba(26, 90, 235, 0.8);
}

.gr-color-denim-opacity-9 {
  color: rgba(26, 90, 235, 0.9);
}

.bg-dodger-opacity-visible {
  background-color: #336ef7;
}

.bg-dodger-opacity-1 {
  background-color: rgba(51, 110, 247, 0.1);
}

.bg-dodger-opacity-15 {
  background-color: rgba(51, 110, 247, 0.15);
}

.bg-dodger-opacity-2 {
  background-color: rgba(51, 110, 247, 0.2);
}

.bg-dodger-opacity-3 {
  background-color: rgba(51, 110, 247, 0.3);
}

.bg-dodger-opacity-4 {
  background-color: rgba(51, 110, 247, 0.4);
}

.bg-dodger-opacity-5 {
  background-color: rgba(51, 110, 247, 0.5);
}

.bg-dodger-opacity-6 {
  background-color: rgba(51, 110, 247, 0.6);
}

.bg-dodger-opacity-p6 {
  background-color: rgba(51, 110, 247, 0.06);
}

.bg-dodger-opacity-7 {
  background-color: rgba(51, 110, 247, 0.7);
}

.bg-dodger-opacity-8 {
  background-color: rgba(51, 110, 247, 0.8);
}

.bg-dodger-opacity-9 {
  background-color: rgba(51, 110, 247, 0.9);
}

.gr-color-dodger-opacity-visible {
  color: #336ef7;
}

.gr-color-dodger-opacity-1 {
  color: rgba(51, 110, 247, 0.1);
}

.gr-color-dodger-opacity-15 {
  color: rgba(51, 110, 247, 0.15);
}

.gr-color-dodger-opacity-2 {
  color: rgba(51, 110, 247, 0.2);
}

.gr-color-dodger-opacity-3 {
  color: rgba(51, 110, 247, 0.3);
}

.gr-color-dodger-opacity-4 {
  color: rgba(51, 110, 247, 0.4);
}

.gr-color-dodger-opacity-5 {
  color: rgba(51, 110, 247, 0.5);
}

.gr-color-dodger-opacity-6 {
  color: rgba(51, 110, 247, 0.6);
}

.gr-color-dodger-opacity-p6 {
  color: rgba(51, 110, 247, 0.06);
}

.gr-color-dodger-opacity-7 {
  color: rgba(51, 110, 247, 0.7);
}

.gr-color-dodger-opacity-8 {
  color: rgba(51, 110, 247, 0.8);
}

.gr-color-dodger-opacity-9 {
  color: rgba(51, 110, 247, 0.9);
}

.bg-dodger-2-opacity-visible {
  background-color: #4d83ff;
}

.bg-dodger-2-opacity-1 {
  background-color: rgba(77, 131, 255, 0.1);
}

.bg-dodger-2-opacity-15 {
  background-color: rgba(77, 131, 255, 0.15);
}

.bg-dodger-2-opacity-2 {
  background-color: rgba(77, 131, 255, 0.2);
}

.bg-dodger-2-opacity-3 {
  background-color: rgba(77, 131, 255, 0.3);
}

.bg-dodger-2-opacity-4 {
  background-color: rgba(77, 131, 255, 0.4);
}

.bg-dodger-2-opacity-5 {
  background-color: rgba(77, 131, 255, 0.5);
}

.bg-dodger-2-opacity-6 {
  background-color: rgba(77, 131, 255, 0.6);
}

.bg-dodger-2-opacity-p6 {
  background-color: rgba(77, 131, 255, 0.06);
}

.bg-dodger-2-opacity-7 {
  background-color: rgba(77, 131, 255, 0.7);
}

.bg-dodger-2-opacity-8 {
  background-color: rgba(77, 131, 255, 0.8);
}

.bg-dodger-2-opacity-9 {
  background-color: rgba(77, 131, 255, 0.9);
}

.gr-color-dodger-2-opacity-visible {
  color: #4d83ff;
}

.gr-color-dodger-2-opacity-1 {
  color: rgba(77, 131, 255, 0.1);
}

.gr-color-dodger-2-opacity-15 {
  color: rgba(77, 131, 255, 0.15);
}

.gr-color-dodger-2-opacity-2 {
  color: rgba(77, 131, 255, 0.2);
}

.gr-color-dodger-2-opacity-3 {
  color: rgba(77, 131, 255, 0.3);
}

.gr-color-dodger-2-opacity-4 {
  color: rgba(77, 131, 255, 0.4);
}

.gr-color-dodger-2-opacity-5 {
  color: rgba(77, 131, 255, 0.5);
}

.gr-color-dodger-2-opacity-6 {
  color: rgba(77, 131, 255, 0.6);
}

.gr-color-dodger-2-opacity-p6 {
  color: rgba(77, 131, 255, 0.06);
}

.gr-color-dodger-2-opacity-7 {
  color: rgba(77, 131, 255, 0.7);
}

.gr-color-dodger-2-opacity-8 {
  color: rgba(77, 131, 255, 0.8);
}

.gr-color-dodger-2-opacity-9 {
  color: rgba(77, 131, 255, 0.9);
}

.bg-olive-opacity-1 {
  background-color: #1aaf762c;
}

.bg-spray-opacity-visible {
  background-color: #65efe3;
}

.bg-spray-opacity-1 {
  background-color: rgba(101, 239, 227, 0.1);
}

.bg-spray-opacity-15 {
  background-color: rgba(101, 239, 227, 0.15);
}

.bg-spray-opacity-2 {
  background-color: rgba(101, 239, 227, 0.2);
}

.bg-spray-opacity-3 {
  background-color: rgba(101, 239, 227, 0.3);
}

.bg-spray-opacity-4 {
  background-color: rgba(101, 239, 227, 0.4);
}

.bg-spray-opacity-5 {
  background-color: rgba(101, 239, 227, 0.5);
}

.bg-spray-opacity-6 {
  background-color: rgba(101, 239, 227, 0.6);
}

.bg-spray-opacity-p6 {
  background-color: rgba(101, 239, 227, 0.06);
}

.bg-spray-opacity-7 {
  background-color: rgba(101, 239, 227, 0.7);
}

.bg-spray-opacity-8 {
  background-color: rgba(101, 239, 227, 0.8);
}

.bg-spray-opacity-9 {
  background-color: rgba(101, 239, 227, 0.9);
}

.gr-color-spray-opacity-visible {
  color: #65efe3;
}

.gr-color-spray-opacity-1 {
  color: rgba(101, 239, 227, 0.1);
}

.gr-color-spray-opacity-15 {
  color: rgba(101, 239, 227, 0.15);
}

.gr-color-spray-opacity-2 {
  color: rgba(101, 239, 227, 0.2);
}

.gr-color-spray-opacity-3 {
  color: rgba(101, 239, 227, 0.3);
}

.gr-color-spray-opacity-4 {
  color: rgba(101, 239, 227, 0.4);
}

.gr-color-spray-opacity-5 {
  color: rgba(101, 239, 227, 0.5);
}

.gr-color-spray-opacity-6 {
  color: rgba(101, 239, 227, 0.6);
}

.gr-color-spray-opacity-p6 {
  color: rgba(101, 239, 227, 0.06);
}

.gr-color-spray-opacity-7 {
  color: rgba(101, 239, 227, 0.7);
}

.gr-color-spray-opacity-8 {
  color: rgba(101, 239, 227, 0.8);
}

.gr-color-spray-opacity-9 {
  color: rgba(101, 239, 227, 0.9);
}

.bg-turquoise-opacity-visible {
  background-color: #50d7e5;
}

.bg-turquoise-opacity-1 {
  background-color: rgba(80, 215, 229, 0.1);
}

.bg-turquoise-opacity-15 {
  background-color: rgba(80, 215, 229, 0.15);
}

.bg-turquoise-opacity-2 {
  background-color: rgba(80, 215, 229, 0.2);
}

.bg-turquoise-opacity-3 {
  background-color: rgba(80, 215, 229, 0.3);
}

.bg-turquoise-opacity-4 {
  background-color: rgba(80, 215, 229, 0.4);
}

.bg-turquoise-opacity-5 {
  background-color: rgba(80, 215, 229, 0.5);
}

.bg-turquoise-opacity-6 {
  background-color: rgba(80, 215, 229, 0.6);
}

.bg-turquoise-opacity-p6 {
  background-color: rgba(80, 215, 229, 0.06);
}

.bg-turquoise-opacity-7 {
  background-color: rgba(80, 215, 229, 0.7);
}

.bg-turquoise-opacity-8 {
  background-color: rgba(80, 215, 229, 0.8);
}

.bg-turquoise-opacity-9 {
  background-color: rgba(80, 215, 229, 0.9);
}

.gr-color-turquoise-opacity-visible {
  color: #50d7e5;
}

.gr-color-turquoise-opacity-1 {
  color: rgba(80, 215, 229, 0.1);
}

.gr-color-turquoise-opacity-15 {
  color: rgba(80, 215, 229, 0.15);
}

.gr-color-turquoise-opacity-2 {
  color: rgba(80, 215, 229, 0.2);
}

.gr-color-turquoise-opacity-3 {
  color: rgba(80, 215, 229, 0.3);
}

.gr-color-turquoise-opacity-4 {
  color: rgba(80, 215, 229, 0.4);
}

.gr-color-turquoise-opacity-5 {
  color: rgba(80, 215, 229, 0.5);
}

.gr-color-turquoise-opacity-6 {
  color: rgba(80, 215, 229, 0.6);
}

.gr-color-turquoise-opacity-p6 {
  color: rgba(80, 215, 229, 0.06);
}

.gr-color-turquoise-opacity-7 {
  color: rgba(80, 215, 229, 0.7);
}

.gr-color-turquoise-opacity-8 {
  color: rgba(80, 215, 229, 0.8);
}

.gr-color-turquoise-opacity-9 {
  color: rgba(80, 215, 229, 0.9);
}

.bg-regent-opacity-visible {
  background-color: #b0d5e8;
}

.bg-regent-opacity-1 {
  background-color: rgba(176, 213, 232, 0.1);
}

.bg-regent-opacity-15 {
  background-color: rgba(176, 213, 232, 0.15);
}

.bg-regent-opacity-2 {
  background-color: rgba(176, 213, 232, 0.2);
}

.bg-regent-opacity-3 {
  background-color: rgba(176, 213, 232, 0.3);
}

.bg-regent-opacity-4 {
  background-color: rgba(176, 213, 232, 0.4);
}

.bg-regent-opacity-5 {
  background-color: rgba(176, 213, 232, 0.5);
}

.bg-regent-opacity-6 {
  background-color: rgba(176, 213, 232, 0.6);
}

.bg-regent-opacity-p6 {
  background-color: rgba(176, 213, 232, 0.06);
}

.bg-regent-opacity-7 {
  background-color: rgba(176, 213, 232, 0.7);
}

.bg-regent-opacity-8 {
  background-color: rgba(176, 213, 232, 0.8);
}

.bg-regent-opacity-9 {
  background-color: rgba(176, 213, 232, 0.9);
}

.gr-color-regent-opacity-visible {
  color: #b0d5e8;
}

.gr-color-regent-opacity-1 {
  color: rgba(176, 213, 232, 0.1);
}

.gr-color-regent-opacity-15 {
  color: rgba(176, 213, 232, 0.15);
}

.gr-color-regent-opacity-2 {
  color: rgba(176, 213, 232, 0.2);
}

.gr-color-regent-opacity-3 {
  color: rgba(176, 213, 232, 0.3);
}

.gr-color-regent-opacity-4 {
  color: rgba(176, 213, 232, 0.4);
}

.gr-color-regent-opacity-5 {
  color: rgba(176, 213, 232, 0.5);
}

.gr-color-regent-opacity-6 {
  color: rgba(176, 213, 232, 0.6);
}

.gr-color-regent-opacity-p6 {
  color: rgba(176, 213, 232, 0.06);
}

.gr-color-regent-opacity-7 {
  color: rgba(176, 213, 232, 0.7);
}

.gr-color-regent-opacity-8 {
  color: rgba(176, 213, 232, 0.8);
}

.gr-color-regent-opacity-9 {
  color: rgba(176, 213, 232, 0.9);
}

.bg-coral-opacity-visible {
  background-color: #fa8557;
}

.bg-coral-opacity-1 {
  background-color: rgba(250, 133, 87, 0.1);
}

.bg-coral-opacity-15 {
  background-color: rgba(250, 133, 87, 0.15);
}

.bg-coral-opacity-2 {
  background-color: rgba(250, 133, 87, 0.2);
}

.bg-coral-opacity-3 {
  background-color: rgba(250, 133, 87, 0.3);
}

.bg-coral-opacity-4 {
  background-color: rgba(250, 133, 87, 0.4);
}

.bg-coral-opacity-5 {
  background-color: rgba(250, 133, 87, 0.5);
}

.bg-coral-opacity-6 {
  background-color: rgba(250, 133, 87, 0.6);
}

.bg-coral-opacity-p6 {
  background-color: rgba(250, 133, 87, 0.06);
}

.bg-coral-opacity-7 {
  background-color: rgba(250, 133, 87, 0.7);
}

.bg-coral-opacity-8 {
  background-color: rgba(250, 133, 87, 0.8);
}

.bg-coral-opacity-9 {
  background-color: rgba(250, 133, 87, 0.9);
}

.gr-color-coral-opacity-visible {
  color: #fa8557;
}

.gr-color-coral-opacity-1 {
  color: rgba(250, 133, 87, 0.1);
}

.gr-color-coral-opacity-15 {
  color: rgba(250, 133, 87, 0.15);
}

.gr-color-coral-opacity-2 {
  color: rgba(250, 133, 87, 0.2);
}

.gr-color-coral-opacity-3 {
  color: rgba(250, 133, 87, 0.3);
}

.gr-color-coral-opacity-4 {
  color: rgba(250, 133, 87, 0.4);
}

.gr-color-coral-opacity-5 {
  color: rgba(250, 133, 87, 0.5);
}

.gr-color-coral-opacity-6 {
  color: rgba(250, 133, 87, 0.6);
}

.gr-color-coral-opacity-p6 {
  color: rgba(250, 133, 87, 0.06);
}

.gr-color-coral-opacity-7 {
  color: rgba(250, 133, 87, 0.7);
}

.gr-color-coral-opacity-8 {
  color: rgba(250, 133, 87, 0.8);
}

.gr-color-coral-opacity-9 {
  color: rgba(250, 133, 87, 0.9);
}

.bg-orange-opacity-visible {
  background-color: #fa5f1c;
}

.bg-orange-opacity-1 {
  background-color: rgba(250, 95, 28, 0.1);
}

.bg-orange-opacity-15 {
  background-color: rgba(250, 95, 28, 0.15);
}

.bg-orange-opacity-2 {
  background-color: rgba(250, 95, 28, 0.2);
}

.bg-orange-opacity-3 {
  background-color: rgba(250, 95, 28, 0.3);
}

.bg-orange-opacity-4 {
  background-color: rgba(250, 95, 28, 0.4);
}

.bg-orange-opacity-5 {
  background-color: rgba(250, 95, 28, 0.5);
}

.bg-orange-opacity-6 {
  background-color: rgba(250, 95, 28, 0.6);
}

.bg-orange-opacity-p6 {
  background-color: rgba(250, 95, 28, 0.06);
}

.bg-orange-opacity-7 {
  background-color: rgba(250, 95, 28, 0.7);
}

.bg-orange-opacity-8 {
  background-color: rgba(250, 95, 28, 0.8);
}

.bg-orange-opacity-9 {
  background-color: rgba(250, 95, 28, 0.9);
}

.gr-color-orange-opacity-visible {
  color: #fa5f1c;
}

.gr-color-orange-opacity-1 {
  color: rgba(250, 95, 28, 0.1);
}

.gr-color-orange-opacity-15 {
  color: rgba(250, 95, 28, 0.15);
}

.gr-color-orange-opacity-2 {
  color: rgba(250, 95, 28, 0.2);
}

.gr-color-orange-opacity-3 {
  color: rgba(250, 95, 28, 0.3);
}

.gr-color-orange-opacity-4 {
  color: rgba(250, 95, 28, 0.4);
}

.gr-color-orange-opacity-5 {
  color: rgba(250, 95, 28, 0.5);
}

.gr-color-orange-opacity-6 {
  color: rgba(250, 95, 28, 0.6);
}

.gr-color-orange-opacity-p6 {
  color: rgba(250, 95, 28, 0.06);
}

.gr-color-orange-opacity-7 {
  color: rgba(250, 95, 28, 0.7);
}

.gr-color-orange-opacity-8 {
  color: rgba(250, 95, 28, 0.8);
}

.gr-color-orange-opacity-9 {
  color: rgba(250, 95, 28, 0.9);
}

.bg-orange-2-opacity-visible {
  background-color: #fa6032;
}

.bg-orange-2-opacity-1 {
  background-color: rgba(250, 96, 50, 0.1);
}

.bg-orange-2-opacity-15 {
  background-color: rgba(250, 96, 50, 0.15);
}

.bg-orange-2-opacity-2 {
  background-color: rgba(250, 96, 50, 0.2);
}

.bg-orange-2-opacity-3 {
  background-color: rgba(250, 96, 50, 0.3);
}

.bg-orange-2-opacity-4 {
  background-color: rgba(250, 96, 50, 0.4);
}

.bg-orange-2-opacity-5 {
  background-color: rgba(250, 96, 50, 0.5);
}

.bg-orange-2-opacity-6 {
  background-color: rgba(250, 96, 50, 0.6);
}

.bg-orange-2-opacity-p6 {
  background-color: rgba(250, 96, 50, 0.06);
}

.bg-orange-2-opacity-7 {
  background-color: rgba(250, 96, 50, 0.7);
}

.bg-orange-2-opacity-8 {
  background-color: rgba(250, 96, 50, 0.8);
}

.bg-orange-2-opacity-9 {
  background-color: rgba(250, 96, 50, 0.9);
}

.gr-color-orange-2-opacity-visible {
  color: #fa6032;
}

.gr-color-orange-2-opacity-1 {
  color: rgba(250, 96, 50, 0.1);
}

.gr-color-orange-2-opacity-15 {
  color: rgba(250, 96, 50, 0.15);
}

.gr-color-orange-2-opacity-2 {
  color: rgba(250, 96, 50, 0.2);
}

.gr-color-orange-2-opacity-3 {
  color: rgba(250, 96, 50, 0.3);
}

.gr-color-orange-2-opacity-4 {
  color: rgba(250, 96, 50, 0.4);
}

.gr-color-orange-2-opacity-5 {
  color: rgba(250, 96, 50, 0.5);
}

.gr-color-orange-2-opacity-6 {
  color: rgba(250, 96, 50, 0.6);
}

.gr-color-orange-2-opacity-p6 {
  color: rgba(250, 96, 50, 0.06);
}

.gr-color-orange-2-opacity-7 {
  color: rgba(250, 96, 50, 0.7);
}

.gr-color-orange-2-opacity-8 {
  color: rgba(250, 96, 50, 0.8);
}

.gr-color-orange-2-opacity-9 {
  color: rgba(250, 96, 50, 0.9);
}

.bg-red-opacity-visible {
  background-color: #f71e3f;
}

.bg-red-opacity-1 {
  background-color: rgba(247, 30, 63, 0.1);
}

.bg-red-opacity-15 {
  background-color: rgba(247, 30, 63, 0.15);
}

.bg-red-opacity-2 {
  background-color: rgba(247, 30, 63, 0.2);
}

.bg-red-opacity-3 {
  background-color: rgba(247, 30, 63, 0.3);
}

.bg-red-opacity-4 {
  background-color: rgba(247, 30, 63, 0.4);
}

.bg-red-opacity-5 {
  background-color: rgba(247, 30, 63, 0.5);
}

.bg-red-opacity-6 {
  background-color: rgba(247, 30, 63, 0.6);
}

.bg-red-opacity-p6 {
  background-color: rgba(247, 30, 63, 0.06);
}

.bg-red-opacity-7 {
  background-color: rgba(247, 30, 63, 0.7);
}

.bg-red-opacity-8 {
  background-color: rgba(247, 30, 63, 0.8);
}

.bg-red-opacity-9 {
  background-color: rgba(247, 30, 63, 0.9);
}

.gr-color-red-opacity-visible {
  color: #f71e3f;
}

.gr-color-red-opacity-1 {
  color: rgba(247, 30, 63, 0.1);
}

.gr-color-red-opacity-15 {
  color: rgba(247, 30, 63, 0.15);
}

.gr-color-red-opacity-2 {
  color: rgba(247, 30, 63, 0.2);
}

.gr-color-red-opacity-3 {
  color: rgba(247, 30, 63, 0.3);
}

.gr-color-red-opacity-4 {
  color: rgba(247, 30, 63, 0.4);
}

.gr-color-red-opacity-5 {
  color: rgba(247, 30, 63, 0.5);
}

.gr-color-red-opacity-6 {
  color: rgba(247, 30, 63, 0.6);
}

.gr-color-red-opacity-p6 {
  color: rgba(247, 30, 63, 0.06);
}

.gr-color-red-opacity-7 {
  color: rgba(247, 30, 63, 0.7);
}

.gr-color-red-opacity-8 {
  color: rgba(247, 30, 63, 0.8);
}

.gr-color-red-opacity-9 {
  color: rgba(247, 30, 63, 0.9);
}

.bg-red-2-opacity-visible {
  background-color: #fc3f3f;
}

.bg-red-2-opacity-1 {
  background-color: rgba(252, 63, 63, 0.1);
}

.bg-red-2-opacity-15 {
  background-color: rgba(252, 63, 63, 0.15);
}

.bg-red-2-opacity-2 {
  background-color: rgba(252, 63, 63, 0.2);
}

.bg-red-2-opacity-3 {
  background-color: rgba(252, 63, 63, 0.3);
}

.bg-red-2-opacity-4 {
  background-color: rgba(252, 63, 63, 0.4);
}

.bg-red-2-opacity-5 {
  background-color: rgba(252, 63, 63, 0.5);
}

.bg-red-2-opacity-6 {
  background-color: rgba(252, 63, 63, 0.6);
}

.bg-red-2-opacity-p6 {
  background-color: rgba(252, 63, 63, 0.06);
}

.bg-red-2-opacity-7 {
  background-color: rgba(252, 63, 63, 0.7);
}

.bg-red-2-opacity-8 {
  background-color: rgba(252, 63, 63, 0.8);
}

.bg-red-2-opacity-9 {
  background-color: rgba(252, 63, 63, 0.9);
}

.gr-color-red-2-opacity-visible {
  color: #fc3f3f;
}

.gr-color-red-2-opacity-1 {
  color: rgba(252, 63, 63, 0.1);
}

.gr-color-red-2-opacity-15 {
  color: rgba(252, 63, 63, 0.15);
}

.gr-color-red-2-opacity-2 {
  color: rgba(252, 63, 63, 0.2);
}

.gr-color-red-2-opacity-3 {
  color: rgba(252, 63, 63, 0.3);
}

.gr-color-red-2-opacity-4 {
  color: rgba(252, 63, 63, 0.4);
}

.gr-color-red-2-opacity-5 {
  color: rgba(252, 63, 63, 0.5);
}

.gr-color-red-2-opacity-6 {
  color: rgba(252, 63, 63, 0.6);
}

.gr-color-red-2-opacity-p6 {
  color: rgba(252, 63, 63, 0.06);
}

.gr-color-red-2-opacity-7 {
  color: rgba(252, 63, 63, 0.7);
}

.gr-color-red-2-opacity-8 {
  color: rgba(252, 63, 63, 0.8);
}

.gr-color-red-2-opacity-9 {
  color: rgba(252, 63, 63, 0.9);
}

.bg-yellow-opacity-visible {
  background-color: #ffb300;
}

.bg-yellow-opacity-1 {
  background-color: rgba(255, 179, 0, 0.1);
}

.bg-yellow-opacity-15 {
  background-color: rgba(255, 179, 0, 0.15);
}

.bg-yellow-opacity-2 {
  background-color: rgba(255, 179, 0, 0.2);
}

.bg-yellow-opacity-3 {
  background-color: rgba(255, 179, 0, 0.3);
}

.bg-yellow-opacity-4 {
  background-color: rgba(255, 179, 0, 0.4);
}

.bg-yellow-opacity-5 {
  background-color: rgba(255, 179, 0, 0.5);
}

.bg-yellow-opacity-6 {
  background-color: rgba(255, 179, 0, 0.6);
}

.bg-yellow-opacity-p6 {
  background-color: rgba(255, 179, 0, 0.06);
}

.bg-yellow-opacity-7 {
  background-color: rgba(255, 179, 0, 0.7);
}

.bg-yellow-opacity-8 {
  background-color: rgba(255, 179, 0, 0.8);
}

.bg-yellow-opacity-9 {
  background-color: rgba(255, 179, 0, 0.9);
}

.gr-color-yellow-opacity-visible {
  color: #ffb300;
}

.gr-color-yellow-opacity-1 {
  color: rgba(255, 179, 0, 0.1);
}

.gr-color-yellow-opacity-15 {
  color: rgba(255, 179, 0, 0.15);
}

.gr-color-yellow-opacity-2 {
  color: rgba(255, 179, 0, 0.2);
}

.gr-color-yellow-opacity-3 {
  color: rgba(255, 179, 0, 0.3);
}

.gr-color-yellow-opacity-4 {
  color: rgba(255, 179, 0, 0.4);
}

.gr-color-yellow-opacity-5 {
  color: rgba(255, 179, 0, 0.5);
}

.gr-color-yellow-opacity-6 {
  color: rgba(255, 179, 0, 0.6);
}

.gr-color-yellow-opacity-p6 {
  color: rgba(255, 179, 0, 0.06);
}

.gr-color-yellow-opacity-7 {
  color: rgba(255, 179, 0, 0.7);
}

.gr-color-yellow-opacity-8 {
  color: rgba(255, 179, 0, 0.8);
}

.gr-color-yellow-opacity-9 {
  color: rgba(255, 179, 0, 0.9);
}

.bg-yellow-2-opacity-visible {
  background-color: #facf32;
}

.bg-yellow-2-opacity-1 {
  background-color: rgba(250, 207, 50, 0.1);
}

.bg-yellow-2-opacity-15 {
  background-color: rgba(250, 207, 50, 0.15);
}

.bg-yellow-2-opacity-2 {
  background-color: rgba(250, 207, 50, 0.2);
}

.bg-yellow-2-opacity-3 {
  background-color: rgba(250, 207, 50, 0.3);
}

.bg-yellow-2-opacity-4 {
  background-color: rgba(250, 207, 50, 0.4);
}

.bg-yellow-2-opacity-5 {
  background-color: rgba(250, 207, 50, 0.5);
}

.bg-yellow-2-opacity-6 {
  background-color: rgba(250, 207, 50, 0.6);
}

.bg-yellow-2-opacity-p6 {
  background-color: rgba(250, 207, 50, 0.06);
}

.bg-yellow-2-opacity-7 {
  background-color: rgba(250, 207, 50, 0.7);
}

.bg-yellow-2-opacity-8 {
  background-color: rgba(250, 207, 50, 0.8);
}

.bg-yellow-2-opacity-9 {
  background-color: rgba(250, 207, 50, 0.9);
}

.gr-color-yellow-2-opacity-visible {
  color: #facf32;
}

.gr-color-yellow-2-opacity-1 {
  color: rgba(250, 207, 50, 0.1);
}

.gr-color-yellow-2-opacity-15 {
  color: rgba(250, 207, 50, 0.15);
}

.gr-color-yellow-2-opacity-2 {
  color: rgba(250, 207, 50, 0.2);
}

.gr-color-yellow-2-opacity-3 {
  color: rgba(250, 207, 50, 0.3);
}

.gr-color-yellow-2-opacity-4 {
  color: rgba(250, 207, 50, 0.4);
}

.gr-color-yellow-2-opacity-5 {
  color: rgba(250, 207, 50, 0.5);
}

.gr-color-yellow-2-opacity-6 {
  color: rgba(250, 207, 50, 0.6);
}

.gr-color-yellow-2-opacity-p6 {
  color: rgba(250, 207, 50, 0.06);
}

.gr-color-yellow-2-opacity-7 {
  color: rgba(250, 207, 50, 0.7);
}

.gr-color-yellow-2-opacity-8 {
  color: rgba(250, 207, 50, 0.8);
}

.gr-color-yellow-2-opacity-9 {
  color: rgba(250, 207, 50, 0.9);
}

.bg-casablanca-opacity-visible {
  background-color: #f8c546;
}

.bg-casablanca-opacity-1 {
  background-color: rgba(248, 197, 70, 0.1);
}

.bg-casablanca-opacity-15 {
  background-color: rgba(248, 197, 70, 0.15);
}

.bg-casablanca-opacity-2 {
  background-color: rgba(248, 197, 70, 0.2);
}

.bg-casablanca-opacity-3 {
  background-color: rgba(248, 197, 70, 0.3);
}

.bg-casablanca-opacity-4 {
  background-color: rgba(248, 197, 70, 0.4);
}

.bg-casablanca-opacity-5 {
  background-color: rgba(248, 197, 70, 0.5);
}

.bg-casablanca-opacity-6 {
  background-color: rgba(248, 197, 70, 0.6);
}

.bg-casablanca-opacity-p6 {
  background-color: rgba(248, 197, 70, 0.06);
}

.bg-casablanca-opacity-7 {
  background-color: rgba(248, 197, 70, 0.7);
}

.bg-casablanca-opacity-8 {
  background-color: rgba(248, 197, 70, 0.8);
}

.bg-casablanca-opacity-9 {
  background-color: rgba(248, 197, 70, 0.9);
}

.gr-color-casablanca-opacity-visible {
  color: #f8c546;
}

.gr-color-casablanca-opacity-1 {
  color: rgba(248, 197, 70, 0.1);
}

.gr-color-casablanca-opacity-15 {
  color: rgba(248, 197, 70, 0.15);
}

.gr-color-casablanca-opacity-2 {
  color: rgba(248, 197, 70, 0.2);
}

.gr-color-casablanca-opacity-3 {
  color: rgba(248, 197, 70, 0.3);
}

.gr-color-casablanca-opacity-4 {
  color: rgba(248, 197, 70, 0.4);
}

.gr-color-casablanca-opacity-5 {
  color: rgba(248, 197, 70, 0.5);
}

.gr-color-casablanca-opacity-6 {
  color: rgba(248, 197, 70, 0.6);
}

.gr-color-casablanca-opacity-p6 {
  color: rgba(248, 197, 70, 0.06);
}

.gr-color-casablanca-opacity-7 {
  color: rgba(248, 197, 70, 0.7);
}

.gr-color-casablanca-opacity-8 {
  color: rgba(248, 197, 70, 0.8);
}

.gr-color-casablanca-opacity-9 {
  color: rgba(248, 197, 70, 0.9);
}

.bg-indigo-opacity-visible {
  background-color: #5877be;
}

.bg-indigo-opacity-1 {
  background-color: rgba(88, 119, 190, 0.1);
}

.bg-indigo-opacity-15 {
  background-color: rgba(88, 119, 190, 0.15);
}

.bg-indigo-opacity-2 {
  background-color: rgba(88, 119, 190, 0.2);
}

.bg-indigo-opacity-3 {
  background-color: rgba(88, 119, 190, 0.3);
}

.bg-indigo-opacity-4 {
  background-color: rgba(88, 119, 190, 0.4);
}

.bg-indigo-opacity-5 {
  background-color: rgba(88, 119, 190, 0.5);
}

.bg-indigo-opacity-6 {
  background-color: rgba(88, 119, 190, 0.6);
}

.bg-indigo-opacity-p6 {
  background-color: rgba(88, 119, 190, 0.06);
}

.bg-indigo-opacity-7 {
  background-color: rgba(88, 119, 190, 0.7);
}

.bg-indigo-opacity-8 {
  background-color: rgba(88, 119, 190, 0.8);
}

.bg-indigo-opacity-9 {
  background-color: rgba(88, 119, 190, 0.9);
}

.gr-color-indigo-opacity-visible {
  color: #5877be;
}

.gr-color-indigo-opacity-1 {
  color: rgba(88, 119, 190, 0.1);
}

.gr-color-indigo-opacity-15 {
  color: rgba(88, 119, 190, 0.15);
}

.gr-color-indigo-opacity-2 {
  color: rgba(88, 119, 190, 0.2);
}

.gr-color-indigo-opacity-3 {
  color: rgba(88, 119, 190, 0.3);
}

.gr-color-indigo-opacity-4 {
  color: rgba(88, 119, 190, 0.4);
}

.gr-color-indigo-opacity-5 {
  color: rgba(88, 119, 190, 0.5);
}

.gr-color-indigo-opacity-6 {
  color: rgba(88, 119, 190, 0.6);
}

.gr-color-indigo-opacity-p6 {
  color: rgba(88, 119, 190, 0.06);
}

.gr-color-indigo-opacity-7 {
  color: rgba(88, 119, 190, 0.7);
}

.gr-color-indigo-opacity-8 {
  color: rgba(88, 119, 190, 0.8);
}

.gr-color-indigo-opacity-9 {
  color: rgba(88, 119, 190, 0.9);
}

.bg-shamrock-opacity-visible {
  background-color: #25ce95;
}

.bg-shamrock-opacity-1 {
  background-color: rgba(37, 206, 149, 0.1);
}

.bg-shamrock-opacity-15 {
  background-color: rgba(37, 206, 149, 0.15);
}

.bg-shamrock-opacity-2 {
  background-color: rgba(37, 206, 149, 0.2);
}

.bg-shamrock-opacity-3 {
  background-color: rgba(37, 206, 149, 0.3);
}

.bg-shamrock-opacity-4 {
  background-color: rgba(37, 206, 149, 0.4);
}

.bg-shamrock-opacity-5 {
  background-color: rgba(37, 206, 149, 0.5);
}

.bg-shamrock-opacity-6 {
  background-color: rgba(37, 206, 149, 0.6);
}

.bg-shamrock-opacity-p6 {
  background-color: rgba(37, 206, 149, 0.06);
}

.bg-shamrock-opacity-7 {
  background-color: rgba(37, 206, 149, 0.7);
}

.bg-shamrock-opacity-8 {
  background-color: rgba(37, 206, 149, 0.8);
}

.bg-shamrock-opacity-9 {
  background-color: rgba(37, 206, 149, 0.9);
}

.gr-color-shamrock-opacity-visible {
  color: #25ce95;
}

.gr-color-shamrock-opacity-1 {
  color: rgba(37, 206, 149, 0.1);
}

.gr-color-shamrock-opacity-15 {
  color: rgba(37, 206, 149, 0.15);
}

.gr-color-shamrock-opacity-2 {
  color: rgba(37, 206, 149, 0.2);
}

.gr-color-shamrock-opacity-3 {
  color: rgba(37, 206, 149, 0.3);
}

.gr-color-shamrock-opacity-4 {
  color: rgba(37, 206, 149, 0.4);
}

.gr-color-shamrock-opacity-5 {
  color: rgba(37, 206, 149, 0.5);
}

.gr-color-shamrock-opacity-6 {
  color: rgba(37, 206, 149, 0.6);
}

.gr-color-shamrock-opacity-p6 {
  color: rgba(37, 206, 149, 0.06);
}

.gr-color-shamrock-opacity-7 {
  color: rgba(37, 206, 149, 0.7);
}

.gr-color-shamrock-opacity-8 {
  color: rgba(37, 206, 149, 0.8);
}

.gr-color-shamrock-opacity-9 {
  color: rgba(37, 206, 149, 0.9);
}

.bg-black-opacity-visible {
  background-color: black;
}

.bg-black-opacity-1 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-opacity-15 {
  background-color: rgba(0, 0, 0, 0.15);
}

.bg-black-opacity-2 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-opacity-3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-opacity-4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-opacity-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-opacity-6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-opacity-p6 {
  background-color: rgba(0, 0, 0, 0.06);
}

.bg-black-opacity-7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-opacity-8 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-opacity-9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.gr-color-black-opacity-visible {
  color: black;
}

.gr-color-black-opacity-1 {
  color: rgba(0, 0, 0, 0.1);
}

.gr-color-black-opacity-15 {
  color: rgba(0, 0, 0, 0.15);
}

.gr-color-black-opacity-2 {
  color: rgba(0, 0, 0, 0.2);
}

.gr-color-black-opacity-3 {
  color: rgba(0, 0, 0, 0.3);
}

.gr-color-black-opacity-4 {
  color: rgba(0, 0, 0, 0.4);
}

.gr-color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5);
}

.gr-color-black-opacity-6 {
  color: rgba(0, 0, 0, 0.6);
}

.gr-color-black-opacity-p6 {
  color: rgba(0, 0, 0, 0.06);
}

.gr-color-black-opacity-7 {
  color: rgba(0, 0, 0, 0.7);
}

.gr-color-black-opacity-8 {
  color: rgba(0, 0, 0, 0.8);
}

.gr-color-black-opacity-9 {
  color: rgba(0, 0, 0, 0.9);
}

.bg-black-2-opacity-visible {
  background-color: #2b3940;
}

.bg-black-2-opacity-1 {
  background-color: rgba(43, 57, 64, 0.1);
}

.bg-black-2-opacity-15 {
  background-color: rgba(43, 57, 64, 0.15);
}

.bg-black-2-opacity-2 {
  background-color: rgba(43, 57, 64, 0.2);
}

.bg-black-2-opacity-3 {
  background-color: rgba(43, 57, 64, 0.3);
}

.bg-black-2-opacity-4 {
  background-color: rgba(43, 57, 64, 0.4);
}

.bg-black-2-opacity-5 {
  background-color: rgba(43, 57, 64, 0.5);
}

.bg-black-2-opacity-6 {
  background-color: rgba(43, 57, 64, 0.6);
}

.bg-black-2-opacity-p6 {
  background-color: rgba(43, 57, 64, 0.06);
}

.bg-black-2-opacity-7 {
  background-color: rgba(43, 57, 64, 0.7);
}

.bg-black-2-opacity-8 {
  background-color: rgba(43, 57, 64, 0.8);
}

.bg-black-2-opacity-9 {
  background-color: rgba(43, 57, 64, 0.9);
}

.gr-color-black-2-opacity-visible {
  color: #2b3940;
}

.gr-color-black-2-opacity-1 {
  color: rgba(43, 57, 64, 0.1);
}

.gr-color-black-2-opacity-15 {
  color: rgba(43, 57, 64, 0.15);
}

.gr-color-black-2-opacity-2 {
  color: rgba(43, 57, 64, 0.2);
}

.gr-color-black-2-opacity-3 {
  color: rgba(43, 57, 64, 0.3);
}

.gr-color-black-2-opacity-4 {
  color: rgba(43, 57, 64, 0.4);
}

.gr-color-black-2-opacity-5 {
  color: rgba(43, 57, 64, 0.5);
}

.gr-color-black-2-opacity-6 {
  color: rgba(43, 57, 64, 0.6);
}

.gr-color-black-2-opacity-p6 {
  color: rgba(43, 57, 64, 0.06);
}

.gr-color-black-2-opacity-7 {
  color: rgba(43, 57, 64, 0.7);
}

.gr-color-black-2-opacity-8 {
  color: rgba(43, 57, 64, 0.8);
}

.gr-color-black-2-opacity-9 {
  color: rgba(43, 57, 64, 0.9);
}

.bg-gray-opacity-visible {
  background-color: #6b6e6f;
}

.bg-gray-opacity-1 {
  background-color: rgba(107, 110, 111, 0.1);
}

.bg-gray-opacity-15 {
  background-color: rgba(107, 110, 111, 0.15);
}

.bg-gray-opacity-2 {
  background-color: rgba(107, 110, 111, 0.2);
}

.bg-gray-opacity-3 {
  background-color: rgba(107, 110, 111, 0.3);
}

.bg-gray-opacity-4 {
  background-color: rgba(107, 110, 111, 0.4);
}

.bg-gray-opacity-5 {
  background-color: rgba(107, 110, 111, 0.5);
}

.bg-gray-opacity-6 {
  background-color: rgba(107, 110, 111, 0.6);
}

.bg-gray-opacity-p6 {
  background-color: rgba(107, 110, 111, 0.06);
}

.bg-gray-opacity-7 {
  background-color: rgba(107, 110, 111, 0.7);
}

.bg-gray-opacity-8 {
  background-color: rgba(107, 110, 111, 0.8);
}

.bg-gray-opacity-9 {
  background-color: rgba(107, 110, 111, 0.9);
}

.gr-color-gray-opacity-visible {
  color: #6b6e6f;
}

.gr-color-gray-opacity-1 {
  color: rgba(107, 110, 111, 0.1);
}

.gr-color-gray-opacity-15 {
  color: rgba(107, 110, 111, 0.15);
}

.gr-color-gray-opacity-2 {
  color: rgba(107, 110, 111, 0.2);
}

.gr-color-gray-opacity-3 {
  color: rgba(107, 110, 111, 0.3);
}

.gr-color-gray-opacity-4 {
  color: rgba(107, 110, 111, 0.4);
}

.gr-color-gray-opacity-5 {
  color: rgba(107, 110, 111, 0.5);
}

.gr-color-gray-opacity-6 {
  color: rgba(107, 110, 111, 0.6);
}

.gr-color-gray-opacity-p6 {
  color: rgba(107, 110, 111, 0.06);
}

.gr-color-gray-opacity-7 {
  color: rgba(107, 110, 111, 0.7);
}

.gr-color-gray-opacity-8 {
  color: rgba(107, 110, 111, 0.8);
}

.gr-color-gray-opacity-9 {
  color: rgba(107, 110, 111, 0.9);
}

.bg-smoke-opacity-visible {
  background-color: #6B6E6F;
}

.bg-smoke-opacity-1 {
  background-color: rgba(126, 137, 137, 0.1);
}

.bg-smoke-opacity-15 {
  background-color: rgba(126, 137, 137, 0.15);
}

.bg-smoke-opacity-2 {
  background-color: rgba(126, 137, 137, 0.2);
}

.bg-smoke-opacity-3 {
  background-color: rgba(126, 137, 137, 0.3);
}

.bg-smoke-opacity-4 {
  background-color: rgba(126, 137, 137, 0.4);
}

.bg-smoke-opacity-5 {
  background-color: rgba(126, 137, 137, 0.5);
}

.bg-smoke-opacity-6 {
  background-color: rgba(126, 137, 137, 0.6);
}

.bg-smoke-opacity-p6 {
  background-color: rgba(126, 137, 137, 0.06);
}

.bg-smoke-opacity-7 {
  background-color: rgba(126, 137, 137, 0.7);
}

.bg-smoke-opacity-8 {
  background-color: rgba(126, 137, 137, 0.8);
}

.bg-smoke-opacity-9 {
  background-color: rgba(126, 137, 137, 0.9);
}

.gr-color-smoke-opacity-visible {
  color: #6B6E6F;
}

.gr-color-smoke-opacity-1 {
  color: rgba(126, 137, 137, 0.1);
}

.gr-color-smoke-opacity-15 {
  color: rgba(126, 137, 137, 0.15);
}

.gr-color-smoke-opacity-2 {
  color: rgba(126, 137, 137, 0.2);
}

.gr-color-smoke-opacity-3 {
  color: rgba(126, 137, 137, 0.3);
}

.gr-color-smoke-opacity-4 {
  color: rgba(126, 137, 137, 0.4);
}

.gr-color-smoke-opacity-5 {
  color: rgba(126, 137, 137, 0.5);
}

.gr-color-smoke-opacity-6 {
  color: rgba(126, 137, 137, 0.6);
}

.gr-color-smoke-opacity-p6 {
  color: rgba(126, 137, 137, 0.06);
}

.gr-color-smoke-opacity-7 {
  color: rgba(126, 137, 137, 0.7);
}

.gr-color-smoke-opacity-8 {
  color: rgba(126, 137, 137, 0.8);
}

.gr-color-smoke-opacity-9 {
  color: rgba(126, 137, 137, 0.9);
}

.bg-pink-opacity-visible {
  background-color: #fc4980;
}

.bg-pink-opacity-1 {
  background-color: rgba(252, 73, 128, 0.1);
}

.bg-pink-opacity-15 {
  background-color: rgba(252, 73, 128, 0.15);
}

.bg-pink-opacity-2 {
  background-color: rgba(252, 73, 128, 0.2);
}

.bg-pink-opacity-3 {
  background-color: rgba(252, 73, 128, 0.3);
}

.bg-pink-opacity-4 {
  background-color: rgba(252, 73, 128, 0.4);
}

.bg-pink-opacity-5 {
  background-color: rgba(252, 73, 128, 0.5);
}

.bg-pink-opacity-6 {
  background-color: rgba(252, 73, 128, 0.6);
}

.bg-pink-opacity-p6 {
  background-color: rgba(252, 73, 128, 0.06);
}

.bg-pink-opacity-7 {
  background-color: rgba(252, 73, 128, 0.7);
}

.bg-pink-opacity-8 {
  background-color: rgba(252, 73, 128, 0.8);
}

.bg-pink-opacity-9 {
  background-color: rgba(252, 73, 128, 0.9);
}

.gr-color-pink-opacity-visible {
  color: #fc4980;
}

.gr-color-pink-opacity-1 {
  color: rgba(252, 73, 128, 0.1);
}

.gr-color-pink-opacity-15 {
  color: rgba(252, 73, 128, 0.15);
}

.gr-color-pink-opacity-2 {
  color: rgba(252, 73, 128, 0.2);
}

.gr-color-pink-opacity-3 {
  color: rgba(252, 73, 128, 0.3);
}

.gr-color-pink-opacity-4 {
  color: rgba(252, 73, 128, 0.4);
}

.gr-color-pink-opacity-5 {
  color: rgba(252, 73, 128, 0.5);
}

.gr-color-pink-opacity-6 {
  color: rgba(252, 73, 128, 0.6);
}

.gr-color-pink-opacity-p6 {
  color: rgba(252, 73, 128, 0.06);
}

.gr-color-pink-opacity-7 {
  color: rgba(252, 73, 128, 0.7);
}

.gr-color-pink-opacity-8 {
  color: rgba(252, 73, 128, 0.8);
}

.gr-color-pink-opacity-9 {
  color: rgba(252, 73, 128, 0.9);
}

.bg-violet-opacity-visible {
  background-color: #9c5fff;
}

.bg-violet-opacity-1 {
  background-color: rgba(156, 95, 255, 0.1);
}

.bg-violet-opacity-15 {
  background-color: rgba(156, 95, 255, 0.15);
}

.bg-violet-opacity-2 {
  background-color: rgba(156, 95, 255, 0.2);
}

.bg-violet-opacity-3 {
  background-color: rgba(156, 95, 255, 0.3);
}

.bg-violet-opacity-4 {
  background-color: rgba(156, 95, 255, 0.4);
}

.bg-violet-opacity-5 {
  background-color: rgba(156, 95, 255, 0.5);
}

.bg-violet-opacity-6 {
  background-color: rgba(156, 95, 255, 0.6);
}

.bg-violet-opacity-p6 {
  background-color: rgba(156, 95, 255, 0.06);
}

.bg-violet-opacity-7 {
  background-color: rgba(156, 95, 255, 0.7);
}

.bg-violet-opacity-8 {
  background-color: rgba(156, 95, 255, 0.8);
}

.bg-violet-opacity-9 {
  background-color: rgba(156, 95, 255, 0.9);
}

.gr-color-violet-opacity-visible {
  color: #9c5fff;
}

.gr-color-violet-opacity-1 {
  color: rgba(156, 95, 255, 0.1);
}

.gr-color-violet-opacity-15 {
  color: rgba(156, 95, 255, 0.15);
}

.gr-color-violet-opacity-2 {
  color: rgba(156, 95, 255, 0.2);
}

.gr-color-violet-opacity-3 {
  color: rgba(156, 95, 255, 0.3);
}

.gr-color-violet-opacity-4 {
  color: rgba(156, 95, 255, 0.4);
}

.gr-color-violet-opacity-5 {
  color: rgba(156, 95, 255, 0.5);
}

.gr-color-violet-opacity-6 {
  color: rgba(156, 95, 255, 0.6);
}

.gr-color-violet-opacity-p6 {
  color: rgba(156, 95, 255, 0.06);
}

.gr-color-violet-opacity-7 {
  color: rgba(156, 95, 255, 0.7);
}

.gr-color-violet-opacity-8 {
  color: rgba(156, 95, 255, 0.8);
}

.gr-color-violet-opacity-9 {
  color: rgba(156, 95, 255, 0.9);
}

.bg-mercury-opacity-visible {
  background-color: #e5e5e5;
}

.bg-mercury-opacity-1 {
  background-color: rgba(229, 229, 229, 0.1);
}

.bg-mercury-opacity-15 {
  background-color: rgba(229, 229, 229, 0.15);
}

.bg-mercury-opacity-2 {
  background-color: rgba(229, 229, 229, 0.2);
}

.bg-mercury-opacity-3 {
  background-color: rgba(229, 229, 229, 0.3);
}

.bg-mercury-opacity-4 {
  background-color: rgba(229, 229, 229, 0.4);
}

.bg-mercury-opacity-5 {
  background-color: rgba(229, 229, 229, 0.5);
}

.bg-mercury-opacity-6 {
  background-color: rgba(229, 229, 229, 0.6);
}

.bg-mercury-opacity-p6 {
  background-color: rgba(229, 229, 229, 0.06);
}

.bg-mercury-opacity-7 {
  background-color: rgba(229, 229, 229, 0.7);
}

.bg-mercury-opacity-8 {
  background-color: rgba(229, 229, 229, 0.8);
}

.bg-mercury-opacity-9 {
  background-color: rgba(229, 229, 229, 0.9);
}

.gr-color-mercury-opacity-visible {
  color: #e5e5e5;
}

.gr-color-mercury-opacity-1 {
  color: rgba(229, 229, 229, 0.1);
}

.gr-color-mercury-opacity-15 {
  color: rgba(229, 229, 229, 0.15);
}

.gr-color-mercury-opacity-2 {
  color: rgba(229, 229, 229, 0.2);
}

.gr-color-mercury-opacity-3 {
  color: rgba(229, 229, 229, 0.3);
}

.gr-color-mercury-opacity-4 {
  color: rgba(229, 229, 229, 0.4);
}

.gr-color-mercury-opacity-5 {
  color: rgba(229, 229, 229, 0.5);
}

.gr-color-mercury-opacity-6 {
  color: rgba(229, 229, 229, 0.6);
}

.gr-color-mercury-opacity-p6 {
  color: rgba(229, 229, 229, 0.06);
}

.gr-color-mercury-opacity-7 {
  color: rgba(229, 229, 229, 0.7);
}

.gr-color-mercury-opacity-8 {
  color: rgba(229, 229, 229, 0.8);
}

.gr-color-mercury-opacity-9 {
  color: rgba(229, 229, 229, 0.9);
}

.bg-ebony-clay-opacity-visible {
  background-color: #1d292e;
}

.bg-ebony-clay-opacity-1 {
  background-color: rgba(29, 41, 46, 0.1);
}

.bg-ebony-clay-opacity-15 {
  background-color: rgba(29, 41, 46, 0.15);
}

.bg-ebony-clay-opacity-2 {
  background-color: rgba(29, 41, 46, 0.2);
}

.bg-ebony-clay-opacity-3 {
  background-color: rgba(29, 41, 46, 0.3);
}

.bg-ebony-clay-opacity-4 {
  background-color: rgba(29, 41, 46, 0.4);
}

.bg-ebony-clay-opacity-5 {
  background-color: rgba(29, 41, 46, 0.5);
}

.bg-ebony-clay-opacity-6 {
  background-color: rgba(29, 41, 46, 0.6);
}

.bg-ebony-clay-opacity-p6 {
  background-color: rgba(29, 41, 46, 0.06);
}

.bg-ebony-clay-opacity-7 {
  background-color: rgba(29, 41, 46, 0.7);
}

.bg-ebony-clay-opacity-8 {
  background-color: rgba(29, 41, 46, 0.8);
}

.bg-ebony-clay-opacity-9 {
  background-color: rgba(29, 41, 46, 0.9);
}

.gr-color-ebony-clay-opacity-visible {
  color: #1d292e;
}

.gr-color-ebony-clay-opacity-1 {
  color: rgba(29, 41, 46, 0.1);
}

.gr-color-ebony-clay-opacity-15 {
  color: rgba(29, 41, 46, 0.15);
}

.gr-color-ebony-clay-opacity-2 {
  color: rgba(29, 41, 46, 0.2);
}

.gr-color-ebony-clay-opacity-3 {
  color: rgba(29, 41, 46, 0.3);
}

.gr-color-ebony-clay-opacity-4 {
  color: rgba(29, 41, 46, 0.4);
}

.gr-color-ebony-clay-opacity-5 {
  color: rgba(29, 41, 46, 0.5);
}

.gr-color-ebony-clay-opacity-6 {
  color: rgba(29, 41, 46, 0.6);
}

.gr-color-ebony-clay-opacity-p6 {
  color: rgba(29, 41, 46, 0.06);
}

.gr-color-ebony-clay-opacity-7 {
  color: rgba(29, 41, 46, 0.7);
}

.gr-color-ebony-clay-opacity-8 {
  color: rgba(29, 41, 46, 0.8);
}

.gr-color-ebony-clay-opacity-9 {
  color: rgba(29, 41, 46, 0.9);
}

.bg-hit-gray-opacity-visible {
  background-color: #adb4b7;
}

.bg-hit-gray-opacity-1 {
  background-color: rgba(173, 180, 183, 0.1);
}

.bg-hit-gray-opacity-15 {
  background-color: rgba(173, 180, 183, 0.15);
}

.bg-hit-gray-opacity-2 {
  background-color: rgba(173, 180, 183, 0.2);
}

.bg-hit-gray-opacity-3 {
  background-color: rgba(173, 180, 183, 0.3);
}

.bg-hit-gray-opacity-4 {
  background-color: rgba(173, 180, 183, 0.4);
}

.bg-hit-gray-opacity-5 {
  background-color: rgba(173, 180, 183, 0.5);
}

.bg-hit-gray-opacity-6 {
  background-color: rgba(173, 180, 183, 0.6);
}

.bg-hit-gray-opacity-p6 {
  background-color: rgba(173, 180, 183, 0.06);
}

.bg-hit-gray-opacity-7 {
  background-color: rgba(173, 180, 183, 0.7);
}

.bg-hit-gray-opacity-8 {
  background-color: rgba(173, 180, 183, 0.8);
}

.bg-hit-gray-opacity-9 {
  background-color: rgba(173, 180, 183, 0.9);
}

.gr-color-hit-gray-opacity-visible {
  color: #adb4b7;
}

.gr-color-hit-gray-opacity-1 {
  color: rgba(173, 180, 183, 0.1);
}

.gr-color-hit-gray-opacity-15 {
  color: rgba(173, 180, 183, 0.15);
}

.gr-color-hit-gray-opacity-2 {
  color: rgba(173, 180, 183, 0.2);
}

.gr-color-hit-gray-opacity-3 {
  color: rgba(173, 180, 183, 0.3);
}

.gr-color-hit-gray-opacity-4 {
  color: rgba(173, 180, 183, 0.4);
}

.gr-color-hit-gray-opacity-5 {
  color: rgba(173, 180, 183, 0.5);
}

.gr-color-hit-gray-opacity-6 {
  color: rgba(173, 180, 183, 0.6);
}

.gr-color-hit-gray-opacity-p6 {
  color: rgba(173, 180, 183, 0.06);
}

.gr-color-hit-gray-opacity-7 {
  color: rgba(173, 180, 183, 0.7);
}

.gr-color-hit-gray-opacity-8 {
  color: rgba(173, 180, 183, 0.8);
}

.gr-color-hit-gray-opacity-9 {
  color: rgba(173, 180, 183, 0.9);
}

.bg-gallery-opacity-visible {
  background-color: #f0f0f0;
}

.bg-gallery-opacity-1 {
  background-color: rgba(240, 240, 240, 0.1);
}

.bg-gallery-opacity-15 {
  background-color: rgba(240, 240, 240, 0.15);
}

.bg-gallery-opacity-2 {
  background-color: rgba(240, 240, 240, 0.2);
}

.bg-gallery-opacity-3 {
  background-color: rgba(240, 240, 240, 0.3);
}

.bg-gallery-opacity-4 {
  background-color: rgba(240, 240, 240, 0.4);
}

.bg-gallery-opacity-5 {
  background-color: rgba(240, 240, 240, 0.5);
}

.bg-gallery-opacity-6 {
  background-color: rgba(240, 240, 240, 0.6);
}

.bg-gallery-opacity-p6 {
  background-color: rgba(240, 240, 240, 0.06);
}

.bg-gallery-opacity-7 {
  background-color: rgba(240, 240, 240, 0.7);
}

.bg-gallery-opacity-8 {
  background-color: rgba(240, 240, 240, 0.8);
}

.bg-gallery-opacity-9 {
  background-color: rgba(240, 240, 240, 0.9);
}

.gr-color-gallery-opacity-visible {
  color: #f0f0f0;
}

.gr-color-gallery-opacity-1 {
  color: rgba(240, 240, 240, 0.1);
}

.gr-color-gallery-opacity-15 {
  color: rgba(240, 240, 240, 0.15);
}

.gr-color-gallery-opacity-2 {
  color: rgba(240, 240, 240, 0.2);
}

.gr-color-gallery-opacity-3 {
  color: rgba(240, 240, 240, 0.3);
}

.gr-color-gallery-opacity-4 {
  color: rgba(240, 240, 240, 0.4);
}

.gr-color-gallery-opacity-5 {
  color: rgba(240, 240, 240, 0.5);
}

.gr-color-gallery-opacity-6 {
  color: rgba(240, 240, 240, 0.6);
}

.gr-color-gallery-opacity-p6 {
  color: rgba(240, 240, 240, 0.06);
}

.gr-color-gallery-opacity-7 {
  color: rgba(240, 240, 240, 0.7);
}

.gr-color-gallery-opacity-8 {
  color: rgba(240, 240, 240, 0.8);
}

.gr-color-gallery-opacity-9 {
  color: rgba(240, 240, 240, 0.9);
}

.bg-squeeze-opacity-visible {
  background-color: #edf8f5;
}

.bg-squeeze-opacity-1 {
  background-color: rgba(237, 248, 245, 0.1);
}

.bg-squeeze-opacity-15 {
  background-color: rgba(237, 248, 245, 0.15);
}

.bg-squeeze-opacity-2 {
  background-color: rgba(237, 248, 245, 0.2);
}

.bg-squeeze-opacity-3 {
  background-color: rgba(237, 248, 245, 0.3);
}

.bg-squeeze-opacity-4 {
  background-color: rgba(237, 248, 245, 0.4);
}

.bg-squeeze-opacity-5 {
  background-color: rgba(237, 248, 245, 0.5);
}

.bg-squeeze-opacity-6 {
  background-color: rgba(237, 248, 245, 0.6);
}

.bg-squeeze-opacity-p6 {
  background-color: rgba(237, 248, 245, 0.06);
}

.bg-squeeze-opacity-7 {
  background-color: rgba(237, 248, 245, 0.7);
}

.bg-squeeze-opacity-8 {
  background-color: rgba(237, 248, 245, 0.8);
}

.bg-squeeze-opacity-9 {
  background-color: rgba(237, 248, 245, 0.9);
}

.gr-color-squeeze-opacity-visible {
  color: #edf8f5;
}

.gr-color-squeeze-opacity-1 {
  color: rgba(237, 248, 245, 0.1);
}

.gr-color-squeeze-opacity-15 {
  color: rgba(237, 248, 245, 0.15);
}

.gr-color-squeeze-opacity-2 {
  color: rgba(237, 248, 245, 0.2);
}

.gr-color-squeeze-opacity-3 {
  color: rgba(237, 248, 245, 0.3);
}

.gr-color-squeeze-opacity-4 {
  color: rgba(237, 248, 245, 0.4);
}

.gr-color-squeeze-opacity-5 {
  color: rgba(237, 248, 245, 0.5);
}

.gr-color-squeeze-opacity-6 {
  color: rgba(237, 248, 245, 0.6);
}

.gr-color-squeeze-opacity-p6 {
  color: rgba(237, 248, 245, 0.06);
}

.gr-color-squeeze-opacity-7 {
  color: rgba(237, 248, 245, 0.7);
}

.gr-color-squeeze-opacity-8 {
  color: rgba(237, 248, 245, 0.8);
}

.gr-color-squeeze-opacity-9 {
  color: rgba(237, 248, 245, 0.9);
}

.bg-helio-opacity-visible {
  background-color: #9c5fff;
}

.bg-helio-opacity-1 {
  background-color: rgba(156, 95, 255, 0.1);
}

.bg-helio-opacity-15 {
  background-color: rgba(156, 95, 255, 0.15);
}

.bg-helio-opacity-2 {
  background-color: rgba(156, 95, 255, 0.2);
}

.bg-helio-opacity-3 {
  background-color: rgba(156, 95, 255, 0.3);
}

.bg-helio-opacity-4 {
  background-color: rgba(156, 95, 255, 0.4);
}

.bg-helio-opacity-5 {
  background-color: rgba(156, 95, 255, 0.5);
}

.bg-helio-opacity-6 {
  background-color: rgba(156, 95, 255, 0.6);
}

.bg-helio-opacity-p6 {
  background-color: rgba(156, 95, 255, 0.06);
}

.bg-helio-opacity-7 {
  background-color: rgba(156, 95, 255, 0.7);
}

.bg-helio-opacity-8 {
  background-color: rgba(156, 95, 255, 0.8);
}

.bg-helio-opacity-9 {
  background-color: rgba(156, 95, 255, 0.9);
}

.gr-color-helio-opacity-visible {
  color: #9c5fff;
}

.gr-color-helio-opacity-1 {
  color: rgba(156, 95, 255, 0.1);
}

.gr-color-helio-opacity-15 {
  color: rgba(156, 95, 255, 0.15);
}

.gr-color-helio-opacity-2 {
  color: rgba(156, 95, 255, 0.2);
}

.gr-color-helio-opacity-3 {
  color: rgba(156, 95, 255, 0.3);
}

.gr-color-helio-opacity-4 {
  color: rgba(156, 95, 255, 0.4);
}

.gr-color-helio-opacity-5 {
  color: rgba(156, 95, 255, 0.5);
}

.gr-color-helio-opacity-6 {
  color: rgba(156, 95, 255, 0.6);
}

.gr-color-helio-opacity-p6 {
  color: rgba(156, 95, 255, 0.06);
}

.gr-color-helio-opacity-7 {
  color: rgba(156, 95, 255, 0.7);
}

.gr-color-helio-opacity-8 {
  color: rgba(156, 95, 255, 0.8);
}

.gr-color-helio-opacity-9 {
  color: rgba(156, 95, 255, 0.9);
}

.bg-athens-opacity-visible {
  background-color: #f4f5f8;
}

.bg-athens-opacity-1 {
  background-color: rgba(244, 245, 248, 0.1);
}

.bg-athens-opacity-15 {
  background-color: rgba(244, 245, 248, 0.15);
}

.bg-athens-opacity-2 {
  background-color: rgba(244, 245, 248, 0.2);
}

.bg-athens-opacity-3 {
  background-color: rgba(244, 245, 248, 0.3);
}

.bg-athens-opacity-4 {
  background-color: rgba(244, 245, 248, 0.4);
}

.bg-athens-opacity-5 {
  background-color: rgba(244, 245, 248, 0.5);
}

.bg-athens-opacity-6 {
  background-color: rgba(244, 245, 248, 0.6);
}

.bg-athens-opacity-p6 {
  background-color: rgba(244, 245, 248, 0.06);
}

.bg-athens-opacity-7 {
  background-color: rgba(244, 245, 248, 0.7);
}

.bg-athens-opacity-8 {
  background-color: rgba(244, 245, 248, 0.8);
}

.bg-athens-opacity-9 {
  background-color: rgba(244, 245, 248, 0.9);
}

.gr-color-athens-opacity-visible {
  color: #f4f5f8;
}

.gr-color-athens-opacity-1 {
  color: rgba(244, 245, 248, 0.1);
}

.gr-color-athens-opacity-15 {
  color: rgba(244, 245, 248, 0.15);
}

.gr-color-athens-opacity-2 {
  color: rgba(244, 245, 248, 0.2);
}

.gr-color-athens-opacity-3 {
  color: rgba(244, 245, 248, 0.3);
}

.gr-color-athens-opacity-4 {
  color: rgba(244, 245, 248, 0.4);
}

.gr-color-athens-opacity-5 {
  color: rgba(244, 245, 248, 0.5);
}

.gr-color-athens-opacity-6 {
  color: rgba(244, 245, 248, 0.6);
}

.gr-color-athens-opacity-p6 {
  color: rgba(244, 245, 248, 0.06);
}

.gr-color-athens-opacity-7 {
  color: rgba(244, 245, 248, 0.7);
}

.gr-color-athens-opacity-8 {
  color: rgba(244, 245, 248, 0.8);
}

.gr-color-athens-opacity-9 {
  color: rgba(244, 245, 248, 0.9);
}

.bg-concrete-opacity-visible {
  background-color: #f2f2f2;
}

.bg-concrete-opacity-1 {
  background-color: rgba(242, 242, 242, 0.1);
}

.bg-concrete-opacity-15 {
  background-color: rgba(242, 242, 242, 0.15);
}

.bg-concrete-opacity-2 {
  background-color: rgba(242, 242, 242, 0.2);
}

.bg-concrete-opacity-3 {
  background-color: rgba(242, 242, 242, 0.3);
}

.bg-concrete-opacity-4 {
  background-color: rgba(242, 242, 242, 0.4);
}

.bg-concrete-opacity-5 {
  background-color: rgba(242, 242, 242, 0.5);
}

.bg-concrete-opacity-6 {
  background-color: rgba(242, 242, 242, 0.6);
}

.bg-concrete-opacity-p6 {
  background-color: rgba(242, 242, 242, 0.06);
}

.bg-concrete-opacity-7 {
  background-color: rgba(242, 242, 242, 0.7);
}

.bg-concrete-opacity-8 {
  background-color: rgba(242, 242, 242, 0.8);
}

.bg-concrete-opacity-9 {
  background-color: rgba(242, 242, 242, 0.9);
}

.gr-color-concrete-opacity-visible {
  color: #f2f2f2;
}

.gr-color-concrete-opacity-1 {
  color: rgba(242, 242, 242, 0.1);
}

.gr-color-concrete-opacity-15 {
  color: rgba(242, 242, 242, 0.15);
}

.gr-color-concrete-opacity-2 {
  color: rgba(242, 242, 242, 0.2);
}

.gr-color-concrete-opacity-3 {
  color: rgba(242, 242, 242, 0.3);
}

.gr-color-concrete-opacity-4 {
  color: rgba(242, 242, 242, 0.4);
}

.gr-color-concrete-opacity-5 {
  color: rgba(242, 242, 242, 0.5);
}

.gr-color-concrete-opacity-6 {
  color: rgba(242, 242, 242, 0.6);
}

.gr-color-concrete-opacity-p6 {
  color: rgba(242, 242, 242, 0.06);
}

.gr-color-concrete-opacity-7 {
  color: rgba(242, 242, 242, 0.7);
}

.gr-color-concrete-opacity-8 {
  color: rgba(242, 242, 242, 0.8);
}

.gr-color-concrete-opacity-9 {
  color: rgba(242, 242, 242, 0.9);
}

.bg-allports-opacity-visible {
  background-color: #0070ac;
}

.bg-allports-opacity-1 {
  background-color: rgba(0, 112, 172, 0.1);
}

.bg-allports-opacity-15 {
  background-color: rgba(0, 112, 172, 0.15);
}

.bg-allports-opacity-2 {
  background-color: rgba(0, 112, 172, 0.2);
}

.bg-allports-opacity-3 {
  background-color: rgba(0, 112, 172, 0.3);
}

.bg-allports-opacity-4 {
  background-color: rgba(0, 112, 172, 0.4);
}

.bg-allports-opacity-5 {
  background-color: rgba(0, 112, 172, 0.5);
}

.bg-allports-opacity-6 {
  background-color: rgba(0, 112, 172, 0.6);
}

.bg-allports-opacity-p6 {
  background-color: rgba(0, 112, 172, 0.06);
}

.bg-allports-opacity-7 {
  background-color: rgba(0, 112, 172, 0.7);
}

.bg-allports-opacity-8 {
  background-color: rgba(0, 112, 172, 0.8);
}

.bg-allports-opacity-9 {
  background-color: rgba(0, 112, 172, 0.9);
}

.gr-color-allports-opacity-visible {
  color: #0070ac;
}

.gr-color-allports-opacity-1 {
  color: rgba(0, 112, 172, 0.1);
}

.gr-color-allports-opacity-15 {
  color: rgba(0, 112, 172, 0.15);
}

.gr-color-allports-opacity-2 {
  color: rgba(0, 112, 172, 0.2);
}

.gr-color-allports-opacity-3 {
  color: rgba(0, 112, 172, 0.3);
}

.gr-color-allports-opacity-4 {
  color: rgba(0, 112, 172, 0.4);
}

.gr-color-allports-opacity-5 {
  color: rgba(0, 112, 172, 0.5);
}

.gr-color-allports-opacity-6 {
  color: rgba(0, 112, 172, 0.6);
}

.gr-color-allports-opacity-p6 {
  color: rgba(0, 112, 172, 0.06);
}

.gr-color-allports-opacity-7 {
  color: rgba(0, 112, 172, 0.7);
}

.gr-color-allports-opacity-8 {
  color: rgba(0, 112, 172, 0.8);
}

.gr-color-allports-opacity-9 {
  color: rgba(0, 112, 172, 0.9);
}

.bg-marino-opacity-visible {
  background-color: #4064ad;
}

.bg-marino-opacity-1 {
  background-color: rgba(64, 100, 173, 0.1);
}

.bg-marino-opacity-15 {
  background-color: rgba(64, 100, 173, 0.15);
}

.bg-marino-opacity-2 {
  background-color: rgba(64, 100, 173, 0.2);
}

.bg-marino-opacity-3 {
  background-color: rgba(64, 100, 173, 0.3);
}

.bg-marino-opacity-4 {
  background-color: rgba(64, 100, 173, 0.4);
}

.bg-marino-opacity-5 {
  background-color: rgba(64, 100, 173, 0.5);
}

.bg-marino-opacity-6 {
  background-color: rgba(64, 100, 173, 0.6);
}

.bg-marino-opacity-p6 {
  background-color: rgba(64, 100, 173, 0.06);
}

.bg-marino-opacity-7 {
  background-color: rgba(64, 100, 173, 0.7);
}

.bg-marino-opacity-8 {
  background-color: rgba(64, 100, 173, 0.8);
}

.bg-marino-opacity-9 {
  background-color: rgba(64, 100, 173, 0.9);
}

.gr-color-marino-opacity-visible {
  color: #4064ad;
}

.gr-color-marino-opacity-1 {
  color: rgba(64, 100, 173, 0.1);
}

.gr-color-marino-opacity-15 {
  color: rgba(64, 100, 173, 0.15);
}

.gr-color-marino-opacity-2 {
  color: rgba(64, 100, 173, 0.2);
}

.gr-color-marino-opacity-3 {
  color: rgba(64, 100, 173, 0.3);
}

.gr-color-marino-opacity-4 {
  color: rgba(64, 100, 173, 0.4);
}

.gr-color-marino-opacity-5 {
  color: rgba(64, 100, 173, 0.5);
}

.gr-color-marino-opacity-6 {
  color: rgba(64, 100, 173, 0.6);
}

.gr-color-marino-opacity-p6 {
  color: rgba(64, 100, 173, 0.06);
}

.gr-color-marino-opacity-7 {
  color: rgba(64, 100, 173, 0.7);
}

.gr-color-marino-opacity-8 {
  color: rgba(64, 100, 173, 0.8);
}

.gr-color-marino-opacity-9 {
  color: rgba(64, 100, 173, 0.9);
}

.bg-poppy-opacity-visible {
  background-color: #be392b;
}

.bg-poppy-opacity-1 {
  background-color: rgba(190, 57, 43, 0.1);
}

.bg-poppy-opacity-15 {
  background-color: rgba(190, 57, 43, 0.15);
}

.bg-poppy-opacity-2 {
  background-color: rgba(190, 57, 43, 0.2);
}

.bg-poppy-opacity-3 {
  background-color: rgba(190, 57, 43, 0.3);
}

.bg-poppy-opacity-4 {
  background-color: rgba(190, 57, 43, 0.4);
}

.bg-poppy-opacity-5 {
  background-color: rgba(190, 57, 43, 0.5);
}

.bg-poppy-opacity-6 {
  background-color: rgba(190, 57, 43, 0.6);
}

.bg-poppy-opacity-p6 {
  background-color: rgba(190, 57, 43, 0.06);
}

.bg-poppy-opacity-7 {
  background-color: rgba(190, 57, 43, 0.7);
}

.bg-poppy-opacity-8 {
  background-color: rgba(190, 57, 43, 0.8);
}

.bg-poppy-opacity-9 {
  background-color: rgba(190, 57, 43, 0.9);
}

.gr-color-poppy-opacity-visible {
  color: #be392b;
}

.gr-color-poppy-opacity-1 {
  color: rgba(190, 57, 43, 0.1);
}

.gr-color-poppy-opacity-15 {
  color: rgba(190, 57, 43, 0.15);
}

.gr-color-poppy-opacity-2 {
  color: rgba(190, 57, 43, 0.2);
}

.gr-color-poppy-opacity-3 {
  color: rgba(190, 57, 43, 0.3);
}

.gr-color-poppy-opacity-4 {
  color: rgba(190, 57, 43, 0.4);
}

.gr-color-poppy-opacity-5 {
  color: rgba(190, 57, 43, 0.5);
}

.gr-color-poppy-opacity-6 {
  color: rgba(190, 57, 43, 0.6);
}

.gr-color-poppy-opacity-p6 {
  color: rgba(190, 57, 43, 0.06);
}

.gr-color-poppy-opacity-7 {
  color: rgba(190, 57, 43, 0.7);
}

.gr-color-poppy-opacity-8 {
  color: rgba(190, 57, 43, 0.8);
}

.gr-color-poppy-opacity-9 {
  color: rgba(190, 57, 43, 0.9);
}

.bg-polar-opacity-visible {
  background-color: #f3f9fc;
}

.bg-polar-opacity-1 {
  background-color: rgba(243, 249, 252, 0.1);
}

.bg-polar-opacity-15 {
  background-color: rgba(243, 249, 252, 0.15);
}

.bg-polar-opacity-2 {
  background-color: rgba(243, 249, 252, 0.2);
}

.bg-polar-opacity-3 {
  background-color: rgba(243, 249, 252, 0.3);
}

.bg-polar-opacity-4 {
  background-color: rgba(243, 249, 252, 0.4);
}

.bg-polar-opacity-5 {
  background-color: rgba(243, 249, 252, 0.5);
}

.bg-polar-opacity-6 {
  background-color: rgba(243, 249, 252, 0.6);
}

.bg-polar-opacity-p6 {
  background-color: rgba(243, 249, 252, 0.06);
}

.bg-polar-opacity-7 {
  background-color: rgba(243, 249, 252, 0.7);
}

.bg-polar-opacity-8 {
  background-color: rgba(243, 249, 252, 0.8);
}

.bg-polar-opacity-9 {
  background-color: rgba(243, 249, 252, 0.9);
}

.gr-color-polar-opacity-visible {
  color: #f3f9fc;
}

.gr-color-polar-opacity-1 {
  color: rgba(243, 249, 252, 0.1);
}

.gr-color-polar-opacity-15 {
  color: rgba(243, 249, 252, 0.15);
}

.gr-color-polar-opacity-2 {
  color: rgba(243, 249, 252, 0.2);
}

.gr-color-polar-opacity-3 {
  color: rgba(243, 249, 252, 0.3);
}

.gr-color-polar-opacity-4 {
  color: rgba(243, 249, 252, 0.4);
}

.gr-color-polar-opacity-5 {
  color: rgba(243, 249, 252, 0.5);
}

.gr-color-polar-opacity-6 {
  color: rgba(243, 249, 252, 0.6);
}

.gr-color-polar-opacity-p6 {
  color: rgba(243, 249, 252, 0.06);
}

.gr-color-polar-opacity-7 {
  color: rgba(243, 249, 252, 0.7);
}

.gr-color-polar-opacity-8 {
  color: rgba(243, 249, 252, 0.8);
}

.gr-color-polar-opacity-9 {
  color: rgba(243, 249, 252, 0.9);
}

.bg-egg-blue-opacity-visible {
  background-color: #02bfd5;
}

.bg-egg-blue-opacity-1 {
  background-color: rgba(2, 191, 213, 0.1);
}

.bg-egg-blue-opacity-15 {
  background-color: rgba(2, 191, 213, 0.15);
}

.bg-egg-blue-opacity-2 {
  background-color: rgba(2, 191, 213, 0.2);
}

.bg-egg-blue-opacity-3 {
  background-color: rgba(2, 191, 213, 0.3);
}

.bg-egg-blue-opacity-4 {
  background-color: rgba(2, 191, 213, 0.4);
}

.bg-egg-blue-opacity-5 {
  background-color: rgba(2, 191, 213, 0.5);
}

.bg-egg-blue-opacity-6 {
  background-color: rgba(2, 191, 213, 0.6);
}

.bg-egg-blue-opacity-p6 {
  background-color: rgba(2, 191, 213, 0.06);
}

.bg-egg-blue-opacity-7 {
  background-color: rgba(2, 191, 213, 0.7);
}

.bg-egg-blue-opacity-8 {
  background-color: rgba(2, 191, 213, 0.8);
}

.bg-egg-blue-opacity-9 {
  background-color: rgba(2, 191, 213, 0.9);
}

.gr-color-egg-blue-opacity-visible {
  color: #02bfd5;
}

.gr-color-egg-blue-opacity-1 {
  color: rgba(2, 191, 213, 0.1);
}

.gr-color-egg-blue-opacity-15 {
  color: rgba(2, 191, 213, 0.15);
}

.gr-color-egg-blue-opacity-2 {
  color: rgba(2, 191, 213, 0.2);
}

.gr-color-egg-blue-opacity-3 {
  color: rgba(2, 191, 213, 0.3);
}

.gr-color-egg-blue-opacity-4 {
  color: rgba(2, 191, 213, 0.4);
}

.gr-color-egg-blue-opacity-5 {
  color: rgba(2, 191, 213, 0.5);
}

.gr-color-egg-blue-opacity-6 {
  color: rgba(2, 191, 213, 0.6);
}

.gr-color-egg-blue-opacity-p6 {
  color: rgba(2, 191, 213, 0.06);
}

.gr-color-egg-blue-opacity-7 {
  color: rgba(2, 191, 213, 0.7);
}

.gr-color-egg-blue-opacity-8 {
  color: rgba(2, 191, 213, 0.8);
}

.gr-color-egg-blue-opacity-9 {
  color: rgba(2, 191, 213, 0.9);
}

.bg-conch-opacity-visible {
  background-color: #d2dcd6;
}

.bg-conch-opacity-1 {
  background-color: rgba(210, 220, 214, 0.1);
}

.bg-conch-opacity-15 {
  background-color: rgba(210, 220, 214, 0.15);
}

.bg-conch-opacity-2 {
  background-color: rgba(210, 220, 214, 0.2);
}

.bg-conch-opacity-3 {
  background-color: rgba(210, 220, 214, 0.3);
}

.bg-conch-opacity-4 {
  background-color: rgba(210, 220, 214, 0.4);
}

.bg-conch-opacity-5 {
  background-color: rgba(210, 220, 214, 0.5);
}

.bg-conch-opacity-6 {
  background-color: rgba(210, 220, 214, 0.6);
}

.bg-conch-opacity-p6 {
  background-color: rgba(210, 220, 214, 0.06);
}

.bg-conch-opacity-7 {
  background-color: rgba(210, 220, 214, 0.7);
}

.bg-conch-opacity-8 {
  background-color: rgba(210, 220, 214, 0.8);
}

.bg-conch-opacity-9 {
  background-color: rgba(210, 220, 214, 0.9);
}

.gr-color-conch-opacity-visible {
  color: #d2dcd6;
}

.gr-color-conch-opacity-1 {
  color: rgba(210, 220, 214, 0.1);
}

.gr-color-conch-opacity-15 {
  color: rgba(210, 220, 214, 0.15);
}

.gr-color-conch-opacity-2 {
  color: rgba(210, 220, 214, 0.2);
}

.gr-color-conch-opacity-3 {
  color: rgba(210, 220, 214, 0.3);
}

.gr-color-conch-opacity-4 {
  color: rgba(210, 220, 214, 0.4);
}

.gr-color-conch-opacity-5 {
  color: rgba(210, 220, 214, 0.5);
}

.gr-color-conch-opacity-6 {
  color: rgba(210, 220, 214, 0.6);
}

.gr-color-conch-opacity-p6 {
  color: rgba(210, 220, 214, 0.06);
}

.gr-color-conch-opacity-7 {
  color: rgba(210, 220, 214, 0.7);
}

.gr-color-conch-opacity-8 {
  color: rgba(210, 220, 214, 0.8);
}

.gr-color-conch-opacity-9 {
  color: rgba(210, 220, 214, 0.9);
}

.gr-opacity-visible {
  opacity: 1;
}

.gr-opacity-1 {
  opacity: 0.1;
}

.gr-opacity-15 {
  opacity: 0.15;
}

.gr-opacity-2 {
  opacity: 0.2;
}

.gr-opacity-3 {
  opacity: 0.3;
}

.gr-opacity-4 {
  opacity: 0.4;
}

.gr-opacity-5 {
  opacity: 0.5;
}

.gr-opacity-6 {
  opacity: 0.6;
}

.gr-opacity-p6 {
  opacity: 0.06;
}

.gr-opacity-7 {
  opacity: 0.7;
}

.gr-opacity-8 {
  opacity: 0.8;
}

.gr-opacity-9 {
  opacity: 0.9;
}

.gr-fill-color {
  fill: var(--color-texts) !important;
}

.bg-default {
  background: var(--bg);
}

.bg-default-1 {
  background: var(--bg-1);
}

.bg-default-2 {
  background: var(--bg-2);
}

.bg-default-3 {
  background: var(--bg-3);
}

.bg-default-4 {
  background: var(--bg-4);
}

.bg-default-5 {
  background: var(--bg-5);
}

.bg-default-6 {
  background: var(--bg-6);
}

.gr-fill-default-4 {
  fill: var(--bg-4);
}

.bg-opposite {
  background: var(--bg-opposite);
}

.border-color-2 {
  border-color: var(--border-color-2);
}

/* ---------------------------
  02.Utility -> { Flex } 
------------------------------*/
.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.flex-x-center {
  display: flex;
  justify-content: center;
}

/* ---------------------------
  02.Utility -> { Grid } 
------------------------------*/
@media (min-width: 576px) {
  .row-sm {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 992px) {
  .row-lg {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 768px) {
  .row-md {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1200px) {
  .row-xl {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

/* ---------------------------
  02.Utility -> { Shadows } 
------------------------------*/
/*~~~~~ Normal Shadows ~~~~~*/
.shadow-1 {
  box-shadow: 20px 20px 40px rgba(175, 175, 175, 0.16);
}

.shadow-2 {
  box-shadow: 17px 26px 99px rgba(114, 114, 114, 0.16);
}

.shadow-4 {
  box-shadow: 16px 41px 89px rgba(129, 129, 129, 0.16);
}

.shadow-5 {
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.08);
}

.shadow-6 {
  box-shadow: 0 13px 16px rgba(0, 0, 0, 0.08);
}

.shadow-7 {
  box-shadow: 0 29px 75px rgba(156, 156, 156, 0.16);
}

.shadow-8 {
  box-shadow: 2px 2px 4px rgba(130, 130, 130, 0.16);
}

.shadow-9 {
  box-shadow: 0 10px 35px rgba(178, 178, 178, 0.16);
}

.shadow-10 {
  box-shadow: 5px 5px 10px rgba(0, 176, 116, 0.16);
}

.shadow-blue {
  box-shadow: 15px 15px 30px rgba(72, 133, 250, 0.2);
}

.shadow-green {
  box-shadow: 15px 15px 30px rgba(38, 193, 149, 0.2);
}

.shadow-casablanca {
  box-shadow: 15px 15px 30px rgba(248, 197, 70, 0.2);
}

/* ---------------------------
  02.Utility -> { Size } 
------------------------------*/
/*~~~~~ Circle Sizes ~~~~~*/
.circle-9 {
  max-width: 9px;
  min-width: 9px;
  max-height: 9px;
  min-height: 9px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-24 {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-20 {
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-36 {
  max-width: 36px;
  min-width: 36px;
  max-height: 36px;
  min-height: 36px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-30 {
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-32 {
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-40 {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-41 {
  max-width: 41px;
  min-width: 41px;
  max-height: 41px;
  min-height: 41px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-48 {
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-54 {
  max-width: 54px;
  min-width: 54px;
  max-height: 54px;
  min-height: 54px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-56 {
  max-width: 56px;
  min-width: 56px;
  max-height: 56px;
  min-height: 56px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-72 {
  max-width: 72px;
  min-width: 72px;
  max-height: 72px;
  min-height: 72px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-79 {
  max-width: 79px;
  min-width: 79px;
  max-height: 79px;
  min-height: 79px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*~~~~~ Square Sizes ~~~~~*/
.square-14 {
  max-width: 14px;
  min-width: 14px;
  max-height: 14px;
  min-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-38 {
  max-width: 38px;
  min-width: 38px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-52 {
  max-width: 52px;
  min-width: 52px;
  max-height: 52px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-60 {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-70 {
  max-width: 70px;
  min-width: 70px;
  max-height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-72 {
  max-width: 72px;
  min-width: 72px;
  max-height: 72px;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-95 {
  max-width: 95px;
  min-width: 95px;
  max-height: 95px;
  min-height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-92 {
  max-width: 92px;
  min-width: 92px;
  max-height: 92px;
  min-height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-215 {
  max-width: 215px;
  min-width: 215px;
  max-height: 215px;
  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-144 {
  max-width: 144px;
  min-width: 144px;
  max-height: 144px;
  min-height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-100 {
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*~~~~~ responsive width ~~~~~*/
@media (min-width: 480px) {
  .w-xs-25 {
    width: 25% !important;
  }

  .w-xs-50 {
    width: 50% !important;
  }

  .w-xs-75 {
    width: 75% !important;
  }

  .w-xs-100 {
    width: 100% !important;
  }

  .w-xs-auto {
    width: auto !important;
  }

  .w-xs-40 {
    width: 40% !important;
  }

  .w-xs-45 {
    width: 45% !important;
  }

  .w-xs-px-50 {
    width: 50px !important;
  }

  .w-xs-px-33 {
    width: 33px !important;
  }

  .w-xs-px-48 {
    width: 48px !important;
  }

  .w-xs-55 {
    width: 55% !important;
  }

  .w-xs-60 {
    width: 60% !important;
  }

  .w-xs-100vh {
    width: 100vh !important;
  }

  .w-xs-63px {
    width: 63px !important;
  }

  .w-xs-79px {
    width: 79px !important;
  }

  .w-xs-px-144 {
    width: 144px !important;
  }

  .w-xs-180 {
    width: 180px !important;
  }

  .w-xs-px-180 {
    width: 180px !important;
  }
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-px-50 {
    width: 50px !important;
  }

  .w-sm-px-33 {
    width: 33px !important;
  }

  .w-sm-px-48 {
    width: 48px !important;
  }

  .w-sm-55 {
    width: 55% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-100vh {
    width: 100vh !important;
  }

  .w-sm-63px {
    width: 63px !important;
  }

  .w-sm-79px {
    width: 79px !important;
  }

  .w-sm-px-144 {
    width: 144px !important;
  }

  .w-sm-180 {
    width: 180px !important;
  }

  .w-sm-px-180 {
    width: 180px !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-px-50 {
    width: 50px !important;
  }

  .w-md-px-33 {
    width: 33px !important;
  }

  .w-md-px-48 {
    width: 48px !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-100vh {
    width: 100vh !important;
  }

  .w-md-63px {
    width: 63px !important;
  }

  .w-md-79px {
    width: 79px !important;
  }

  .w-md-px-144 {
    width: 144px !important;
  }

  .w-md-180 {
    width: 180px !important;
  }

  .w-md-px-180 {
    width: 180px !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-px-50 {
    width: 50px !important;
  }

  .w-lg-px-33 {
    width: 33px !important;
  }

  .w-lg-px-48 {
    width: 48px !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-100vh {
    width: 100vh !important;
  }

  .w-lg-63px {
    width: 63px !important;
  }

  .w-lg-79px {
    width: 79px !important;
  }

  .w-lg-px-144 {
    width: 144px !important;
  }

  .w-lg-180 {
    width: 180px !important;
  }

  .w-lg-px-180 {
    width: 180px !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-px-50 {
    width: 50px !important;
  }

  .w-xl-px-33 {
    width: 33px !important;
  }

  .w-xl-px-48 {
    width: 48px !important;
  }

  .w-xl-55 {
    width: 55% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-100vh {
    width: 100vh !important;
  }

  .w-xl-63px {
    width: 63px !important;
  }

  .w-xl-79px {
    width: 79px !important;
  }

  .w-xl-px-144 {
    width: 144px !important;
  }

  .w-xl-180 {
    width: 180px !important;
  }

  .w-xl-px-180 {
    width: 180px !important;
  }
}

@media (min-width: 1366px) {
  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-45 {
    width: 45% !important;
  }

  .w-xxl-px-50 {
    width: 50px !important;
  }

  .w-xxl-px-33 {
    width: 33px !important;
  }

  .w-xxl-px-48 {
    width: 48px !important;
  }

  .w-xxl-55 {
    width: 55% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-100vh {
    width: 100vh !important;
  }

  .w-xxl-63px {
    width: 63px !important;
  }

  .w-xxl-79px {
    width: 79px !important;
  }

  .w-xxl-px-144 {
    width: 144px !important;
  }

  .w-xxl-180 {
    width: 180px !important;
  }

  .w-xxl-px-180 {
    width: 180px !important;
  }
}

@media (min-width: 1600px) {
  .w-xxxl-25 {
    width: 25% !important;
  }

  .w-xxxl-50 {
    width: 50% !important;
  }

  .w-xxxl-75 {
    width: 75% !important;
  }

  .w-xxxl-100 {
    width: 100% !important;
  }

  .w-xxxl-auto {
    width: auto !important;
  }

  .w-xxxl-40 {
    width: 40% !important;
  }

  .w-xxxl-45 {
    width: 45% !important;
  }

  .w-xxxl-px-50 {
    width: 50px !important;
  }

  .w-xxxl-px-33 {
    width: 33px !important;
  }

  .w-xxxl-px-48 {
    width: 48px !important;
  }

  .w-xxxl-55 {
    width: 55% !important;
  }

  .w-xxxl-60 {
    width: 60% !important;
  }

  .w-xxxl-100vh {
    width: 100vh !important;
  }

  .w-xxxl-63px {
    width: 63px !important;
  }

  .w-xxxl-79px {
    width: 79px !important;
  }

  .w-xxxl-px-144 {
    width: 144px !important;
  }

  .w-xxxl-180 {
    width: 180px !important;
  }

  .w-xxxl-px-180 {
    width: 180px !important;
  }
}

/* ---------------------------
  02.Utility -> { Borders } 
------------------------------*/
.rounded-xs {
  border-radius: 3px;
}

.rounded-top-xs {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rounded-bottom-xs {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rounded-left-xs {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.rounded-right-xs {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rounded-md {
  border-radius: 0.5rem;
}

.rounded-top-md {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-bottom-md {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-left-md {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-right-md {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 1.25rem;
}

.rounded-top-xl {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.rounded-bottom-xl {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.rounded-left-xl {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}

.rounded-right-xl {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.rounded-0 {
  border-radius: 0px;
}

.rounded-top-0 {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-left-0 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-right-0 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-3 {
  border-radius: 3px;
}

.rounded-top-3 {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rounded-bottom-3 {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rounded-left-3 {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.rounded-right-3 {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-top-5 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rounded-bottom-5 {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rounded-left-5 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-right-5 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-top-6 {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.rounded-bottom-6 {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.rounded-left-6 {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.rounded-right-6 {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-top-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-bottom-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rounded-left-8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-right-8 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rounded-4 {
  border-radius: 10px;
}

.rounded-top-4 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-bottom-4 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rounded-left-4 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-right-4 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.border-width-1 {
  border-width: 1px !important;
}

.border-width-2 {
  border-width: 2px !important;
}

.border-width-3 {
  border-width: 3px !important;
}

/* ---------------------------
  02.Utility -> { Common } 
------------------------------*/
span,
a {
  display: inline-block;
}

.line-height-reset {
  line-height: 1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-9 {
  z-index: 9;
}

.z-index-supper {
  z-index: 800;
}

.z-index-supper-duper {
  z-index: 10000;
}

.z-index-n1 {
  z-index: -1;
}

.z-index-n2 {
  z-index: -2;
}

.z-index-lower {
  z-index: -9999;
}

/* Focus Reset */
.focus-reset:focus {
  box-shadow: none;
  outline: none;
}

.table-y-middle {
  vertical-align: middle !important;
}

/* ---------------------------
  02.Utility -> { Tablet } 
------------------------------*/
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F4F5F8;
}

/* ---------------------------
  02.Utility -> { Typography } 
------------------------------*/
p {
  font-size: 1.125rem;
  color: var(--color-texts);
}

.font-size-1 {
  font-size: 8px;
  line-height: 1;
}

.font-size-2 {
  font-size: 11px;
  line-height: 1;
}

.font-size-3 {
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.26px;
}

.font-size-4 {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
}

.font-size-5 {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
}

.font-size-6 {
  font-size: 1.3125rem;
  line-height: 1.62;
  letter-spacing: -0.21px;
}

@media (max-width: 1200px) {
  .font-size-6 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}

.font-size-7 {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.24px;
}

@media (max-width: 1200px) {
  .font-size-7 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.font-size-8 {
  font-size: 2.125rem;
  line-height: 1.76;
  letter-spacing: -0.34px;
}

@media (max-width: 1200px) {
  .font-size-8 {
    font-size: calc(1.3375rem + 1.05vw);
  }
}

.font-size-9 {
  font-size: 2.5rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
}

@media (max-width: 1200px) {
  .font-size-9 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

.font-size-10 {
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -0.48px;
}

@media (max-width: 1200px) {
  .font-size-10 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

.font-size-11 {
  font-size: 3.75rem;
  line-height: 1.2;
  letter-spacing: -0.9px;
}

@media (max-width: 1200px) {
  .font-size-11 {
    font-size: calc(1.5rem + 3vw);
  }
}

.font-size-12 {
  font-size: 4.375rem;
  line-height: 1.2;
  letter-spacing: -4px;
}

.font-weight-semibold {
  font-weight: 600;
}

.line-height-reset {
  line-height: 1;
}

.font-size-small {
  font-size: 13px;
}

/* ---------------------------
  03.Theme Utility -> { Hovers } 
------------------------------*/
/*~~~~~~~~~~~~~~~~
  All Hover Effects
~~~~~~~~~~~~~~~~~~~~~~~*/
.hover-shadow-1 {
  transition: .4s;
}

.hover-shadow-1:hover {
  box-shadow: 20px 20px 40px rgba(175, 175, 175, 0.16);
}

.hover-shadow-2 {
  transition: .4s;
}

.hover-shadow-2:hover {
  border: 1px solid transparent !important;
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.08);
}

/* used in featured job homepage-2 */
.hover-shadow-3 {
  transition: .4s;
}

.hover-shadow-3:hover {
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.08);
}

.hover-shadow-hitgray {
  transition: .4s;
}

.hover-shadow-hitgray:hover {
  box-shadow: 0 29px 62px rgba(130, 130, 130, 0.16);
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  transition: 0.4s;
}

a.bg-white:hover:hover,
a.bg-white:focus:hover,
button.bg-white:hover:hover,
button.bg-white:focus:hover {
  background-color: #fff !important;
}

a.hover-color-primary {
  transition: .4s color;
}

a.hover-color-primary:hover {
  color: #8B3300 !important;
}

.hover-bg-green,
a.hover-bg-green {
  transition: .4s color;
}

.hover-bg-green:hover,
a.hover-bg-green:hover {
  color: #fff !important;
  background-color: #8B3300 !important;
}

.hover-text-hitgray,
a.hover-text-hitgray {
  transition: .4s color;
}

.hover-text-hitgray:hover,
a.hover-text-hitgray:hover {
  color: #adb4b7 !important;
}

.hover-border-green {
  border: 2px solid transparent;
}

.hover-border-green:hover {
  border: 2px solid #8B3300;
}

.list-hover-primary a {
  transition: .4s color;
}

.list-hover-primary a:hover {
  color: #8B3300 !important;
}

.tab-menu-items a {
  transition: 0.4s;
  border-bottom: 2px solid transparent;
}

.tab-menu-items a:hover,
.tab-menu-items a.active {
  border-bottom: 2px solid #8B3300;
  color: #8B3300;
}

.pagination.page-link.active {
  background: transparent;
  color: #8B3300 !important;
}

.pagination-hover-primary .page-link:hover {
  background: transparent;
  color: #8B3300 !important;
}

/* ---------------------------
  03.Theme Utility -> { Positioning } 
------------------------------*/
@media (min-width: 992px) {
  .position-lg-static {
    position: static;
  }
}

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute !important;
  }
}

.position-lg-absolute {
  position: static;
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

@media (min-width: 768px) {
  .pos-abs-md {
    position: absolute;
  }
}

@media (min-width: 992px) {
  .pos-abs-lg {
    position: absolute !important;
  }
}

@media (min-width: 1200px) {
  .pos-abs-xl {
    position: absolute !important;
  }
}

@media (min-width: 992px) {
  .pos-abs-lg-ly-center {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

@media (min-width: 992px) {
  .pos-abs-lg-ry-center {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.pos-abs-tl {
  position: absolute;
  top: 0;
  left: 0;
}

.pos-abs-tr {
  position: absolute;
  top: 0;
  right: 0;
}

.pos-abs-bl {
  position: absolute;
  bottom: 0;
  left: 0;
}

.pos-abs-br {
  position: absolute;
  bottom: 0;
  right: 0;
}

.pos-abs-cr {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .pos-lg-abs-c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.pos-abs-cl {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (min-width: 480px) {
  .pos-xs-abs-cl {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.pos-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pos-abs-br-custom {
  position: absolute;
  bottom: -50px;
  right: -36px;
}

.pos-abs-bl-custom {
  position: absolute;
  bottom: -50px;
  left: -36px;
  z-index: -1;
}

.pos-abs-bl-custom-2 {
  position: absolute;
  left: 30px;
  bottom: 28px;
}

.pos-abs-tl-custom {
  position: absolute;
  top: -6px;
  left: 45px;
}

.pos-abs-tr-custom {
  position: absolute;
  top: -32px;
  right: -38px;
}

@media (min-width: 992px) {
  .left-lg-p20 {
    left: 20%;
  }
}

.translate-n50 {
  transform: translate(-50%, -50%);
}

.translateX-n50 {
  transform: translateX(-50%);
}

.translateY-50 {
  transform: translateY(50%);
}

.chart-postion {
  top: 14px;
  left: 3px;
}

/* ---------------------------
  03.Theme Utility -> { Sizes } 
------------------------------*/
/* Theme Size Utilities */
.min-h-100vh {
  min-height: 100vh;
}

.max-width-130px {
  max-width: 130px;
}

.max-height-px-18 {
  max-height: 18px;
}

.min-height-32 {
  min-height: 32px;
}

.min-width-px-70 {
  min-width: 70px;
}

.min-width-px-80 {
  min-width: 80px;
}

.min-width-px-96 {
  min-width: 96px;
}

.min-width-px-100 {
  min-width: 100px;
}

.min-width-px-110 {
  min-width: 110px;
}

.min-width-px-125 {
  min-width: 125px;
}

.min-width-px-135 {
  min-width: 135px;
}

.min-width-px-155 {
  min-width: 155px;
}

.min-width-px-170 {
  min-width: 170px;
}

.min-width-px-185 {
  min-width: 185px;
}

.min-width-px-205 {
  min-width: 205px;
}

.min-width-px-235 {
  min-width: 235px;
}

.min-width-px-210 {
  min-width: 210px;
}

.min-width-px-273 {
  min-width: 273px;
}

.max-width-px-311 {
  max-width: 311px;
}

.max-width-px-840 {
  max-width: 840px;
}

@media (min-width: 480px) {
  .scale-xs-1 {
    transform: scale(1) !important;
  }
}

.scale-p7 {
  transform: scale(0.7);
}

.h-1173 {
  height: 1173px;
}

.h-1413 {
  height: 1413px;
}

.min-height-px-18 {
  min-height: 18px;
}

@media (min-width: 1366px) {
  .pb-custom-300 {
    padding-bottom: 300px !important;
  }
}

@media (min-width: 1600px) {
  .pb-custom-300 {
    padding-bottom: 130px !important;
  }
}

/* --------------------------------
  03.Theme Utility -> { Typograpy } 
-----------------------------------*/
.letter-spacing-np09 {
  letter-spacing: -0.09px;
}

.line-height-1p6 {
  line-height: 1.6;
}

.line-height-1p4 {
  line-height: 1.4;
}

/* ---------------------------------
  03.Theme Utility -> { Gradient } 
------------------------------------*/
.bg-gradient-1 {
  background-image: linear-gradient(to right, rgba(0, 176, 116, 0.1) 0%, rgba(88, 88, 0, 0.05) 100%);
}

.bg-gradient-2 {
  background-image: linear-gradient(180deg, #ffffff 0%, #f4f5f8 100%);
}

/* 
---------------------------------
-------> THEME CUSTOM STYLES 
---------------------------------
*/
.btn-h-60 {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-responsive {
  padding-left: 1px;
  padding-right: 1px;
}

/* ----------------------------
  Hero Form 1
------------------------------*/
.filter-search-form-1 .nice-select .current {
  width: calc(100% - 35px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.filter-search-form-1 .nice-select .list {
  min-width: 290px;
}

/* ----------------------------
  Search list 1
------------------------------*/
.filter-search-form-2.search-1-adjustment .nice-select .current,
.search-filter.search-1-adjustment .nice-select .current {
  width: calc(100% - 35px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.filter-search-form-2.search-1-adjustment .nice-select .list,
.search-filter.search-1-adjustment .nice-select .list {
  min-width: 290px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-columns {
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 1200px) and (max-width: 1365.98px) {
  .card-columns {
    -moz-column-count: 5;
    column-count: 5;
  }
}

.testimonial-slider-one {
  position: relative;
}

.testimonial-slider-one .slick-dots {
  position: absolute;
  left: 0;
  bottom: -68px;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.testimonial-slider-one .slick-dots li {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.13);
  margin: 3px;
  border-radius: 50%;
  transition: 0.4s;
}

.testimonial-slider-one .slick-dots li.slick-active {
  background-color: #EA8A00;
}

.testimonial-slider-one .slick-dots li button {
  display: none;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Hero Area Custom Styles
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media (min-width: 992px) {
  .hero-image-positioning-2 {
    transform: translateX(40%) scale(0.7);
  }
}

@media (min-width: 1200px) {
  .hero-image-positioning-2 {
    transform: translateX(50%) scale(1);
  }
}

.hero-image-positioning-2 .single-image {
  overflow: hidden;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .hero-image-positioning-2 .single-image {
    max-height: 700px;
  }
}

.hero-image-positioning-2 .single-image img {
  width: 100%;
}

@media (min-width: 992px) {
  .hero-image-positioning-2 .single-image img {
    width: auto;
    height: 100%;
  }
}

.rotate-n10 {
  transform: rotate(-10deg);
}

.border-10 {
  border-style: solid;
  border-width: 10px;
}

.border-hit-gray-opacity-5 {
  border-color: rgba(173, 180, 183, 0.5);
}

.recomended-pricing {
  position: relative;
}

.recomended-pricing:before {
  position: absolute;
  content: "";
  left: 2px;
  top: 0px;
  width: 99%;
  height: 5px;
  background: #EA8A00;
  border-radius: 0px 0px 10px 10px;
}

/* Featured Card 3 title exerpt */
.f03-title-exerpt {
  white-space: nowrap;
  width: 242px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Gallery Image */
.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery .single-item {
  max-width: 20%;
  width: 20%;
}

.gallery .single-item img {
  width: 100%;
}

/* OR devider */
.or-devider {
  text-align: center;
  position: relative;
  z-index: 1;
}

.or-devider:before {
  content: "";
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0%;
  margin-top: 1.5px;
  position: absolute;
  background: var(--border-color);
  z-index: -1;
}

.or-devider span {
  display: inline-block;
  background: white;
  padding: 15px 25px;
}

/*/------ Dashboard Adjustment Styles ------/*/
@media (min-width: 768px) {
  .dashboard-main-container {
    padding-left: 290px;
  }
}

@media (min-width: 1600px) {
  .dashboard-main-container {
    max-width: calc(100% - 290px);
    margin-left: auto;
    padding-left: 75px;
    padding-right: 75px;
  }
}

.checkbox-bg-primary input[type='checkbox']+label:before {
  background-color: red;
}

.search-filter .nice-select .list {
  width: auto;
}

.slider-price input {
  background: transparent;
  border: transparent;
  color: #1a1f2b;
  text-align: left;
  word-spacing: 0;
}

.ui-slider-horizontal {
  margin-top: 5px;
  height: 5px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: none;
  background: #fff;
  font-weight: normal;
  color: #454545;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  outline: none;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -10px;
}

.ui-widget.ui-widget-content {
  border: none;
}

.pm-range-slider {
  min-width: 290px;
  max-width: 290px;
  background: #D2DCD6;
}

.pm-range-slider .ui-widget-header {
  background: #8B3300;
  top: 0;
  height: 100%;
}

.graph {
  min-width: 290px;
  max-width: 290px;
  left: 0;
  top: 14px;
}

@media (min-width: 992px) {
  .graph {
    top: 12px;
  }
}

@media (min-width: 1200px) {
  .graph {
    top: 16px;
  }
}

.graph span {
  width: 16px;
  height: 60px;
  background-color: #D2DCD6;
}

.graph span:nth-child(1) {
  height: 10px;
}

.graph span:nth-child(2) {
  height: 14px;
}

.graph span:nth-child(3) {
  height: 14px;
}

.graph span:nth-child(4) {
  height: 19px;
}

.graph span:nth-child(5) {
  height: 27px;
}

.graph span:nth-child(6) {
  height: 41px;
}

.graph span:nth-child(7) {
  height: 50px;
}

.graph span:nth-child(8) {
  height: 37px;
}

.graph span:nth-child(9) {
  height: 60px;
}

.graph span:nth-child(10) {
  height: 27px;
}

.graph span:nth-child(11) {
  height: 19px;
}

.graph span:nth-child(12) {
  height: 27px;
}

.graph span:nth-child(13) {
  height: 14px;
}

.overflow-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e5e5e5;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 46px;
}

.active-item-black {
  color: #8B3300;
}

.result-view-type a {
  color: #adb4b7;
}

.result-view-type a.active {
  color: #2b3940;
}

@media (min-width: 576px) {

  .container,
  .container-xs,
  .container-sm {
    max-width: 540px;
  }
}

@media (max-width: 1200px) {

  h1,
  .h1 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

@media (max-width: 1200px) {

  h2,
  .h2 {
    font-size: calc(1.375rem + 1.5vw);
  }
}



@media (max-width: 1200px) {

  h3,
  .h3 {
    font-size: calc(1.3375rem + 1.05vw);
  }
}

@media (max-width: 1200px) {

  h5,
  .h5 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.625rem + 4.5vw);
  }
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.5rem + 3vw);
  }
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.4375rem + 2.25vw);
  }
}

@media (max-width: 1200px) {

  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

@media (min-width: 768px) {

  .container,
  .container-xs,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1366px) {

  .container,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1285px;
  }
}

@media (min-width: 480px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xs-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xs-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xs-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xs-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xs-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xs-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xs-first {
    order: -1;
  }

  .order-xs-last {
    order: 13;
  }

  .order-xs-0 {
    order: 0;
  }

  .order-xs-1 {
    order: 1;
  }

  .order-xs-2 {
    order: 2;
  }

  .order-xs-3 {
    order: 3;
  }

  .order-xs-4 {
    order: 4;
  }

  .order-xs-5 {
    order: 5;
  }

  .order-xs-6 {
    order: 6;
  }

  .order-xs-7 {
    order: 7;
  }

  .order-xs-8 {
    order: 8;
  }

  .order-xs-9 {
    order: 9;
  }

  .order-xs-10 {
    order: 10;
  }

  .order-xs-11 {
    order: 11;
  }

  .order-xs-12 {
    order: 12;
  }

  .offset-xs-0 {
    margin-left: 0;
  }

  .offset-xs-1 {
    margin-left: 8.33333%;
  }

  .offset-xs-2 {
    margin-left: 16.66667%;
  }

  .offset-xs-3 {
    margin-left: 25%;
  }

  .offset-xs-4 {
    margin-left: 33.33333%;
  }

  .offset-xs-5 {
    margin-left: 41.66667%;
  }

  .offset-xs-6 {
    margin-left: 50%;
  }

  .offset-xs-7 {
    margin-left: 58.33333%;
  }

  .offset-xs-8 {
    margin-left: 66.66667%;
  }

  .offset-xs-9 {
    margin-left: 75%;
  }

  .offset-xs-10 {
    margin-left: 83.33333%;
  }

  .offset-xs-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1366px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1600px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxxl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxxl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxxl-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xxxl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxxl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxxl-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxxl-first {
    order: -1;
  }

  .order-xxxl-last {
    order: 13;
  }

  .order-xxxl-0 {
    order: 0;
  }

  .order-xxxl-1 {
    order: 1;
  }

  .order-xxxl-2 {
    order: 2;
  }

  .order-xxxl-3 {
    order: 3;
  }

  .order-xxxl-4 {
    order: 4;
  }

  .order-xxxl-5 {
    order: 5;
  }

  .order-xxxl-6 {
    order: 6;
  }

  .order-xxxl-7 {
    order: 7;
  }

  .order-xxxl-8 {
    order: 8;
  }

  .order-xxxl-9 {
    order: 9;
  }

  .order-xxxl-10 {
    order: 10;
  }

  .order-xxxl-11 {
    order: 11;
  }

  .order-xxxl-12 {
    order: 12;
  }

  .offset-xxxl-0 {
    margin-left: 0;
  }

  .offset-xxxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxxl-3 {
    margin-left: 25%;
  }

  .offset-xxxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxxl-6 {
    margin-left: 50%;
  }

  .offset-xxxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxxl-9 {
    margin-left: 75%;
  }

  .offset-xxxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxxl-11 {
    margin-left: 91.66667%;
  }
}




/*# sourceMappingURL=maps/bootstrap.css.map */
